import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import moment from "services/locale/momentInit.js";

import SiteView from "components/Site/SiteView/";

import { loadSiteViewLiveRefreshCancel } from "services/redux/actions";

import {
  getScheduledEventsFilteredInTimeRange,
  intervalFilter,
  eventCountFilter,
  getScheduledFilter,
  createGetEventMeta,
  getScheduledReportExpanded,
  // getActualFromOccurrence,
  getActualWithTimezone,
  getDiffFromOccurrence,
  getExpanded,
  getExpandedHourly,
  getExpandedRowsCount
} from "services/redux/selectors/reports/scheduled";
import {
  getColumns,
  getConfig,
  getFiltered
} from "services/redux/selectors/reports/common/";
import { getReportType } from "services/redux/selectors/reports/router";

import { getSelectedChartSiteId } from "services/redux/selectors/reports/common/hourly";

import {
  resetExpanded,
  changeDataTableFiltered,
  changeGroupTimeframe,
  setScheduledReportHourlySelectedChartSite,
  setScheduledReportUserTimezoneUTCOffset,
} from "services/redux/actions/";

import { getGroups } from "services/redux/selectors";

import Toolbar from "./Toolbar";
import TrGroupComponent from "./TrGroupComponent";
import TrGroupComponentHourly from "./TrGroupComponentHourly";

import {
  CustomIdColumn,
  NameColumn,
  GroupColumn,
  SiteRefColumn,
  TypeColumn,
  ResultColumn,
  ExpectedColumn,
  ActualColumn,
  DifferenceColumn,
  EventTextColumn,
  OccurrencesColumn,
  ExpandColumn,
  // 08/21 columns for hourly report
  PeriodColumn,
  CountColumn,
  SelectChartColumn
  // /08/21
} from "./Columns";

class DataTable extends React.Component {
  state = {
    selectedSite: null,
    selectedChartSiteId: this.props.selectedChartSiteId,
    // selectedChartSiteId: getSelectedChartSiteId(state, props)(state, props),
    bundle_id: (this.props.reportType !== "peoplecount" && this.props.reportType !== "peoplecounthourly") ? 2 : null
  };

  componentDidMount = () => {
    const timezoneUTCOffset = (new Date()).getTimezoneOffset();
    const timezoneUTCOffsetHours = Math.floor((Math.abs(timezoneUTCOffset)/60));
    const timezoneUTCOffsetMinutes = Math.abs(timezoneUTCOffset) % 60;
    const timezoneUTCOffsetSign = (timezoneUTCOffset >= 0) ? 1 : -1;

    this.props.dispatch(
      setScheduledReportUserTimezoneUTCOffset({
        reportType: this.props.reportType,
        userTimezoneUTCOffset: {
          hours: timezoneUTCOffsetHours,
          minutes: timezoneUTCOffsetMinutes,
          sign: timezoneUTCOffsetSign,
        }
      })
    );
  }

  onSelectSite = site => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      const acked = new moment(site.occurrences[0].acked);

      // Timeframe is from 6 hours before the time of the event until now
      let timeframe = new moment().diff(acked, "hours") + 6;

      // Limit timeframe to maximum of 4 weeks
      if (timeframe > 672) timeframe = 672;

      this.props.dispatch(
        changeGroupTimeframe({
          timeframe
        })
      );

      this.setState({
        selectedSite: site,
        bundle_id: (this.props.reportType !== "peoplecount" && this.props.reportType !== "peoplecounthourly") ? 2 : null
      });

      // Horizontally scroll type column into view (doesn't work very well)
      // document
      //   .querySelector(".header-type")
      //   .scrollIntoView({ behavior: "smooth", inline: "start" });

      // Only set bundle id if selecting for the first time
      // const bundle_id =
      //   selectedSite === null ? this.props.router.params.bundle_id || "" : "";

      // this.props.history.push(
      //   `/enterprise/${enterprise_id}/${group_id}/${site.id}/${bundle_id}`
      // );
    } else {
      document.body.classList.remove("sidepane-active");

      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        // this.props.history.push(`/enterprise/${enterprise_id}/${group_id}`);

        this.setState({
          selectedSite: null
        });
      }, 600);
    }
  };
  onFilteredChangeCustom = (value, accessor) => {
    let filtered = [...this.props.filtered];
    let insertNewFilter = 1;
    let shouldResetExpanded = false;

    // Filter already exists
    if (filtered.length) {
      filtered = filtered
        .map((filter, i) => {
          if (filter["id"] === accessor) {
            insertNewFilter = 0;

            // Filter text value is empty
            if (value === "" || !value.length) {
              // filtered = filtered.slice(i, 1);
              // To be removed (see remove falsey below)
              return false;
            } else {
              return {
                ...filter,
                value
              };
              // filter["value"] = value;
            }
          }
          return filter;
        })
        // Remove falsey values
        .filter(falsey => falsey);
      // Filter existed but will be removed - reset
      //  expanded
      if (filtered.length === 0) {
        shouldResetExpanded = true;
      }
    }

    // Filter doesn't exist - add
    if (insertNewFilter) {
      // filtered.push({ id: accessor, value: value });
      filtered = [...filtered, { id: accessor, value: value }];
      // Introducing a new filter - reset expanded
      shouldResetExpanded = true;
    }

    if (shouldResetExpanded) {
      this.props.dispatch(
        resetExpanded({
          reportType: this.props.reportType
        })
      );
    }

    this.props.dispatch(
      changeDataTableFiltered({
        filtered,
        reportType: this.props.reportType
      })
    );
  };
  // 08/21 handler for selecting a site to display individual hourly data
  // added in support of new people counting hourly report
  onClickChartSite = site => {
    const { selectedChartSiteId } = this.state;

    // New site selected
    if (site) {
      if (site.id !== selectedChartSiteId) {
        // $(document.querySelector(".page-content-wrapper")).animate({ scrollTop: 0 }, "slow");
        document.querySelector(".page-content-wrapper").scrollTop = 0;
        this.props.dispatch(
          setScheduledReportHourlySelectedChartSite({
            reportType: this.props.reportType,
            id: site.id
          })
        );
        this.setState({
          selectedChartSiteId: site.id,
        });
      } else {
        this.props.dispatch(
          setScheduledReportHourlySelectedChartSite({
            reportType: this.props.reportType,
            id: null
          })
        );
        this.setState({
          selectedChartSiteId: null,
        });
      }
    } 
  }
  render() {
    let {
      sites,
      filter,
      reportType,
      columns,
      config,
      groups,
      getEventMeta,
      expanded,
      filtered,
      getReportTitle,
      dispatch,
      changeFilter
    } = this.props;

    const { selectedSite, selectedChartSiteId } = this.state;

    if (sites.length === 0) return null;

    // Apply more filtering just for the DataTable
    sites = [
      ...sites.filter(
        site =>
          intervalFilter(site, filter.interval) &&
          eventCountFilter(site, filter.eventCount, config.charts.pie.filter, reportType)
      )
    ];

    const expandedRowsCount = getExpandedRowsCount(expanded);

    // const defaultPageSize = sites.length > 20 ? 20 : 10;
    const defaultPageSize = 20;

    const eventTextFilter = filtered.find(({ id }) => id === "result");

    return (
      <>
        <Toolbar
          reportTitle={getReportTitle()}
          reportType={reportType} 
          sites={sites}
          dataResolver={() => {
            const data = this.reactTable.getResolvedState().sortedData;

            return columns.buildExportArray({
              data,
              getEventMeta: getEventMeta,
              // getActualFromOccurrence,
              getActualWithTimezone,
              getDiffFromOccurrence,
            });
          }}
          dataResolverFull={() => {
            const data = this.reactTable.getResolvedState().sortedData;

            return columns.buildExportArrayFull({
              data,
              getEventMeta: getEventMeta,
              // getActualFromOccurrence,
              getActualWithTimezone,
              getDiffFromOccurrence,
              sites,
            });
          }}
        />
        <ReactTable
          ref={r => (this.reactTable = r)}
          data={sites}
          className={`${expandedRowsCount > 0 ? "focus-expanded" : ""} ${
            eventTextFilter ? " event-text-filter-active" : ""
          }`}
          filterable
          filtered={this.props.filtered}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
          }}
          defaultPageSize={defaultPageSize}
          // Sort by "Actual" time header by default
          defaultSorted={
            reportType === "peoplecounthourly" // 08/21
              ? [{
                  id: "site-name"
                }]
              : reportType === "overnight" 
                  ? [{
                      id: "occurrences",
                      desc: true
                    }]
                  : [{
                      id: "actual-time",
                      desc: true
                    }]
          }
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          TrGroupComponent={reportType === "peoplecounthourly" ? TrGroupComponentHourly : TrGroupComponent} // 08/21
          // Override row group props for sub rows
          getTrGroupProps={(state, rowInfo, column) => {
            const props = {
              filtered,
              getEventMeta,
              eventTextFilter,
              columns,
              groups
            };
            if (rowInfo !== undefined) {
              return {
                ...props,
                expanded: reportType === "peoplecounthourly" 
                  ? getExpandedHourly(rowInfo.original)
                    ? 1
                    : 0
                  : getExpanded(rowInfo.original, eventTextFilter)
                    ? 1
                    : 0,
                site: rowInfo.original
              };
            }
            return {
              ...props,
              expanded: 0,
              className: ""
            };
          }}
          columns={[
            CustomIdColumn({ columns }),
            NameColumn({ columns, onSelectSite: this.onSelectSite }),
            GroupColumn({ columns, groups }),
            SiteRefColumn({ columns }),
            TypeColumn({ columns }),
            ResultColumn({
              columns,
              eventTextFilter,
              getEventMeta,
              config,
              sites,
              changeFilter
            }),
            ExpectedColumn({ columns, eventTextFilter, getEventMeta }),
            ActualColumn({ columns, eventTextFilter, getEventMeta }),
            DifferenceColumn({ columns }),
            EventTextColumn({ columns, eventTextFilter, getEventMeta }),
            // 08/21 Add hourly-related report columns
            PeriodColumn({ columns }),
            CountColumn({ columns }),
            SelectChartColumn({ columns, onClickChartSite: this.onClickChartSite, selectedChartSiteId: selectedChartSiteId }),
            // /08/21
            OccurrencesColumn({ columns }),
            ExpandColumn({ columns, eventTextFilter, reportType, dispatch })
          ]}
          // className=" -highlight"
        />
        <SiteView
          site={selectedSite}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={bundle_id => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
        />
      </>
    );
  }
}
DataTable.propTypes = {
  // ALARM_TYPES: PropTypes.any.isRequired,
  // intervalFilter: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
  sites: PropTypes.any.isRequired,
  changeFilter: PropTypes.any.isRequired,
  getReportTitle: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    sites: getScheduledEventsFilteredInTimeRange(state, props),
    // ALARM_TYPES: getAlarmTypes(state, props),
    filter: getScheduledFilter(state, props),
    getEventMeta: occurrence => createGetEventMeta(state, props)(occurrence),
    columns: getColumns(state, props),
    reportType: getReportType(state, props),
    expanded: getScheduledReportExpanded(state, props),
    groups: getGroups(state),
    config: getConfig(state, props),
    filtered: getFiltered(state, props),
    selectedChartSiteId: getSelectedChartSiteId(state, props)(state, props)
  };
};
export default connect(mapStateToProps)(DataTable);
