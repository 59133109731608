const initialState = {
  data: []
};

const all = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_SITES_BACKGROUND_START":
      return { ...state, loading: true };
    case "LOAD_SITES_BACKGROUND_SUCCESS":
      return {
        ...state,
        loading: false,
        meta: action.meta,
        // Add to the current data
        data: {
          ...state.data,
          // cacheSubKey is enterprise_id
          [action.cacheSubKey]: action.data
        }
      };
    case "LOAD_SITES_BACKGROUND_RESTORE":
      // TODO: how can we prevent a disk restore if we know there has been no change to the data?
      //  solution is to use memoize in the saga
      // if (_.get(action, "unchanged")) {
      //   return { ...state, loading: false };

      // }
      return {
        ...state,
        loading: false,
        meta: action.meta,
        // Add to the current data
        data: {
          ...state.data,
          // cacheSubKey is enterprise_id
          [action.cacheSubKey]: action.data
        }
      };

    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    default:
      return state;
  }
};

export default all;