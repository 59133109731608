/**
 * Tether oauth connect button
 */

import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

import { withT } from "services/translation/";

import { TETHER_AUTHORISE_URI } from "./config";

function TetherConnect({ site_id, integration_id = 2, active_on_site, t }) {
  // Since tether will only redirect to non dynamic URLs
  // we have to serialise some of our state here
  const tetherConnect = {
    site_id,
    integration_id,
    originalPathname: window.location.pathname
  };

  // Reset tether oauth session storage if it exists
  if (sessionStorage.getItem("tetherConnect") !== null) {
    sessionStorage.removeItem("tetherConnect");
  }

  if (active_on_site) {
    return <Button disabled>{t("connected")}</Button>;
  }

  return (
    // <a href={}>
    <Button
      variant="outline-primary"
      onClick={() => {
        sessionStorage.setItem("tetherConnect", JSON.stringify(tetherConnect));
        // Redirect to tether oauth
        window.location = TETHER_AUTHORISE_URI;
      }}>
      {t("connect_to_tether")}
    </Button>
    // </a>
  );
}

const mapStateToProps = state => {
  return {
    site_id: state.app.router.params.site_id
  };
};
export default connect(mapStateToProps)(
  withT(
    TetherConnect,
    "site_overview.side_pane.manage_integrations.providers.tether"
  )
);
