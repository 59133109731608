import _ from "lodash";

const initialState = {
  loading: null,
  data: []
};

const eventMetaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_EVENT_META_BACKGROUND_START":
      return { ...state, loading: true };
    case "LOAD_EVENT_META_BACKGROUND_SUCCESS":
      return { ...state, loading: false, data: action.data };
    case "LOAD_EVENT_META_BACKGROUND_RESTORE":
      if (_.get(action, "unchanged")) {
        return { ...state, loading: false };
      }
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};

export default eventMetaReducer;