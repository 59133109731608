import React from "react";

import {
  getAcked,
  formatTimeDifference,
  getDiffFromOccurrence
} from "services/redux/selectors/reports/scheduled";

import get from "lodash/get";

import moment from "services/locale/momentInit.js";

export function DifferenceColumn({ columns }) {
  const column = columns["DIFFERENCE"];

  return {
    ...column,
    filterMethod: (filter, row) => {
      const event = get(row[filter.id], "0");
      const rowText = formatTimeDifference(event);
      return rowText.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
    },
    sortMethod: (a, b) => {
      // Calculate value of time difference
      const getDiff = value => {
        const occurrence = get(value, "0");
        const acked = moment(getAcked(occurrence));
        const ideal = acked
          .clone()
          .subtract(get(value, "0.diff_from_ideal"), "minutes");
        return acked.diff(ideal);
      };
      //console.log(getDiff(a) + ">" + getDiff(b));
      const diffA = getDiff(a);
      const diffB = getDiff(b);
      if (diffA === diffB) return 0;
      return diffA > diffB ? 1 : -1;
    },
    Cell: row => <span>{getDiffFromOccurrence(get(row.value, "0"))}</span>
  };
}
