import { all } from "redux-saga/effects";

import * as reports from "./reports/";
import * as enterprises from "./enterprises/";
import * as sites from "./sites/";
import * as eventMeta from "./eventMeta/";
import * as app from "./app/";
import * as user from "./user/";
import * as groups from "./groups/";
import * as users from "./users/";
import * as notifications from "./notifications/";
import * as enterpriseManagerSagas from "./enterpriseManager/";
import * as alarmResponse from "./alarmResponse/";
import * as voiceCalls from "./voiceCalls/";
import * as customReports from "./customReports/";
import * as companies from "./companies/";
import * as company from "./company/";
import * as sop from "./sop/";
import * as sopReports from "./sopReports/";
import * as developers from "./developers/";
import * as eventPairs from "./eventPairs/";
import * as serviceDesk from "./serviceDesk/";

export default function* rootSaga() {
  yield all([
    reports.rootSaga(),
    enterprises.rootSaga(),
    sites.rootSaga(),
    eventMeta.rootSaga(),
    app.rootSaga(),
    groups.rootSaga(),
    user.rootSaga(),
    users.rootSaga(),
    notifications.rootSaga(),
    enterpriseManagerSagas.rootSaga(),
    alarmResponse.rootSaga(), //alarm-response
    voiceCalls.rootSaga(),
    customReports.rootSaga(),
    companies.rootSaga(),
    company.rootSaga(),
    serviceDesk.rootSaga(),
    sop.rootSaga(),
    sopReports.rootSaga(),
    developers.rootSaga(),
    eventPairs.rootSaga(),
  ]);
}
