import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Form } from "react-bootstrap";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getEventPairListStart, getEventPairMapsStart } from "services/redux/actions/enterpriseManager";
import { getEventPairOptions } from "services/redux/selectors/enterpriseManager";

import DropdownTreeSelectCustom from "components/Common/DropdownTreeSelect/DropdownTreeSelectCustom";

import Alert from "components/Common/Alert";

import conxtdOut from "apis/conxtdOut";

class AddEventPairModal extends React.Component {
  state = {
    selectedEventPairOptions: null,
    eventPairOptions: [],
    saving: false,
  }

  componentDidUpdate(prevProps) {
    const { show, enterprise, eventPairOptions } = this.props;

    if ((!prevProps.show) && show) { // 'show' changing from false to true
      this.props.dispatch(
        getEventPairListStart({
          enterprise_id: enterprise.id,
        })
      );

      this.setState({
        eventPairOptions: [ ...eventPairOptions ],
        selectedEventPairOptions: null,
      })
    }

    if (eventPairOptions !== prevProps.eventPairOptions) {
      this.setState({ eventPairOptions: [ ...eventPairOptions ] });
    }
  }

  render() {
    const { enterprise, show, onHide } = this.props;
    const { eventPairOptions, selectedEventPairOptions, saving } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-md"
        centered
      >
        <Form onSubmit={(e) => {
          e.preventDefault();

          this.setState({ saving: true });

          const selectedEventsArray = selectedEventPairOptions.map((option) => {
            return option.value;
          })

          const addEventPair = conxtdOut.post(`/sop/event-pair-maps/add/${enterprise.id}`, selectedEventsArray);
      
          addEventPair.then(() => {
            this.props.dispatch(
              getEventPairMapsStart({
                enterprise_id: enterprise.id,
              })
            );

            this.setState({ saving: false });

            onHide();

          }).catch((error) => {
            this.setState({ saving: false });

            console.log("error", error.message);
            Alert({
              text: "Add events failed, please try again later.",
              icon: "error",
              timerProgressBar: true,
              timer: 5000,
            });
          });
        }}>

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Events
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "height": "500px"
            }}
          >
            <Form.Label>Select Events</Form.Label>
            <div className="d-flex with-clear-all">
              <DropdownTreeSelectCustom
                options={eventPairOptions}
                onChange={(selectedNodes) => {
                  this.setState({ selectedEventPairOptions: selectedNodes ? selectedNodes : null });
                }}
                clearable
                onClearAll={() => {
                  this.setState({ selectedEventPairOptions: null });
                }}
                mode="multiSelect"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2 p-2"
                  variant="primary"
                  onClick={() => {
                    this.setState({
                      selectedEventPairOptions: null,
                    });
                    onHide();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="p-2"
                  variant="success"
                  type="submit"
                  disabled={saving || !selectedEventPairOptions}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    eventPairOptions: getEventPairOptions(state),
  };
};

export default connect(mapStateToProps)(AddEventPairModal);