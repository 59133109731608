import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import moment from "services/locale/momentInit.js";

import ReactTable from "react-table";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import EventCell from "components/Common/DataTable/EventCell/";

import { sortNumber } from "services/redux/selectors/reports/scheduled";

import { contains } from "services/filters/";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import Loading from "components/Loading";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import { getActualWithTimezone } from "services/redux/selectors/customReports";


class DataTable extends React.Component {
    render() {
        // const { id, data, loading, getEventMetaById, t } = this.props;
        const { data, loading, getEventMetaById, t, site } = this.props;

        const columns = [];

        if (loading !== false) {
            return (<Loading center className="mt-2 mb-2" />)
        }
        // date column
        columns.push({
            width: 100,
            Header: t("headers.date"),
            accessor: ({ acked }) => {
                const localDate = new moment(acked);
                if (site.timezone) {
                    const timezoneDate = new moment.utc(localDate).tz(site.timezone);
                    return timezoneDate.format("DD/MM/YYYY");
                } else {
                    return null
                }
            },
            className: "acked-date",
            timezone: site.timezone,
            id: "acked-date",
        });
        // time column
        columns.push({
            width: 80,
            Header: t("headers.time"),
            accessor: ({acked}) => {
                const occurrence = {
                    acked: acked,
                    timezone: site.timezone,
                    format: "HH:mm",
                  }
                  return occurrence;
            },
            Cell: ({ row }) => {
                return <ActualTimezoneDate occurrence={row["acked-time"]} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
            },
            sortMethod: (occurrenceA, occurrenceB) => {
                let ackedA;
                let ackedB;
                
                if (occurrenceA.timezone) {
                  const localDate = new moment(occurrenceA.acked);
                  ackedA = new moment.utc(localDate).tz(occurrenceA.timezone);
                } else {
                  ackedA = occurrenceA.acked;
                }
      
                if (occurrenceB.timezone) {
                  const localDate = new moment(occurrenceB.acked);
                  ackedB = new moment.utc(localDate).tz(occurrenceB.timezone);
                } else {
                  ackedB = occurrenceB.acked;
                }
      
                const a = moment(ackedA).format("YYYYMMDDHHmmss");
                const b = moment(ackedB).format("YYYYMMDDHHmmss");
                return sortNumber(a, b);
              },
            filterMethod: (filter, row) => {
                return (
                  String(getActualWithTimezone(row["acked-time"])) //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
                );
              },
            className: "acked-time",
            id: "acked-time",
        });
        // event column
        columns.push({
            width: 160,
            Header: t("headers.event"),
            className: "alarm-type",
            accessor: ({ conxtd_alarm_id }) => {
                const event = getEventMetaById(conxtd_alarm_id);

                return event.caption;
            },
            Cell: ({ original }) => {
                const event = getEventMetaById(original.conxtd_alarm_id);

                return <EventCell event={event} focus={true} onClick={() => { }} />;
            },
            id: "event",
        });
        // detail column
        columns.push({
            Header: t("headers.detail"),
            accessor: "text",
            id: "detail",
        });
        columns.push({
            Header: t("headers.zone"),
            accessor: "event_field.zone",
            id: "zone",
            width: 60,
        });
        columns.push({
            Header: t("headers.area"),
            accessor: "event_field.area",
            id: "area",
            width: 55,
        });
        columns.push({
            Header: t("headres.loop"),
            accessor: "event_field.loop",
            id: "loop",
            width: 55,
        });
        columns.push({
            Header: t("headers.panel"),
            accessor: "event_field.panel",
            id: "panel",
            width: 55,
        });
        columns.push({
            Header: t("headers.point"),
            accessor: "event_field.point",
            id: "point",
            width: 55,
        });

        const Table = (
            <div className="card visual-data">
                <ReactTable
                    ref={(r) => (this.reactTable = r)}
                    filterable
                    defaultPageSize={10}
                    defaultFilterMethod={(filter, row) => {
                        return contains({
                            filter: filter.value,
                            value: String(row[filter.id]),
                            ignoreCase: true,
                        });
                    }}
                    data={data}
                    columns={columns}
                    loadingText=""
                />
            </div>
        );

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <ExportDropdown
                            size="sm"
                            className="mb-2"
                            disabled={data.length === 0}
                            reportTitle="Engineers Mode Session Events"
                            dataResolver={() => {
                                const data = this.reactTable.getResolvedState().sortedData;

                                let result = data.map((row) => {
                                    return [
                                        row["acked-date"] || "",
                                        getActualWithTimezone(row["acked-time"]),
                                        row["event"] || "",
                                        row["detail"] || "",
                                        // row["address"],
                                        // row["postcode"],
                                        // row["transmitter-ref"]
                                    ];
                                });
                                result.unshift([
                                    t("headers.date"),
                                    t("headers.time"),
                                    t("headers.event"),
                                    t("headers.detail"),
                                    // "Address",
                                    // "Postcode",
                                    // "Transmitter Ref"
                                ]);

                                return result;
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {Table}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        getEventMetaById: createGetEventMetaById(state, props),
    };
};

export default withT(
    connect(mapStateToProps)(DataTable),
    "site_overview.side_pane.mode_view.data_table"
);