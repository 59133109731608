import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withT } from "services/translation/";

import { Row, Col } from "react-bootstrap";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import UserSearch from "./UserSearch";
import InviteForm from "./InviteForm/";

import Icon from "components/Icons/Icon";

/**
 * User Management UserInvite
 *
 * A search form for searching for and inviting users
 * to a specific group
 *
 * 1. Search for user
 *
 * 2. Either
 * - User already invited to this group
 * - User exists, show invite form with existing user details, with
 *    role select and feature select
 * - User doesn't exist on system, allow set up of profile
 *
 *
 */
class UserInvite extends React.Component {
  render() {
    const { users, t } = this.props;

    const showInviteForm = !!users.actions.invite.user;

    const form = showInviteForm ? <InviteForm /> : <UserSearch />;

    return (
      <>
        <Row>
          <Col>
            <h4 className="mt-0 pb-2 header-title">
              <Icon className="fas fa-user-plus pr-1" />
              {t("title")}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>{form}</Col>
        </Row>
      </>
    );
  }
}
UserInvite.propTypes = {
  group: PropTypes.object.isRequired,
  enterprise: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(UserInvite),
  "user_management.user_invite"
);
