import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InlineChartWidget from "components/Enterprise/Reports/Charts/InlineChartWidget";

import { default as T } from "services/i18n/Translate";

import { getPieChartTotals } from "services/redux/selectors/reports/common/pie";
import { getConfig } from "services/redux/selectors/reports/common/";

class FilterException extends React.Component {
  render() {
    const { totals, config } = this.props;

    const total = totals.data
      .map(item => item.value)
      .reduce((acc, item) => item + acc, 0);

    return (
      <InlineChartWidget>
        <>
          <h5>{total}</h5>
          <p className="text-muted">
            <T>Total sites</T>
          </p>
        </>
        <>
          <h5>
            {
              this.props.totals.data.find(item => item.name.includes("Failed"))
                .value
            }
          </h5>
          <p className="text-muted">
            {config.type} <T> failed</T>
          </p>
        </>
      </InlineChartWidget>
    );
  }
}

FilterException.propTypes = {
  totals: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => {
  return {
    totals: getPieChartTotals(state, props)(state, props),
    config: getConfig(state, props)
  };
};
export default connect(mapStateToProps)(FilterException);
