import React from "react";

function BackgroundAnimation() {
  const largeDevice = window.innerWidth > 900;
  return (
    largeDevice
      ?
        <video
          poster="/login-background-image.png"
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          className="bgvid"
        >
          <source src="/login-background-animation.webm" type="video/webm" />
        </video>
      :
        // the .account-page-wrapper class provides a default background image if we're not showing the animation
        <></>
  )
};
export default BackgroundAnimation;