import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";

import Loading from "components/Loading";

import Alert from "components/Common/Alert";

import {
  requestUpdateKeyholderStart,
  requestUpdateKeyholderCancel,
  requestRemoveKeyholderStart,
  requestMoveKeyholderStart
} from "services/redux/actions";

import Icon from "components/Icons/Icon";

class KeyholderCard extends React.Component {
  state = {
    editing: false,

    name: "",
    email: "",
    phone: "",
    job_title: ""
  };
  componentDidMount = () => {
    this.resetFields();
  };
  resetFields = () => {
    const { position, name, email, phone, job_title } = this.props;

    this.setState({
      position,
      name,
      email,
      phone,
      job_title
    });
  };
  updateKeyholder = () => {
    const { dispatch, uuid, enterprise_site_id, position } = this.props;

    const { name, email, phone, job_title } = this.state;

    dispatch(
      requestUpdateKeyholderStart({
        params: {
          position,
          name,
          email,
          phone,
          job_title
        },

        uuid,
        enterprise_site_id
      })
    );
  };
  removeKeyholder = () => {
    const { dispatch, uuid, enterprise_site_id } = this.props;

    dispatch(
      requestRemoveKeyholderStart({
        params: { uuid, enterprise_site_id }
      })
    );
  };
  moveKeyholder = direction => {
    const { dispatch, uuid, enterprise_site_id } = this.props;

    dispatch(
      requestMoveKeyholderStart({
        direction,
        params: { uuid, enterprise_site_id }
      })
    );
  };
  componentDidUpdate = () => {
    const { actions, dispatch } = this.props;

    if (actions.update.success && this.state.editing) {
      this.setState({ editing: false });
      // Reset success state
      dispatch(requestUpdateKeyholderCancel());
    }
  };
  render() {
    const {
      actions,
      loading,

      keyholders,
      position,
      t
    } = this.props;

    const removing = actions.remove.loading === true;
    const moving = actions.move.loading === true;
    const updating = actions.update.loading === true;

    const {
      editing,

      name,
      email,
      phone,
      job_title
    } = this.state;

    return (
      <Form>
        <Card bg="light">
          <Row>
            <Col xs="2">
              <Button
                onClick={() => this.moveKeyholder("Up")}
                disabled={
                  loading ||
                  editing ||
                  updating ||
                  moving ||
                  removing ||
                  position === 1
                }
                style={{ height: "50%", fontSize: "20px", width: "100%" }}
                variant="light">
                <Icon className="fas fa-arrow-circle-up" />
              </Button>
              <Button
                onClick={() => this.moveKeyholder("Down")}
                disabled={
                  loading ||
                  editing ||
                  updating ||
                  moving ||
                  removing ||
                  position === keyholders.length
                }
                style={{ height: "50%", fontSize: "20px", width: "100%" }}
                variant="light">
                <Icon className="fas fa-arrow-circle-down" />
              </Button>
            </Col>
            <Col xs="10" className="pl-0">
              <Card.Body className="pl-0">
                <Form.Group as={Row} className="mb-0">
                  <Col sm="12">
                    <InputGroup className="font-14">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={
                            editing
                              ? {}
                              : {
                                  background: "transparent",
                                  border: "none"
                                }
                          }>
                          <Icon className="fas fa-address-card fa-lg" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        ref={r => (this.name = r)}
                        className="font-16 font-weight-bold"
                        name="name"
                        plaintext={!editing}
                        readOnly={!editing}
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="border-bottom mb-0 font-14">
                  <Form.Label column sm="3">
                    {t("email")}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      ref={r => (this.email = r)}
                      className="text-right font-14 text-muted"
                      name="email"
                      plaintext={!editing}
                      readOnly={!editing}
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="border-bottom mb-0 font-14">
                  <Form.Label column sm="3">
                    {t("job_title")}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      ref={r => (this.job_title = r)}
                      className="text-right text-muted"
                      name="job_title"
                      plaintext={!editing}
                      readOnly={!editing}
                      value={job_title}
                      onChange={e =>
                        this.setState({ job_title: e.target.value })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2 font-14">
                  <Form.Label column sm="3">
                    {t("phone")}
                  </Form.Label>

                  <Col sm="9">
                    <Form.Control
                      ref={r => (this.phone = r)}
                      className="text-right text-muted"
                      name="phone"
                      plaintext={!editing}
                      readOnly={!editing}
                      value={phone}
                      onChange={e => this.setState({ phone: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-0 font-14">
                  <Col sm="12">
                    {editing ? (
                      <>
                        <Button
                          disabled={loading || updating || removing || moving}
                          onClick={() => {
                            this.updateKeyholder();
                          }}
                          variant="success"
                          className="float-right">
                          {updating && <Loading />} {t("save_button")}
                        </Button>
                        <Button
                          disabled={loading || updating || removing || moving}
                          onClick={() => {
                            this.setState({ editing: false });
                            this.resetFields();
                          }}
                          className="float-right mr-2">
                          {t("cancel_button")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={loading || updating || removing || moving}
                          onClick={() => this.setState({ editing: true })}
                          className="float-right">
                          <Icon className="fas fa-edit mr-1" />
                          {t("edit_button")}
                        </Button>
                        <Button
                          disabled={loading || updating || removing || moving}
                          variant="danger"
                          onClick={() => {
                            Alert({
                              text: t("confirm_remove", name),
                              icon: "warning",
                              showCancelButton: true
                            }).then(result => {
                              // Confirmed - remove
                              if (result.value) {
                                this.removeKeyholder();
                              }
                            });
                          }}
                          className="float-right mr-2">
                          {removing && <Loading />}{" "}
                          <Icon className="fas fa-times mr-1" />
                          {t("remove_button")}
                        </Button>
                      </>
                    )}
                  </Col>
                </Form.Group>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    actions: state.sites.current.keyholders.actions,
    loading: state.sites.current.keyholders.loading === true
  };
};
export default connect(mapStateToProps)(
  withT(KeyholderCard, "site_overview.side_pane.keyholders.edit")
);
