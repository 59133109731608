/**
 * Users/Management/Remove - Remove user's access to a group
 */

import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserManagementRemoveSuccess,
  requestUserManagementRemoveFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchRemove() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_MANAGEMENT_REMOVE_START", function*(action) {
    yield race({
      task: call(requestRemove, action),
      cancel: take([
        "REQUEST_USER_MANAGEMENT_REMOVE_CANCEL",
        "REQUEST_USER_MANAGEMENT_REMOVE_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestRemove(action) {
  const { enterprise_id, group_id, user_id } = action;
  try {
    if (!user_id) {
      throw new Error("Invalid value supplied for user_id: " + user_id);
    }

    if (!enterprise_id) {
      throw new Error(
        "Invalid value supplied for enterprise_id: " + enterprise_id
      );
    }
    if (!group_id) {
      throw new Error("Invalid value supplied for group_id: " + group_id);
    }

    const response = yield call(
      conxtdOut.post,
      `/EnterpriseUsers/delete/${enterprise_id}/${group_id}/${user_id}`
    );
    // const response = yield call(() => {
    //   return { data: {} };
    // });

    yield put(requestUserManagementRemoveSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestUserManagementRemoveFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
