import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { Button } from "react-bootstrap";
import Icon from "components/Icons/Icon";

// import { setEmSelectedTab } from "services/redux/actions/enterpriseManager";

import "./Navigation.scss";

/**
 * Enterprise Manager main page navigation - top buttons
 *
 **/

class Navigation extends Component {

  componentDidMount() {
    // we're not showing the Integrations tab for India yet,
    // so if the selected tab is set to "Integrations" (from visiting another system e.g. Testing, Staging),
    // set it to "Groups"
    const { user, selectedTab, history, location } = this.props;
    if (user.environment === "india" && selectedTab === "integrations") {
      history.push(`${location.pathname}?tab=groups`);
    }
  }

  render() {
    const { user, selectedTab, history, location } = this.props;
    // let addModalClose = () => this.setState({ addModalShow: false });
    // const handleCloseImport = () => {
    //   this.setState({ showImport: false });
    // };
    // const siteCount = this.props.siteCount || null;
    // const filter = siteCount.filter(
    //   (data) => data.enterprise_group_id === this.state.groupId
    // );

    // let onExportClick = () => {
    //   this.setState({
    //     showExport: true,
    //     site_count: filter[0].site_count.live_sites,
    //   });
    // };

    // let onImportClick = () =>
    //   this.setState({
    //     showImport: true,
    //   });

    let tabs = [
      { key: "groups", name: "Groups", icon: "fa-sitemap" },
      // { name: "billing", icon: "fa-credit-card" },
      { key: "user-actions", name: "User Actions", icon: "fa-clipboard-list" },
      // { name: "notifications", icon: "fa-bell" },
      // { name: "scheduler", icon: "fa-pencil-ruler" },
      { key: "settings", name: "Settings", icon: "fa-sliders-h" },
      // { key: "integrations", name: "Integrations", icon: "fa-th-large" },
    ]

    // don't show the integrations tab for india
    if (user.environment !== "india") {
      tabs = [
        ...tabs,
        { key: "integrations", name: "Apps", icon: "fa-th-large" },
      ]
    }
    tabs = [
      ...tabs,
      { key: "priority-events-management", name: "Priority Events Manager", icon: "fa-exclamation-triangle" },
    ]

    return (
      <div className="d-flex flex-row ml-2">
        {tabs.map((tab) => {
          return (
            <Button 
              variant={tab.key===selectedTab && "active"}
              data-intercom-target={`${tab.name}`}
              className="em-nav-button mr-1"
              key={tab.key}
              onClick = {() => {
                // this.props.dispatch(
                //   setEmSelectedTab({
                //     selectedTab: tab.name,
                //   })
                // );
                history.push(`${location.pathname}?tab=${tab.key}`);
              }}
            >
              <span className="px-2"><span className="em-nav-icon"><Icon className={`fas ${tab.icon} mr-2`} title={tab.name} /></span>{tab.name}</span>
            </Button>
          )
        })}

        {/* <Button variant="primary mr-3 mb-2" onClick={onExportClick}>
          {<Icon className="fas fa-file-export" title="Export" />}
          <span style={{ paddingLeft: "5px" }}>Export</span>
        </Button>
        {this.state.showExport ? (
          <Export
            groupId={this.state.groupId}
            site_count={this.state.site_count}
            enterpriseName={this.props.enterprise.name}
          />
        ) : null}

        <Button variant="primary mr-3 mb-2" onClick={onImportClick}>
          {<Icon className="fas fa-file-import" title="Import" />}
          <span style={{ paddingLeft: "5px" }}>Import</span>
        </Button>
        <Import
          show={this.state.showImport}
          enterpriseId={this.props.enterprise.id}
          groupId={this.state.groupId}
          onHide={handleCloseImport}
        />

        <Button
          variant="primary mb-2"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ addModalShow: true });
          }}
        >
          {<Icon className="fas fa-plus" />}
          <span style={{ paddingLeft: "5px" }}>Add Group</span>
        </Button>
        <AddGroupModal show={this.state.addModalShow} onHide={addModalClose} /> */}
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    // enterprise: getEnterpriseFromRoute(state, props),
    // siteCount: state.enterpriseManager.counts,
    // selectedTab: state.enterpriseManager.selectedTab,
    selectedTab: state.enterpriseManagerPersist.selectedTab,
  };
};
export default withRouter(connect(mapStateToProps)(Navigation));