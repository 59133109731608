import React from "react";

const smoke = (props) => (
  <svg
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 312.336 312.336"
  >
    <g>
      <path
        fill={props.color}
        d="M169.125,285.971c-26.075,0-51.285-10.448-69.86-28.814c-6.194,2.667-12.918,4.069-19.697,4.069
        c-27.418,0-49.722-22.304-49.722-49.728c0-2.93,0.28-5.901,0.829-8.873C11.391,188.359,0,165.936,0,141.829
        c0-37.913,28.617-70.099,65.85-74.938c9.326-24.399,32.555-40.527,58.994-40.527c23.074,0,44.173,12.608,55.241,32.567
        c7.972-2.757,16.284-4.153,24.798-4.153c41.947,0,76.072,34.119,76.072,76.06c0,1.76-0.078,3.55-0.233,5.448
        c18.843,7.375,31.612,25.729,31.612,46.267c0,29.118-25.347,52.449-54.853,49.459C240.501,264.932,206.299,285.971,169.125,285.971
        z"
      />
    </g>
  </svg>
);

export default smoke;