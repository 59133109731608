import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import ReactTable from "react-table";
import "react-table/react-table.css";

import CompanyType from "../CompanyType";

import {
  loadCompaniesStart,
  setCompaniesDisplayPageIndex,
} from "services/redux/actions/companies";

import _ from "lodash";

class DataTable extends React.Component {
  state = {
    previousDataTableState: {
      page: 0,
      sorted: [],
    }
  };

  render() {
    const { 
      companies,
      nameFilter,
      pagination,
      displayPageIndex,
    } = this.props;

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "ID",
        Header: "Id",
        id: "id",
        width: 60,
        show: false,
        sortable: false,
        accessor: data => data.id,
      },
      {
        key: "LOGO",
        Header: "Logo",
        id: "logo_url",
        width: 120,
        resizable: false,
        sortable: false,
        accessor: data => data.logo_url,
        Cell: ({ row }) => {
          return row.logo_url
            ? <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50px" }}
              >
                <img
                  src={row.logo_url}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="" 
                />
              </div>
            : <div style={{ height: "50px" }}></div>
        },
      },
      {
        key: "NAME",
        Header: "Name",
        id: "name",
        width: 300,
        accessor: data => data.name,
        Cell: ({ row }) => {
          return <div style={{ fontSize: "15px", paddingLeft: "3px" }}>{row.name}</div>
        }
      },
      {
        key: "TYPE",
        Header: "Type",
        id: "type",
        accessor: data => data.type,
        Cell: ({ row }) => {
          return <CompanyType companyType={row.type} />
        }
      },
    ]

    return (
      <>
        <div className="card visual-data">
          <ReactTable
            ref={r => (this.reactTable = r)}
            columns={columns}
            data={companies}
            manual
            page={displayPageIndex}
            pages={pagination.pageCount}
            onPageChange={(pageIndex) => {
              this.props.dispatch(
                setCompaniesDisplayPageIndex({
                  displayPageIndex: pageIndex, // keeps track of the page index that the table component displays (0-indexed)
                })
              )
            }}
            filterable={false}
            sortable={companies.length > 0}
            defaultSorted={[
              {
                id: "name",
                desc: false,
              },
            ]}
            defaultPageSize={20}
            noDataText={noDataMsg}
            onFetchData={(state, instance) => {
              if (state.data && state.data.length > 0) {
                // to prevent hitting the api unnecessarily, first check that there has actually been a change in state
                const dataTableState = {
                  page: state.page,
                  pageSize: state.pageSize,
                  sorted: state.sorted,
                }
                if (!_.isEqual(dataTableState, this.state.previousDataTableState)) {
                  let sortField = null;
                  let sortDirection = null;

                  if (state.sorted && state.sorted[0]) {
                    sortField = state.sorted[0].id;
                    sortDirection = state.sorted[0].desc ? "desc" : "asc";
                  } 

                  this.props.dispatch(
                    loadCompaniesStart({ 
                      pageSize: state.pageSize,
                      requestPageNumber: state.page + 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                      nameFilter: nameFilter,
                      sortField: sortField,
                      sortDirection: sortDirection,
                    })
                  );

                  this.setState({
                    previousDataTableState: dataTableState,
                  })
                }
              }
            }}
            getTrGroupProps={(state, rowInfo, column) => {
              return {
                className: "waves-effect",
                onClick: () => {
                  this.props.history.push(
                    `/companies/company/${rowInfo.row.id}`
                  );
                },
              };
            }}
          />
        </div>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    companies: state.companies.data.companies,
    pagination: state.companies.data.pagination,
    // nameFilter: state.companies.nameFilter,
    displayPageIndex: state.companies.displayPageIndex,
  };
};
export default withRouter(connect(mapStateToProps)(DataTable));