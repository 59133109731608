import React from "react";

const building = props => (
  <svg
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 511.627 511.627">
    <g>
      <g fill={props.color}>
        <path
          d="M451.385,5.424C447.768,1.809,443.486,0,438.538,0H73.089c-4.952,0-9.233,1.809-12.85,5.424
			c-3.615,3.617-5.424,7.898-5.424,12.847v475.082c0,4.952,1.809,9.233,5.424,12.851c3.621,3.617,7.902,5.424,12.85,5.424h365.449
			c4.948,0,9.233-1.807,12.847-5.424c3.61-3.617,5.428-7.898,5.428-12.851V18.271C456.809,13.323,455.002,9.042,451.385,5.424z
			 M420.264,475.085H310.632v-63.953c0-2.478-0.907-4.62-2.707-6.427c-1.817-1.807-3.949-2.711-6.427-2.711h-91.367
			c-2.474,0-4.615,0.904-6.423,2.711c-1.807,1.807-2.712,3.949-2.712,6.427v63.953H91.36V36.542h328.904V475.085L420.264,475.085z"
        />
        <path
          d="M137.042,401.994h18.271c2.474,0,4.616-0.899,6.423-2.71c1.809-1.813,2.714-3.949,2.714-6.427v-18.274
			c0-2.471-0.905-4.609-2.714-6.42c-1.807-1.807-3.949-2.71-6.423-2.71h-18.271c-2.474,0-4.615,0.903-6.423,2.71
			c-1.809,1.811-2.712,3.949-2.712,6.42v18.274c0,2.478,0.903,4.613,2.712,6.427C132.428,401.091,134.568,401.994,137.042,401.994z"
        />
        <path
          d="M137.042,328.904h18.271c2.474,0,4.616-0.904,6.423-2.711c1.809-1.813,2.714-3.949,2.714-6.427v-18.274
			c0-2.471-0.905-4.616-2.714-6.42c-1.807-1.807-3.949-2.71-6.423-2.71h-18.271c-2.474,0-4.615,0.903-6.423,2.71
			c-1.809,1.804-2.712,3.949-2.712,6.42v18.274c0,2.478,0.903,4.613,2.712,6.427C132.428,328,134.568,328.904,137.042,328.904z"
        />
        <path
          d="M210.135,328.904h18.272c2.473,0,4.615-0.904,6.423-2.711c1.807-1.813,2.712-3.949,2.712-6.427v-18.274
			c0-2.471-0.909-4.613-2.712-6.42c-1.809-1.807-3.951-2.71-6.423-2.71h-18.272c-2.474,0-4.615,0.903-6.423,2.71
			c-1.807,1.807-2.712,3.949-2.712,6.42v18.274c0,2.478,0.905,4.613,2.712,6.427C205.52,328,207.661,328.904,210.135,328.904z"
        />
        <path
          d="M137.042,255.813h18.271c2.474,0,4.616-0.903,6.423-2.712c1.809-1.809,2.714-3.949,2.714-6.424v-18.271
			c0-2.475-0.905-4.617-2.714-6.424c-1.807-1.809-3.949-2.712-6.423-2.712h-18.271c-2.474,0-4.615,0.903-6.423,2.712
			c-1.809,1.807-2.712,3.949-2.712,6.424v18.271c0,2.475,0.903,4.615,2.712,6.424S134.568,255.813,137.042,255.813z"
        />
        <path
          d="M356.311,401.994h18.274c2.478,0,4.616-0.899,6.427-2.71c1.811-1.813,2.707-3.949,2.707-6.427v-18.274
			c0-2.471-0.903-4.609-2.707-6.42c-1.811-1.807-3.949-2.71-6.427-2.71h-18.274c-2.471,0-4.613,0.903-6.42,2.71
			c-1.811,1.811-2.711,3.949-2.711,6.42v18.274c0,2.478,0.9,4.613,2.711,6.427C351.694,401.091,353.84,401.994,356.311,401.994z"
        />
        <path
          d="M283.224,328.904h18.274c2.478,0,4.613-0.904,6.427-2.711c1.8-1.813,2.703-3.949,2.703-6.427v-18.274
			c0-2.471-0.903-4.613-2.703-6.42c-1.813-1.807-3.949-2.71-6.427-2.71h-18.274c-2.471,0-4.62,0.903-6.427,2.71
			c-1.801,1.807-2.711,3.949-2.711,6.42v18.274c0,2.478,0.907,4.613,2.711,6.427C278.61,328,280.753,328.904,283.224,328.904z"
        />
        <path
          d="M210.135,255.813h18.272c2.473,0,4.615-0.903,6.423-2.712c1.807-1.809,2.712-3.949,2.712-6.424v-18.271
			c0-2.475-0.909-4.613-2.712-6.424c-1.809-1.809-3.951-2.712-6.423-2.712h-18.272c-2.474,0-4.615,0.903-6.423,2.712
			c-1.807,1.807-2.712,3.949-2.712,6.424v18.271c0,2.475,0.905,4.615,2.712,6.424C205.52,254.91,207.661,255.813,210.135,255.813z"
        />
        <path
          d="M137.042,182.725h18.271c2.474,0,4.616-0.905,6.423-2.712c1.809-1.809,2.714-3.946,2.714-6.423v-18.271
			c0-2.474-0.905-4.62-2.714-6.423c-1.807-1.809-3.949-2.714-6.423-2.714h-18.271c-2.474,0-4.615,0.905-6.423,2.714
			c-1.809,1.807-2.712,3.949-2.712,6.423v18.271c0,2.474,0.903,4.615,2.712,6.423C132.428,181.819,134.568,182.725,137.042,182.725z
			"
        />
        <path
          d="M356.311,328.904h18.274c2.478,0,4.616-0.904,6.427-2.711c1.811-1.813,2.707-3.949,2.707-6.427v-18.274
			c0-2.471-0.903-4.613-2.707-6.42c-1.811-1.807-3.949-2.71-6.427-2.71h-18.274c-2.471,0-4.613,0.903-6.42,2.71
			c-1.811,1.804-2.711,3.949-2.711,6.42v18.274c0,2.478,0.9,4.613,2.711,6.427C351.694,328,353.84,328.904,356.311,328.904z"
        />
        <path
          d="M283.224,255.813h18.274c2.478,0,4.613-0.903,6.427-2.712c1.8-1.809,2.703-3.949,2.703-6.424v-18.271
			c0-2.475-0.903-4.613-2.703-6.424c-1.813-1.809-3.949-2.712-6.427-2.712h-18.274c-2.471,0-4.62,0.903-6.427,2.712
			c-1.801,1.807-2.711,3.949-2.711,6.424v18.271c0,2.475,0.907,4.615,2.711,6.424C278.61,254.91,280.753,255.813,283.224,255.813z"
        />
        <path
          d="M210.135,182.725h18.272c2.473,0,4.615-0.905,6.423-2.712c1.807-1.809,2.712-3.946,2.712-6.423v-18.271
			c0-2.474-0.909-4.62-2.712-6.423c-1.809-1.809-3.951-2.714-6.423-2.714h-18.272c-2.474,0-4.615,0.905-6.423,2.714
			c-1.807,1.807-2.712,3.949-2.712,6.423v18.271c0,2.474,0.905,4.615,2.712,6.423C205.52,181.819,207.661,182.725,210.135,182.725z"
        />
        <path
          d="M137.042,109.632h18.271c2.474,0,4.616-0.905,6.423-2.713c1.809-1.807,2.714-3.949,2.714-6.424V82.225
			c0-2.475-0.905-4.615-2.714-6.424c-1.807-1.807-3.949-2.712-6.423-2.712h-18.271c-2.474,0-4.615,0.905-6.423,2.712
			c-1.809,1.809-2.712,3.949-2.712,6.424v18.271c0,2.475,0.903,4.617,2.712,6.424C132.428,108.728,134.568,109.632,137.042,109.632z
			"
        />
        <path
          d="M356.311,255.813h18.274c2.478,0,4.616-0.903,6.427-2.712s2.707-3.949,2.707-6.424v-18.271
			c0-2.475-0.903-4.613-2.707-6.424c-1.811-1.809-3.949-2.712-6.427-2.712h-18.274c-2.471,0-4.613,0.903-6.42,2.712
			c-1.811,1.807-2.711,3.949-2.711,6.424v18.271c0,2.475,0.9,4.615,2.711,6.424C351.694,254.91,353.84,255.813,356.311,255.813z"
        />
        <path
          d="M283.224,182.725h18.274c2.478,0,4.613-0.905,6.427-2.712c1.8-1.809,2.703-3.946,2.703-6.423v-18.271
			c0-2.474-0.903-4.62-2.703-6.423c-1.813-1.809-3.949-2.714-6.427-2.714h-18.274c-2.471,0-4.613,0.905-6.427,2.714
			c-1.801,1.807-2.711,3.949-2.711,6.423v18.271c0,2.474,0.907,4.615,2.711,6.423C278.61,181.819,280.753,182.725,283.224,182.725z"
        />
        <path
          d="M210.135,109.632h18.272c2.473,0,4.615-0.905,6.423-2.713c1.807-1.807,2.712-3.949,2.712-6.424V82.225
			c0-2.475-0.909-4.615-2.712-6.424c-1.809-1.807-3.951-2.712-6.423-2.712h-18.272c-2.474,0-4.615,0.905-6.423,2.712
			c-1.807,1.809-2.712,3.949-2.712,6.424v18.271c0,2.475,0.905,4.617,2.712,6.424C205.52,108.728,207.661,109.632,210.135,109.632z"
        />
        <path
          d="M356.311,182.725h18.274c2.478,0,4.616-0.905,6.427-2.712c1.811-1.809,2.707-3.946,2.707-6.423v-18.271
			c0-2.474-0.903-4.62-2.707-6.423c-1.811-1.809-3.949-2.714-6.427-2.714h-18.274c-2.471,0-4.613,0.905-6.42,2.714
			c-1.811,1.807-2.711,3.949-2.711,6.423v18.271c0,2.474,0.9,4.615,2.711,6.423C351.694,181.819,353.84,182.725,356.311,182.725z"
        />
        <path
          d="M283.224,109.632h18.274c2.478,0,4.613-0.905,6.427-2.713c1.8-1.807,2.703-3.949,2.703-6.424V82.225
			c0-2.475-0.903-4.615-2.703-6.424c-1.813-1.807-3.949-2.712-6.427-2.712h-18.274c-2.471,0-4.62,0.905-6.427,2.712
			c-1.801,1.809-2.711,3.949-2.711,6.424v18.271c0,2.475,0.907,4.617,2.711,6.424C278.61,108.728,280.753,109.632,283.224,109.632z"
        />
        <path
          d="M356.311,109.632h18.274c2.478,0,4.616-0.905,6.427-2.713c1.811-1.807,2.707-3.949,2.707-6.424V82.225
			c0-2.475-0.903-4.615-2.707-6.424c-1.811-1.807-3.949-2.712-6.427-2.712h-18.274c-2.471,0-4.613,0.905-6.42,2.712
			c-1.811,1.809-2.711,3.949-2.711,6.424v18.271c0,2.475,0.9,4.617,2.711,6.424C351.694,108.728,353.84,109.632,356.311,109.632z"
        />
      </g>
    </g>
  </svg>
);

export default building;