import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Row, Col, Button } from "react-bootstrap";

import moment from "services/locale/momentInit.js";

import { Tooltip } from "components/Common/Tooltip/";

import Icon from "components/Icons/Icon";

import "./Modes.scss";

function ModeCard({ mode_session, sudo_state, key, mode_type, t, changeView, changeYear, site }) {

    const showEvents = id => {
        changeView(id);
    }

    //to be used to show the year the events occured in
    // const chYear = year => {
    //     console.log(year);
    //     changeYear(year);
    // }

    const localTimezoneText = moment.tz.guess();

    const eventMomentTimeInLocalTz = new moment(mode_session.time_in);
    let eventMomentTimeInSiteTz = eventMomentTimeInLocalTz;
    if (site.timezone) {
        eventMomentTimeInSiteTz = new moment.utc(eventMomentTimeInLocalTz).tz(site.timezone);
    }
    
    // const eventMomentTimeIn = new moment(mode_session.time_in);

    // let year = eventMomentTimeIn.format("YYYY");
    let monthString = eventMomentTimeInSiteTz.format("MMM");
    let day = eventMomentTimeInSiteTz.format("DD");
    let dayString = eventMomentTimeInSiteTz.format("dddd");

    let timeInSiteTz = eventMomentTimeInSiteTz.format("HH:mm");
    let timeInLocalTz = eventMomentTimeInLocalTz.format("HH:mm");

    let timeOutSiteTz = null;
    let timeOutLocalTz = null;

    let inMode = "Still in Filter Mode";

    let numberOfEvents = mode_session.number_of_events === null ? 0 : mode_session.number_of_events

    let eventMomentTimeOutSiteTz = null;
    let eventMomentTimeOutLocalTz = null;
    if (mode_session.time_out !== null) {
        eventMomentTimeOutLocalTz = new moment(mode_session.time_out);
        if (site.timezone) {
            eventMomentTimeOutSiteTz = new moment.utc(eventMomentTimeOutLocalTz).tz(site.timezone);
        } else {
            eventMomentTimeOutSiteTz = eventMomentTimeOutLocalTz
        }
        // eventMomentTimeOut = new moment(mode_session.time_out);

        const timeDiff = eventMomentTimeOutSiteTz - eventMomentTimeInSiteTz;

        timeOutSiteTz = " - " + eventMomentTimeOutSiteTz.format("HH:mm");
        timeOutLocalTz = " - " + eventMomentTimeOutLocalTz.format("HH:mm");

        inMode = ((timeDiff / 1000) > 59 && (timeDiff / 1000) < 3600)
            //work out how many minutes and seconds it was in mode for
            ? Math.floor((timeDiff / (1000 * 60))) + " minutes " + (timeDiff / 1000) % 60 + " seconds"
            //Then check if the time is over an hour 
            : ((timeDiff / 1000) > 3600)
                ? Math.floor((timeDiff / (1000 * 60 * 60))) + " hours " + Math.floor((timeDiff / (1000 * 60)) % 60) + " minutes"
                : timeDiff / 1000 + " seconds";
    }


    return (
        <Button className="mode-card" variant="light" size="lg" block onClick={() => { showEvents(mode_session.id) }} style={{ cursor: "pointer", marginBottom: "20px" }}>
            <Row>
                <Col xs="auto" style={{ textAlign: "left" }} className="col-left-circle pt-2">
                    <div className="circle-date-element">
                        <Tooltip
                            placement="top"
                            description={eventMomentTimeOutLocalTz === null ? "Still in filter mode" : eventMomentTimeOutLocalTz.format("HH:mm DD/MM/YYYY") + " " + localTimezoneText}
                        >
                            <div className="circle-date-text">
                                <span className="circle-date-day">{day}</span>
                                <br />
                                <span className="circle-date-month">{monthString}</span>
                            </div>
                        </Tooltip>
                    </div>
                </Col>
                <Col xs="4" style={{ textAlign: "left", minHeight: "100px" }} className="col-mid-times">
                    <Row className="col-mid-card">
                        <span className="col-day-string">
                            {dayString}
                        </span>
                    </Row>
                    <Row className="col-mid-card">
                        {
                            localTimezoneText !== site.timezone 
                                ? 
                                    <Tooltip
                                        placement="top"
                                        description={timeInLocalTz + timeOutLocalTz + " " + localTimezoneText}
                                    >
                                        <span className="col-in-out">
                                            {timeInSiteTz}{timeOutSiteTz}
                                        </span>
                                    </Tooltip>
                                : 
                                    <span className="col-in-out">
                                        {timeInSiteTz}{timeOutSiteTz}
                                    </span>
                        }   
                    </Row>
                    <Row className="col-mid-card">
                        <span>
                            {localTimezoneText !== site.timezone ? site.timezone : ""}
                        </span>
                    </Row>
                    <Row className="col-mid-card">
                        <span className="col-time-difference">
                            {inMode}
                        </span>
                    </Row>
                </Col>
                <Col xs="auto" className="event-icon-col">
                    <div className="event-icon-number">
                        <span className="bell-icon-span">
                            <span style={{ paddingRight: "7px" }}><Icon className="far fa-bell icon" /></span>
                            <span>{numberOfEvents}</span>
                        </span>
                    </div>
                </Col>
                <Col xs="auto" sm={{ span: 1, offset: 1 }} className="right-chevron-col">
                    <div className="right-chevron">
                        <Icon className="fas fa-chevron-right" />
                    </div>
                </Col>
            </Row>
        </Button>
    );
}

const mapStateToProps = (state, props) => {
    return {};
};

export default withT(
    connect(mapStateToProps)(ModeCard),
    "site_overview.side_pane.m"
)