/**
 * Site overview bundles tab view
 */

import React from "react";
import { connect } from "react-redux";

import moment from "services/locale/momentInit.js";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import { withT } from "services/translation/";

import { changeGroupTimeframe } from "services/redux/actions/";
import LoadMore from "components/Common/LoadMore/";

import BundleItem from "./BundleItem";

const LATEST_EVENTS_BUNDLE_ID = 2;

const EventBundle = ({
  bundle,
  eventMeta,
  changePage,
  onSelectBundle,
  timezone,
}) => {
  const { title, events } = bundle;

  const { text } = events[0];

  return (
    <BundleItem
      style={{ marginBottom: "56px" }}
      title={title}
      description={text}
      eventMeta={eventMeta}
      event={events[0]}
      bundle_id={bundle.id}
      changePage={changePage}
      onSelectBundle={onSelectBundle}
      timezone={timezone}
    />
  );
};

class Bundles extends React.Component {
  render() {
    const { site, getEventMetaById, onSelectBundle, dispatch, t } = this.props;
    const latestEventsBundle = site.alarmBundles.find(
      ({ id }) => id === LATEST_EVENTS_BUNDLE_ID
    );

    if (!latestEventsBundle) {
      return null;
    }
        
    const firstOfLatestEvents =
      (latestEventsBundle &&
        latestEventsBundle.events.length > 0 &&
        latestEventsBundle.events[0]) ||
      null;

    const firstOfLatestEventsMeta =
      firstOfLatestEvents &&
      getEventMetaById(firstOfLatestEvents.conxtd_alarm_id);

    const bundles = site.alarmBundles
      // Only keep bundles with events and hide "Latest Events"
      .filter(
        ({ events, title }) => events.length > 0 && title !== "Latest Events"
      )
      .sort(({ events: eventsB }, { events: eventsA }) => {
        const ackedA = new moment(eventsA[0].acked);
        const ackedB = new moment(eventsB[0].acked);

        return ackedA.isBefore(ackedB) ? -1 : 1;
      });

    const noEventsInTimeframe = (
      <div className="row px-1">
        <div className="col-md-12">
          <h6 className="my-4 font-14 text-muted">
            {t("site_overview.no_events_in_timeframe")}
          </h6>
          <LoadMore
            loading={site.loading}
            timeframe={site.timeframe}
            onLoadMore={(timeframe) => {
              dispatch(changeGroupTimeframe({ timeframe }));
            }}
          />
        </div>
      </div>
    );

    const showLatestEvents = latestEventsBundle.events.length > 0;

    const totalEvents = site.alarmBundles.reduce((acc, item) => {
      return acc + item.events.length;
    }, 0);

    // Latest Events doesn't contain all events that are available
    // "Other" bundles can have events even if Latest Events
    // is empty
    const content =
      totalEvents === 0 ? (
        noEventsInTimeframe
      ) : (
        <>
          {showLatestEvents && (
            <>
              <div className="row px-1">
                <div className="col-md-12">
                  <h4 className="mt-0 header-title mb-1">
                    {t("site_overview.latest_event")}
                  </h4>
                  <div className="latest-message">
                    <BundleItem
                      event={firstOfLatestEvents}
                      eventMeta={firstOfLatestEventsMeta}
                      description={firstOfLatestEvents.text}
                      title={firstOfLatestEventsMeta.caption}
                      bundle_id={LATEST_EVENTS_BUNDLE_ID}
                      onSelectBundle={onSelectBundle}
                      timezone={site.timezone}
                      site={site}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </>
          )}

          <div className="row px-1">
            <div className="col-md-12">
              <h4 className="mt-0 header-title mb-1">
                {t("site_overview.alarm_bundles")}
              </h4>

              <div className="latest-message" style={{ marginBottom: "90px" }}>
                {bundles.map((bundle, index) => {
                  const { conxtd_alarm_id } = bundle.events[0];

                  const eventMeta = getEventMetaById(conxtd_alarm_id);
                  return (
                    <EventBundle
                      onSelectBundle={onSelectBundle}
                      key={index}
                      bundle={bundle}
                      eventMeta={eventMeta}
                      timezone={site.timezone}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      );

    return content;
  }
}

const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default connect(mapStateToProps)(withT(Bundles));
