import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

import { default as T } from "services/i18n/Translate";

class ProgressBar extends React.Component {
  state = {
    refreshPct: 0,
    lastRefreshFromNow: "",
    intervalId: null
  };
  UNSAFE_componentWillMount() {
    const intervalId = setInterval(() => {
      const { lastRefresh, nextRefresh } = this.props;
      if (!lastRefresh || !nextRefresh) return;
      const totalDiff = lastRefresh.diff(nextRefresh);
      const currentDiff = moment().diff(nextRefresh);
      const refreshPct = 100 - (currentDiff / totalDiff) * 100;
      const lastRefreshFromNow = lastRefresh
        .fromNow()
        .replace("a few seconds", "less than a minute");
      this.setState({ refreshPct, lastRefreshFromNow });
    }, 500);
    this.setState({ intervalId });
  }
  componentWillUnmount() {
    // Destroy interval
    clearInterval(this.state.intervalId);
  }
  render() {
    const { lastRefresh, nextRefresh } = this.props;
    if (!lastRefresh || !nextRefresh) return null;

    const style = {
      width: this.state.refreshPct + "%"
    };
    if (this.props.color) style.background = this.props.color;

    const progressBar = (
      <div
        style={{
          width: "246px",
          margin: "-10px auto 0 auto",
          height: "10px"
        }}>
        <div
          className="progress mt-4"
          style={{
            height: "1px"
          }}>
          <div
            className="progress-bar progress-bar-animated"
            role="progressbar"
            aria-valuenow={this.state.refreshPct}
            aria-valuemin="0"
            aria-valuemax="100"
            style={style}
          />
        </div>
      </div>
    );

    const placement = _.get(this.props, "placement") || "right";

    return (
      <>
        <div className={this.props.className}>
          <T>Live Refresh</T>{" "}
          <OverlayTrigger
            placement={placement}
            overlay={
              <Tooltip
                id={`tooltip-${placement}`}
                style={{ pointerEvents: "none" }}>
                {/* <h6>
                  <T>Live Refresh</T>
                </h6> */}
                <div style={{ fontSize: "12px" }} className="py-1">
                  <T>
                    Priority Events will automatically refresh when this progress bar reaches completion.
                  </T>
                  <br />
                  <br />
                  Refresh cycle: 30 seconds
                </div>
              </Tooltip>
            }>
            <Icon icon="info" />
          </OverlayTrigger>
        </div>
        {progressBar}
      </>
    );
  }
}
export default ProgressBar;