import React from "react";

import { ButtonGroup, Button } from "react-bootstrap";

import { Tooltip } from "components/Common/Tooltip/";

const ViewAs = ({ changeViewAs, viewAs, options }) => {
  return (
    <ButtonGroup size="sm">
      {options.map(({ key, Icon, description }, index) => {
        return (
          <Tooltip description={description} placement="bottom" key={index}>
            <Button
              variant={`${viewAs === key ? "" : "outline-"}primary`}
              onClick={() => changeViewAs(key)}>
              {Icon}
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default ViewAs;
