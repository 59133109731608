const initialState = {
  completedReport: {
    data : {
      events: [],
      pagination: {
        pageCount: 1,
      },
    },
    displayPageIndex: 0,
    pageSize: 20,
    sortMapField: null,
    sortDirection: "desc",
    loading: false,
  }
};

export default function sopReports(state = initialState, action) {
  switch (action.type) {
    case "LOAD_SOP_COMPLETED_REPORT_START":
      return {
        ...state,
        completedReport: {
          ...state.completedReport,
          loading: true,
        },
      };

    case "LOAD_SOP_COMPLETED_REPORT_SUCCESS":
      return {
        ...state,
        completedReport: {
          ...state.completedReport,
          data: action.data,
          displayPageIndex: action.displayPageIndex || state.completedReport.displayPageIndex,
          pageSize: action.startAction.pageSize || 20,
          sortMapField: action.startAction.sortMapField || null,
          sortDirection: action.startAction.sortDirection || "desc",
          loading: false,
        },
      };

    case "LOAD_SOP_COMPLETED_REPORT_FAILED":
      return {
        ...state,
        completedReport: {
          ...state.completedReport,
          loading: false,
        },
      };

    case "LOAD_SOP_COMPLETED_REPORT_CANCEL":
      return {
        ...state,
        completedReport: {
          ...state.completedReport,
          loading: false,
        },
      };

    case "CLEAR_SOP_COMPLETED_REPORT":
      return {
        ...state,
        completedReport: {
          data : {
            events: [],
            pagination: {
              pageCount: 1,
            },
          },
          displayPageIndex: 0,
          pageSize: 20,
          sortMapField: null,
          sortDirection: "desc",
          loading: false,
        },
      };

    case "SET_SOP_COMPLETED_REPORT_DISPLAY_PAGE_INDEX":
      return {
        ...state,
        completedReport: {
          ...state.completedReport,
          displayPageIndex: action.displayPageIndex,
        },
      };

    default:
      return state;
  }
}