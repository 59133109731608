import React from "react";
import { connect } from "react-redux";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { Link } from "react-router-dom";

import { getCurrentGroup } from "services/redux/selectors/groups";

class GroupPath extends React.Component {
  render() {
    const {
      currentGroup,
      delimiter,
      enterprise,
      className = "text-light",
      asLinks = true
    } = this.props;

    const path = this.props.path || currentGroup.path || null;

    if (!path) return null;

    let linkPrefix = `/enterprise/${enterprise.id}`;

    return (
      <>
        {path.map((p, index) => {
          let groupLink = linkPrefix;
          if (index !== 0) {
            groupLink = linkPrefix + "/" + p.id;
          }

          const delimiterComponent =
            index === path.length - 1 ? null : (
              <span className={`px-2 ${className}`}>{delimiter}</span>
            );

          return (
            <React.Fragment key={index}>
              {asLinks ? (
                <Link className={className} to={groupLink}>
                  {p.name}
                </Link>
              ) : (
                <span className={className}>{p.name}</span>
              )}
              {delimiterComponent}
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroup(state),
    enterprise: getEnterpriseFromRoute(state, props)
  };
};
export default connect(mapStateToProps)(GroupPath);
