/**
 * Caching utility functions for sagas
 *
 * https://gitlab.com/kirt/react-launchpad/-/wikis/Caching
 */

import _ from "lodash";

import moment from "services/locale/momentInit.js";

import CACHE_CONFIG from "constants/CACHE_CONFIG";

const caching = { on: true };

caching.stale = async function(
  cacheIndexes,
  namespace,
  // Removing the cachekey that is delimited by a "/"
  { timeToStale } = CACHE_CONFIG[namespace.split("/")[0]] || {
    timeToStale: null
  }
) {
  // 30 min
  // const timeToStale = 1000 * 60 * 30;
  // const { timeToStale } = CACHE_CONFIG[namespace] || { timeToStale: null };
  if (!caching.on) return true;

  if (timeToStale === null) {
    console.warn(
      `Missing cache config entry "${namespace}" in constants/CACHE_CONFIG.json. Defaulting to 30min`
    );
    timeToStale = 1000 * 60 * 30;
  }

  // If the data itself is null for whatever reason, the cache is invalid
  if (sessionStorage.getItem(namespace) === null) return true;
  // if (!await getCache()) return true;

  const lastFetched = _.get(cacheIndexes, "lastFetched." + namespace) || null;

  //const diff = Date.now() - lastFetched;
  //console.log(diff / 1000);

  const diff = new moment().valueOf() - lastFetched;

  const dataStale =
    lastFetched === null || timeToStale === null || diff > timeToStale;

  return !!dataStale;
};

caching.flush = function(cacheKey) {
  // Specified cache key
  if (cacheKey) {
    sessionStorage.removeItem(cacheKey);
    return;
  }
  // _.each(CACHE_CONFIG, (item, key) => {
  //   sessionStorage.removeItem(key);
  // });
  // sessionStorage.removeItem("lastFetched");

  const navCollapsed = localStorage.getItem("navCollapsed");
  const darkMode = localStorage.getItem("darkMode");

  // Just delete all from localstorage
  localStorage.clear();
  sessionStorage.clear();

  localStorage.setItem("navCollapsed", navCollapsed);
  localStorage.setItem("darkMode", darkMode);
};
// Add the above function to global scope
window.caching = { flush: caching.flush };

export default caching;
