import produce from "immer";

import { calculateUnknownAlarmStates } from "services/redux/selectors/groups";

const initialState = {
  data: [],
  loading: null,
  current: {
    loading: null,
    children: [],
    filters: [],
    // This value also affects site view, see getCurrentSite
    timeframe: 24,
  },
};

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_GROUPS_BACKGROUND_START":
      return { ...state, loading: true };
    case "LOAD_GROUPS_BACKGROUND_SUCCESS":
      return {
        ...state,
        loading: false,
        // Add to the current data
        data: {
          ...state.data,
          // cacheSubKey is enterprise_id
          [action.cacheSubKey]: action.data.groups,
          groupsTree: action.data.groupTree,
        },
      };
    case "LOAD_GROUPS_BACKGROUND_RESTORE":
      // TODO: how can we prevent a disk restore if we know there has been no change to the data?
      // if (_.get(action, "unchanged")) {
      //   return { ...state, loading: false };

      // }
      return {
        ...state,
        loading: false,
        // Add to the current data
        data: {
          ...state.data,
          // cacheSubKey is enterprise_id
          [action.cacheSubKey]: action.data.groups,
        },
      };
    case "LOAD_GROUP_OVERVIEW_CANCEL":
      return {
        ...state,
        current: {
          ...initialState.current,
          filters: [],
          loading: null,
        },
      };
    case "LOAD_GROUP_OVERVIEW_FAILED":
      return {
        ...state,
        current: {
          ...initialState.current,
          filters: [],
          loading: true,
        },
      };
    case "LOAD_GROUP_OVERVIEW_START":
      const current = action.refreshing
        ? {
            // Preserve current group data
            ...state.current,
            loading: true,
            timeframe: action.timeframe,
          }
        : {
            ...initialState.current,
            // Replace current group data, but preserve filters
            filters: state.current.filters,
            loading: true,
            // Preserve timeframe
            // timeframe: state.current.timeframe
          };
      return {
        ...state,
        current,
      };
    case "LOAD_GROUP_OVERVIEW_SUCCESS":
      const {
        total_children,
        statusDetails,
        group,
        children,
        path,
        sites,
      } = action.data;

      // Use this to reset filters when loading root group/enterprise
      // const isRootGroup = group.parent_id === null;
      const isRootGroup = path.length === 1;

      return {
        ...state,
        // We show data for "current view" because
        //  for nested groups it's very difficult to
        //  merge properly. The backend may need to be
        //  re-formatted
        current: {
          ...group,
          // #265 Only show reports if we have a valid group
          // this is a workaround for restricting access
          // to reports for users that have group access
          // but not enterprise level access
          showReports: group !== null,
          total_children,
          stats: {
            ...statusDetails,
            alarmColours: {
              ...statusDetails.alarmColours,
              unknown: calculateUnknownAlarmStates(statusDetails),
            },
          },
          // Add unknown alarm states for children
          children: children.map((group) => {
            const { stats } = group;
            const unknown = calculateUnknownAlarmStates(stats);

            return {
              ...group,
              stats: {
                ...stats,
                alarmColours: {
                  ...stats.alarmColours,
                  unknown,
                },
              },
            };
          }),
          path,
          sites,
          // Preserve filters if not root group (and not refreshing)
          filters:
            isRootGroup && !action.refreshing
              ? []
              : // Don't preserve text filters unless it's an auto-refresh
                state.current.filters.filter(
                  ({ filterGroup }) =>
                    !(filterGroup === "text" && !action.refreshing)
                ),
          loading: false,
          // Preserve timeframe
          timeframe: state.current.timeframe,
        },
      };
    case "CHANGE_GROUP_OVERVIEW_FILTER":
      const { filter } = action;

      // The idea here is that filter.key will match
      // the property key that it is filtering on
      return produce(state, (draftState) => {
        // Find a filter with a type and filter group equal to the
        // specified one
        const filterIndex = state.current.filters.findIndex((f) => {
          const { type, filterGroup } = f;
          return type === filter.type && filterGroup === filter.filterGroup;
        });

        // This is a toggle filter
        if (filter.toggle) {
          // Not found, add
          if (filterIndex === -1) {
            draftState.current.filters = [
              ...draftState.current.filters,
              filter,
            ];
          }
          // Exists, remove or update
          else {
            // Remove
            if (state.current.filters[filterIndex].key === filter.key) {
              draftState.current.filters.splice(filterIndex, 1);
            }
            // Update
            else {
              draftState.current.filters[filterIndex] = filter;
            }
          }
        }
        // This is a text/search filter
        if (filter.filterGroup === "text") {
          // Not found, add
          if (filterIndex === -1) {
            draftState.current.filters = [
              ...draftState.current.filters,
              filter,
            ];
          }
          // Exists, update or remove
          else {
            // Empty string, remove
            if (filter.value.length === 0) {
              draftState.current.filters.splice(filterIndex, 1);
            }
            // Update
            else {
              draftState.current.filters[filterIndex] = filter;
            }
          }
        }
      });
    // Change site state
    case "REQUEST_CHANGE_SITE_SET_START":
      localStorage.setItem("siteId", action.id);
      localStorage.setItem("remoteToggle", !state.remoteToggle);

      return produce(state, (draftState) => {
        const siteIndex = state.current.sites.findIndex(
          ({ id }) => id === action.id
        );
        draftState.current.sites[siteIndex].zoneState.loading = true;
      });
    case "REQUEST_CHANGE_SITE_SET_FAILED":
      localStorage.setItem("remoteToggle", false);
      localStorage.setItem("siteId", false);

      return produce(state, (draftState) => {
        const siteIndex = state.current.sites.findIndex(
          ({ id }) => id === action.id
        );
        delete draftState.current.sites[siteIndex].zoneState.loading;
      });
    case "REQUEST_CHANGE_SITE_SET_SUCCESS":
      localStorage.setItem("remoteToggle", false);
      localStorage.setItem("siteId", false);

      return produce(state, (draftState) => {
        const siteIndex = state.current.sites.findIndex(
          ({ id }) => id === action.id
        );
        draftState.current.sites[siteIndex].zoneState = action.set
          ? {
              state: "Unlocked",
              colour: "unsetLightblue",
            }
          : {
              state: "Locked",
              colour: "setBlue",
            };
        draftState.current.sites[siteIndex].zoneState.loading = false;
      });

    case "RESET_GROUP_OVERVIEW_FILTER":
      return produce(state, (draftState) => {
        draftState.current.filters = [];
      });
    case "CHANGE_CURRENT_GROUP_TIMEFRAME":
      return {
        ...state,
        current: {
          ...state.current,
          timeframe: action.timeframe,
        },
      };
    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    default:
      return state;
  }
};

export default groupsReducer;