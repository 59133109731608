import { devMode } from "services/redux/selectors/";

const API_KEY = devMode()
  ? "fSmcT1RQpARm4_jVcZU10-huv1mn-AnbDfsyO8Wf1Xs"
  : "xMlbKmH8_Qvra3_yGx-2A14ul2djWYGmi3jyX7zsmuo";

const CALLBACK_URL = devMode()
  ? encodeURIComponent("https://pro-staging.conxtd.com/oauth/tether")
  : encodeURIComponent("https://app.conxtd.com/oauth/tether");

export const TETHER_AUTHORISE_URI = `https://my.timeline.is/oauth/authorize?client_id=${API_KEY}&redirect_uri=${CALLBACK_URL}&response_type=code`;
