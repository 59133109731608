import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { ButtonToolbar } from "react-bootstrap";

import { Tooltip } from "components/Common/Tooltip/";

import { clearSOPCompletedReport } from "services/redux/actions/sopReports";

import { getSOPCompletedReportData } from "services/redux/selectors";
import { getCustomRef } from "services/redux/selectors/groups";
import { getActualWithTimezone } from "services/redux/selectors/sopReports";
import { sortNumber } from "services/redux/selectors/reports/scheduled";
import { formatMstoMS } from "services/date/date";

import { AssignedUser } from "../../Cells/AssignedUser";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import EventCell from "components/Common/DataTable/EventCell/";
import ExportDropdown from "components/Enterprise/Export/ExportDropdown";
import SiteView from "components/Site/SiteView/";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import "./DataTable.scss";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

class DataTableClientSide extends React.Component {
  state = {
    showSidePane: false,
    selectedSite: null,
    bundle_id: null,
  };

  onSelectSite = site => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");
      this.setState({
        showSidePane: true,
        selectedSite: site,
        bundle_id: null,
      });
    }
    // Clicked a site that's already being viewed, close
    else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          showSidePane: false,
          selectedSite: null,
          bundle_id: null,
        });
      }, 600);
    }
  };

  sortOccurrence = (occurrenceA, occurrenceB) => {
    let ackedA;
    let ackedB;
    
    if (occurrenceA.timezone) {
      const localDate = new moment(occurrenceA.acked);
      ackedA = new moment.utc(localDate).tz(occurrenceA.timezone);
    } else {
      ackedA = occurrenceA.acked;
    }

    if (occurrenceB.timezone) {
      const localDate = new moment(occurrenceB.acked);
      ackedB = new moment.utc(localDate).tz(occurrenceB.timezone);
    } else {
      ackedB = occurrenceB.acked;
    }

    const a = moment(ackedA).format("YYYYMMDDHHmm");
    const b = moment(ackedB).format("YYYYMMDDHHmm");
    return sortNumber(a, b);
  }

  componentWillUnmount() {
    this.props.dispatch(
      clearSOPCompletedReport({})
    );
  }

  render() {
    const { completedReport, loading } = this.props;

    const { selectedSite } = this.state;

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "CUSTOM_REF",
        Header: "Ref",
        id: "custom_ref",
        className: "text-center",
        width: 60,
        accessor: (original) => {
          if (!_.isEmpty(original.site)) {
            return getCustomRef(original.site);
          } 
        },
      },
      {
        key: "SITE_NAME",
        Header: "Site Name",
        id: "site_name",
        className: "site-name",
        accessor: original => original.site.name,
        Cell: (props) => {
          if (!_.isEmpty(props.original.site)) { 
            return (
              <SiteNameCell
                value={props.row.site_name}
                selectSite={() => {
                  this.onSelectSite(props.original.site);
                }}
              />
            )
          } 
        }
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        width: 85,
        accessor: "site_ref",
      },
      {
        key: "EVENT",
        Header: "Event",
        id: "event",
        className: "alarm-type",
        width: 190,
        accessor: original => original.eventMeta.caption,
        Cell: ({ original }) => {
          return <EventCell
            event={original.eventMeta}
          />
        }
      },
      {
        key: "TIME",
        Header: "Time",
        id: "time",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: original.mct_alarm_log.acked,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.time} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
        },
        sortMethod: this.sortOccurrence,
        filterMethod: (filter, row) => {
          return (
            String(getActualWithTimezone(row.time)) //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
      },
      {
        key: "ASSIGNED",
        Header: "Assigned",
        id: "assigned",
        className: "completed-assigned text-center",
        width: 80,
        accessor: (original) => original.sop_action_assigned_user,
        Cell: ({ row }) => {
          return <AssignedUser
            assignedUser={row.assigned}
            tooltipPlacement="top"
          />
        },
        filterMethod: (filter, row) => {
          const { assigned } = row;
          if (
            String(assigned.first_name + " " + assigned.last_name)
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          ) {
            return true;
          } else if (
            String(assigned.first_name[0] + assigned.last_name[0])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        },
        sortMethod: (a, b) => {
          // sort by initials
          const initialsA = String(a.first_name[0] + a.last_name[0]).toLowerCase();
          const initialsB = String(b.first_name[0] + b.last_name[0]).toLowerCase();
          const compareInitials = initialsA.localeCompare(initialsB);
          if (compareInitials !== 0) {
            return compareInitials;
          } else {
            // if initials are the same, sort by full name
            const fullNameA = String(a.first_name + a.last_name).toLowerCase();
            const fullNameB = String(b.first_name + b.last_name).toLowerCase();
            return fullNameA.localeCompare(fullNameB);
          }
        },
      },
      {
        key: "ACTIONED",
        Header: "Actioned",
        id: "actioned",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: original.created,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.actioned} />
        },
        sortMethod: this.sortOccurrence,
        filterMethod: (filter, row) => {
          return (
            String(getActualWithTimezone(row.actioned))
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
      },
      {
        key: "COMPLETED",
        Header: "Completed",
        id: "completed",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: (original.sop_close_down_log && original.sop_close_down_log.created) || null,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.completed} />
        },
        sortMethod: this.sortOccurrence,
        filterMethod: (filter, row) => {
          return (
            String(getActualWithTimezone(row.completed))
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
      },
      {
        key: "CLOSURE",
        Header: "Closure",
        id: "closure",
        width: 90,
        accessor: original => original.closeDownOption,
        Cell: ({ row }) => {
          let CloseDownType = "Other";
          if (row.closure.type === "R") {
            CloseDownType = "Real Alarm"
          } else if (row.closure.type === "F") {
            CloseDownType = "False Alarm"
          }
          return <Tooltip 
            description={row.closure.option}
            placement="left"
          >
            <span>{CloseDownType}</span>
          </Tooltip>
        },
        filterMethod: (filter, row) => {
          const { closure } = row;
          if (
            closure.option
            .toLowerCase()
            .indexOf(filter.value.toLowerCase()) !== -1
          ) {
            return true;
          } else if (closure.type === "R") {
            if (
              String("Real Alarm")
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            ) {
              return true;
            } else {
              return false;
            }
          } else if (closure.type === "F") {
            if (
              String("False Alarm")
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            ) {
              return false;
            }
          } else if (closure.type === "U") {
            if (
              String("Other")
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            ) {
              return false;
            }
          }
        },
        sortMethod: (a, b) => {
          // sort by type
          const compareType = a.type.localeCompare(b.type);
          if (compareType !== 0) {
            return compareType;
          } else {
            // if type is the same, sort by option
            return a.option.localeCompare(b.option);
          }
        },
      },
      {
        key: "RESPONSE",
        Header: "Response",
        id: "response",
        className: "text-right",
        width: 90,
        accessor: original => original.response,
        Cell: ({ row }) => {
          const response = row.response;
          // return <div className="mr-2">{(response >= 3600000) ? formatMstoDHMS(response): formatMstoHMS(response)}</div>
          // return <div className="mr-2">{formatMstoHMS(response)}</div>
          return <div className="mr-2">{formatMstoMS(response)}</div>
        }
      },
      {
        key: "DURATION",
        Header: "Duration",
        id: "duration",
        className: "text-right",
        width: 90,
        accessor: original => original.duration,
        Cell: ({ row }) => {
          const duration = row.duration;
          // return <div className="mr-2">{(duration >= 3600000) ? formatMstoDHMS(duration): formatMstoHMS(duration)}</div>
          // return <div className="mr-2">{formatMstoHMS(duration)}</div>
          return <div className="mr-2">{formatMstoMS(duration)}</div>
        }
      }
    ]

    return (
      <div>
        {/* <div className="card visual-data site-list"> */}
        <div className="card visual-data">
          <ButtonToolbar className="p-2">
            <ExportDropdown
              disabled={loading || (completedReport.length === 0)}
              reportTitle={"Completed Action Report"}
              dataResolver={() => {
                const data = this.reactTable.getResolvedState().sortedData;
    
                let result = data.map(row => {
                  // console.log(row);
                  const time = {
                    acked: row._original.mct_alarm_log.acked,
                    timezone: row._original.site.timezone,
                    format: "HH:mm ddd DD/MM/YYYY",
                  }
                  const actioned= {
                    acked: row._original.created,
                    timezone: row._original.site.timezone,
                    format: "HH:mm ddd DD/MM/YYYY",
                  }
                  const completed= {
                    acked: row._original.sop_close_down_log.created,
                    timezone: row._original.site.timezone,
                    format: "HH:mm ddd DD/MM/YYYY",
                  }
                  let closure = "Other";
                  if (row["closure"].type === "R") {
                    closure = "Real Alarm"
                  } else if (row["closure"].type === "F") {
                    closure = "False Alarm"
                  }
                  return [
                    row["custom_ref"] || "",
                    row["site_name"] || "",
                    row["site_ref"] || "",
                    row["event"] || "",
                    getActualWithTimezone(time), //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
                    row["assigned"] ? row["assigned"].first_name + " " + row["assigned"].last_name : "",
                    getActualWithTimezone(actioned),
                    getActualWithTimezone(completed),
                    closure,
                    // formatMstoHMS(row["response"]) || "",
                    // formatMstoHMS(row["duration"]) || "",
                    formatMstoMS(row["response"]) || "",
                    formatMstoMS(row["duration"]) || "",
                  ];
                });
                result.unshift([
                  "Custom Ref",
                  "Site Name",
                  "Site Ref",
                  "Event",
                  "Time",
                  "Assigned",
                  "Actioned",
                  "Completed",
                  "Closure",
                  "Response",
                  "Duration",
                ]);
    
                return result;
              }}
    
            />
          </ButtonToolbar>
          <ReactTable
            ref={r => (this.reactTable = r)}
            columns={columns}
            data={loading ? [] : completedReport}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            defaultPageSize={20}
            noDataText={noDataMsg}
            defaultSorted={[
              {
                id: "time",
                desc: true,
              },
            ]}
          />
          <SiteView
            site={selectedSite}
            selectSite={this.onSelectSite}
            bundle_id={this.state.bundle_id}
            onSelectBundle={bundle_id => {
              // Bundle selected
              if (bundle_id) {
                this.setState({ bundle_id });
              }
              // Invalid bundle id, deselect
              else {
                this.setState({ bundle_id: null });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    completedReport: getSOPCompletedReportData(state, props),
    loading: state.sopReports.completedReport.loading,
  };
};
export default connect(mapStateToProps)(DataTableClientSide);
