import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadSOPActionMapStart,
  loadSOPActionMapFailed,
  loadSOPActionMapSuccess,
  loadSOPActionMapCancel,
  loadSOPCloseDownOptionsStart,
  loadSOPCloseDownOptionsSuccess,
  loadSOPCloseDownOptionsFailed,
  loadSOPCloseDownOptionsCancel,
} from "services/redux/actions/sop";

import conxtdOut from "apis/conxtdOut";

// one-off saga to get the sop action map for an alarm

export function* watchLoadSOPActionMap() {
  yield actionWatcher({
    actions: {
      start: loadSOPActionMapStart,
      failed: loadSOPActionMapFailed,
      success: loadSOPActionMapSuccess,
      cancel: loadSOPActionMapCancel,
    },
    saga: loadSOPActionMapRe,
  });
}

// https://mars.conxtd.com/out_api/sop/sop-action-maps/action-map/:enterprise_id/:enterprise_group_id/:sudo_state_id
export function* loadSOPActionMapRe(action) {
  try {
    const { enterprise_id, group_id, sudo_state_id, sop_actioned_event_id, sop_action_map_id } = action;

    if (!enterprise_id || !group_id || !sudo_state_id) 
      throw new Error(
        "enterprise_id, group_id and sudo_state_id are required for loading sop action map"
      );

    const url = `/sop/sop-action-maps/action-map/${enterprise_id}/${group_id}/${sudo_state_id}${sop_actioned_event_id ? "/" + sop_actioned_event_id : ""}${sop_action_map_id ? "/" + sop_action_map_id : ""}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// one-off saga to get the sop close down options for an alarm

export function* watchLoadSOPCloseDownOptions() {
  yield actionWatcher({
    actions: {
      start: loadSOPCloseDownOptionsStart,
      failed: loadSOPCloseDownOptionsFailed,
      success: loadSOPCloseDownOptionsSuccess,
      cancel: loadSOPCloseDownOptionsCancel,
    },
    saga: loadSOPCloseDownOptionsRe,
  });
}

// /out_api/sop/sop-action-process/close-down-options/:enterprise_id/:enterprise_group_id
export function* loadSOPCloseDownOptionsRe(action) {
  try {
    const { enterprise_id, group_id } = action;

    if (!enterprise_id || !group_id) 
      throw new Error(
        "enterprise_id and group_id are required for loading sop close down options"
      );

    const url = `/sop/sop-action-process/close-down-options/${enterprise_id}/${group_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}