import { put, call } from "redux-saga/effects";
import _ from "lodash";

import * as actions from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

/**
 * EXCEPTION REPORT - loadStart
 *
 * @param {REDUX_ACTION} action
 */
export function* loadStart(action) {
  const { reportType } = action;
  const params = _.omit(action, ["type", "reportType"]);
  let reportKey;
  if (action.reportType === "panic") {
    reportKey = "pa";
  } else {
    reportKey = "fire";
  }
  //console.log(params);
  const url = _.get(params, "week")
    ? `/analyticx/nonstandard-reports/non-${reportKey}-weekly-report`
    : `/analyticx/nonstandard-reports/non-${reportKey}-report`;
  try {
    const response = yield call(conxtdOut.get, url, {
      params
    });

    const result = {
      reportType,
      [reportType]: response.data
    };
    // Essentially this: result = { panic: response.data }
    // _.set(result, reportType, response.data);

    yield put(actions.loadExceptionReportSuccess(result));
  } catch (error) {
    yield put(actions.loadExceptionReportFailed({ error }));
  }
}
