/**
 * 09/21 - open close by area
 * Customisations for child rows, i.e. expansion
 * of area states
 */

import React from "react";

import EventCell from "components/Common/DataTable/EventCell/";

import TimezoneDate from "components/Common/TimezoneDate";

import { NA } from "services/redux/selectors";

import GROUPS from "constants/GROUPS";

import _ from "lodash";

import moment from "services/locale/momentInit.js";

const today = new moment();

const ChildRowColumns = [
  {
    key: "CUSTOM_ID",
    content: ({ sudoState }) => sudoState.custom_ref || NA(),
    className: "text-muted"
  },
  {
    key: "NAME",
    content: ({ sudoState }) => sudoState.name || NA(),
    className: "text-muted"
  },
  {
    key: "GROUP",
    content: ({ sudoState }) => { 
      const path = _.get(sudoState, "enterprise_groups.0.path");
      return path && path.join(GROUPS.GROUP_PATH_DELIMITER);
    },
    className: "text-muted"
  },
  {
    key: "SITE_REF",
    content: ({ sudoState }) => sudoState.site_ref,
    className: "text-muted"
  },
  {
    key: "PERIMETER",
    content: ({ sudoState, focus }) => {
      return (
        sudoState.perimeter_event_meta 
          ? <EventCell
              event={sudoState.perimeter_event_meta }
              focus={focus}
              // onClick={() => {
              //   config.dataTable.clickableEventFilters && changeFilter(event.label);
              // }}
            />
          : ""
      )
    },
    className: "alarm-type",
  },
  {
    key: "AREA",
    content: ({ area_state, areaContainsFilter }) => {
      if (area_state.area) {
        return <span className={`text-muted ${areaContainsFilter ? "contains-filter" : ""}`}>{area_state.area}</span>
      } else {
        return NA();
      }
    },
    // className: "text-muted ",
  },
  {
    key: "AREANAME",
    content: ({ area_state, areaNameContainsFilter }) => {
      if (area_state.area_name) {
        return <span className={`text-muted ${areaNameContainsFilter ? "contains-filter" : ""}`}>{area_state.area_name}</span>
      } else {
        return "";
      }
    },
    // className: "text-muted"
  },
  {
    key: "STATE",
    content: ({ area_state, focus, underline }) => {
      return (
        area_state.area_state_event_meta
          ? <EventCell
              event={area_state.area_state_event_meta}
              focus={focus}
              underline={underline}
              onClick={() => {}}
            />
          : ""
      );
    },
    className: "alarm-type area-state",
  },
  {
    key: "LASTSTATEEVENT",
    content: ({ area_state, lastStateEventContainsFilter }) => {
      if (area_state.alarm_text) {
        return <span className={`text-muted ${lastStateEventContainsFilter ? "contains-filter" : ""}`}>{area_state.alarm_text}</span>
      } else {
        return "";
      }
    },
    // className: "text-muted"
  },
  {
    key: "LASTSTATECHANGE",
    content: ({ sudoState, area_state, lastStateChangeContainsFilter }) => {
      if (area_state.event_time) {
        let colorClass = "";
        const eventTime = new moment(area_state.event_time);
        const daysAgo = -eventTime.diff(today, "day");
        if (daysAgo > 0 && daysAgo <= 6) {
          colorClass = "last-state-change-orange muted";
        } else if (daysAgo > 6) {
          colorClass = "last-state-change-red muted";
        } else {
          colorClass = "text-muted";
        }

        if (!sudoState.timezone) {
          return (
            <div className={`${colorClass} ${lastStateChangeContainsFilter ? "contains-filter" : ""}`}>
              {eventTime.format("DD/MM/YYYY HH:mm")}
            </div>
          );
        } else {
          return (
            <div className={`${colorClass} ${lastStateChangeContainsFilter ? "contains-filter" : ""}`}>
              <TimezoneDate date={area_state.event_time} timezone={sudoState.timezone} format="DD/MM/YYYY HH:mm" hideTimezone />
            </div>
          );
        }
      } else {
        return <div>{NA()}</div>
      }
    },
  },
  {
    key: "EXPAND",
    content: null
  }
];
 
export default ChildRowColumns;