import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Form, FormGroup } from "react-bootstrap";

import { loadDeveloperAppsStart } from "services/redux/actions/developers";

import Alert from "components/Common/Alert";

import conxtdOut from "apis/conxtdOut";

class EditAppModal extends React.Component {

  editApp = (appID, appName, redirectURI) => {
    const editApp = conxtdOut.post(`/Oauth/edit/${appID}`, {
      app_name: appName,
      redirect_uri: redirectURI,
    });

    editApp.then((response) => {
      Alert({
        text: "App updated.",
        icon: "success",
        timer: 1500,
        showConfirmButton: true,
      });

      this.props.dispatch(
        loadDeveloperAppsStart()
      );

      this.props.onHide();
      
    }).catch((error) => {
      console.log("Error details", error);
      Alert({
        text: "Issue updating App. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
    })
  }

  deleteApp = (appId) => {
    const deleteApp = conxtdOut.post(`/Oauth/delete/${appId}`);

    deleteApp.then((response) => {
      Alert({
        text: "App deleted.",
        icon: "success",
        timer: 1500,
        showConfirmButton: true,
      });

      this.props.dispatch(
        loadDeveloperAppsStart()
      );

      this.props.onHide();
      
    }).catch((error) => {
      console.log("Error details", error);
      Alert({
        text: "Issue deleting App. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
    })
  }

  render() {
    const { appDetails } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Form
          className="edit-app-form"
          onSubmit={(e) => {
            e.preventDefault();
            this.editApp(appDetails.id, e.target.app_name.value, e.target.redirect_uri.value);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit App
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="app_name">
              <Form.Label>App Name</Form.Label>
              <Form.Control
                type="text"
                name="App_name"
                className="mb-3"
                required
                defaultValue={appDetails && appDetails.app_name}
              />
            </FormGroup>
            <FormGroup controlId="redirect_uri">
              <Form.Label>Redirect URI</Form.Label>
              <Form.Control
                type="text"
                name="Redirect_uri"
                className="mb-3"
                required
                defaultValue={appDetails && appDetails.redirect_uri}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex">
                <Button
                  className="mr-auto p-2"
                  variant="danger"
                  onClick={() => {
                    Alert({
                      text: "Are you sure you wish to delete this App?",
                      icon: "warning",
                      showCancelButton: true
                    }).then(result => {
                      if (result.value) {
                        // confirmed - remove
                        this.deleteApp(appDetails.id); 
                      }
                    });
                  }}
                >
                  Delete
                </Button>
                <Button
                  className="mr-2 p-2"
                  variant="primary"
                  onClick={this.props.onHide}
                >
                  Close
                </Button>
                <Button className="p-2" variant="success" type="submit">
                  Update
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(EditAppModal);