/**
 * Filters
 *
 * Customisible array/string filters, returning true or
 * false for keeping or discarding items
 */

/**
 * Example:
 *
 * contains({ value: "Site name", filter:"site" })
 *
 * @param {}
 */
export const contains = ({ filter, value, ignoreCase = true }) => {
  let filterString = ignoreCase ? filter.toLowerCase() : filter;
  let valueString = ignoreCase ? value.toLowerCase() : value;

  // Remove those misleading double spaces
  filterString = filterString.replace("  ", " ");
  valueString = valueString.replace("  ", " ");

  return valueString.includes(filterString);
};
export const exact = ({ filter, value, ignoreCase = true }) => {
  let filterString = ignoreCase ? filter.toLowerCase() : filter.value;
  let valueString = ignoreCase ? value.toLowerCase() : value;

  // Remove those misleading double spaces
  filterString = filterString.replace("  ", " ");
  valueString = valueString.replace("  ", " ");

  return valueString.indexOf(filterString) === 0;
};
export const nonZero = ({ value }) => {
  return value !== 0;
};
