import { put } from "redux-saga/effects";

import * as actions from "services/redux/actions";

/**
 * Link groups action to sites background loader
 * @param {action} action
 */
export function* toGroupsBackgroundLoader(action) {
  const { enterprise_id } = action;
  yield put(actions.loadGroupsBackgroundStart({ enterprise_id }));
}
