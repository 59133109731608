import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  getIntegrationsStart,
  getIntegrationsSuccess,
  getIntegrationsFailed,
  getIntegrationsCancel,
} from "services/redux/actions/enterpriseManager";

// import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

// one-off saga to load integrations

export function* watchGetIntegrations() {
  yield actionWatcher({
    actions: {
      start: getIntegrationsStart,
      failed: getIntegrationsFailed,
      success: getIntegrationsSuccess,
      cancel: getIntegrationsCancel,
    },
    saga: getIntegrationsRe,
  });
}

// https://mars.conxtd.com/out_api//integrations/enterpriseIntegration/{enteprise_id}
export function* getIntegrationsRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id) 
      throw new Error(
        "enterprise_id is required for loading integrations"
      );

    const url = `/integrations/enterpriseIntegration/${enterprise_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}