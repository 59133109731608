import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { getPinMapEventPairOptions } from "services/redux/selectors/enterpriseManager";

import "./EditElement.scss";

import find from "lodash/find";

const EditElement = ({ darkMode, id, eventPairOptions, setExistingRecords, setChangeDetected, element }) => {

  const [alarmTypeValue, setAlarmTypeValue] = useState({label: "", value: ""});
  const [toDelete, setToDelete] = useState(false);
  
  useEffect(() => {
    setAlarmTypeValue({label: element.event_pair_name, value: element.event_pair_id});
  }, [element]);

  const handleChange = (e, propertyType, selectedEventPairOption) => {
    setChangeDetected(true);
    setExistingRecords((prevState) => {
    const foundElement = prevState.find((element) => element.id === id);
      if (foundElement) {
        switch (propertyType) {
          case 'alarm':
            foundElement.event_pair_id = selectedEventPairOption.value;
            break

          case 'customText':
            foundElement.custom_text = e.target.value;
            break

          case 'reverse':
            foundElement.reverse = e.target.checked  === true ? "Y" : "N";
            break

          case 'delete':
            e.target.checked ? foundElement.action = 'delete' : foundElement.action = 'edit';
            break

          default:
            break;
        }
      }

      return foundElement ? [...prevState] : prevState;
    });
  };

  const darkModeSelectStyles = {
    option: (provided) => ({
      ...provided,
      color: 'white',
      borderColor: 'transparent',
      backgroundColor: '#13274d',
      '&:hover': {
        backgroundColor: '#b9bfca',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#13274d',
      borderColor: 'transparent',
      '&:focus-within': {
        borderColor: 'transparent',
        boxShadow: 'none'
      },
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#b9bfca',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: '#13274d',
    }),
  };

  return (
    <div
      className={`${darkMode ? "darkMode" : ""} container`}
      id="form-outer-wrapper-edit"
    >
      <div className='row flex-nowrap'>
        <div className={`pl-0 pr-2 col-2 ${toDelete ? "no-click opacity-reduced" : ""}`}>
          <Form.Control
            data-intercom-target={"edit-pins-pin"}
            name="pin"
            type="string"
            value={element.pin}
            disabled={true}
            className="w-100"
          />
        </div>
        <div 
          className="form-element m-0 pl-0 pr-2 col-3"
          id="form-dropdown-menu"
        >
          <Select
            data-intercom-target={"edit-pins-alarm-type-select"}
            name="alarm_type"
            options={eventPairOptions}
            value={alarmTypeValue}
            placeholder="Select alarm type"
            required
            className={`w-100 ${toDelete ? "no-click opacity-reduced" : ""}`}
            maxMenuHeight={200}
            onChange={(e) => {
              const selectedEventPairOption = e ? find(eventPairOptions, ['value', e.value]) : null;
              handleChange(e, "alarm", selectedEventPairOption);
              setAlarmTypeValue(selectedEventPairOption);
            }}
            styles={darkMode ? darkModeSelectStyles : ''}
          />
        </div>
        <div className="form-element col-xl-5 col-lg-5 col-md-4 col-sm-4 pl-0">
          <Form.Control
            data-intercom-target={"edit-pins-custom-text"}
            name="custom_text"
            component="input"
            type="text"
            value={element.custom_text ? element.custom_text : ""}
            className={`w-100 ${toDelete ? "no-click opacity-reduced" : ""}`}
            onChange={(e) => {
              handleChange(e, "customText")
            }}
          />
        </div>
        <div className='container m-0' id="buttons-container-edit">
          <div className="row d-flex justify-content-between m-0 p-0" id="buttons-container-child-edit">
            <div className="form-child-content-element">
              <Form.Control
                data-intercom-target={"edit-pins-reverse-checkbox"}
                name="reverse"
                component="input"
                type="checkbox"
                checked={element.reverse === "Y" ? true : false}
                id="reverse"
                className={`w-50 ${toDelete ? "no-click opacity-reduced"  : ""}`}
                onChange={(e) => {
                  handleChange(e, "reverse")
                }}
              />
            </div>
            <div className="form-child-content-element">
              <Form.Control
                data-intercom-target={"edit-pins-delete-checkbox"}
                name="delete"
                component="input"
                type="checkbox"
                id='delete-record-button'
                className="d-flex justify-content-center checkbox-style"
                onChange={(e) => {
                  handleChange(e, "delete");
                  e.target.checked ? setToDelete(true) : setToDelete(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    eventPairOptions: getPinMapEventPairOptions(state),
    darkMode: state.app.darkMode,
  };
};

export default connect(mapStateToProps, null)(EditElement);
