import moment from "services/locale/momentInit.js";
import { getNow } from "services/locale/selectors";

export const getTodayEnabled = (
  now,
  { disableTodayUntil, disableTodayFrom }
) => {
  // const now = new moment().hour(12).minute(1);
  const from = new moment(disableTodayFrom, "HH:mm");
  const until = new moment(disableTodayUntil, "HH:mm");

  // Today is enabled if time is before 06:00 or after 12:00
  return now.isBefore(from) || now.isAfter(until);
};

/**
 * getYesterdayIsLive
 *
 * For close, yesterday technically ends at 6AM today,
 *   which means liveClose will be "yesterday's" data
 *   until midday, and today will be inaccessible.
 *   E.g. if it's 1AM, liveClose will still
 *   be for "yesterday" until 6AM.
 *
 * @param {*} config
 */
export const getYesterdayIsLive = (now, { yesterdayEndsAt }) => {
  const endOfYesterday = new moment(yesterdayEndsAt, "HH:mm");

  return now.isBefore(endOfYesterday);
};
/**
 * getDefaultDay
 *
 * Returns date object of the default selected day -
 *   today or yesterday based on availability
 *   of the data. E.g. close reports aren't available
 *   until noon, so this will be yesterday until then
 *
 *
 * @returns date object
 *
 * @param {*} config
 */
export const getDefaultDay = (now, dayPicker) => {
  const yesterday = getNow().subtract(1, "day");

  return getTodayEnabled(now, dayPicker) ? now.toDate() : yesterday.toDate();
};
