import { watchLoadCallLogs, watchGetTranscription } from "./voiceCalls";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCallLogs(),
      watchGetTranscription(),
    ],
  );
}