import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  getUptickSitesStart,
  getUptickSitesFailed,
  getUptickSitesSuccess,
  getUptickSitesCancel,
  getUptickLinkedSitesStart,
  getUptickLinkedSitesFailed,
  getUptickLinkedSitesSuccess,
  getUptickLinkedSitesCancel,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

// load Uptick sites
export function* watchGetUptickSites() {
  yield actionWatcher({
    actions: {
      start: getUptickSitesStart,
      failed: getUptickSitesFailed,
      success: getUptickSitesSuccess,
      cancel: getUptickSitesCancel,
    },
    saga: getUptickSitesRe,
  });
}

export function* getUptickSitesRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id)
      throw new Error("enterprise_id is required for loading Uptick sites");

    const limit = action.pageSize || 20;
    const page = action.requestPageNumber || 1;

    const sort = action.sortField || "";
    const direction = action.sortDirection || "";

    let filters = [];
    if (action.filters && action.filters.length > 0) {
      action.filters.forEach(filter => {
        filters["filters[" + filter.id + "]"] = filter.value;
      });
    }

    const params = new URLSearchParams({
      limit: limit,
      page: page,
      sort: sort,
      direction: direction,
      ...filters,
    });

    const url = `/uptick/getSites/${enterprise_id}/?${params.toString()}`;

    const result = yield call(conxtdOut.get, url, action.filters);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// load CONXTD sites that are linked to a Uptick site
export function* watchGetUptickLinkedSites() {
  yield actionWatcher({
    actions: {
      start: getUptickLinkedSitesStart,
      failed: getUptickLinkedSitesFailed,
      success: getUptickLinkedSitesSuccess,
      cancel: getUptickLinkedSitesCancel,
    },
    saga: getUptickLinkedSitesRe,
  });
}

export function* getUptickLinkedSitesRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id)
      throw new Error("enterprise_id is required for loading Uptick sites");

    const url = `/uptick/getSiteAssociateDetails/${enterprise_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}