import React from "react";

import { Tooltip } from "components/Common/Tooltip/";

import moment from "services/locale/momentInit.js";

import ReactTimeAgo from 'react-time-ago';

function TimeagoTimezone({
  date,
  format = "HH:mm DD/MM/YYYY",
  localFormat = null,
  placement = "top",
}) {
  const localDate = new moment(date);

  if (!date) return null;

  const localTimezoneText = moment.tz.guess();

  return (
    <Tooltip 
      placement={placement}
      description={`${localDate.format(
        localFormat || format
      )} ${localTimezoneText}`}
    >
      <ReactTimeAgo date={new moment(date).valueOf()} locale="en-GB" timeStyle="round" tooltip={false}/>
    </Tooltip>
  );
}
export default TimeagoTimezone;