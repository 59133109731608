export const loadSOPActionMapStart = (params) => {
  return { type: "LOAD_SOP_ACTION_MAP_START", ...params };
};

export const loadSOPActionMapSuccess = (params) => {
  return { type: "LOAD_SOP_ACTION_MAP_SUCCESS", ...params };
}

export const loadSOPActionMapFailed = (params) => {
  return { type: "LOAD_SOP_ACTION_MAP_FAILED", ...params };
}

export const loadSOPActionMapCancel = (params) => {
  return { type: "LOAD_SOP_ACTION_MAP_CANCEL", ...params };
}

export const clearSOPActionMap = (params) => {
  return { type: "CLEAR_SOP_ACTION_MAP", ...params };
}

export const setSOPCurrentContactStatus = (params) => {
  return { type: "SET_SOP_CURRENT_CONTACT_STATUS", ...params };
}

export const loadSOPCloseDownOptionsStart = (params) => {
  return { type: "LOAD_SOP_CLOSE_DOWN_OPTIONS_START", ...params };
};

export const loadSOPCloseDownOptionsSuccess = (params) => {
  return { type: "LOAD_SOP_CLOSE_DOWN_OPTIONS_SUCCESS", ...params };
}

export const loadSOPCloseDownOptionsFailed = (params) => {
  return { type: "LOAD_SOP_CLOSE_DOWN_OPTIONS_FAILED", ...params };
}

export const loadSOPCloseDownOptionsCancel = (params) => {
  return { type: "LOAD_SOP_CLOSE_DOWN_OPTIONS_CANCEL", ...params };
}