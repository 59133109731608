import { watchLoadCompanyFilterData, watchLoadCompany } from "./company";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCompanyFilterData(),
      watchLoadCompany(),
    ],
  );
}