import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import moment from "services/locale/momentInit.js";

import ReactTable from "react-table";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import EventCell from "components/Common/DataTable/EventCell/";

import { contains } from "services/filters/";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

class DataTable extends React.Component {
  render() {
    const { bundle, t, getEventMetaById, site } = this.props;
    const columns = [];
    const timezone = site.timezone;

    columns.push({
      width: 100,
      Header: t("headers.date"),
      accessor: ({ acked }) => moment(acked).format("DD/MM/YYYY"),
      className: "acked-date",
      id: "acked-date",
    });
    columns.push({
      width: 80,
      Header: t("headers.time"),
      accessor: ({ acked }) => moment.tz(acked, timezone).format("HH:mm"),
      className: "acked-time",
      id: "acked-time",
    });
    columns.push({
      width: 160,
      Header: t("headers.event"),
      className: "alarm-type",
      accessor: ({ conxtd_alarm_id }) => {
        const event = getEventMetaById(conxtd_alarm_id);

        return event.caption;
      },
      Cell: ({ original }) => {
        const event = getEventMetaById(original.conxtd_alarm_id);

        return <EventCell event={event} focus={true} onClick={() => {}} />;
      },
      id: "event",
    });
    columns.push({
      Header: t("headers.detail"),
      accessor: "text",
      id: "detail",
      // Cell: ({ value }) => {
      //   const conxtd_alarm_id = 3;
      //   const event = getEventMetaById(conxtd_alarm_id);

      //   return event.caption;
      // }
    });
    columns.push({
      Header: t("headers.zone"),
      accessor: "event_field.zone",
      id: "zone",
      width: 60,
      // Cell: ({ value }) => {
      //   const conxtd_alarm_id = 3;
      //   const event = getEventMetaById(conxtd_alarm_id);

      //   return event.caption;
      // }
    });
    columns.push({
      Header: t("headers.area"),
      accessor: "event_field.area",
      id: "area",
      width: 55,
      // Cell: ({ value }) => {
      //   const conxtd_alarm_id = 3;
      //   const event = getEventMetaById(conxtd_alarm_id);

      //   return event.caption;
      // }
    });
    columns.push({
      Header: t("headers.loop"),
      accessor: "event_field.loop",
      id: "loop",
      width: 55,
    });
    columns.push({
      Header: t("headers.panel"),
      accessor: "event_field.panel",
      id: "panel",
      width: 55,
    });
    columns.push({
      Header: t("headers.point"),
      accessor: "event_field.point",
      id: "point",
      width: 55,
    });

    const Table = (
      <div className="card visual-data">
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          filterable
          // noDataText={t("no_data_available")}
          defaultPageSize={10}
          // defaultSorted={[
          //   {
          //     id: "site-name",
          //     desc: false
          //   }
          // ]}
          defaultFilterMethod={(filter, row) => {
            return contains({
              filter: filter.value,
              value: String(row[filter.id]),
              ignoreCase: true,
            });
          }}
          data={bundle.events}
          columns={columns}
        />
      </div>
    );

    return (
      <>
        <div className="row">
          <div className="col-12">
            <ExportDropdown
              size="sm"
              className="mb-2"
              disabled={bundle.events.length === 0}
              reportTitle={
                site.name.length >= 32 ? site.name.substring(0, 31) : site.name
              }
              dataResolver={() => {
                const data = this.reactTable.getResolvedState().sortedData;

                let result = data.map((row) => {
                  // console.log(row);
                  return [
                    row["acked-date"] || "",
                    row["acked-time"] || "",
                    row["event"] || "",
                    row["detail"] || "",
                    row["zone"] || "",
                    row["area"] || "",
                    row["loop"] || "",
                    row["panel"] || "",
                    row["point"] || "",
                    // row["address"],
                    // row["postcode"],
                    // row["transmitter-ref"]
                  ];
                });
                result.unshift([
                  t("headers.date"),
                  t("headers.time"),
                  t("headers.event"),
                  t("headers.detail"),
                  t("headers.zone"),
                  t("headers.area"),
                  t("headers.loop"),
                  t("headers.panel"),
                  t("headers.point"),
                  // "Address",
                  // "Postcode",
                  // "Transmitter Ref"
                ]);

                return result;
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">{Table}</div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withT(
  connect(mapStateToProps)(DataTable),
  "site_overview.side_pane.events.data_table"
);
