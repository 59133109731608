import keyholderActions from "./keyholderActions";
import integrations from "./integrations";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

const initialState = {
  loading: null,
  // shouldUpdate: null,
  keyholders: {
    data: [],
    loading: null,
    stale: false,
    actions: keyholderActions.initialState,
  },
  refreshing: false,
  refreshCount: 0,
  highlightEvent: true,
  integrations: {
    loading: null,
    data: [],
    cameras: {
      loading: null,
      data: [],
      actions: {
        add: {
          loading: null,
        },
        remove: {
          loading: null,
        },
      },
    },
    locations: {
      loading: null,
      data: [],
      actions: {
        add: {
          loading: null,
        },
      },
    },
    admin: {
      showTether: false,
      showEagleEye: false,
    },
    actions: {
      connectTether: {
        success: null,
        error: {}
      },
      connectEagleEye: {
        loading: false,
        success: null,
        error: {}
      },
      connectVideoLoft: {
        loading: false,
        success: null,
        error: {}
      }
    }
  },
  event_pairs: {
    loading: null,
    init_load: false,
    data: {
      full: [],
      partial: [],
      area_names: [],
    },
  },
  // Moved to groups.current
  // timeframe: 24,
  alarmBundles: [],
  //mode session
  modes_view: {
    loading: null,
    data: null,
    error: false,
  },
  mode_events: {
    loading: true,
    data: null,
    error: false
  },
  recentLinkedComments: {},
  notificationHistories: {},
};

const addCommentToAlarmBundles = (comment, bundleId, alarmBundles) => {
  const alarmBundlesReturn = [ 
    ...alarmBundles.map((bundle) => {
      if (String(bundle.id) === String(bundleId)) {
        return { 
          ...bundle,
          events: [ 
            ...bundle.events.map((event) => {
              if (String(event.id) === String(comment.mct_alarm_log_id)) {
                return { 
                  ...event,
                  linked_comments: [ 
                    ...event.linked_comments,
                    comment, 
                  ]
                }
              } else {
                return { ...event }
              }
            }) 
          ]
        }; 
      } else {
        return { ...bundle }
      }
    }) 
  ];
  return alarmBundlesReturn;
}

const current = (state = initialState, action) => {
  state = keyholderActions.reducer(state, action);
  switch (action.type) {
    case "LOAD_SITE_VIEW_REFRESH":
      return {
        ...state,
        stale: true,
        refreshing: true,
      };
    case "LOAD_SITE_VIEW_START":
      return {
        ...state,
        // Set site id - useful for passing down to components
        // that need it for api calls
        id: action.id,
        // If this is not a refresh, we will reset
        // loading to null so the interface doesn't
        // show an old site
        loading: state.refreshing ? true : null,

        // Flag keyholders as stale
        keyholders: {
          ...state.keyholders,
          stale: true,
        },

        // Reset should update flag
        stale: false,
      };
    case "LOAD_SITE_VIEW_FAILED":
      return {
        ...state,
        loading: null,
      };
    case "LOAD_SITE_VIEW_SUCCESS":
      // cross check the recent linked comments stored in the state with the linked comments coming in from the api
      // to see if there are any that have been posted recently that haven't come through in the api yet
      let recentLinkedCommentsCheck = {};

      if (state.recentLinkedComments.events) {
        const alarmBundleTemp = _.find(action.site.alarmBundles, ["id", state.recentLinkedComments.alarmBundleId]);

        if (alarmBundleTemp) {
          recentLinkedCommentsCheck = {
            ...state.recentLinkedComments,
            events: [ 
              ...state.recentLinkedComments.events.map((recentLinkedCommentEvent) => {
                if (recentLinkedCommentEvent.linked_comments) {
                  const eventTemp = _.find(alarmBundleTemp.events, (event) => { return String(event.id) === String(recentLinkedCommentEvent.id) });
      
                  return {
                    ...recentLinkedCommentEvent,
                    linked_comments: recentLinkedCommentEvent.linked_comments.filter((recentLinkedComment) => {
                      // filter out any recent linked comments that are now present in the api 
                      // - we no longer have to worry about keeping track of them
                      let matchComment = false;
                      if (eventTemp) {
                        for (let i=0; i<eventTemp.linked_comments.length; i++) {
                          // match on the combination of time posted and username
                          const timeTemp = new moment(eventTemp.linked_comments[i].time)
                          if (timeTemp.utc().format() === recentLinkedComment.time
                              && eventTemp.linked_comments[i].user.first_name === recentLinkedComment.user.first_name
                              && eventTemp.linked_comments[i].user.last_name === recentLinkedComment.user.last_name) {
                            matchComment = true;
                            break;
                          }
                        }
                      }
                      return !matchComment;
                    })
                  }
                } else {
                  return { ...recentLinkedCommentEvent }
                }
              }).filter((recentLinkedCommentEvent) => {
                // remove any event items with empty linked_comments arrays
                return (recentLinkedCommentEvent.linked_comments.length !== 0)
              })
            ]
          }
        }
      } // recentLinkedCommentsCheck now contains any recently posted comments that have not yet made their way into the api response

      let alarmBundlesCheck = [ ...action.site.alarmBundles ];

      // any recent linked comments that haven't come through in the api yet 
      // need to be added to the linked comments in the current site state so they can be displayed
      if (!_.isEmpty(recentLinkedCommentsCheck)) {
        recentLinkedCommentsCheck.events.forEach((event) => {
          if (event.linked_comments && event.linked_comments.length > 0) {
            event.linked_comments.forEach((comment) => {
              alarmBundlesCheck = addCommentToAlarmBundles(comment, recentLinkedCommentsCheck.alarmBundleId, alarmBundlesCheck);
            })
          }
        })
      } 
      

      return {
        ...state,
        ...action.site,
        alarmBundles: [ ...alarmBundlesCheck ],
        loading: false,
        refreshing: false,
        highlightEvent: state.refreshCount === 0,
        refreshCount: state.refreshCount + 1,
        recentLinkedComments: { ...recentLinkedCommentsCheck }
      };

    case "LOAD_SITE_VIEW_LIVE_REFRESH":
      return {
        ...state,
        stale: true,
        refreshing: true,
      };

    case "LOAD_SITE_VIEW_LIVE_REFRESH_START":
      return {
        ...state,
        // Set site id - useful for passing down to components
        // that need it for api calls
        id: action.id,
        refreshing: true,
        stale: true,
      };

    // note: the live refresh functionality uses LOAD_SITE_VIEW_SUCCESS as it's success action type

    case "LOAD_SITE_VIEW_LIVE_REFRESH_FAILED":
      return {
        ...state,
        loading: null,
      };

    case "LOAD_SITE_VIEW_LIVE_REFRESH_CANCEL":
      return {
        ...state,
        loading: null,
      };

    case "SET_SITE_VIEW_REFRESH_COUNT":
      return {
        ...state,
        refreshCount: action.refreshCount,
      }

    case "ADD_LINKED_COMMENT":
      // add the comment that has just been created by the user to the linked comments in the current site in the state,
      // so it will show in the comment chain before the next refresh of EnterpriseSite/view.
      // also store it in the recent linked comments section of the state.
      // this is just temporary until the next refresh of EnterpriseSite/view.
      // when that happens, the success reducer for the api will cross check the recent comments 
      // against the linked comments in the api response (the comment has also been posted to the back end)
      // if the comment exists in the api, it will be displayed as normal from that, 
      // or else if the comment hasn't yet been fully processed in the back end, it will be displayed from the recent comments store
      // until the next refresh of the api when it should be there.

      const newLinkedComment = {
        mct_alarm_log_id: action.mct_alarm_log_id,
        text: action.text,
        time: action.time,
        user: {
          first_name: action.first_name,
          last_name: action.last_name,
        }
      }

      // add the comment into the site bundle
      const alarmBundlesAdd = addCommentToAlarmBundles(newLinkedComment, action.bundle_id, state.alarmBundles);

      // add the comment into the recent comments store in the state, 
      // just as a temporary measure until it's processed by the back end and returned in the api data
      let recentLinkedCommentsAdd = {}
      if (!state.recentLinkedComments.events || (String(state.recentLinkedComments.alarmBundleId) !== String(action.bundle_id))) {
        // if there are no previous events, or the bundle id is different, just add the comment while creating the correct structure afresh.
        // (if the bundle id is different, that means we're looking at a different bundle 
        // than when the previous recent linked comments were added and we can overwrite them.
        // if the user clicks back to the bundle, the back end should have had enough time to return those comments in the site view response)
        recentLinkedCommentsAdd = {
          events: [
            {
              id: action.mct_alarm_log_id,
              linked_comments: [
                newLinkedComment
              ]
            }
          ]
        }
      } else {
        // add the comment into the existing recent_comments structure
        const eventIndex = _.findIndex(state.recentLinkedComments.events, ["id", action.mct_alarm_log_id]);
        if (eventIndex !== -1) {
          // other recent comments exist for this event (denoted by the mct_alarm_log_id), so add the new comment in with them
          recentLinkedCommentsAdd = {
            events: [
              ...state.recentLinkedComments.events.map((event) => {
                if (String(event.id) === String(action.mct_alarm_log_id)) {
                  return { 
                    ...event,
                    linked_comments: [ 
                      ...event.linked_comments,
                      newLinkedComment, 
                    ]
                  }
                } else {
                  return { ...event }
                }
              })
            ]
          }
        } else {
          // no other recent comments exist for the event, so add the event into the events array (denoted by the mct_alarm_log_id), and then add the linked comment
          recentLinkedCommentsAdd = {
            events: [
              ...state.recentLinkedComments.events,
              {
                id: action.mct_alarm_log_id,
                linked_comments: [
                  newLinkedComment
                ]
              }
            ]
          }
        }
      }

      return {
        ...state,
        alarmBundles: [ ...alarmBundlesAdd ],
        recentLinkedComments: { 
          ...recentLinkedCommentsAdd,
          alarmBundleId: action.bundle_id,
         },
      };

    case "LOAD_NOTIFICATION_HISTORY_START":
      return {
        ...state,
        notificationHistories: {
          ...state.notificationHistories,
          [action.mct_alarm_log_id]: {
            data: [],
          }
        }
      };

    case "LOAD_NOTIFICATION_HISTORY_SUCCESS":
      return {
        ...state,
        notificationHistories: {
          ...state.notificationHistories,
          [action.startAction.mct_alarm_log_id]: {
            data: action.data.notificationHistory || []
          }
        }
      };

    case "LOAD_NOTIFICATION_HISTORY_FAILED":
      return {
        ...state,
        notificationHistories: {
          ...state.notificationHistories,
          [action.startAction.mct_alarm_log_id]: {
            data: [],
          }
        }
      };

    case "LOAD_NOTIFICATION_HISTORY_CANCEL":
      return {
        ...state,
        notificationHistories: {
          ...state.notificationHistories,
          [action.startAction.mct_alarm_log_id]: {
            data: [],
          }
        }
      };

    case "CLEAR_NOTIFICATION_HISTORIES":
      return {
        ...state,
        notificationHistories: {},
      }

    // Keyholders
    case "LOAD_SITE_KEYHOLDERS_CANCEL":
      return {
        ...state,
        keyholders: initialState.keyholders,
      };
    case "LOAD_SITE_KEYHOLDERS_START":
      return {
        ...state,
        keyholders: {
          // Preserve actions state
          actions: state.keyholders.actions,

          stale: false,
          // data: [],
          data: state.keyholders.data,
          loading: true,
        },
      };
    case "LOAD_SITE_KEYHOLDERS_SUCCESS":
      return {
        ...state,
        keyholders: {
          // Preserve actions state
          actions: state.keyholders.actions,

          stale: false,
          data: action.keyholders || [],
          loading: false,
        },
      };
  //Engineers/Test Mode Sessions
    case "LOAD_SITE_MODE_SESSION_START":
      return {
        ...state,
        modes_view: {
          loading: true,
          data: null,
        }
      }
    case "LOAD_SITE_MODE_SESSION_SUCCESS":
      return {
        ...state,
        modes_view: {
          loading: false,
          data: action.data[0]
        }
      }

      case "LOAD_SITE_MODE_SESSION_FAILED":
        return {
          ...state,
          modes_view: {
            loading: false,
            data: null,
            error: true,
          }
        }

      case "LOAD_SITE_MODE_SESSION_CANCEL":
        return {
          ...state,
          modes_view: {
            loading: false,
            data: null,
          }
        }
  //Engineers/Test Mode Events
    case "LOAD_SITE_MODE_EVENTS_START":
      return {
        ...state,
        mode_events: {
          loading: true,
        }
      }
    case "LOAD_SITE_MODE_EVENTS_SUCCESS":
      return {
        ...state,
        mode_events: {
          loading: false,
          data: action.data[0]
        }
    }

    case "LOAD_SITE_MODE_EVENTS_FAILED":
      return {
        ...state,
        mode_events: {
          loading: false,
          error: true
        },
      }

    case "LOAD_SITE_MODE_EVENTS_CANCEL":
      return {
        ...state,
        mode_events: {
          loading: false,
        },
      }
  //end mode sessions
    // case "CHANGE_CURRENT_GROUP_TIMEFRAME":
    //   return {
    //     ...state,
    //     timeframe: action.timeframe
    //   };
    case "LOAD_OPEN_CLOSE_BY_AREA_START":
      return {
        ...state,
        event_pairs: {
          loading: true,
          init_load: state.event_pairs.init_load,
          data: {
            full: state.event_pairs.data.full,
            partial: state.event_pairs.data.partial,
            area_names: state.event_pairs.data.area_names,
          }
        }
      }
    case "LOAD_OPEN_CLOSE_BY_AREA_SUCCESS":
      return {
        ...state,
        event_pairs: {
          loading: false,
          init_load: true,
          data: {
            full: action.data.full,
            partial: action.data.partial,
            area_names: action.data.area_names,
          }
        }
      }
    case "LOAD_OPEN_CLOSE_BY_AREA_FAILURE":
      return {
        ...state,
        event_pairs: {
          loading: false,
        }
      }
    case "LOAD_OPEN_CLOSE_BY_AREA_CANCEL":
      return {
        ...state,
        event_pairs: {
          loading: false,
        }
      }
    case "LOAD_SITE_INTEGRATIONS_START":
    case "LOAD_SITE_INTEGRATIONS_FAILED":
    case "LOAD_SITE_INTEGRATIONS_SUCCESS":
    case "REQUEST_TETHER_CONNECT_SUCCESS":
    case "REQUEST_TETHER_CONNECT_FAILED":
    case "REQUEST_EAGLE_EYE_CONNECT_START":
    case "REQUEST_EAGLE_EYE_CONNECT_SUCCESS":
    case "REQUEST_EAGLE_EYE_CONNECT_FAILED":
    case "REQUEST_VIDEO_LOFT_CONNECT_START":
    case "REQUEST_VIDEO_LOFT_CONNECT_SUCCESS":
    case "REQUEST_VIDEO_LOFT_CONNECT_FAILED":
    case "LOAD_SITE_CAMERAS_START":
    case "LOAD_SITE_CAMERAS_SUCCESS":
    case "LOAD_SITE_CAMERAS_FAILED":
    case "LOAD_INTEGRATION_LOCATIONS_START":
    case "LOAD_INTEGRATION_LOCATIONS_SUCCESS":
    case "LOAD_INTEGRATION_LOCATIONS_FAILED":
    case "LOAD_INTEGRATION_LOCATIONS_CANCEL":
    case "REQUEST_ADD_INTEGRATION_LOCATION_START":
    case "REQUEST_ADD_INTEGRATION_LOCATION_CANCEL":
    case "REQUEST_ADD_INTEGRATION_LOCATION_SUCCESS":
    case "REQUEST_ADD_INTEGRATION_LOCATION_FAILURE":
    case "REQUEST_ADD_SITE_CAMERA_START":
    case "REQUEST_ADD_SITE_CAMERA_FAILED":
    case "REQUEST_ADD_SITE_CAMERA_SUCCESS":
    case "REQUEST_EDIT_SITE_CAMERA_START":
    case "REQUEST_EDIT_SITE_CAMERA_FAILED":
    case "REQUEST_EDIT_SITE_CAMERA_SUCCESS":
    case "REQUEST_REMOVE_SITE_CAMERA_START":
    case "REQUEST_REMOVE_SITE_CAMERA_SUCCESS":
    case "REQUEST_REMOVE_SITE_CAMERA_FAILED":
      return {
        ...state,
        integrations: integrations(state.integrations, action),
      };
    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    default:
      return state;
  }
};

export default current;