import { call, take, race, put, takeLatest } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

import cacheIndexes from "../cacheIndexes";

import { liveRefreshFor } from "services/redux/sagas/common";

export const links = {
  toEventMetaBackgroundLoader: function* () {
    yield put(actions.loadEventMetaBackgroundStart());
  },
};

/**
 * Group overview loader watcher saga
 */
export function* watchLoadOverviewBackground() {
  // while (true) {
  // const action = yield take("LOAD_GROUP_OVERVIEW_START");

  // Take latest action and cancel previous
  yield takeLatest("LOAD_GROUP_OVERVIEW_START", function* (action) {
    yield race({
      task: call(loadOverviewBackground, action),
      cancel: take([
        "LOAD_GROUP_OVERVIEW_CANCEL",
        // "LOAD_GROUP_OVERVIEW_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
  // }
}

/**
 * Group overview (aka "current" group) loader saga
 *
 * @param {*} action requires group_id and enterprise_id
 */
export function* loadOverviewBackground(action) {
  if (!action.group_id || !action.enterprise_id)
    throw new Error(
      "group_id and enterprise_id are required for loading groups stats"
    );

  const { group_id, enterprise_id, interval, refresh } = action;

  const timeframe = action.timeframe || "24";

  const params = {
    startAction: actions.loadGroupOverviewStart,
    startActionParams: action,
    successAction: actions.loadGroupOverviewSuccess,
    successActionParams: {
      group_id,
      enterprise_id,
    },
    failedAction: actions.loadGroupOverviewFailed,
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseGroupDashboard/groupDetails/${enterprise_id}/${group_id}/${timeframe}`
    ),
    interval,
    key: "data",
    refresh: refresh || false,
  };

  yield liveRefreshFor(params);
}

/**
 * Groups background loader watcher saga
 */
export function* watchLoadBackground() {
  while (true) {
    const action = yield take("LOAD_GROUPS_BACKGROUND_START");

    yield race({
      task: call(loadBackground, action),
      cancel: take([
        "LOAD_GROUPS_BACKGROUND_CANCEL",
        "LOAD_GROUPS_BACKGROUND_FAILED",
        "CANCEL_ALL",
      ]),
    });
  }
}

/**
 * Groups background loader saga
 *
 * @param {*} action requires group_id and enterprise_id
 */
export function* loadBackground(action) {
  if (!action.enterprise_id)
    throw new Error("enterprise_id is required for loading groups");
  const params = {
    action,
    reducerKey: "groups",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseStructure/getGroupsForEnterprise/${action.enterprise_id}`
    ),
    successAction: actions.loadGroupsBackgroundSuccess,
    failedAction: actions.loadGroupsBackgroundFailed,
    restoreAction: actions.loadGroupsBackgroundRestore,
    extraParams: { cacheSubKey: action.enterprise_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}

// export function* loadGroupsStart(action) {
//   //conxtdOut.loadGroups

//   const groups = yield call(conxtdOut.loadGroupTree, action.enterpriseId);

//   yield put(actions.loadGroupTreeSuccess({ groups /*.groups*/ }));

//   // yield put(actions.loadGroupTreeFailed({ error }));
//   //}
// }
