import React, { Component } from 'react';

import DropdownTreeSelectContainer from "./DropdownTreeSelectContainer";

import "./DropdownTreeSelectCustom.scss";

import isEqual from 'lodash/isEqual'

const clearAllSVG = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E"

export default class DropdownTreeSelectCustom extends Component {
  state = {
    options: [],
    changeCounter: 0,
  }

  componentDidMount() {
    this.setState({
      options: [
        ...this.props.options,
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    const { changeCounter } = this.state;

    if (!isEqual(prevProps.options, options)) {
      this.setState({
        options: [
          ...this.props.options.map((option) => {
            return {
              ...option,
              diffCheck: changeCounter,
            }
          })
        ]
      });
      this.setState({ changeCounter: (changeCounter + 1) });
    }
  }

  render() {
    const { onChange, clearable, onClearAll, ...rest } = this.props;
    const { options, changeCounter } = this.state;

    return (
      <div className={`d-flex ${clearable ? " with-clear-all" : ""}`}>
        <DropdownTreeSelectContainer
          data={options}
          onChange={(currentNode, selectedNodes) => {
            onChange(selectedNodes);
          }}
          mode="multiSelect"
          {...rest}
        />
        {
          clearable && <button
            className="dropdowntree-clear-all"
            type="button"
            onClick={() => {
              onClearAll();
              this.setState({ 
                options: options.map((option) => {
                  if (option.children) {
                    return {
                      ...option,
                      checked: false,
                      diffCheck: changeCounter,
                      children: option.children.map((childOption) => {
                        return {
                          ...childOption,
                          checked: false,
                          diffCheck: changeCounter,
                        }
                      })
                    }
                  } else {
                    return {
                      ...option,
                      checked: false,
                      diffCheck: changeCounter,
                    }
                  } 
                }) 
              })
              this.setState({ changeCounter: (changeCounter + 1) });
            }}
          >
            <img src={clearAllSVG} alt="Clear all" className="filter-clear-all" />
          </button>
        }
      </div>
    )
  }
}