import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { Redirect } from "react-router-dom";

// import StatisticsList from "./Statistics/StatisticsList";
// import GroupList from "./GroupList";

import Loading from "components/Loading";

import { getRouterParams } from "services/redux/selectors/app/";

import { getTemplate } from "components/Templates/Dashboards/selectors";

// import { devMode } from "services/redux/selectors/";

import {
  getEnterpriseFromRoute,
  getEnterprises,
} from "services/redux/selectors/enterprises";

import { getCurrentGroup } from "services/redux/selectors/groups";

import {
  loadReportsOverviewStart,
  loadGroupOverviewStart,
  loadReportsOverviewCancel,
  loadGroupOverviewCancel,
  loadSiteViewStart,
  loadUserManagementAccessStart,//alarm-response
} from "services/redux/actions";

import { getCurrentSite } from "services/redux/selectors/sites";

class Overview extends React.Component {
  loadGroups = (props) => {
    // const currentGroupLoading = props.currentGroup.loading === true;

    if (!props.enterprise) {
      return;
    }

    const group_id =
      (props.router.params.group_id &&
        parseInt(props.router.params.group_id)) ||
      props.enterprise.root_group_id;

    // Should load by group id from router.params
    //  if specified, otherwise root group
    const params = {
      enterprise_id: props.enterprise.id,
      group_id: group_id,
      key: "all",
      refresh: true,
      interval: 30 * 1000,
      timeframe: props.currentGroup.timeframe,
    };

    const newTimeframe =
      props.currentGroup.timeframe !== this.props.currentGroup.timeframe;

    // Technically if timeframe has changed we are refreshing
    if (newTimeframe) params.refreshing = true;

    // if (!currentGroupLoading) {
    this.props.dispatch(loadGroupOverviewStart(params));
    // }
  };
  loadReports = (props) => {
    const reportsLoading = props.reports.overview.all.loading === true;

    if (!props.enterprise) {
      return;
    }

    // Should load by group id from router.params
    //  if specified, otherwise root group
    const params = {
      enterprise_id: props.enterprise.id,
      group_id: props.router.params.group_id || props.enterprise.root_group_id,
      key: "all",
      refresh: true,
      interval: 60 * 1000,
    };

    if (!reportsLoading) {
      this.props.dispatch(loadReportsOverviewStart(params));
    }
  };
  loadSite = (props) => {
    const { site } = props;
    const { site_id } = props.router.params;
    if (!site_id) {
      return;
    }
    // Cancel previous
    // this.props.dispatch(loadSiteViewCancel());
    this.props.dispatch(
      loadSiteViewStart({
        id: site_id,
        timeframe: site.timeframe,
      })
    );

    // site.id
  };
  loadUserRole = (props) => { //alarm-response
    const { enterprise, group } = props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    this.props.dispatch(
      loadUserManagementAccessStart({
        enterprise_id: enterprise.id,
        group_id,
      })
    );
  };
  componentWillUnmount() {
    this.props.dispatch(loadReportsOverviewCancel());
    this.props.dispatch(loadGroupOverviewCancel());
  }
  componentDidMount() {
    // Load reports and groups
    this.loadReports(this.props);
    this.loadGroups(this.props);
    this.loadUserRole(this.props); //alarm-response
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentGroupInitial = nextProps.currentGroup.loading === null;

    const newEnterpriseOrGroup =
      this.props.router.params.group_id !== nextProps.router.params.group_id ||
      this.props.router.params.enterprise_id !==
        nextProps.router.params.enterprise_id;

    const noReportsLoadedYet =
      // Selected enterprise has been loaded
      //  and reports haven't been yet, retrigger
      this.props.reports.overview.all.loading === null;

    const newTimeframe =
      nextProps.currentGroup.timeframe !== this.props.currentGroup.timeframe;

    const siteStale = nextProps.site.stale;

    const shouldUpdateReports =
      // No reports yet
      noReportsLoadedYet ||
      // New group incoming
      newEnterpriseOrGroup;

    const shouldUpdateGroups =
      // Group overview is in initial state
      currentGroupInitial ||
      // New group incoming
      newEnterpriseOrGroup ||
      // New timeframe has been set
      newTimeframe;

    const shouldUpdateSite =
      // New timeframe set
      newTimeframe ||
      // Site data has been invalidated by group refresh
      siteStale;

    // Cancel any previous reports call
    if (newEnterpriseOrGroup) {
      this.props.dispatch(loadReportsOverviewCancel());
    }

    if (shouldUpdateReports) {
      this.loadReports(nextProps);
    }
    // When loading with no enterprises or expired
    // enterprise, this won't dispatch
    if (shouldUpdateGroups) {
      this.loadGroups(nextProps);
    }

    if (shouldUpdateSite) {
      this.loadSite(nextProps);
    }
  }

  render() {
    const { enterprise, router } = this.props;
    // if (!devMode()) {
    //   return (
    //     <Redirect
    //       to={`/enterprise/${this.props.match.params.enterprise_id}/root/reports`}
    //     />
    //   );
    // }

    if (!enterprise) return <Loading />;

    let templateId = router.params.group_id
      ? "group-overview"
      : "enterprise-overview";

    const Template = getTemplate(templateId);

    return (
      <Template
        current={this.props.currentGroup}
        loading={this.props.currentGroup.loading}
      />
    );
  }
}
Overview.propTypes = {
  site: PropTypes.any,

  reports: PropTypes.object,
  enterprise: PropTypes.object,

  // enterprises: PropTypes.object,

  router: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    site: getCurrentSite(state, props),

    reports: state.reports,
    // eventMeta: state.eventMeta,
    enterprise: getEnterpriseFromRoute(state, props),
    enterprises: getEnterprises(state, props),
    router: { params: getRouterParams(state, props) },
    currentGroup: getCurrentGroup(state),
  };
};

Overview = connect(mapStateToProps)(Overview);
export { Overview };
export default withRouter(Overview);
