import React from "react";
import { connect, ReactReduxContext, Provider } from "react-redux";

import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";

import RoleSelect from "components/Enterprise/UserManagement/Common/RoleSelect";
import Features from "components/Enterprise/UserManagement/Common/Features";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import { Button, Form, Col, Card } from "react-bootstrap";

import { devMode } from "services/redux/selectors/";

import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import {
  requestUserManagementEditStart,
  requestUserManagementEditNotified,
  loadUserManagementUserListStart
} from "services/redux/actions";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ReactAlert = withReactContent(Swal);

class Edit extends React.Component {
  state = {
    role: "",
    features: []
  };
  getCurrentRole = () => {
    const { users, user } = this.props;
    const { roles } = users.current.options;

    // We have to search for the role and match by name
    // because we don't get ids from the user list but we use
    // ids to send changes for roles
    const currentRole = user && roles.find(role => role.name === user.role);

    return currentRole;
  };
  componentDidMount = () => {
    const { user } = this.props;

    const currentRole = this.getCurrentRole();

    this.setState({
      role: (currentRole && currentRole.id) || "",
      features: user.features
    });
  };

  componentDidUpdate() {
    const {
      group,
      enterprise,
      users,
      user,
      t
    } = this.props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    const user_id = user.id;

    const selectedUserId = users.actions.edit.user_id;

    const { success, error } = users.actions.edit;

    if (user_id === selectedUserId) {
      if (success) {
        // Refresh
        this.props.dispatch(
          loadUserManagementUserListStart({
            group_id,
            enterprise_id: enterprise.id
          })
        );
        Alert({
          text: t("success_message"),
          icon: "success",
          timerProgressBar: true,
          timer: 1500,
        });
        this.props.dispatch(requestUserManagementEditNotified());
      }
      if (error.message) {
        Alert({
          text: t(`errors.remove${devMode() ? "_dev" : ""}`, error.message),
          icon: "error",
          didClose: () => {
            // Reset error message
            this.props.dispatch(requestUserManagementEditNotified());
          }
        });
      }
    }
  }

  render() {
    const {
      group,
      enterprise,
      users,
      disabled,
      user,
      loggedInUser,
      t
    } = this.props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    const user_id = user.id;

    const selectedUserId = users.actions.edit.user_id;

    const { loading } = users.actions.edit;
    // const { success, error, loading } = users.actions.edit;

    // if (user_id === selectedUserId) {
    //   if (success) {
    //     // Refresh
    //     this.props.dispatch(
    //       loadUserManagementUserListStart({
    //         group_id,
    //         enterprise_id: enterprise.id
    //       })
    //     );
    //     Alert({
    //       text: t("success_message"),
    //       icon: "success"
    //     });
    //     this.props.dispatch(requestUserManagementEditNotified());
    //   }
    //   if (error.message) {
    //     Alert({
    //       text: t(`errors.remove${devMode() ? "_dev" : ""}`, error.message),
    //       icon: "error",
    //       onAfterClose: () => {
    //         // Reset error message
    //         this.props.dispatch(requestUserManagementEditNotified());
    //       }
    //     });
    //   }
    // }

    return (
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Button
            disabled={disabled || loggedInUser.email === user.email}
            onClick={() => {
              const currentRole = this.getCurrentRole();
              if (!currentRole) return;
              // Reset role state in case it has changed since previous
              // open
              this.setState({ role: this.getCurrentRole().id });

              ReactAlert.fire({
                customClass: "col-8",
                title: t("manage_access_title"),
                showCancelButton: true,
                confirmButtonText: t("confirm_button"),
                cancelButtonText: t("cancel_button"),
                html: (
                  <Form className="text-left" id="EditUser">
                    <Card bg="light">
                      <Card.Body>
                        <Card.Title className="text-uppercase font-weight-bold font-16">
                          <Icon className="fas fa-user-circle pr-2" />

                          {t(
                            "full_name",
                            user.first_name || "",
                            user.last_name || ""
                          )}
                          <span className="ml-2 text-muted text-lowercase font-12">
                            {user.email}
                          </span>
                        </Card.Title>
                        <Form.Row>
                          <Form.Group
                            controlId="formGroupEditUserAccess"
                            as={Col}
                            xl="6"
                            sm="6">
                            <Form.Label>{t("access_level")}</Form.Label>
                            <Provider store={store}>
                              <RoleSelect
                                user={user}
                                onSelect={e => {
                                  this.setState({
                                    role: e.target.value
                                  });
                                }}
                              />
                            </Provider>
                          </Form.Group>
                          {users.current.options.features.length > 0 && (
                            <Form.Group
                              controlId="formGroupEditUserFeatures"
                              as={Col}
                              xl="6"
                              sm="6">
                              <Form.Label>{t("features")}</Form.Label>
                              <Provider store={store}>
                                <Features
                                  user={user}
                                  onChange={() => {
                                    const features = [];

                                    document
                                      .querySelectorAll(
                                        "#EditUser input:checked"
                                      )
                                      .forEach(({ id }) => {
                                        features.push(parseInt(id));
                                      });

                                    this.setState({ features });
                                  }}
                                />
                              </Provider>
                            </Form.Group>
                          )}
                        </Form.Row>
                      </Card.Body>
                    </Card>
                  </Form>
                )
              }).then(result => {
                if (result.value) {
                  const { role, features } = this.state;
                  if (!role || !features) {
                    return;
                  }

                  // Build a changes object, setting the properties that
                  // have changed
                  const changes = {};
                  if (features !== user.features) {
                    changes.featureIds = features;
                  }
                  // Always send role_id
                  // if (parseInt(role) !== parseInt(this.getCurrentRole().id)) {
                  changes.role_id = parseInt(role);
                  // }

                  // No changes have been made
                  if (Object.keys(changes).length === 0) return;

                  this.props.dispatch(
                    requestUserManagementEditStart({
                      group_id,
                      enterprise_id: enterprise.id,
                      user_id: user.id,
                      user: {
                        ...user,
                        // Any changes will overwrite user
                        ...changes
                      }
                    })
                  );
                }
              });
              // Alert({
              //   text: t("confirm_message", groupName, userEmail),
              //   icon: "warning",
              //   showCancelButton: true,
              //   confirmButtonText: t("confirm_button"),
              //   cancelButtonText: t("cancel_button")
              // }).then(result => {
              //   if (result.value) {
              //     this.props.dispatch(
              //       requestUserManagementEditStart({
              //         user_id,
              //         group_id,
              //         enterprise_id: enterprise.id
              //       })
              //     );
              //   }
              // });
            }}>
            {user_id === selectedUserId && loading === true && <Loading />}{" "}
            <Icon className="fas fa-edit" />
          </Button>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    group: getGroupFromRoute(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
    loggedInUser: state.user,
    users: state.users
  };
};
export default connect(mapStateToProps)(
  withT(Edit, "user_management.user_list.edit_user")
);
