/**
 * Kept for legacy compatability for old reports
 */

import COLOUR_CODES from "constants/COLOUR_CODES.json";

const defaultColours = [
  COLOUR_CODES["restoreGreen"],
  COLOUR_CODES["failRed"],
  COLOUR_CODES["alertOrange"],
  COLOUR_CODES["infoPurple"],
];

export const COLORS = {
  default: defaultColours,
  open: defaultColours,
  close: defaultColours,
  fire: defaultColours,
  panic: defaultColours,
  overnight: ["#12274E"],
  peoplecount: ["#12274E"],
};
export const YKEYS = {
  open: ["ON_TIME", "LATE", "EARLY", "ABNORMAL"],
  close: ["ON_TIME", "LATE", "EARLY", "ABNORMAL"],
  fire: ["ON_TIME", "LATE", "EARLY", "ABNORMAL"],
  panic: ["ON_TIME", "LATE", "EARLY", "ABNORMAL"],
  overnight: ["none"],
  peoplecount: ["none"],
};
export const LABELS = {
  open: ["On Time", "Late", "Early", "Abnormal"],
  close: ["On Time", "Late", "Early", "Abnormal"],
  fire: ["On Time", "Late", "Early", "Abnormal"],
  panic: ["ON_TIME", "LATE", "EARLY", "ABNORMAL"],
  overnight: ["None"],
  peoplecount: ["None"],
};
