import { put, call } from "redux-saga/effects";

import { default as api } from "apis/facewatch";

import * as actions from "services/redux/actions";

// Facewatch Report
export function* loadStart(action) {
  const { dateFrom, dateTo } = action;

  try {
    const response = yield call(api.fetchPost, "/report", {
      dateFrom,
      dateTo
    });

    const result = { data: response.data };

    yield put(actions.loadFacewatchSuccess(result));
  } catch (error) {
    yield put(actions.loadFacewatchFailed({ error }));
  }
}
