// LOAD USER MANAGER OVERVIEW
export const loadUserManagementAccessReset = params => {
  return { type: "LOAD_USER_MANAGEMENT_ACCESS_RESET", ...params };
};
export const loadUserManagementAccessStart = params => {
  return { type: "LOAD_USER_MANAGEMENT_ACCESS_START", ...params };
};
export const loadUserManagementAccessSuccess = params => {
  return { type: "LOAD_USER_MANAGEMENT_ACCESS_SUCCESS", ...params };
};
export const loadUserManagementAccessFailed = params => {
  return { type: "LOAD_USER_MANAGEMENT_ACCESS_FAILED", ...params };
};
export const loadUserManagementAccessCancel = params => {
  return { type: "LOAD_USER_MANAGEMENT_ACCESS_CANCEL", ...params };
};
// LOAD USER MANAGER USER LIST
export const loadUserManagementUserListStart = params => {
  return { type: "LOAD_USER_MANAGEMENT_USER_LIST_START", ...params };
};
export const loadUserManagementUserListSuccess = params => {
  return { type: "LOAD_USER_MANAGEMENT_USER_LIST_SUCCESS", ...params };
};
export const loadUserManagementUserListFailed = params => {
  return { type: "LOAD_USER_MANAGEMENT_USER_LIST_FAILED", ...params };
};
export const loadUserManagementUserListCancel = params => {
  return { type: "LOAD_USER_MANAGEMENT_USER_LIST_CANCEL", ...params };
};
// REQUEST USER MANAGER INVITE
export const requestUserManagementInviteStart = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_INVITE_START" };
};
export const requestUserManagementInviteSuccess = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_INVITE_SUCCESS" };
};
export const requestUserManagementInviteFailed = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_INVITE_FAILED" };
};
export const requestUserManagementInviteCancel = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_INVITE_CANCEL" };
};
export const requestUserManagementInviteNotified = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_INVITE_NOTIFIED" };
};
// REQUEST USER MANAGER REMOVE
export const requestUserManagementRemoveStart = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_REMOVE_START" };
};
export const requestUserManagementRemoveSuccess = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_REMOVE_SUCCESS" };
};
export const requestUserManagementRemoveFailed = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_REMOVE_FAILED" };
};
export const requestUserManagementRemoveCancel = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_REMOVE_CANCEL" };
};
export const requestUserManagementRemoveNotified = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_REMOVE_NOTIFIED" };
};
// REQUEST USER MANAGER EDIT
export const requestUserManagementEditStart = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_EDIT_START" };
};
export const requestUserManagementEditSuccess = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_EDIT_SUCCESS" };
};
export const requestUserManagementEditFailed = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_EDIT_FAILED" };
};
export const requestUserManagementEditCancel = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_EDIT_CANCEL" };
};
export const requestUserManagementEditNotified = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_EDIT_NOTIFIED" };
};
// REQUEST USER MANAGER SEARCH
export const requestUserManagementSearchStart = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_SEARCH_START" };
};
export const requestUserManagementSearchSuccess = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_SEARCH_SUCCESS" };
};
export const requestUserManagementSearchFailed = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_SEARCH_FAILED" };
};
export const requestUserManagementSearchCancel = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_SEARCH_CANCEL" };
};
export const requestUserManagementSearchReset = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_SEARCH_RESET" };
};

// REQUEST USER MANAGER CREATE
export const requestUserManagementCreateStart = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_CREATE_START" };
};
export const requestUserManagementCreateSuccess = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_CREATE_SUCCESS" };
};
export const requestUserManagementCreateFailed = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_CREATE_FAILED" };
};
export const requestUserManagementCreateCancel = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_CREATE_CANCEL" };
};
export const requestUserManagementCreateReset = params => {
  return { ...params, type: "REQUEST_USER_MANAGEMENT_CREATE_RESET" };
};
