import React from "react";

import icons from "./SVG/index";

class SVGIcon extends React.Component {
  render() {
    const { type, source } = this.props;
    let icon = icons[this.props.type];

    const width = source === 'vm' ? "25px" : "100%";

    if (typeof icon === "undefined") {
      if (type !== null && type !== "none")
        console.warn("Missing icon type: " + this.props.type);

      // Revert to default
      icon = icons["burglary"];
    }
    const { color } = this.props || { color: "#000000" };
    return icon({ color, width });
  }
}
export default SVGIcon;
