/**
 * Reports focused on counting of failed out of a total
 * i.e. 10 sites out of 100 failed
 *
 * panic and fire (exception)
 */

import * as yup from "yup";

const config = yup
  .object()
  .shape({
    title: yup.string().optional()
  })
  .optional();

const sites = yup.array().of(yup.object().required());

const totals = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required(),
      value: yup.number().required(),
      colour: yup.string().required()
    })
  )
  .required();

export default yup.object().shape({
  data: yup
    .object()
    .shape({
      totals,
      sites,
      config
    })
    .required()
});
