import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLayout from "./PageLayout";

import moment from "services/locale/momentInit.js";
import get from "lodash/get";

import { getNow } from "services/locale/selectors";

import {
  loadScheduledReportStart,
  loadScheduledReportCancel
} from "services/redux/actions";

import { loadSiteViewLiveRefreshCancel, setScheduledReportUserTimezoneUTCOffset } from "services/redux/actions";

import { getRouterParams } from "services/redux/selectors/app/";

import { getReportType } from "services/redux/selectors/reports/router";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getScheduledReport } from "services/redux/selectors/reports/scheduled/";
import { getConfig } from "services/redux/selectors/reports/common/";

import {
  getDefaultDay,
  getYesterdayIsLive
} from "components/Enterprise/Reports/Common/DayPicker/selectors";

class ScheduledReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      day: getDefaultDay(getNow(), props.config.dayPicker),
      interval: null
    };
  }
  /**
   * Calculates interval based on current time
   * See constants/Reports/config
   * Open:
   *  Time > 4AM or Time < 11AM - short interval
   *  Else: long interval
   * Close:
   *  Time 3PM - 2AM - short interval
   *  Else: long interval
   */
  calculateInterval() {
    // Default/no interval set
    if (this.props.config.liveRefresh === null) return null;

    const {
      windowStart,
      windowEnd,
      shortInterval,
      longInterval
    } = this.props.config.liveRefresh;

    const now = getNow();
    // const windowStartHour = INTERVALS[reportType].windowStart;
    // const windowEndHour = INTERVALS[reportType].windowEnd;

    const windowStartMoment = getNow()
      .minutes(0)
      .hours(windowStart);

    let windowEndMoment = getNow()
      .minutes(0)
      .hours(windowEnd);

    // End hour is before the start hour - this must mean it's tomorrow
    if (windowEnd < windowStart) {
      windowEndMoment = windowEndMoment.add(1, "days");
    }

    if (now.isAfter(windowStartMoment) && now.isBefore(windowEndMoment)) {
      // console.log("short");
      return shortInterval;
    }
    // console.log("long");
    return longInterval;
  }
  componentDidMount() {
    // const reports = this.props.reports;
    // const reportType = this.props.reportType;
    const { loading, reportType } = this.props;

    if (reportType === "default") return;

    // const interval = this.calculateInterval();

    // Set default day selection to yesterday until today is enabled

    loading !== true && this.changeDate(this.state.day);

    // // Defaults to today if no 'day' param is passed
    // const params = {
    //   enterprise_id: this.props.enterprise_id,
    //   interval,
    //   reportType,
    //   live: true,
    //   // Fallback to no refresh if interval is falsey
    //   refresh: interval || false
    // };

    // Dispatch request for initial report data
    //  only dispatch if we aren't already loading one
    // loading !== true && this.props.dispatch(loadScheduledReportStart(params));
  }
  componentWillUnmount() {
    const { reportType } = this.props;
    // Cancel any live report auto refresh
    this.props.dispatch(loadScheduledReportCancel({ reportType }));
  }
  changeDate = date => {
    const { router } = this.props;

    // Hide sidepanel if visible (#272)
    document.body.classList.remove("sidepane-active");

    this.props.dispatch(
      loadSiteViewLiveRefreshCancel()
    );

    // “day” to get data for a particular date
    // the default format is Y-m-d (but you can pass also d-m-Y)
    let params = {
      enterprise_id: this.props.enterprise.id,
      reportType: this.props.reportType,
      group_id:
        router.params.group_id === "root"
          ? this.props.enterprise.root_group_id
          : router.params.group_id
    };

    this.setState({ day: date });

    if (!this.live(date)) {
      // Cancel any live report auto refresh
      this.props.dispatch(loadScheduledReportCancel());

      params.day = moment(date).format("YYYY-MM-DD");

      // Dispatch request for report data
      this.props.dispatch(loadScheduledReportStart(params));
    } else {
      // Fallback to no refresh if interval is falsey
      params.refresh = this.calculateInterval() || false;
      params.live = true;
      params.interval = this.calculateInterval();
      this.props.dispatch(loadScheduledReportStart(params));
    }

    const timezoneUTCOffset = date.getTimezoneOffset();
    const timezoneUTCOffsetHours = Math.floor((Math.abs(timezoneUTCOffset)/60));
    const timezoneUTCOffsetMinutes = Math.abs(timezoneUTCOffset) % 60;
    const timezoneUTCOffsetSign = (timezoneUTCOffset >= 0) ? 1 : -1;

    this.props.dispatch(
      setScheduledReportUserTimezoneUTCOffset({
        reportType: this.props.reportType,
        userTimezoneUTCOffset: {
          hours: timezoneUTCOffsetHours,
          minutes: timezoneUTCOffsetMinutes,
          sign: timezoneUTCOffsetSign,
        }
      })
    );
  };
  getReportTitle = () => {
    const date = moment(this.state.day).format("YYYY-MM-DD");

    return this.props.reportType + " " + date;
  };
  // Checks if 'date' is for live data
  live = date => {
    const now = getNow();
    const yesterday = getNow().subtract(1, "day");
    const day = new moment(date);

    // Yesterday is still the "live" day
    //   for this report
    if (getYesterdayIsLive(now, this.props.config.dayPicker)) {
      return (
        day.get("month") === yesterday.get("month") &&
        day.get("date") === yesterday.get("date")
      );
    }

    return (
      day.get("month") === now.get("month") &&
      day.get("date") === now.get("date")
    );
  };
  render() {
    // const data = _.get(this.props.reports, reportType);

    return (
      <PageLayout
        changeDate={this.changeDate}
        selectedDay={this.state.day}
        getReportTitle={this.getReportTitle}
        index={get(this.props, "index")}
      />
    );
  }
}
ScheduledReportPage.propTypes = {
  reportType: PropTypes.string.isRequired,
  enterprise: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    // reports: state.reports,
    loading: getScheduledReport(state, props).loading,
    reportType: getReportType(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
    router: { params: getRouterParams(state, props) },
    config: getConfig(state, props)
  };
};

export default connect(mapStateToProps)(ScheduledReportPage);
