import { createSelector } from "reselect";

import { getEventTotals } from "../outstanding/bundle/index";

import { getReportPath } from "services/redux/selectors/reports/router";
import { getScheduledTotals, getScheduledTotal } from "../scheduled/index";

const getTotals = {
  scheduled: getScheduledTotals,
  exception: getScheduledTotals,
  outstanding: getEventTotals,
  default: () => {
    return { data: [], colors: [] };
  },
};

// const filters = {
//   open: getEventTypeFilter,
//   close: getEventTypeFilter,
//   fire: getEventTypeFilter,

//   overnight: getEventCountFilter,

//   bundle: getTimeInFailFilter,
//   default: () => {
//     return {};
//   }
// };

/**
 * @returns {data: Array}
 */
export const getPieChartTotals = createSelector(
  [getReportPath],
  (reportPath) => getTotals[reportPath[0] || "default"]
);

export const getPieChartTotal = createSelector(
  [getReportPath],
  (reportPath) => {
    if (reportPath[0] === "scheduled") {
      return getScheduledTotal
    } else {
      return () => {
        return "";
      }
    }
  }
);

