import React from "react";
import { connect } from "react-redux";

import { renderToStaticMarkup } from "react-dom/server";
import html2pdf from "html2pdf.js";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { Button } from "react-bootstrap";

import { assignAlarmSOPUser } from "services/redux/actions/alarmResponse";
import { loadSOPActionMapStart } from "services/redux/actions/sop";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import {
  getGroupFromRoute,
  getGroups,
  getGroupById,
} from "services/redux/selectors/groups";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import Alert from "components/Common/Alert";
import { Tooltip } from "components/Common/Tooltip/";
import SVGIcon from "components/Icons/SVGIcon";

import { OtherEvent, AssignedUser, SiteGroup } from "../Cells/";

import ManualStep from "./Steps/ManualStep";
import KeyholderStep from "./Steps/KeyholderStep";
import DispatchKeyholderStep from "./Steps/DispatchKeyholderStep";

import { buildSOPViewCompletedActionJSX } from "./Export/SOPViewCompletedJSX";

import ReactTimeAgo from 'react-time-ago';

import moment from "services/locale/momentInit.js";

import { formatMstoMS } from "services/date/date";

import COLOUR_CODES from "constants/COLOUR_CODES.json";

import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

import "./SOPView.scss";

class SOPView extends React.Component {

  getUserIcon = (firstName, lastName) => {
    return (
      <div className="step-link-comment-icon">
        <Tooltip
          description={`${firstName} ${lastName}`}
          placement="left"
        >
          <span>{`${firstName[0]}${lastName[0]}`}</span>
        </Tooltip>
      </div>
    )
  }

  handleAssignUser = () => {
    const { 
      enterpriseId,
      groupId,
      user,
      row,
      liveRefreshAlarms,
      muted,
      continuousSounds,
      playNextPrioritySound,
    } = this.props;
    const sudoStateId = row.sudo_state[0].id;
    const mctAlarmLogId = row.sudo_state[0].mct_alarm_log_id;
    const enterpriseSiteId = row.enterprise_site_id;
    const userId = user.id;

    const assignUserButton = document.getElementById("sop-assign-user-button");

    // /out_api/sop/sop-action-process/assign-event/{{enterprise_id}}/{{group_id}}/{{sudo_state_id}}
    // Required Parameters:
    //   mct_alarm_log_id
    //   enterprise_site_id
    //   user_id
    const assignUser = conxtdOut.post(`/sop/sop-action-process/assign-event/${enterpriseId}/${groupId}/${sudoStateId}`, {
      mct_alarm_log_id: mctAlarmLogId,
      enterprise_site_id: enterpriseSiteId,
      user_id: userId,
    });

    assignUserButton.classList.add("cursor-progress");
    assignUserButton.disabled = true;

    assignUser.then(() => {
      const sop_action_assigned_user = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        assigned: Date.now(),
      }

      if (liveRefreshAlarms) {
        // socket not connected so dispatch action to update user in state so it's visible before next refresh
        this.props.dispatch(
          assignAlarmSOPUser({
            enterprise_site_id: enterpriseSiteId,
            sudo_state_id: sudoStateId,
            sop_action_assigned_user: sop_action_assigned_user,
          })
        );
      }

      this.props.dispatch(
        loadSOPActionMapStart({
          enterprise_id: enterpriseId,
          group_id: groupId,
          sudo_state_id: sudoStateId,
        })
      );

      if (continuousSounds && !muted) {
        try {
          playNextPrioritySound(sudoStateId);
        } catch(ignore) {}
      }

    }).catch((error) => {
      let errorMessage = "";
      if (error.response) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.message;
      }

      this.props.dispatch(
        loadSOPActionMapStart({
          enterprise_id: enterpriseId,
          group_id: groupId,
          sudo_state_id: sudoStateId,
        })
      );

      if (continuousSounds && !muted) {
        try {
          playNextPrioritySound(sudoStateId);
        } catch(ignore) {}
      }

      console.log("Error on assign user: ", errorMessage);
      Alert({
        text: "Issue assigning user. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
      assignUserButton.classList.remove("cursor-progress");
      assignUserButton.disabled = false;
    })
  }

  handleAmendAction = (sopActionedEventId, sudoStateId, actionUpdateType, status, systemData, comment, addComment) => { // addComment is a requirement for the back end 
                                                                                                                         // and should be true if we're adding a comment 
                                                                                                                         // without making any other changes at the same time
    return new Promise(async (resolve, reject) => {
      const { enterpriseId, groupId } = this.props;

      const encodedComment = encodeURIComponent(comment);

      // /out_api/sop/sop-action-process/update-action/:enterprise_id/:enterprise_group_id/:sop_actioned_event_id
      // Required Parameters;
      //   action_update_type - "A" for amending an action, "C" for continue to next keyholder
      //   status - the contact status you wish to change the current event to. This is retrieved from the "action-map" call
      //   system_data - id of the contact, be it site or keyholder, that you wish to contact. Bear in mind that you do not have to parse this when using a manual step
      // Optional Parameters;
      //   comment

      const updateAction = conxtdOut.post(`/sop/sop-action-process/update-action/${enterpriseId}/${groupId}/${sopActionedEventId}`, {
        action_update_type: actionUpdateType,
        status: status,
        system_data: systemData,
        comment: encodedComment,
        add_comment: addComment,
      });

      updateAction.then(() => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        resolve();

      }).catch((error) => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      })
    })
  }

  handleEscalateAction = (sopActionedEventId, comment) => {
    return new Promise(async (resolve, reject) => {
      const { enterpriseId, groupId, row } = this.props;

      // /out_api/sop/sop-action-process/update-action/:enterprise_id/:enterprise_group_id/:sop_actioned_event_id
      // Required Parameters;
      //   action_update_type - "E"
      // Optional Parameters;
      //   comment

      const sudoStateId = row.sudo_state[0].id;

      let params = {
        action_update_type: "E",
      };

      if (comment !== "") {
        params.comment = encodeURIComponent(comment);
      }

      const escalateAction = conxtdOut.post(`/sop/sop-action-process/update-action/${enterpriseId}/${groupId}/${sopActionedEventId}`, params);

      escalateAction.then(() => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        resolve();

      }).catch((error) => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
        
      })
    })
  }

  handleDeEscalateAction = (sopActionedEventId, comment) => {
    return new Promise(async (resolve, reject) => {
      const { enterpriseId, groupId, row } = this.props;

      // /out_api/sop/sop-action-process/update-action/:enterprise_id/:enterprise_group_id/:sop_actioned_event_id
      // Required Parameters;
      //   action_update_type - "D"
      // Optional Parameters;
      //   comment

      const sudoStateId = row.sudo_state[0].id;

      let params = {
        action_update_type: "D",
      };

      if (comment !== "") {
        params.comment = encodeURIComponent(comment);
      }

      const deEscalateAction = conxtdOut.post(`/sop/sop-action-process/update-action/${enterpriseId}/${groupId}/${sopActionedEventId}`, params);

      deEscalateAction.then(() => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        resolve();

      }).catch((error) => {
        this.props.dispatch(
          loadSOPActionMapStart({
            enterprise_id: enterpriseId,
            group_id: groupId,
            sudo_state_id: sudoStateId,
          })
        );

        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
        
      })
    })
  }

  render() {
    const {
      enterprise,
      group,
      groupId,
      groups,
      userAccess,
      row,
      sop,
      getEventMetaById,
      user,
      darkMode,
    } = this.props;

    let voiceCalls = false;
    if ((enterprise.id !== -1) && (enterprise.voice_calls) && (userAccess.loading === false) && userAccess && group) {
      if ((group.id === "root") || (group.id === enterprise.root_group_id)) {
        if (_.find(userAccess.enterprise.features, ['id', 11])) { // id=11 for Voice Calls
          voiceCalls = true;
        }
      } else {
        const groupAccess = _.find(userAccess.groups, ['id', group.id]);
        if (groupAccess && groupAccess.features && _.find(groupAccess.features, ['id', 11])) { // id=11 for Voice Calls
          voiceCalls = true;
        }
      }
    }

    const closedAction = !!this.props.closedAction;

    let siteGroup = null;

    if (row) {
      const { sopMap } = this.props.sop;

      if (row.site && row.site.enterprise_groups && row.site.enterprise_groups.length > 0) {
        // use the first group in the list, which should be the lowest in the group tree
        siteGroup = getGroupById(groups, row.site.enterprise_groups[0].id);
      }

      const hasActionMap = !!sopMap;

      const sopActionedEventId = (sopMap && sopMap.sop_actioned_event_id) || null;
      const sudoStateId = (row.sudo_state && row.sudo_state[0].id) || null;

      let event_id = "";
      if (row.sudo_state[0].state === 'A') {
        event_id = row.sudo_state[0].alarm_event_id;
      } else if (row.sudo_state[0].state === 'R') {
        event_id = row.sudo_state[0].restore_event_id;
      } else {
        event_id = row.sudo_state[0].event_id;
      }

      const event = getEventMetaById(event_id);
      const { icon, label, colour } = event;

      const assignedUser = (
        <AssignedUser
          assignedUser={sop.assignedUser}
        />
      )

      const isAssignedUser = (sop.assignedUser && sop.assignedUser.user_id && sop.assignedUser.user_id === user.id) || false;

      const time = new moment(row.sudo_state[0].recvd);
      const area = row.sudo_state[0].area;
      const zone = row.sudo_state[0].zone;
      const alarm_text = row.sudo_state[0].alarm_text;

      const eventIcon = icon ? (
        <div
          id={icon}
          className={`svg-icon text-white`}
          style={{
            display: "inline-block",
            width: "18px",
            height: "18px",
            position: "relative",
            top: "-1px",
            marginRight: "6px",
          }}
        >
          <SVGIcon type={icon} color={"#ffffff"} />
        </div>
      ) : null;

      let alarm1 = null;
      let alarm2 = null;
      let alarm3 = null;
      const { sudo_state } = row;
      if (sudo_state[1]) {
        if (sudo_state[1].state === 'A') {
          alarm1 = getEventMetaById(sudo_state[1].alarm_event_id);
        } else {
          alarm1 = getEventMetaById(sudo_state[1].restore_event_id);
        }
      }
      if (sudo_state[2]) {
        if (sudo_state[2].state === 'A') {
          alarm2 = getEventMetaById(sudo_state[2].alarm_event_id);
        } else {
          alarm2 = getEventMetaById(sudo_state[2].restore_event_id);
        }
      }
      if (sudo_state[3]) {
        if (sudo_state[3].state === 'A') {
          alarm3 = getEventMetaById(sudo_state[3].alarm_event_id);
        } else {
          alarm3 = getEventMetaById(sudo_state[3].restore_event_id);
        }
      }
      const moreAlarmsNumber = sudo_state.length - 4;

      const moreAlarms = (
        <div>
          <div 
            className="d-flex justify-content-start"
            style={{height: "36px"}}
          >
            {alarm1 && <OtherEvent 
              recvd={row.sudo_state[1].recvd} 
              alarm={alarm1}
              alarm_text={row.sudo_state[1].alarm_text}
              area={row.sudo_state[1].area}
              zone={row.sudo_state[1].zone}
            />}
            {alarm2 && <OtherEvent 
              recvd={row.sudo_state[2].recvd} 
              alarm={alarm2}
              alarm_text={row.sudo_state[2].alarm_text}
              area={row.sudo_state[2].area}
              zone={row.sudo_state[2].zone}
            />}
            {alarm3 && <OtherEvent 
              recvd={row.sudo_state[3].recvd} 
              alarm={alarm3}
              alarm_text={row.sudo_state[3].alarm_text}
              area={row.sudo_state[3].area}
              zone={row.sudo_state[3].zone}
            />}
            {(moreAlarmsNumber > 0) && <div className="d-flex align-items-center ml-1 font-weight-bold" style={{fontSize: "large"}}>+{moreAlarmsNumber}</div>}
          </div>
        </div>
      )

      const steps = [];
      if (sopMap && sopMap.sop_action_library) {
        sopMap.sop_action_library.forEach((step, index) => {
          steps.push({
            ...step,
            stepNumber: (index + 1),
          });
          if (closedAction) { 
            if (step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0) {
              step.sop_action.sop_actioned_logs.forEach((logItem) => {
                const logContactStatus = logItem.status;
                const logContactStatusType = logItem.status_key;
                const closeDownStep = (logContactStatusType === "action_update_type") && (logContactStatus === "U");
                if (closeDownStep) {
                  steps.push({
                    ...step,
                    closeDownStep: true,
                    closeDownTime: logItem.created,
                  });
                }
              })
            }
          }
        })
      }

      return (
        <div className={`row pt-4 sop-action-map-content${closedAction ? " closed-action" : ""}`}>
          <div className="col-12" style={{marginBottom: "50px"}}>
            {closedAction &&
              <div className="mb-3 mr-1 d-flex justify-content-between">
                <SiteGroup
                  siteGroup={siteGroup}
                  placement="right"
                />
                <Tooltip
                  placement="left"
                  description="Export to PDF"
                >
                  <Button
                    onClick={() => {
                      if (sopMap) {
                        const JSXContent = buildSOPViewCompletedActionJSX(
                          row,
                          sop.assignedUser,
                          eventIcon,
                          label,
                          colour,
                          time,
                          area,
                          zone,
                          sop.contactStatus,
                          steps
                        );

                        if (JSXContent) {
                          const HTMLContent = renderToStaticMarkup(JSXContent);

                          const fileName = `${row.site_name} - ${label} - ${time.format("DD MMM YYYY")} - SOP.pdf`;

                          var options = {
                            margin: 0.75,
                            filename: fileName,
                            // image: { type: 'jpeg', quality: 0.98 },
                            // html2canvas: { scale: 2 },
                            pagebreak: { avoid: '.avoid-page-break' },
                            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                          };

                          html2pdf(HTMLContent, options);
                        }
                      }
                    }}
                  >
                    Export
                  </Button>
                </Tooltip>
              </div>
            }

            {/* Header information */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              {!closedAction && <div>{assignedUser}</div>}
              <div
                style={{
                  backgroundColor: colour,
                  padding: "0.3rem 1rem 0.3rem 1rem",
                  borderRadius: "16px",
                  height: "32px",
                  paddingTop: "6px",
                  fontWeight: "bold"
                }}
                className="d-flex justify-content-between flex-grow-1 text-light mx-1"
              >
                {
                  alarm_text 
                  ? <Tooltip
                      placement="left"
                      description={alarm_text}
                    >
                      <div>
                        {eventIcon}
                        <span>{label}</span>
                      </div>
                    </Tooltip>
                  : <div>
                      {eventIcon}
                      <span>{label}</span>
                    </div>
                }
                <div>{time.format("HH:mm")}</div>
                <div>
                  <ReactTimeAgo 
                    date={time.valueOf()} 
                    locale="en-GB"
                    timeStyle="twitter"
                    tooltip={false}
                    wrapperComponent={({ verboseDate, children }) => {
                      return <Tooltip 
                        description={verboseDate}
                        placement="right"
                      >{children}</Tooltip>
                    }}
                  />
                </div>
                <div>{area}</div>
                <div>{zone}</div>
              </div>
              <div>{!closedAction && moreAlarms}</div>
            </div>
            <div className="d-flex justify-content-center">
              {!hasActionMap && <div style={{fontWeight: "bold", fontSize: "16px"}}>No steps configured for this event</div>}
              {
                hasActionMap && !sop.assignedUser &&
                <Button
                  id="sop-assign-user-button"
                  onClick={this.handleAssignUser}
                >Assign Me</Button>
              }
            </div>

            {/* Steps */}
            {sopMap && sopMap.sop_action_library &&
              <VerticalTimeline layout="1-column" className="sop-action-map-timeline py-0">

                {/* Closed action details */}
                {closedAction && 
                  <VerticalTimelineElement
                    key={"closedItemHeader"}
                    className="sop-action-map-timeline-element vertical-timeline-element--work py-4 my-2"
                    contentStyle={ darkMode
                      ? { border: "1px solid #bbb", backgroundColor: "#0f1f3e" }
                      : { border: "1px solid #bbb" }
                    }
                    contentArrowStyle={{ borderRight: "7px solid #bbb" }}
                    icon={<div className="step-number-icon">{`${
                        (sop.assignedUser.first_name && sop.assignedUser.first_name.length > 0) ? String(sop.assignedUser.first_name[0]).toUpperCase() : ""
                      }${
                        (sop.assignedUser.last_name && sop.assignedUser.last_name.length > 0) ? String(sop.assignedUser.last_name[0]).toUpperCase() : ""
                      }`}</div>}
                    iconStyle={{ background: "#2c3e50", marginTop: "2em" }}
                  >
                    <h6 className="vertical-timeline-element-title">{`${sop.assignedUser.first_name} ${sop.assignedUser.last_name}`}</h6>
                    <div
                      className="mt-2 d-flex justify-content-between"
                      style={{borderBottom: "1px solid lightgray"}}
                    >
                      <div style={{ fontWeight: "bold" }}>Assigned</div>
                      <div
                        className="text-muted"
                        style={{minWidth: "130px", textAlign: "right"}}
                      >{(new moment(sop.assignedUser.assigned)).format("HH:mm DD/MM/YYYY")}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{borderBottom: "1px solid lightgray"}}
                    >
                      <div style={{ fontWeight: "bold" }}>Response</div>
                      <div
                        className="text-muted"
                        style={{minWidth: "130px", textAlign: "right"}}
                      >{formatMstoMS(row.response)}</div>
                    </div>
                  </VerticalTimelineElement>
                }

                {/* list out all the steps  */}
                {steps.map((step, stepIndex) => {

                  const stepType = step.system_data; // whether it's manual, keyholder...

                  let activeStep = false;
                  if (closedAction) {
                    // in the case of a closed action, show all steps that were completed as "active", and the ones that weren't completed as greyed out
                    activeStep = step.sop_action.complete;
                  } else if (sop.assignedUser) {
                    activeStep = (stepIndex === sopMap.active_step_index);
                  }

                  const firstStep = (stepIndex === 0);
                  const lastStep = (stepIndex === (sopMap.sop_action_library.length - 1));

                  if (!step.closeDownStep) { // just one of the normal steps
                    if (stepType === "keyholder") {
                      return <KeyholderStep
                        key={stepIndex}
                        enterprise={enterprise}
                        groupId={groupId}
                        site={row.site}
                        sopActionedEventId={sopActionedEventId}
                        sudoStateId={sudoStateId}
                        step={step}
                        stepNumber={step.stepNumber}
                        activeStep={activeStep}
                        closedAction={closedAction}
                        firstStep={firstStep}
                        lastStep={lastStep}
                        isAssignedUser={isAssignedUser}
                        handleAmendAction={this.handleAmendAction}
                        handleEscalateAction={this.handleEscalateAction}
                        handleDeEscalateAction={this.handleDeEscalateAction}
                        voiceCalls={voiceCalls}
                      />
                    } else if (stepType === "keyholding_company") {
                      return <DispatchKeyholderStep
                        key={stepIndex}
                        enterprise={enterprise}
                        groupId={groupId}
                        site={row.site}
                        sopActionedEventId={sopActionedEventId}
                        sudoStateId={sudoStateId}
                        step={step}
                        stepNumber={step.stepNumber}
                        activeStep={activeStep}
                        closedAction={closedAction}
                        firstStep={firstStep}
                        lastStep={lastStep}
                        isAssignedUser={isAssignedUser}
                        handleAmendAction={this.handleAmendAction}
                        handleEscalateAction={this.handleEscalateAction}
                        handleDeEscalateAction={this.handleDeEscalateAction}
                        voiceCalls={voiceCalls}
                      />
                    } else {
                      return <ManualStep
                        key={stepIndex}
                        sopActionedEventId={sopActionedEventId}
                        sudoStateId={sudoStateId}
                        step={step}
                        stepNumber={step.stepNumber}
                        activeStep={activeStep}
                        closedAction={closedAction}
                        firstStep={firstStep}
                        lastStep={lastStep}
                        isAssignedUser={isAssignedUser}
                        handleAmendAction={this.handleAmendAction}
                        handleEscalateAction={this.handleEscalateAction}
                        handleDeEscalateAction={this.handleDeEscalateAction}
                      />
                    }
                  } else { // not one of the steps in the action map - this is an extra item added to the list that displays the close down information
                    /* close down details */
                    const created = new moment(step.closeDownTime);
                    
                    return (
                      <VerticalTimelineElement
                        key={"closedItemDetails"}
                        className="sop-action-map-timeline-element vertical-timeline-element--work py-4 my-2"
                        contentStyle={ darkMode
                          ? { border: "1px solid #bbb", backgroundColor: "#0f1f3e" }
                          : { border: "1px solid #bbb" }
                        }
                        contentArrowStyle={{ borderRight: "7px solid #bbb" }}
                        icon={<span className="step-number-icon">&#10003;</span>}
                        iconStyle={{ 
                          background: COLOUR_CODES["restoreGreen"],
                          marginTop: "2em",
                          fontSize: "16px",
                        }}
                      >
                        <h6 className="vertical-timeline-element-title">
                          {row.closeDownOption.type === "R" && "Real Alarm"}
                          {row.closeDownOption.type === "F" && "False Alarm"}
                          {row.closeDownOption.type === "U" && "Other"}
                        </h6>
                        <div
                          className="mt-2 d-flex justify-content-between"
                          style={{borderBottom: "1px solid lightgray"}}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {row.closeDownOption.option}
                          </div>
                          <div 
                            className="text-muted"
                            style={{minWidth: "130px", textAlign: "right"}}
                          >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                        </div>
                        <div>
                          {
                            row.sop_close_down_log
                            && row.sop_close_down_log.sop_close_down_comment
                            && row.sop_close_down_log.sop_close_down_comment.comment
                            && (row.sop_close_down_log.sop_close_down_comment.comment.length > 0)
                            &&
                              <div 
                                className="ml-2 d-flex justify-content-between"
                                style={{borderBottom: "1px solid lightgray"}}
                              >
                                <div>{decodeURIComponent(row.sop_close_down_log.sop_close_down_comment.comment)}</div>
                                <div 
                                  className="text-muted"
                                  style={{minWidth: "130px", textAlign: "right"}}
                                >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                              </div>
                          }
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{borderBottom: "1px solid lightgray"}}
                        >
                          <div style={{ fontWeight: "bold" }}>SOP Duration</div>
                          <div 
                            className="text-muted"
                            style={{minWidth: "130px", textAlign: "right"}}
                          >{formatMstoMS(row.duration)}</div>
                        </div>
                      </VerticalTimelineElement>
                    )
                  }
                })}
              </VerticalTimeline>
            }
          </div>
        </div>
      );
    } else {
      return (<div></div>);
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props) || {
      id: "root",
    },
    groups: getGroups(state, props),
    user: state.user,
    userAccess: state.user.access,
    sop: state.sop,
    getEventMetaById: createGetEventMetaById(state, props),
    liveRefreshAlarms: state.alarmResponse.liveRefreshAlarms,
    muted: state.alarmResponse.muted,
    continuousSounds: state.alarmResponse.continuousSounds,
    darkMode: state.app.darkMode,
  };
};
export default connect(mapStateToProps)(SOPView);