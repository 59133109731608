import React from "react";

import { withT } from "services/translation/";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import { getUsingSystemDefault } from "services/redux/selectors/reports/scheduled";

function UsingSystemDefault({ original, value, t }) {
  return getUsingSystemDefault(original) ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`tooltip-right`} style={{ pointerEvents: "none" }}>
          <div style={{ fontSize: "12px" }} className="py-1">
            <b>{t("title")}</b>
            <br />
            {t("description")}
          </div>
        </Tooltip>
      }>
      <Icon className="fas fa-clock" />
    </OverlayTrigger>
  ) : null;
}

export default withT(
  UsingSystemDefault,
  "reports.scheduled.data_table.using_system_default_tooltip"
);
