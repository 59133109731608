import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { ButtonToolbar, ButtonGroup } from "react-bootstrap";

import Remove from "./Remove";
import Edit from "./Edit";

import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getCanEditUser } from "services/redux/selectors/users";

class Options extends React.Component {
  render() {
    const { users, original, canEditUser } = this.props;

    const user_id = original.id;

    const selectedUserId = users.actions.removeAccess.user_id;

    let disabled =
      users.actions.removeAccess.loading === true ||
      users.actions.edit.loading === true;

    let loading = false;

    if (user_id === selectedUserId) {
      loading =
        users.actions.removeAccess.loading === true ||
        users.actions.edit.loading === true;
    }

    // Override user action buttons if user cannot be edited
    if (!canEditUser) {
      disabled = true;
    }

    const content = loading ? (
      <Loading center />
    ) : (
      <ButtonToolbar>
        <ButtonGroup className="mx-auto">
          <Edit user={original} disabled={disabled} />
          <Remove user={original} disabled={disabled} />
        </ButtonGroup>
      </ButtonToolbar>
    );

    return content;
  }
}

const mapStateToProps = (state, props) => {
  return {
    group: getGroupFromRoute(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
    users: state.users,
    canEditUser: getCanEditUser(state, props)
  };
};
export default connect(mapStateToProps)(Options);
