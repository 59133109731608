import React from "react";
import PropTypes from "prop-types";

import { withT } from "services/translation/";

import Loading from "components/Loading";

class LoadMore extends React.Component {
  render() {
    const {
      onLoadMore,
      timeframe,
      hoursOffset = 24,
      timeframeLimit = 168,
      loading,
      t
    } = this.props;

    const canLoadMore = parseInt(timeframe) + 24 < timeframeLimit;

    return (
      <div
        onClick={() => {
          // Prevent more than 1 week of data
          // from being loaded
          if (!canLoadMore) return;
          onLoadMore(parseInt(timeframe) + hoursOffset);
        }}
        className={`btn btn-primary w-100 ${
          loading || !canLoadMore ? "disabled" : ""
        }`}>
        {t("load_more")} {loading && <Loading />}
      </div>
    );
  }
}
LoadMore.propTypes = {
  /** Loading state for button */
  loading: PropTypes.bool,
  /** Current timeframe */
  timeframe: PropTypes.any.isRequired,
  /**
   * (Optional) Hours to offset to passed timeframe when loading more
   * Default is 24, so adds 24 hours to current timeframe
   */
  hoursOffset: PropTypes.number,
  /**
   * (Optional) Limit of timeframe hours to prevent loading too much.
   * Default 168 (7 days)
   */
  timeframeLimit: PropTypes.number
};

export default withT(LoadMore, "timeline");
