import React from "react";

import ErrorBadge from "components/Layout/ErrorBadge";

export default function ApiErrorBadge({ status, message, t, translationKey }) {
  if (status || message) {
    return <ErrorBadge message={t(translationKey, message)} />;
  }
  return null;
}
