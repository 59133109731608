import React from "react";

import ReactTable from "react-table";

import { withT } from "services/translation/";

import { contains } from "services/filters/";

const columnProperties = [
  {
    key: "position"
  },
  {
    key: "name"
  },
  {
    key: "email"
  },
  {
    key: "phone"
  },
  {
    key: "job_title"
  }
  // {
  //   key: "actions"
  // }
];

class DataTable extends React.Component {
  state = {
    filtered: []
  };
  render() {
    const { t, data } = this.props;

    const columns = columnProperties.map(({ key }) => {
      return {
        Header: t("data_table.headers." + key),
        accessor: key,
        id: key
      };
    });

    return (
      <div>
        <ReactTable
          style={{
            overflowX: "scroll"
          }}
          className="mt-2"
          filtered={this.state.filtered}
          defaultSorted={[
            {
              id: "position",
              desc: false
            }
          ]}
          onFilteredChange={filtered => this.setState({ filtered })}
          filterable
          noDataText={t("no_keyholders")}
          defaultPageSize={10}
          defaultFilterMethod={(filter, row) => {
            return contains({
              filter: filter.value,
              value: String(row[filter.id]),
              ignoreCase: true
            });
          }}
          data={data}
          columns={columns}
        />
      </div>
    );
  }
}
export default withT(DataTable, "site_overview.side_pane.keyholders");
