/**
 * Column defaults and config for report data tables.
 *
 * This is built for ReactTable's (v6) columns property and imported
 * by getColumns in selectors/reports/common/index.js
 *
 * getColumns merges any specific reportType with default, allowing
 * for all reports to start as a default and use the settings specified
 * in this folder to customise the data table column properties
 */

import * as scheduled from "./scheduled/";

export default scheduled;
