import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

import Icon from "components/Icons/Icon";

class UserDropdown extends React.Component {
  render() {
    return (
      <Dropdown alignRight id="dropdown-menu-align-right" className="ml-auto">
        <Dropdown.Toggle variant="outline-secondary">
          <Icon className="fas fa-user" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>
            Logged in as {this.props.user.firstName}
          </Dropdown.Header>
          <Dropdown.Divider />
          <Link to="/logout" className="dropdown-item">
            <Icon className="fas fa-sign-out-alt" /> Logout
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.user };
};
export default connect(mapStateToProps)(UserDropdown);
