/**
 * 08/21 mixed type chart that can display both line graphs and bar charts 
 * (and potentially other types of data)
 * 
 * First created to support the people counting hourly report
 * 
 * If in the future a another, different mixed-type report is required, 
 * consider moving some of the below logic into Hourly.js as appropriate 
 * and passing data/options etc into ChartJSMixed on props
 */
import React from "react";
import { Bar } from "react-chartjs-2";

import { map } from "lodash";

class ChartJSMixed extends React.Component {
  render() {

    let labels = map(this.props.lineData.data, "label"); 
    let lineData = map(this.props.lineData.data, "value");
    
    let lineChartDataset = {
      type: 'line',
      label: 'Average',
      borderColor: 'rgb(127, 73, 193)',
      borderWidth: 2,
      // pointBorderColor: 'rgba(0, 0, 0, 0.1)',
      pointBorderWidth: 1,
      fill: false,
      data: lineData,
    }

    let chartData = {};

    if (this.props.barData.data.length > 0 ) {
      let barData = map(this.props.barData.data, "value");

      let barChartDataset = {
        type: 'bar',
        label: this.props.siteName,
        backgroundColor: 'rgb(36, 179, 168)',
        // borderColor: 'white',
        borderWidth: 0,
        data: barData,
      }

      chartData = {
        labels,
        datasets: [
          lineChartDataset,
          barChartDataset
        ],
      };
      
    } else {
      chartData = {
        labels,
        datasets: [
          lineChartDataset
        ],
      };
    }

    let chartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: "Open Sans",
            // display: !this.props.hideAxisLabels
          }
        }],
        xAxes: [{
          ticks: {
            fontFamily: "Open Sans",
            // display: !this.props.hideAxisLabels
          }
        }]
      },
      legend: {
        display: !this.props.hideLegend,
        labels: {
          boxWidth: 20,
          fontFamily: "Open Sans"
        },
        position: "bottom"
      },
      // tooltips: {
      //   backgroundColor: 'rgba(44, 62, 80, 0.8)',
      //   displayColors: false,
      // }
    };

    return (
      <div className="chartjs-mixed-container">
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  }
}
export default ChartJSMixed;
