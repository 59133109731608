import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

import { default as T } from "services/i18n/Translate";

import LoginForm from "./LoginForm";
import CONXTDLogo from "../Layout/CONXTDLogo";

import AccountPage from "components/Layout/AccountPage";

import BackgroundAnimation from "components/Common/BackgroundAnimation";

import "./LoginPage.scss";

class LoginPage extends React.Component {
  state = {
    focused: false,
  };
  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    return (
      <div>
        {/* <video poster="/login-background-image.png" playsInline={true} autoPlay={true} muted={true} loop={true} className="bgvid">
          <source src="/login-background-animation.mp4" type="video/mp4" />
        </video> */}
        <BackgroundAnimation />
        <AccountPage
          id="login-wrapper"
          className={this.state.focused ? "login-focus" : ""}
        >
          <div className="row align-items-center login-page px-0 px-sm-4">
            <div className="col-lg-5 offset-lg-1 pb-2">
              <div className="text-center text-lg-left">
                {/*<div>
                      <CONXTDLogo dark className="mb-4" />
                    </div>*/}
                <h4 className="mb-4 text-muted">
                  <T>Welcome to CONXTD</T>
                </h4>
                <a href={`https://conxtd.com/`} className="waves-effect" target="_blank" rel="noopener noreferrer">
                  <Button variant="outline-primary">
                    <T>Learn about CONXTD</T>
                  </Button>
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="mb-0">
                <div>
                  <div>
                    <h4 className="text-muted float-right font-18 mt-4 pt-2 d-none d-sm-block">
                      <T>Log In</T>
                    </h4>
                    <div className="text-center text-sm-left">
                      <CONXTDLogo
                        dark
                        className="mb-2"
                        style={{ height: "70px" }}
                        wrapperStyle={{
                          display: "block",
                        }}
                      />
                    </div>
                  </div>
                  {/* <Card
                    className="d-block d-md-none"
                    style={{ background: "#27ae60", color: "white" }}
                  >
                    <Card.Title
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "8px",
                      }}
                    >
                      CONXTD App Login
                    </Card.Title>
                    <Card.Subtitle
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        paddingBottom: "11px",
                      }}
                    >
                      Please wait while we redirect you..
                    </Card.Subtitle>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Browser login
                    </Card.Title>
                    <Card.Subtitle
                      style={{
                        fontWeight: "normal",
                        fontSize: "13px",
                        textAlign: "center",
                        paddingLeft: "4px",
                        paddingRight: "4px",

                        paddingBottom: "10px",
                      }}
                    >
                      If you are using a browser on your device (safari/ chrome)
                      please login below
                    </Card.Subtitle>
                  </Card> */}
                  <div>
                    <LoginForm
                      handleFocus={this.handleFocus}
                      handleBlur={this.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccountPage>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { error: state.error, user: state.user };
};
export default connect(mapStateToProps)(LoginPage);
