import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

class ErrorTest extends React.Component {
  render() {
    return (
      <div className="row mt-3">
        <div className="col-12">
          <Button
            onClick={() => {
              this.props.dispatch({
                type: "UNDEFINED_ERROR",
                message: "Test error",
                testError: true
              });
            }}>
            Send Test Error to Intercom
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {};
};
export default connect(mapStateToProps)(ErrorTest);
