import React from "react";
import { connect } from "react-redux";

// import { Row, Col, Form, Button, Collapse, Card } from "react-bootstrap";
import { Col, Form, Button, Card } from "react-bootstrap";

import { withT } from "services/translation/";

import Loading from "components/Loading";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

import {
  requestAddKeyholderStart,
  requestAddKeyholderNotified
} from "services/redux/actions";

import Alert from "components/Common/Alert";

class Add extends React.Component {
  state = {
    open: false
  };
  addKeyholder = () => {
    const { dispatch, enterprise_site_id, total } = this.props;

    const keyholder = {};
    document
      .querySelectorAll("#AddKeyholder input")
      .forEach(input => (keyholder[input.name] = input.value));

    dispatch(
      requestAddKeyholderStart({
        params: {
          ...keyholder,
          position: total + 1,
          enterprise_site_id
        }
      })
    );
  };
  componentDidUpdate() {
    const { t, success, dispatch } = this.props;

    if (success) {
      dispatch(requestAddKeyholderNotified());

      this.setState({ open: false });

      Alert({
        text: t("success_message"),
        icon: "success"
      });
    }
  }
  render() {
    const { open } = this.state;
    const { t, loading } = this.props;

    const form = open && (
      <Card bg="light">
        <Card.Body>
          <Form
            className="mt-2"
            onSubmit={e => {
              this.addKeyholder();
              e.preventDefault();
              // onClose()
            }}
            id="AddKeyholder">
            <Form.Row>
              {[
                {
                  key: "name",
                  type: "text",
                  required: true,
                },
                {
                  key: "email",
                  type: "email",
                  required: false,
                },
                {
                  key: "phone",
                  type: "phone",
                  required: false,
                },
                {
                  key: "job_title",
                  type: "text",
                  required: false,
                }
              ].map(({ key, type, required }) => (
                <Form.Group
                  key={key}
                  controlId={"formGroup" + key}
                  as={Col}
                  sm="6">
                  <Form.Label>{t(key)}</Form.Label>

                  <Form.Control type={type} name={key} required={required} />
                </Form.Group>
              ))}
              <Form.Group controlId="formGroupSubmit" as={Col} xs="12">
                <Button
                  disabled={loading}
                  variant="success"
                  type="submit"
                  className="float-right">
                  <Icon className="fas fa-address-card fa-lg pr-2" />

                  {t("button_submit")}
                  {loading && <Loading />}
                </Button>

                <Button
                  disabled={loading}
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                  variant="primary"
                  className="float-right mr-2">
                  {/* <Icon className="fas fa-user-plus pr-2" /> */}

                  {t("button_cancel")}
                  {/* {loading && <Loading />} */}
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    );
    return (
      <>
        {!open && (
          <div>
            <Button
              onClick={() => this.setState({ open: true })}
              className="mr-2"
              aria-controls="AddKeyholder"
              aria-expanded={open}>
              <Icon className="fas fa-address-card fa-lg mr-1" />
              {t("title")}
            </Button>
            <Tooltip 
              placement="right"
              description="Help"
            >
              <Button
                variant="primary"
                style={{
                  height: "19px",
                  width: "19px",
                  borderRadius: "50%",
                  padding: "0",
                }}
                onClick={() => {
                  window.Intercom('showArticle', 6927381) 
                }}
              ><Icon 
                className="fas fa-question fa-xs"
                style={{
                  position: "relative",
                  bottom: "3px",
                }}
              /></Button>
            </Tooltip>
          </div>
        )}

        {form}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.sites.current.keyholders.actions.add.loading === true,
    success: state.sites.current.keyholders.actions.add.success
  };
};
export default connect(mapStateToProps)(
  withT(Add, "site_overview.side_pane.keyholders.add")
);
