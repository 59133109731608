import React from "react";

export function PeriodColumn({ columns }) {
  const column = columns["PERIOD"];

  return {
    ...column,
    Cell: row => {
      return (
        <>
          Day Total
        </>
      );
    }
  };
}
