/**
 * Sidepane view that slides in from the right of the screen.
 */

import React from "react";
import { connect } from "react-redux";
 import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
 
import Icon from "components/Icons/Icon";

import CloseActionModal from "../CloseActionModal";

import "./SOPSidePane.scss";

import get from "lodash/get";

const createHandleEscape = onEscape => {
  return e => {
    e = e || window.event;
    var isEscape = false;
    if ("key" in e) {
      isEscape = e.key === "Escape" || e.key === "Esc";
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      onEscape();
    }
  };
};

let handleEscape = null;

const ParentPage = ({ children }) => {
  return (
    <div className="row">
      <div className="col-12">{children}</div>
    </div>
  );
};

const ChildPage = ({ children, changePage, text }) => {
  return (
    <>
      <div className="row my-2">
        <div className="col-12">
          <div
            className="btn btn-default border-none side-pane-back text-dark"
            onClick={() => changePage(0)}>
            <Icon className="fas fa-chevron-left mr-2" /> {text}
          </div>
        </div>
      </div>
      <div className="row bg-lightgrey">
        <div
          className="col-12"
          style={{ height: "calc(100vh - 130px)" }}>
          {children}
        </div>
      </div>
    </>
  );
};

// Somehow we have to pass down props through here
const ShiftablePage = ({
  Component,
  currentPage,
  pageKey,
  componentProps,
  changePage,
  backButtonText
}) => {
  const classNames = ["row shiftable"];

  // eslint-disable-next-line default-case
  switch (pageKey) {
    case 0:
      if (currentPage !== pageKey) {
        classNames.push("shift-left");
      }

      return (
        <div className={classNames.join(" ")}>
          <div className="col-md-12 bg-lightgrey">
            <ParentPage>
              <Component {...componentProps} changePage={changePage} />
            </ParentPage>
          </div>
        </div>
      );

    case 1:
      if (currentPage !== pageKey) {
        classNames.push("shift-right");
      }

      return (
        <div className={classNames.join(" ")}>
          <div className="col-md-12 bg-lightgrey">
            <ChildPage changePage={changePage} text={backButtonText}>
              <Component {...componentProps} changePage={changePage} />
            </ChildPage>
          </div>
        </div>
      );
  }
};

/**
* SOPSidePane
*
* Is hidden and shown based on parent class being "sop-sidepane-active"
* which must be manually set
*
*/
class SOPSidePane extends React.Component {
  state = {
    overlayHover: true,
    switchingPage: false,
    closeActionModalShow: false,
  };

  componentDidMount = () => {
    handleEscape = createHandleEscape(this.onHide);

    document.addEventListener("keydown", handleEscape);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", handleEscape);
    handleEscape = null;
  }

  onHide = () => {
    const { onHide } = this.props;

    onHide && onHide();

    // Reset page
    // this.changePage(0);
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   // Update currentPage if changed
  //   if (nextProps.currentPage !== this.state.currentPage) {
  //     this.changePage(nextProps.currentPage);
  //   }
  // }

  changePage = key => {
    const { onChangePage } = this.props;

    // Start page switch animation
    this.setState({
      switchingPage: true
    });

    // Set timeout for visually graceful exit
    onChangePage &&
      setTimeout(() => {
        // Now allow changes to the page
        onChangePage(key);

        this.setState({
          switchingPage: false
        });
      }, 600);
  };

  closeActionModalClose = () => {
    this.setState({ closeActionModalShow: false })
  };

  render() {
    const { onHide, Pages, title, componentProps, sop, user } = this.props;
    const { enterpriseId, groupId, row, closedAction, muteClick } = componentProps;
    const { overlayHover, switchingPage } = this.state;

    const currentPage = switchingPage
      ? // If we're switching, set to the opposite page
        this.props.currentPage === 1
        ? 0
        : 1
      : this.props.currentPage;

    const parentPageComponent = get(Pages, "0.Component") || null;
    const childPageComponent = get(Pages, "1.Component") || null;

    let sopActionedEventId = null;
    let sudoStateId = null;
    let isAssignedUser = false;
    if (row) {
      const { sopMap } = sop;
      sopActionedEventId = (sopMap && sopMap.sop_actioned_event_id) || null;
      sudoStateId = (row.sudo_state && row.sudo_state[0].id) || null;
      isAssignedUser = (sop.assignedUser && sop.assignedUser.user_id && sop.assignedUser.user_id === user.id) || false;
    }

    return (
      <div className="sop-side-pane-container">
        <div className="sop-side-pane">
          {/* Clicking on the overlay should not hide (monday style) */}
          {/* <div className="side-pane-overlay" onClick={() => onHide()}> */}
          <div className={`sop-side-pane-overlay ${overlayHover ? "hover" : ""}`} />
          <div
            className="sop-side-pane-content"
            onClick={e => {
              // Prevent event bubbling
              e.stopPropagation();
            }}
            onMouseOut={e => {
              e.stopPropagation();
              this.setState({ overlayHover: true });
            }}
            onMouseOver={e => {
              e.stopPropagation();
              this.setState({ overlayHover: false });
            }}>
            <div className="container overflow-hidden h-100">
              <div className="row p-3 side-pane-header">
                <div className="col-md-12">
                  <div
                    className={
                      "text-uppercase text-center " +
                      (title && title.length < 28
                        ? "h4 m-t-10 m-t-10"
                        : "h5 m-t-10 m-t-10")
                    }>
                    {title}
                  </div>
                </div>
              </div>
              <div
                className="row m-t-10 pt-3"
                style={{ position: "absolute", top: "0" }}>
                <div className="col-md-12">
                  <Icon
                    onClick={() => onHide()}
                    className="fas fa-times pl-2"
                    style={{
                      fontSize: "20px",
                      cursor: "pointer"
                    }}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ currentPage: currentPage === 0 ? 1 : 0 });
                  }}>
                  Button
                </div>
              </div> */}
              <ShiftablePage
                changePage={this.changePage}
                Component={parentPageComponent}
                componentProps={componentProps}
                pageKey={0}
                currentPage={currentPage}
              />
              <ShiftablePage
                backButtonText={get(Pages, "1.backButtonText") || ""}
                changePage={this.changePage}
                Component={childPageComponent}
                componentProps={componentProps}
                pageKey={1}
                currentPage={currentPage}
              />
            </div>
            <CloseActionModal 
              show={this.state.closeActionModalShow} 
              onHide={this.closeActionModalClose}
              enterpriseId={enterpriseId}
              groupId={groupId}
              sudoStateId={sudoStateId}
              sopActionedEventId={sopActionedEventId}
              muteClick={muteClick}
            />
            {!closedAction && <div className="d-flex justify-content-center align-items-center sop-close-action-footer">
              <Button
                variant="success"
                disabled={!sopActionedEventId || !isAssignedUser}
                onClick={() => {
                  this.setState({
                    closeActionModalShow: true 
                  });
                }}
              >Close down</Button>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
 SOPSidePane.propTypes = {
   /** @param title Title to be shown at the top of the pane */
   title: PropTypes.string.isRequired,
 
   /** @param onHide Hide function called when pane is closed by clicking X or pressing ESC */
   onHide: PropTypes.func.isRequired,
 
   /** @param componentProps Optional props to be sent to child components (pages) */
   componentProps: PropTypes.object,
 
   /**
    * @param currentPage Optional: current page state (0 or 1) to change to a sub-page
    * this is not controlled, so reacts to change from outside
    */
   currentPage: PropTypes.number.isRequired,
 
   /**
    * @param onChangePage Callback when page change occurrs, provides currentPage (0 or 1)
    * i.e. the page that is being switched to
    */
   onChangePage: PropTypes.func
 };

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    sop: state.sop,
  };
};

export default connect(mapStateToProps)(SOPSidePane);