import { getRouterParams } from "services/redux/selectors/app/";

import { createSelector } from "reselect";

const getReportPathFromProps = (state, props) => {
  return props.reportPath || null;
};

export const getReportPath = createSelector(
  [getRouterParams, getReportPathFromProps],
  ({ reportPath }, reportPathFromProps) => {
    return reportPathFromProps || reportPath || ["default", "default"];
  }
);

export const getReportType = createSelector([getReportPath], (reportPath) => {
  return reportPath[1];
});

export const getParentReportType = createSelector(
  [getReportPath],
  (reportPath) => {
    return reportPath[0];
  }
);

// {
//   const reportType = props.reportType || state.app.router.params.reportType;
//   return reportType;
// }

export const getPathname = (props) => props.location.pathname;

export const getEnterpriseId = (state, props) =>
  state.app.router.params.enterprise_id;

export const getGroupId = (state, props) => state.app.router.params.group_id;
