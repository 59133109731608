/**
 * Watcher for loading resources for specified channel
 */

import { call } from "redux-saga/effects";

import {
  loadNotificationsResourcesStart,
  loadNotificationsResourcesFailed,
  loadNotificationsResourcesSuccess,
  loadNotificationsResourcesCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* loadResources(action) {
  try {
    const result = yield call(
      conxtdOut.post,
      "/notifications/channels/loadResourcesForChannel",
      action.params
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
}

export function* watchLoadResources() {
  yield actionWatcher({
    actions: {
      start: loadNotificationsResourcesStart,
      failed: loadNotificationsResourcesFailed,
      success: loadNotificationsResourcesSuccess,
      cancel: loadNotificationsResourcesCancel
    },
    saga: loadResources
  });
}
