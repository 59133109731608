import React from "react";
import { connect } from "react-redux";

/**
 * Enterprise Management Billing Tab
 *
 **/
class Billing extends React.Component {

  render() {

    return (
      <>
        Billing
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};
export default connect(mapStateToProps)(Billing);