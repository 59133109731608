export const loadSOPCompletedReportStart = (params) => {
  return { type: "LOAD_SOP_COMPLETED_REPORT_START", ...params };
};

export const loadSOPCompletedReportSuccess = (params) => {
  return { type: "LOAD_SOP_COMPLETED_REPORT_SUCCESS", ...params };
}

export const loadSOPCompletedReportFailed = (params) => {
  return { type: "LOAD_SOP_COMPLETED_REPORT_FAILED", ...params };
}

export const loadSOPCompletedReportCancel = (params) => {
  return { type: "LOAD_SOP_COMPLETED_REPORT_CANCEL", ...params };
}

export const clearSOPCompletedReport = (params) => {
  return { type: "CLEAR_SOP_COMPLETED_REPORT", ...params };
};

export const setSOPCompletedReportDisplayPageIndex = (params) => {
  return { type: "SET_SOP_COMPLETED_REPORT_DISPLAY_PAGE_INDEX", ...params };
};