import produce from "immer";

const initialAction = {
  loading: null,
  success: null,
  error: {}
};

const initialState = {
  update: initialAction,
  add: initialAction,
  remove: initialAction,
  move: initialAction
};

const reducer = (state, action) => {
  // Only apply to keyholder actions
  if (action.type.indexOf("_KEYHOLDER_") === -1) return state;

  // eslint-disable-next-line no-unused-vars
  let [mode, type, category, status] = action.type.split("_");

  type = type.toLowerCase();

  switch (status) {
    case "START":
      return produce(state, draftState => {
        draftState.keyholders.actions[type].loading = true;
      });
    case "SUCCESS":
      return produce(state, draftState => {
        draftState.keyholders.actions[type].loading = false;
        draftState.keyholders.actions[type].success = true;
      });
    case "FAILED":
      return produce(state, draftState => {
        draftState.keyholders.actions[type].loading = false;
        draftState.keyholders.actions[type].error = action.error || {};
      });
    case "CANCEL":
    case "NOTIFIED":
      return produce(state, draftState => {
        // Reset
        draftState.keyholders.actions[type] = initialAction;
      });
    default:
      return state;
  }
};

const keyholderActions = { initialState, reducer };

export default keyholderActions;