/**
 * Data table custom row component to add expanded site occurrences
 * (sub rows)
 */

import React from "react";

import get from "lodash/get";

import {
  labelContainsFilter,
  getNotExpected,
  getExpanded
} from "services/redux/selectors/reports/scheduled";

import ChildRowColumns from "./ChildRowColumns";

function TrGroupComponent(component) {
  const {
    site,
    eventTextFilter,
    columns,
    groups,
    getEventMeta,
    filtered
  } = component;

  const expandedContent = getExpanded(site, eventTextFilter)
    ? site.occurrences.map((occurrence, index) => {
        let rowsShown = -1;

        const getStyle = index =>
          get(component, `children.0.props.children.${index}.props.style`);

        const not_expected = getNotExpected(occurrence) ? "not-expected" : "";

        return (
          <div
            className={`rt-tr sub-row ${not_expected}`}
            role="row"
            key={site.id + " " + index}>
            {ChildRowColumns.map(cell => {
              const showCell =
                // Show by default
                cell.key === null ? true : columns[cell.key].show;

              if (!showCell) return null;

              const eventMeta = getEventMeta(occurrence);

              let underline = true; //getFocus();
              const filter = filtered.find(({ id }) => id === "result");
              // Filter for this column is active
              if (filter) {
                underline = false;

                underline = labelContainsFilter(eventMeta.label, filter);
                // eventMeta.label
                //   .toLowerCase()
                //   .indexOf(filter.value.toLowerCase()) !== -1;
              }

              rowsShown++;

              const style =
                (cell.style &&
                  cell.style({
                    site,
                    occurrence,
                    eventMeta
                  })) ||
                {};
              const content =
                (cell.content &&
                  cell.content({
                    site,
                    occurrence,
                    index,
                    eventMeta,
                    groups,
                    underline,
                    focus: true
                  })) ||
                null;

              return (
                <div
                  className={`rt-td ${cell.className || ""}`}
                  role="gridcell"
                  style={{
                    ...getStyle(rowsShown),
                    ...style
                  }}
                  key={site.id + " " + index + " " + cell.key}>
                  {content}
                </div>
              );
            })}
          </div>
        );
      })
    : null;

  const firstOccurrence = get(site, "occurrences.0");

  const not_expected = getNotExpected(firstOccurrence) ? "not-expected" : "";

  const expandedClass = component.expanded === 1 ? "expanded" : "";

  return (
    <>
      <div
        className={`rt-tr-group ${not_expected} ${expandedClass}`}
        role="rowgroup"
        key={site && site.id}>
        {component.children}
        {expandedContent}
      </div>
    </>
  );
}

export default TrGroupComponent;
