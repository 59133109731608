import React from "react";

// import ImageGallery from 'react-image-gallery';
import ImageGallery from 'components/Common//ImageGallery/ImageGallery.js';

import { Row } from "react-bootstrap";

// import "react-image-gallery/styles/scss/image-gallery.scss";
import "components/Common//ImageGallery/image-gallery.scss";
import "./ExternalImagesView.scss";
import "./ExternalRecordingView.scss"

import calpisaLogo from "./calipsa_logo.png";


function ExternalImagesView({ events, conxtd_alarm_id, user }) {

  const carouselImages = events.map((event) => {
    return {
      original: event.clip_url,
      thumbnail: event.clip_url,
    }
  });

  const logoRow = (
                    (conxtd_alarm_id === 415 && user.environment === "mars")
                    || (conxtd_alarm_id === 411 && user.environment === "staging")
                    || (conxtd_alarm_id === 521 && user.environment === "live")
                  ) ? (
                    <Row className="d-flex justify-content-end" style={{ height: "50px" }}>
                      <div className="logo-row">
                        <span className="logo-img-element">
                          <img src={calpisaLogo} alt="Calpisa logo" className="integration-logo-small"></img>
                        </span>
                      </div>
                    </Row>
                  ) : null

  return (
    <div>
      <Row className="justify-content-center flex-center pt-1">
        <div className="camera-images-carousel">
          <ImageGallery 
            items={carouselImages} 
            slideDuration={0}
            slideInterval={750}
          />
        </div>
      </Row>
      {logoRow}
    </div>
  );
}

export default ExternalImagesView;