import React from "react";
import Icon from "components/Icons/Icon";

class EnterpriseLogo extends React.Component {
  state = {
    enterprise: {
      logo_url: null
    },
    logo_url_valid: true,
  };
  componentDidMount() {
    const { enterprise } = this.props;
    this.setState({ enterprise });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { enterprise } = nextProps;
    if (enterprise && enterprise.id !== -1) {
      this.setState({ enterprise });
    }
  }
  render() {
    const { className } = this.props;
    const { enterprise } = this.state;

    const fallback = (
      <div className="m-auto">
        <Icon className="far fa-building display-4" />
      </div>
    );

    if (!enterprise.logo_url)
      return (
        <div className="enterprise-logo py-1 px-2">
          <div
            className={(className || "") + " d-flex"}
            style={{
              // minHeight: "100px",
              width: "auto",
              maxWidth: "100%",
              margin: "0 auto",
              display: "block",
              minHeight: "100px"
            }}>
            {fallback}
          </div>
          <div className="text-center h5 pt-3" style={{ minHeight: "48px" }}>
            {enterprise.name}
          </div>
        </div>
      );

    return (
      <div className="enterprise-logo py-1 px-2">
        {/* <object
          data={enterprise.logo_url}
          className={(className || "") + " d-flex"}
          style={{
            height: "100px",
            width: "auto",
            maxWidth: "100%",
            margin: "0 auto",
            display: "block"
          }}>
          {fallback}
        </object> */}
        {this.state.logo_url_valid
          ? <img
              className={(className || "") + " d-flex"}
              style={{
                height: "100px",
                width: "auto",
                maxWidth: "100%",
                margin: "0 auto",
                display: "block"
              }}
              src={enterprise.logo_url}
              alt=""
              onError={()=>{
                this.onerror=null;
                this.setState({
                  logo_url_valid: false,
                })
              }}
            />
          : <div
              className={(className || "") + " d-flex"}
              style={{
                // minHeight: "100px",
                width: "auto",
                maxWidth: "100%",
                margin: "0 auto",
                display: "block",
                minHeight: "100px"
              }}>
              {fallback}
            </div>
        }
        <div className="text-center h5 pt-3" style={{ minHeight: "48px" }}>
          {enterprise.name}
        </div>
      </div>
    );
  }
}
export default EnterpriseLogo;
