import React from "react";
import { connect } from "react-redux";

import { Form, Button } from "react-bootstrap";

import Select from 'react-select';

import { loadSitesBackgroundStart } from "services/redux/actions/sites";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getSiteOptions} from "services/redux/selectors/customReports"; 

import Loading from "components/Loading";

import Icon from "components/Icons/Icon";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

import "./InjectEvents.scss";

class InjectEvents extends React.Component {
  state = {
    injecting: false,
    selectedSiteOption: null,
    selectedEventOption: null,
    eventOptions: [
      // { value: "1", label: "Unconfirmed Intruder 1", type: "BA", zone: "1001" },
      { value: "1", label: "Unconfirmed Intruder", type: "BA" },
      // { value: "2", label: "Unconfirmed Intruder 2", type: "BA", zone: "1002" },
      { value: "3", label: "Comms Fail", type: "YC", zone: "9021" },
      { value: "4", label: "Power Fault", type: "AT" },
      { value: "5", label: "Battery Low", type: "YT" },
      { value: "6", label: "Tamper", type: "TA" },
      { value: "7", label: "Equipment Fault", type: "IA" },
      // { value: "8", label: "Unconfirmed Intruder 1 Restore", type: "BR", zone: "1001" },
      { value: "8", label: "Unconfirmed Intruder Restore", type: "BR" },
      // { value: "9", label: "Unconfirmed Intruder 2 Restore", type: "BR", zone: "1002" },
      { value: "10", label: "Comms Restore", type: "YK", zone: "9021" },
      { value: "11", label: "Power Fault Restore", type: "AR" },
      { value: "12", label: "Battery Low Restore", type: "YR" },
      { value: "13", label: "Tamper Restore", type: "TR" },
      // { value: "14", label: "Fire Fault Restore", type: "FV" },
      { value: "15", label: "Equipment Fault Restore", type: "IR" },
    ]
  }

  componentDidUpdate(prevProps) {
    const { enterprise  } = this.props;

    if (!enterprise && prevProps.enterprise) {
      this.setState({ selectedSiteOption: null }); // clear selection if user is changing enterprise
    }

    if (enterprise && enterprise.id && ((!prevProps.enterprise || enterprise.id !== prevProps.enterprise.id))) {
      // new enterprise selected, so get the site list
      this.props.dispatch(
        loadSitesBackgroundStart({
          enterprise_id: enterprise.id,
        })
      );
    }
  }

  handleInjectEvent = (siteId, eventId) => {
    const { loggedInUser } = this.props;
    const { eventOptions } = this.state;

    if (siteId && eventId) {
      this.setState({ injecting : true });

      const event = _.find(eventOptions, ['value', eventId]).type;
      const zone = _.find(eventOptions, ['value', eventId]).zone || "";
      const text = loggedInUser.firstName + " " + loggedInUser.lastName + " - This is a test event.";
      const time = moment.utc().format();

      const injectEvent = conxtdOut.post(`/Events/add/${siteId}`, {
        event,
        zone,
        text,
        time,
      });


      injectEvent.then((response) => {
        this.setState({ injecting : false });
      }).catch((error) => {
        console.log("Error details", error);
        this.setState({ injecting : false });
      });
    }
  };

  handleCloseClick = () => {
    this.setState({ selectedSiteOption: null });
    this.setState({ selectedEventOption: null });
    document.getElementById("inject-events-container").classList.remove("show");
  }

  render() {
    const { siteOptions } = this.props;
    const { injecting, selectedSiteOption, selectedEventOption, eventOptions } = this.state;

    return (
      <div id="inject-events-container">
        <div className="inject-events">
          <div className="d-flex justify-content-between">
            <div className="inject-events-title pl-2">Inject Test Events</div>
            <div>
              <Icon
                className="fas fa-times p-1"
                style={{
                  fontSize: "20px",
                  cursor: "pointer"
                }}
                onClick={this.handleCloseClick}
              />
            </div>
          </div>

          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              this.handleInjectEvent(
                e.target.inject_event_site.value,
                e.target.inject_event_type.value
              )
            }}
          >
            <label className="d-block pt-3">Sites</label>
            <div>
              <Select // required doesn't work for react-select
                name="inject_event_site"
                key={`inject_event_site_key__${selectedSiteOption}`} // https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
                classNamePrefix="inject-event-select"
                options={siteOptions}
                value={selectedSiteOption || ""}
                onChange={(e) => {
                  this.setState({ selectedSiteOption: _.find(siteOptions, ['value', e.value]) })
                }}
              />
            </div>
            <label className="d-block pt-3">Events</label>
            <div>
              <Select // required doesn't work for react-select
                name="inject_event_type"
                key={`inject_event_type_key__${selectedSiteOption}`}
                classNamePrefix="inject-event-select"
                options={eventOptions}
                value={selectedEventOption || ""}
                onChange={(e) => {
                  this.setState({ selectedEventOption: _.find(eventOptions, ['value', e.value]) })
                }}
              />
            </div>
            <div className="inject-events-submit pt-4">
              <Button
                className="mr-2"
                variant="primary"
                onClick={this.handleCloseClick}
              >Close</Button>
              <Button
                type="submit"
                variant="success"
              >Inject Event</Button>
              <span>&nbsp;&nbsp;{injecting && <Loading left />}</span>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    loggedInUser: state.user,
    enterprise: getEnterpriseFromRoute(state, props),
    siteOptions: getSiteOptions(state, props),
  };
};

export default connect(mapStateToProps)(InjectEvents);
