import React from "react";

import { Button, Form, Row, Col } from "react-bootstrap";

// const user_auth_token =
//   "745f3716ee1cc6efe36bba3a637bf6a9cfeb241422ba955b59ab7d883ece0e5e";
// const recording_id = "5e15718c5f439f568e82362a";

class Tether extends React.Component {
  state = {
    oath_token: null,
    recording_id: null
  };
  initTether = user_auth_token => {
    try {
      new window.TetherX(user_auth_token).recordings();
    } catch (ignore) {}
  };
  componentDidMount() {}
  componentDidUpdate = () => {
    const { oath_token, recording_id } = this.state;
    if (!oath_token || !recording_id) return;

    try {
      new window.TetherX(oath_token).tooltips();
    } catch (ignore) {}
  };
  render() {
    const { oath_token, recording_id } = this.state;
    return (
      <>
        {recording_id && oath_token && (
          <iframe
            style={{ width: "60vw", height: "500px" }}
            title="tetherx-embedded-view"
            className="tetherx-recording-view"
            src={`https://my.timeline.is/recordings/${recording_id}/preview`}></iframe>
        )}
        <Row>
          <Col sm="12">
            <Button
              onClick={() => {
                this.initTether(oath_token);
              }}>
              Initialise Tether
            </Button>
            <Button
              onClick={() => {
                window.$(".tetherx-recording-view").each(function(_, iframe) {
                  const message = {
                    name: "tetherx-user-token",
                    token: oath_token
                  };
                  console.log("posting message to iframe:", message);
                  iframe.contentWindow.postMessage(message, "*");
                });
              }}>
              Post Message
            </Button>
            <Form.Control
              onChange={e => {
                this.setState({ oath_token: e.target.value });
              }}
              name="oath_token"
              component="input"
              type="text"
              placeholder={"Enter oath2 token"}
            />
            <Form.Control
              onChange={e => {
                this.setState({ recording_id: e.target.value });
              }}
              name="recording_id"
              component="input"
              type="text"
              placeholder={"Enter recording id"}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Button
              className="tetherx-live-tooltip"
              tetherx-zone-id={recording_id}>
              Tooltip Button
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
export default Tether;
