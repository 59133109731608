// import React, { useEffect } from "react";
import React from "react";

import { Col } from "react-bootstrap";

import { withT } from "services/translation/";

import CameraCard from "./CameraCard";

function CameraCardList({ integration, t }) {
    // useEffect(() => {
    //     new
    // })

    if (integration.camera_integration_site.camera_sudo_sites.length === 0) {
        return <Col>{t("no_cameras_added")}</Col>
    }

    return integration.camera_integration_site.camera_sudo_sites.map(
        (site, index) => (
            <CameraCard 
            integration={integration}
            key={index} 
            site={site} 
                imaging_server={site.imaging_server} 
                data_server={site.data_server} 
                auth_token={integration.auth_token} 
                integration_id={integration.integration_id} 
                subdomain={integration.subdomain}
                account_id={integration.account_id}
                auth_expired={integration.camera_integration_site.auth_expired}
            />
        )
    );
}
export default withT(
    CameraCardList,
    "site_overview.side_pane.manage_integrations.providers.video_loft"
);