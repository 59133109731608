import React from "react";
import Icon from "components/Icons/Icon";

class NavIcon extends React.Component {
  render() {
    return <Icon {...this.props} />;
  }
}

export default NavIcon;
