import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Redirect } from "react-router-dom";

import { TextFilter } from "components/Common/";

import Loading from "components/Loading";

import DataTable from "./DataTable";

import _ from "lodash";

import {
  loadCompaniesStart,
  clearCompanies,
  setCompaniesDisplayPageIndex,
} from "services/redux/actions/companies";

class Companies extends React.Component {
  state = {
    nameFilter: "",
  }

  componentDidMount() {
    // Flush cache when user navigates here
    // window.caching.flush();

    this.props.dispatch(
      loadCompaniesStart()
    );
  }

  componentWillUnmount() {
    this.props.dispatch(
      clearCompanies()
    );
  }

  render() {
    const { nameFilter } = this.state;
    const { companies, loadingCompanies, sortField, sortDirection, t } = this.props;

    // manage what to do if there are no companies (but not if it's because filtering has been applied)
    if (!companies || (companies.length === 0 && (nameFilter === "" || nameFilter === null))) { 
      if (loadingCompanies === false) {
        // Load has completed but still we have no companies - shouldn't really happen as user should have companies switched off
        return (
          <div>
            <p>Your account currently has no companies. </p>
          </div>
        );
      }
      // we are still loading companies
      return <Loading />;
    }

    // redirect if it's the only company (but not if it's because filtering has been applied)
    if (companies.length === 1 && (nameFilter === "" || nameFilter === null)) {
      const { id } = companies[0];
      return <Redirect to={`companies/company/${id}`} />;
    }

    return (
      <>
        <div className="row">
          <TextFilter
            formClassName="col-12 col-md-4"
            onChange={_.debounce((e) => {
              const { pageSize, displayPageIndex } = this.props;

              this.props.dispatch(
                loadCompaniesStart({
                  nameFilter: e.target.value,
                  sortField: sortField, 
                  sortDirection: sortDirection,
                  requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                  pageSize: pageSize,
                })
              );

              this.setState({
                nameFilter: e.target.value,
              })

              if (displayPageIndex !== 0) {
                this.props.dispatch(
                  setCompaniesDisplayPageIndex({
                    displayPageIndex: 0, // controls the page index that the table component displays (0-indexed)
                  })
                )
              }
            }, 300)}
            placeholder={t("companies.text_filter.placeholder")}
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <DataTable
              nameFilter={nameFilter}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    companies: state.companies.data.companies,
    pageSize: state.companies.pageSize,
    displayPageIndex: state.companies.displayPageIndex,
    sortField: state.companies.sortField,
    sortDirection: state.companies.sortDirection,
    loadingCompanies: state.companies.loadingCompanies,
  };
};
export default withT(connect(mapStateToProps)(Companies));