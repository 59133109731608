import React from "react";
import PropTypes from "prop-types";

import { ButtonToolbar, ButtonGroup } from "react-bootstrap";
import ReactTable from "react-table";

import { withT } from "services/translation/";
import CircleIcon from "components/Icons/CircleIcon";
// import Icon from "components/Icons/Icon";
// import ChannelIcons from "constants/Notifications/ChannelIcons";
import { Tooltip } from "components/Common/Tooltip/";

import RemoveRule from "./RemoveRule";

/**
 * Notification rules table
 */
function Table({ rules, t }) {
  const columns = [
    {
      Header: t("headers.channel_code"),
      accessor: "channel_code",
      Cell: ({ value, original }) => {
        const siteLevelRule = original.sudo_site_id !== null;

        return (
          <Tooltip
            placement="right"
            description={t(
              `${siteLevelRule ? "site" : "group"}_level_rule_explanation`
            )}
          >
            <div style={{ position: "relative" }}>
              <span
                className="pr-2"
                style={{
                  display: "inline-block",
                  // minWidth: "40px",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {/* <Icon className={ChannelIcons[value] || ChannelIcons.default} /> */}

                <span style={{ position: "relative", top: "-8px" }}>
                  <CircleIcon
                    /*   type={siteLevelRule ? "building" : "groups"} */
                    type="building"
                    foregroundColor="#000"
                    size="32"
                  />
                </span>
              </span>
              <span style={{ marginLeft: "40px" }}>{t("types." + value)}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      Header: t("headers.channel_resource_name"),
      accessor: "channel_resource_name",
    },
    {
      Header: t("headers.alarm_class_name"),
      accessor: "alarm_class_name",
    },
    {
      Header: t("headers.bundle_name"),
      accessor: "bundle_name",
    },
    {
      Header: t("headers.actions"),
      Cell: (row) => (
        <ButtonToolbar>
          <ButtonGroup className="ml-auto">
            <RemoveRule id={row.original.id} />
            {/* <Button size="sm" disabled>
              <Icon className="fas fa-edit" />
            </Button> */}
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  return <ReactTable columns={columns} data={rules} defaultPageSize={10} />;
}
Table.propTypes = {
  /**
   * @param rules the list of notification rules to display
   */
  rules: PropTypes.array.isRequired,

  /**
   * @param t translation function
   */
  t: PropTypes.func.isRequired,
};

export default withT(
  Table,
  "site_overview.side_pane.manage_notifications.table"
);
