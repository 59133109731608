import React from "react";

class Loading extends React.Component {
  render() {
    const { className = "", center = null } = this.props;
    const spinner = (
      <div className={"spinner-container " + className}>
        <div className="spinner sm" style={{ display: "inline-block" }}>
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
          <div className="rect6"></div>
        </div>
      </div>
      // <span
      //   className="spinner-grow spinner-grow-sm"
      //   role="status"
      //   aria-hidden="true"
      //   style={this.props.style}
      // />
    );

    return center !== null ? (
      <div style={{ width: "100%", textAlign: "center" }}>{spinner}</div>
    ) : (
      spinner
    );
  }
}

export default Loading;
