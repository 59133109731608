import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Card, Col, Row, Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip/";
import Loading from "components/Loading"

import { withT } from "services/translation/";

import { requestRemoveSiteCameraStart } from "services/redux/actions";

import VideoLoftLiveView from "components/Common/Cameras/CameraList/VideoLoftLiveView";

import {
    getCurrentSite,
    getSites,
    getSiteById
} from "services/redux/selectors/sites";

import SelectBundle from "../SelectBundle";

import Alert from "components/Common/Alert";

import AuthorisationModal from "components/Common/Cameras/AuthorisationModal";

function CameraCard({
    integration,
    site,
    removeCamera,
    auth_token,
    site_id,
    site_name,
    loading,
    integration_id,
    stream_name,
    imaging_server,
    data_server,
    auth_expired,
    subdomain, 
    account_id, 
    t }) {

    const [showVideoLoftState, setShowVideoLoftState] = useState(false);
    const [showModal, setShowState] = useState({show: false});

    function removeCameraHelper() {
        Alert({
            title: t("remove_camera_confirm", site.camera_name),
            icon: "warning",

            showCancelButton: true
        }).then(result => {
            if (result.value) {
                removeCamera({
                    camera_sudo_site_id: site_id,
                    site_id,
                    integration_id,
                    body: {
                        active: false
                    }
                });
            }
        });
    }

    let showModalFunction = () => {
        setShowState({show: true})
    }

    let hideModal = () => {
        setShowState({show: false})
    }

    let integration_type='videoloft';
    let integration_type_string='videoloft';

    const expiredToolTip = (
        <>
        <Tooltip
            title="Authorisation Expired"
            description="Click Here to Reauthorise"
            placement="bottom"
        >
            <Button
                variant="danger"
                data-testid="live-view-button"
                size="sm"
                style={{ 'marginRight': '5px' }}
                onClick={showModalFunction}
            >
                <Icon className="fas fa-video" />
            </Button>
        </Tooltip>
                {showModal && 
                    <AuthorisationModal 
                        show={showModal.show}
                        hideModal={hideModal}
                        properties={integration}
                        integration_type={integration_type}
                        integration_type_string={integration_type_string}
                    />
                    }
                    </>
    );

    return (
        <Col md="12">
            <Card className="border-dark bg-light">
                <Card.Body>
                    <Row>
                        <Col>
                            <div
                                className="float-left font-16 pr-2"
                            >
                                {auth_expired ? (expiredToolTip) :
                                    <>
                                        <Button
                                            variant="outline-primary"
                                            data-testid="live-view-button"
                                            size="sm"
                                            style={{ 'marginRight': '5px' }}
                                            // onClick={() => (setShowEagleEyeState(true))}
                                            onMouseEnter={() => (setShowVideoLoftState(true))}
                                            onMouseLeave={() => (setShowVideoLoftState(false))}
                                        >
                                            <Icon className="fas fa-video" />
                                        </Button>
                                    </>
                                }
                                {site.camera_name}
                            </div>
                            <div className="float-right">
                                {loading ? (
                                    <Loading center />
                                ) : (
                                    <Tooltip
                                        placement="left"
                                        description={t("remove_camera_tooltip")}>
                                        <Button
                                            disabled={loading}
                                            onClick={removeCameraHelper}
                                            size="sm"
                                            variant="danger"
                                            className="px-2">
                                            <Icon className="fas fa-times" />
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {showVideoLoftState === true &&
                        <>
                            <div className="eagle-eye-live-view">
                                <VideoLoftLiveView
                                    subdomain={subdomain}
                                    auth_token={auth_token}
                                    camera_ref={site.camera_ref}
                                    stream_name={site.stream_name}
                                    imaging_server={imaging_server}
                                    data_server={data_server}
                                    src="buttonview"
                                    auth_expired={auth_expired}
                                    account_id={account_id}
                                    />{" "}
                            </div>
                        </>
                    }
                    <hr />
                    <Row>
                        <Col>
                            <SelectBundle
                                currentBundleIds={site.conxtd_alarm_bundles.map(({ id }) => id)}
                                camera_ref={site.camera_ref}
                                camera_name={site.camera_name}
                                camera_integration_site_id={site.camera_integration_site_id}
                                camera_sudo_site_id={site.id}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
}
CameraCard.propTypes = {
    /**
     * @param removeCamera api dispatch to remove camera from site
     */
    removeCamera: PropTypes.func.isRequired,

    /**
     * @param loading api loading status (null|true|false)
     */
    loding: PropTypes.any,

    /**
     * @param site_id id site to use for api integrations refresh
     */
    site_id: PropTypes.any.isRequired,

    /**
     * @param site_name name of the siote for confirm remove modal
     */
    site_name: PropTypes.string.isRequired,

    /**
     * @param integration_id id of the integration
     */
    integration_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
    const site_id = parseInt(getCurrentSite(state, props).id);
    const site = getSiteById(getSites(state, props), site_id);

    return {
        loading: state.sites.current.integrations.cameras.actions.remove.loading,
        site_id,
        site_name: site.name
    };
};
const mapDispatchToProps = {
    removeCamera: requestRemoveSiteCameraStart
};

export default withT(
    connect(mapStateToProps, mapDispatchToProps)(CameraCard),
    "site_overview.side_pane.manage_integrations.providers.video_loft"
);