import React from "react";
import { Link } from "react-router-dom";

class WaveLink extends React.Component {
  render() {
    if (this.props.external) {
      return (
        <a
          href={this.props.to}
          {...this.props}
          className={(this.props.className || "") + " waves-effect"}>
          {this.props.children}
        </a>
      );
    }
    return (
      <Link
        {...this.props}
        className={(this.props.className || "") + " waves-effect"}>
        {this.props.children}
      </Link>
    );
  }
}
export default WaveLink;
