import React from "react";

import { Link } from "react-router-dom";

import ReactTable from "react-table";
import "react-table/react-table.css";

import moment from "services/locale/momentInit.js";

import "./OutLocks.scss";

import AllowAccessApiButton from "components/OutLocks/Common/AllowAccessApiButton";
import DenyAccessApiButton from "components/OutLocks/Common/DenyAccessApiButton";

const data = [
  {
    acked: new moment().subtract(10, "minutes").toDate(),
    sudo_site_id: 1,
    status: "Open",
    name: "Imaging Demo Unit 17",
    id: 20982
  },
  {
    acked: new moment().subtract(30, "minutes").toDate(),
    sudo_site_id: 1,
    status: "Approved",
    name: "Imaging Demo Unit 17",
    id: 20982
  },
  {
    acked: new moment().subtract(1.5, "hours").toDate(),
    sudo_site_id: 1,
    status: "Denied",
    name: "Imaging Demo Unit 17",
    id: 20982
  },
  {
    acked: new moment().subtract(2, "hours").toDate(),
    sudo_site_id: 1,
    status: "Expired",
    name: "Imaging Demo Unit 17",
    id: 20982
  }
];

export default function Table({ selectSite }) {
  return (
    <div className="card">
      <ReactTable
        filterable
        defaultSorted={[
          {
            id: "received",
            desc: true
          }
        ]}
        defaultPageSize={10}
        data={data}
        columns={[
          {
            Header: "Site name",
            id: "site_name",
            accessor: "name",
            Cell: row => (
              <div>
                <Link to="#" onClick={() => selectSite(row.original)}>
                  {row.value}
                </Link>
              </div>
            )
          },
          {
            Header: "Request Recieved",
            id: "received",
            accessor: "acked",
            Cell: row => (
              <div>{moment(row.value).format("HH:mm DD/MM/YYYY")}</div>
            )
          },
          // {
          //   Header: "Duration Open",
          //   id: "duration",
          //   accessor: ({ acked }) => {
          //     return new moment(acked).fromNow();
          //   }
          // },
          {
            Header: "Site Ref",
            width: 120,
            id: "site_ref",
            accessor: "sudo_site_id"
          },
          {
            Header: "Status",
            width: 140,

            id: "status",
            accessor: "status"
          },
          {
            Header: "Action",
            // width: 480,
            width: 260,
            id: "action",
            Cell: ({ original }) => (
              <div>
                {/* <Button size="sm" variant="outline-primary" className="mr-2">
                  <Icon className="fas fa-video" /> Live Camera Feed
                </Button> */}
                <AllowAccessApiButton site_id={original.id} />
                <DenyAccessApiButton site_id={original.id} />
              </div>
            )
          }
        ]}
      />
    </div>
  );
}
