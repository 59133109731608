/**
 * Notification add rule reducer
 */

const add = function(state = {}, action) {
  switch (action.type) {
    case "REQUEST_NOTIFICATIONS_ADD_RULE_START":
      return {
        ...state,
        loading: true
      };
    case "REQUEST_NOTIFICATIONS_ADD_RULE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case "REQUEST_NOTIFICATIONS_ADD_RULE_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false
      };
    case "REQUEST_NOTIFICATIONS_ADD_RULE_NOTIFIED":
      return {
        ...state,
        success: null
      };
    default:
      return state;
  }
}

export default add;