const initialState = {
  loadingPinMaps: null,
  pinMaps: {},
  enterpriseAssociations: [],
  formData: {
    data: null,
    error: null,
  },
  companyFields: {
    data: null,
    error: null,
  },
  enterpriseFields: {
    data: null,
    error: null,
  },
  enterpriseGroups: {
    data: null,
    error: null,
  },
}

const serviceDeskReducer = (state = initialState, action) => {
  switch (action.type) {

    // Add site - get data to populate form
    case "LOAD_FORM_DATA_START":
      return {
        ...state,
        formData: {
          ...state.formData,
          error: null,
        }
      };
    case "LOAD_FORM_DATA_SUCCESS":
      return {
        ...state,
        formData: {
          ...state.formData,
          data: action.response,
        }
      };
    case "LOAD_FORM_DATA_FAILED":
      return {
        ...state,
        formData: {
          ...state.formData,
          error: action.error,
        }
      };
    case "LOAD_FORM_DATA_RESET":
      return {
        ...state,
        formData: initialState.formData,
      };

    // Add site - get company custom fields
    case "LOAD_COMPANY_FIELDS_START":
      return {
        ...state,
        companyFields: {
          ...state.companyFields,
          error: null,
        }
      };
    case "LOAD_COMPANY_FIELDS_SUCCESS":
      return {
        ...state,
        companyFields: {
          ...state.companyFields,
          data: action.response,
        }
      };
    case "LOAD_COMPANY_FIELDS_FAILED":
      return {
        ...state,
        companyFields: {
          ...state.companyFields,
          error: action.error,
        }
      };
    case "LOAD_COMPANY_FIELDS_RESET":
      return {
        ...state,
        companyFields: initialState.companyFields,
      };
    
    // Add site - get enterprise custom fields
    case "LOAD_ENTERPRISE_FIELDS_START":
      return {
        ...state,
        enterpriseFields: {
          ...state.enterpriseFields,
          error: null,
        }
      };
    case "LOAD_ENTERPRISE_FIELDS_SUCCESS":
      return {
        ...state,
        enterpriseFields: {
          ...state.enterpriseFields,
          loading: false,
          data: action.response?.data[0],
        }
      };
    case "LOAD_ENTERPRISE_FIELDS_FAILED":
      return {
        ...state,
        enterpriseFields: {
          ...state.enterpriseFields,
          error: action.error,
        }
      };
    case "LOAD_ENTERPRISE_FIELDS_RESET":
      return {
        ...state,
        enterpriseFields: initialState.enterpriseFields,
      };
    
    // Add site - get enterprise groups
    case "LOAD_ENTERPRISE_GROUPS_START":
      return {
        ...state,
        enterpriseGroups: {
          ...state.enterpriseGroups,
          error: null,
        }
      };
    case "LOAD_ENTERPRISE_GROUPS_SUCCESS":
      return {
        ...state,
        enterpriseGroups: {
          ...state.enterpriseGroups,
          data: action.response?.data,
        }
      };
    case "LOAD_ENTERPRISE_GROUPS_FAILED":
      return {
        ...state,
        enterpriseGroups: {
          ...state.enterpriseGroups,
          error: action.error,
        }
      };
    case "LOAD_ENTERPRISE_GROUPS_RESET":
      return {
        ...state,
        enterpriseGroups: initialState.enterpriseGroups,
      };

    
    // Asset Editor --
    // Get sudo site id (using site ref and company id)
    case "LOAD_SUDO_SITE_ID_START":
      return {
        ...state,
        sudoSiteId: action.data
      };
    case "LOAD_SUDO_SITE_ID_SUCCESS":
      return {
        ...state,
        sudoSiteId: action.response.data
      };
    case "LOAD_SUDO_SITE_ID_FAILED":
      return {
        ...state,
        sudoSiteId: null,
        error: action.error,
      };
    case "LOAD_SUDO_SITE_ID_RESET":
    return {
      ...state,
      sudoSiteId: null,
    };

    // Pin Maps
    case "LOAD_PIN_MAPS_START":
      return {
        ...state,
        loadingPinMaps: true,
      };

    case "LOAD_PIN_MAPS_SUCCESS":
      return {
        ...state,
        pinMaps: action.data,
        loadingPinMaps: false,
      }

    case "LOAD_PIN_MAPS_FAILED":
      return {
        ...state,
        loadingPinMaps: false,
      };

    case "LOAD_PIN_MAPS_CANCEL":
      return {
        ...state,
        loadingPinMaps: false,
      };
    
    case "LOAD_PIN_MAPS_RESET":
      return {
        ...state,
        pinMaps: {},
        loadingPinMaps: false,
      };

    // Load Enterprise Associations
    case "LOAD_ENTERPRISE_ASSOCIATIONS_START":
      return {
        ...state,
        loadingEnterpriseAssociations: true,
      };

    case "LOAD_ENTERPRISE_ASSOCIATIONS_SUCCESS":
      return {
        ...state,
        enterpriseAssociations: action.response.data.enterpriseAssociations,
        loadingEnterpriseAssociations: false,
      }

    case "LOAD_ENTERPRISE_ASSOCIATIONS_FAILED":
      return {
        ...state,
        loadingEnterpriseAssociations: false,
        error: action.error,
      };
    
    default:
      return state;
  }
}

export default serviceDeskReducer;