import React from "react";

const RightColourCell = ({ backgroundColour, displayValue, focus, underline }) => {
  return (
    <div
      className={`inner text-light ${underline ? "underline" : ""} ${
        focus ? "focus" : ""
      }`}
      style={{
        backgroundColor: backgroundColour,
        padding: "0.3rem",
        borderRadius: "0 16px 16px 0",
        height: "32px",
        fontWeight: "bold",
        // margin: "1px 0",
        paddingTop: "6px",
      }}
    >
      {displayValue || <span>&nbsp;</span>}
    </div>
  );
};

export { RightColourCell };