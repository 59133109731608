import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Loading from "components/Loading";

import ViewDashboard from "./ViewDashboard";

// Indexing stuff
import {
  loadReportsOverviewStart,
  loadReportsOverviewCancel
} from "services/redux/actions";

import { getRouterParams } from "services/redux/selectors/app/";

import {
  getEnterpriseFromRoute,
  getEnterprises
} from "services/redux/selectors/enterprises";
// import FacewatchOverview from "./Facewatch/FacewatchOverview";

class ReportsIndexPage extends React.Component {
  loadReports = props => {
    const reportLoadStarted = props.reports.overview.all.loading !== null;

    if (!props.enterprise || reportLoadStarted) {
      return;
    }

    const group_id =
      props.router.params.group_id === "root"
        ? props.enterprise.root_group_id
        : props.router.params.group_id;

    const params = {
      enterprise_id: props.enterprise.id,
      group_id,
      key: "all",
      refresh: true,
      interval: 60 * 1000
    };

    this.props.dispatch(loadReportsOverviewStart(params));
  };
  componentDidMount() {
    // Load reports
    this.loadReports(this.props);
  }
  componentWillUnmount() {
    this.props.dispatch(loadReportsOverviewCancel());
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newEnterprise =
      this.props.location.pathname !== nextProps.location.pathname;

    const noReportsLoadedYet =
      // Selected enterprise has been loaded
      //  and reports haven't been yet, retrigger
      nextProps.reports.overview.all.loading === null;
    if (newEnterprise || noReportsLoadedYet) {
      this.loadReports(nextProps);
    }
  }
  render() {
    if (!this.props.enterprise) return <Loading />;

    return <ViewDashboard />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    reports: state.reports,
    eventMeta: state.eventMeta,
    enterprise: getEnterpriseFromRoute(state, props),
    enterprises: getEnterprises(state, props),
    router: { params: getRouterParams(state, props) }
  };
};
export default withRouter(connect(mapStateToProps)(ReportsIndexPage));
