import React from "react";

import { withRouter } from "react-router-dom";

import Icon from "components/Icons/Icon";
import BREADCRUMBS from "constants/BREADCRUMBS.json";

import _ from "lodash";

class BackArrow extends React.Component {
  render() {
    let paths = window.location.toString().split("/");
    paths.splice(0, 3);

    // Remove hidden paths
    paths = paths.filter((path) => {
      const hidden = _.get(BREADCRUMBS[path], "hidden");
      return hidden !== "true";
    });

    // Don't show backarrow if on top level
    if (paths.length === 1) return null;

    return (
      <button
        type="button"
        className="button-menu-mobile open-left waves-effect"
        onClick={this.props.history.goBack}
      >
        <Icon className="fas fa-arrow-left font-16" />
      </button>
    );
  }
}

export default withRouter(BackArrow);
