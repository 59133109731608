//alarm-response
import React from "react";
import { connect } from "react-redux";

import "react-table/react-table.css";

import StatusIndicator from "react-status-indicator";

import { Button } from "react-bootstrap";

import { Link } from "react-router-dom";

import ToggleButton from "components/Common/Buttons/Toggle";

import { Tooltip } from "components/Common/Tooltip/";

import ProgressBar from "./ProgressBar";

import { 
  setSocketRoomConnected,
  loadAlarmsStart, 
  addAlarmEvent,
  removeOldAlarms,
  removeAllAlarms,
  loadAlarmsRefreshStart,
  loadAlarmsRefreshCancel,
  setAlarmsMuted,
  setAlarmsContinuousSounds,
  assignAlarmSOPUser,
  closeAlarmSOPAction,
} from "services/redux/actions/alarmResponse";
import { addDummyInAlarmSite } from "services/redux/actions/alarmResponse"; //alarm demo
import { loadSOPActionMapStart } from "services/redux/actions/sop";

import {
  getSites,
} from "services/redux/selectors";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import DataTable from "./DataTable";

import DUMMY_ALARM_DATA from "./Notifications/DUMMY_ALARM_DATA.json"; //alarm demo

import ButterToast, { Cinnamon, POS_TOP, POS_RIGHT } from 'butter-toast';

// import UIfx from 'uifx';
// import alertOrangeAudio from 'constants/Sounds/alertOrange.wav';
import alertOrangeAudio from 'constants/Sounds/alertOrange2.mp3';
import failRedAudio from 'constants/Sounds/failRed.wav';
import infoPurpleAudio from 'constants/Sounds/infoPurple.wav';
import restoreGreenAudio from 'constants/Sounds/restoreGreen.wav';
import setBlueAudio from 'constants/Sounds/setBlue.wav';
import testGreyAudio from 'constants/Sounds/testGrey.wav';
import unsetLightBlueAudio from 'constants/Sounds/unsetLightBlue.wav';

import COLOUR_CODES from "constants/COLOUR_CODES.json";

import moment from "services/locale/momentInit.js";

import Icon from "components/Icons/Icon";
import SVGIcon from "components/Icons/SVGIcon";

import socketIOClient from "socket.io-client";

import findIndex from "lodash/findIndex";

import "./AlarmResponse.scss";

// const ALARM_SOUNDS = {
//   alertOrange: new UIfx(
//     alertOrangeAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   failRed: new UIfx(
//     failRedAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   infoPurple: new UIfx(
//     infoPurpleAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   restoreGreen: new UIfx(
//     restoreGreenAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   setBlue: new UIfx(
//     setBlueAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   testGrey: new UIfx(
//     testGreyAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
//   unsetLightBlue: new UIfx(
//     unsetLightBlueAudio,
//     { volume: 0.4, throttleMs: 100 }
//   ),
// }

const ALARM_SOUNDS = {
  alertOrange: new Audio(alertOrangeAudio),
  failRed: new Audio(failRedAudio),
  infoPurple: new Audio(infoPurpleAudio),
  restoreGreen: new Audio(restoreGreenAudio),
  setBlue: new Audio(setBlueAudio),
  testGrey: new Audio(testGreyAudio),
  unsetLightBlue: new Audio(unsetLightBlueAudio),
}

const volume = 0.4;

ALARM_SOUNDS.alertOrange.volume = volume;
ALARM_SOUNDS.failRed.volume = volume;
ALARM_SOUNDS.infoPurple.volume = volume;
ALARM_SOUNDS.restoreGreen.volume = volume;
ALARM_SOUNDS.setBlue.volume = volume;
ALARM_SOUNDS.testGrey.volume = volume;
ALARM_SOUNDS.unsetLightBlue.volume = volume;

ALARM_SOUNDS.alertOrange.loop = false;
ALARM_SOUNDS.failRed.loop = false;
ALARM_SOUNDS.infoPurple.loop = false;
ALARM_SOUNDS.restoreGreen.loop = false;
ALARM_SOUNDS.setBlue.loop = false;
ALARM_SOUNDS.testGrey.loop = false;
ALARM_SOUNDS.unsetLightBlue.loop = false;

const audioErrorComponent = (
  <div 
    style={{
      display: "flex", 
      alignItems: "center", 
      color: COLOUR_CODES["failRed"],
      fontFamily: '"Open Sans", sans-serif',
    }}
  >
    <span>Encountered issue playing sounds</span>
  </div>
);

const key = 'q8z6ZDD^?W,k1DyG/$XJFR0X-iZ)hq~zMili*~zUS55t%mo4{dDfVs5Zpx|aU?B'; //socket

const liveRefreshInterval = (0.5 * 60 * 1000); // refresh every 30 sec

/**
 * Alarm Response container
 *
 **/
class AlarmResponse extends React.Component {
  state = {
    intervalId: null, //alarm demo
    injectAlarms: false, //alarm demo
    dummyAlarmCount: 0, //alarm demo
    initialLoad: false,
    socket: null,
    removeOldAlarmsInterval: null,
    audioErrorToast: null,
    retryContinuousSoundsIntervalId: null,
  };

  componentDidMount() {
    // window.caching.flush();

    // // if the user refreshes the page, but then doesn't interact with it in any way, e.g. by clicking somewhere, 
    // // the browser won't play any sounds. 
    // // we need to check if this has happened and if so, indicate to the user that sounds are muted.
    // // first try playing an audio file, but set the volume to zero because if the user has just come to the page by clicking
    // // the Priority Events link, then we don't want the audio file actually playing out loud
    // testMute.volume = 0;
    // const audioElementPromised = testMute.play();
    // audioElementPromised
    //   .then(() => {
    //     // autoplay started, everyting is ok
    //   })
    //   .catch(() => {
    //     // issue with playback, so show as muted
    //     this.props.dispatch(
    //       setAlarmsMuted({
    //         muted: true
    //       })
    //     ); 
    //   });

    const { enterprise, group, user } = this.props;

    let group_id = (group && group.id) || enterprise.root_group_id;
    if (group_id === "root" || !group_id) {
      group_id = enterprise && enterprise.root_group_id;
      // No enterprises loaded yet
      if (!group_id) return;
    }

    // this.props.dispatch(
    //   loadAlarmsStart({ 
    //     enterprise_id: enterprise.id,
    //     group_id, 
    //   })
    // );

    // this.props.dispatch(
    //   setSocketStatus({ 
    //     socketStatus: "", 
    //   })
    // );

    //I have inclued: !this.state.initialLoad in the state as i was having problems with the connection initiating twice
    if (!this.state.initialLoad) {
      //the socket server url to connect to
      let url = "wss://socket.conxtd.com";
      if (user.environment !== "live") {
        url = "wss://socket-dev.conxtd.com";
      }

      //the socket io function which sets up the connection
      const socket = socketIOClient(url, {
        auth: {
          token: key
        }
      });

      //connect event fired to the socket backend
      socket.on('connect', () => {
        console.log('Socket connection established');

        // send a message to the socket server telling it which room (group) we want to receive events from
        try {
          socket.emit('create', String(group_id), (data) => {
            // the socket server has received the message and is emitting events for the requested group
            console.log("Room connected");
            // store the socket's connected status in the state
            this.props.dispatch(
              setSocketRoomConnected({ 
                socketRoomConnected: true, 
              })
            );

            // cancel the back-up live refresh if it happens to be running
            this.props.dispatch(
              loadAlarmsRefreshCancel()
            );

            // retrieve older data from the api
            this.props.dispatch(
              loadAlarmsStart({ 
                enterprise_id: enterprise.id,
                group_id, 
              })
            ); 
            
            if (enterprise.in_alarm === "C") {
              this.props.dispatch(
                setAlarmsContinuousSounds({ 
                  continuousSounds: true, 
                })
              );
              ALARM_SOUNDS.alertOrange.loop = true;
              ALARM_SOUNDS.failRed.loop = true;
            }
          });
        } catch(err) {
          // some problem connecting to the room
          console.log("Error connecting to room: " + err);
          // if backup live refresh isn't already running, start it
          if (!this.props.liveRefreshAlarms) {
            this.props.dispatch(
              loadAlarmsRefreshStart({ 
                enterprise_id: enterprise.id,
                group_id, 
                interval: liveRefreshInterval,
              })
            );

            // store the socket's connected status in the state
            this.props.dispatch(
              setSocketRoomConnected({ 
                socketRoomConnected: false, 
              })
            );

            // switch off continuous sounds if they are on, and pause any that are already playing
            if (this.props.continuousSounds) {
              ALARM_SOUNDS.alertOrange.pause();
              ALARM_SOUNDS.failRed.pause();

              this.props.dispatch(
                setAlarmsContinuousSounds({ 
                  continuousSounds: false, 
                })
              );
            }
          }
        }

        setTimeout(() => {
          if (!this.props.socketRoomConnected) {
            console.log("Room not connected");
            // the room hasn't connected in a timely manner, so start the backup live refresh
            if (!this.props.liveRefreshAlarms) {
              this.props.dispatch(
                loadAlarmsRefreshStart({ 
                  enterprise_id: enterprise.id,
                  group_id, 
                  interval: liveRefreshInterval,
                })
              );
            }

            // switch off continuous sounds if they are on, and pause any that are already playing
            if (this.props.continuousSounds) {
              ALARM_SOUNDS.alertOrange.pause();
              ALARM_SOUNDS.failRed.pause();
              
              this.props.dispatch(
                setAlarmsContinuousSounds({ 
                  continuousSounds: false, 
                })
              );
            }
          }
        }, 5000);

      });

      //This handles any connection errors
      socket.on('connect_error', err => {
        console.log("Socket connection error: " + err.message);
        // if backup live refresh isn't already running, start it
        if (!this.props.liveRefreshAlarms) {
          this.props.dispatch(
            loadAlarmsRefreshStart({ 
              enterprise_id: enterprise.id,
              group_id, 
              interval: liveRefreshInterval,
            })
          );

          // store the socket's connected status in the state
          this.props.dispatch(
            setSocketRoomConnected({ 
              socketRoomConnected: false, 
            })
          );
        }

        // switch off continuous sounds if they are on, and pause any that are already playing
        if (this.props.continuousSounds) {
          ALARM_SOUNDS.alertOrange.pause();
          ALARM_SOUNDS.failRed.pause();
          
          this.props.dispatch(
            setAlarmsContinuousSounds({ 
              continuousSounds: false, 
            })
          );
        }
      });

      socket.on("RoomTest", (newEvent) => {
        const { muted, continuousSounds, enterprise, group, sop } = this.props;

        if (newEvent && newEvent.id) {
          // a new event
          if ((newEvent !== this.state.prevDataRoom)) { // this test probably isn't necessary now that the event listener
                                                        // has been moved out of render, but leaving it just in case
            this.setState({ prevDataRoom: newEvent}); // probably not necessary as per note above
            if (newEvent.sudo_state && (newEvent.sudo_state.length > 0)) {
              if (newEvent.sudo_state[0].show_toast && !newEvent.sudo_state[0].show_table) {
                // if the new event is set to NOT show in the table but it IS set to show the toast, 
                // call the notifyAlarm function now to show the toast
                this.notifyAlarm(newEvent);
              } else if (newEvent.sudo_state[0].show_table) {
                // else if the new event is set to show in the table, add it to the state,
                // then it will eventually show up in the table.
                // for these items, the reducer will determine their ordering within the sudo_state array of the site
                // and only items that land in the priority position and have show_toast = true will have a toast shown.
                // those that do not land in the priority position will not have a toast show even if they have show_toast = true
                newEvent.sudo_state[0] = { ...newEvent.sudo_state[0], fromSocket: true };
                this.props.dispatch(
                  addAlarmEvent({
                    data: newEvent
                  })
                );
              }  
            }    
          }
        } else if (newEvent && newEvent.update_assigned_user) {
          // an update to the assigned user of an existing event
          this.props.dispatch(
            assignAlarmSOPUser({
              enterprise_site_id: newEvent.enterprise_site_id,
              sudo_state_id: newEvent.sudo_state_id,
              sop_action_assigned_user: newEvent.assigned_user,
            })
          );

          if (sop.sudoStateId && sop.sudoStateId === newEvent.sudo_state_id) {
            // if user has the SOP View open on this event, refresh the sop action map so they can see the newly assigned user
            let groupId = (group && group.id) || enterprise.root_group_id;
            if (groupId === "root" || !groupId) {
              groupId = enterprise && enterprise.root_group_id;
            }
            this.props.dispatch(
              loadSOPActionMapStart({
                enterprise_id: enterprise.id,
                group_id: groupId,
                sudo_state_id: sop.sudoStateId,
              })
            );
          }

          if (continuousSounds && !muted) { // play continuous sound if necessary
            try {
              this.playNextPrioritySound(newEvent.sudo_state_id);
            } catch(ignore) {}
          }
        } else if (newEvent && newEvent.close) {
          // the action on an existing event has been closed
          this.props.dispatch(
            closeAlarmSOPAction({
              enterprise_site_id: newEvent.enterprise_site_id,
              sudo_state_id: newEvent.sudo_state_id,
              sudo_state: newEvent.sudo_state,
            })
          )
        }
      });

      // this sets this socket instance to the state in order for it to be used in the unmount
      this.setState({
        socket: socket
      })
    }

    // turns on initial load to stop multiple socket connections
    this.setState({ initialLoad: true })

    //alarm demo
    const intervalId = setInterval(() => {
      if (this.state.injectAlarms) {
        this.injectDummyAlarm();
      }
    }, 3000);
    this.setState({ intervalId });
    // //alarm demo

    const removeOldAlarmsInterval = setInterval(() => {
      this.props.dispatch(
        removeOldAlarms({ 
          removeAfter: 60, // minutes
        })
      );
    },  (1 * 60 * 1000)); // cycles every minute to check if there are old alarms to remove
    this.setState({ removeOldAlarmsInterval });
  }

  componentDidUpdate(prevProps) {
    const inAlarm = this.props.inAlarm;
    const inAlarmPrev = prevProps.inAlarm;

    const { latestEventMCT, muted, continuousSounds } = this.props;

    if (inAlarm) {
      inAlarm.forEach((item) => {
        if ((item.sudo_state[0].fromSocket) && (item.sudo_state[0].mct_alarm_log_id === latestEventMCT)) {
          // const prevIndex = findIndex(inAlarmPrev, { "site_ref": item.site_ref });
          const prevIndex = findIndex(inAlarmPrev, { "enterprise_site_id": item.enterprise_site_id });
          if (prevIndex === -1) {
            // a new row in the table (i.e there wasn't previously an alarm for that site being displayed)
            this.notifyAlarm(item);
            setTimeout(() => {
              const element = document.getElementById("id"+item.id);
              if (element) {
                element.classList.add("highlight-row");
              }
            }, 500);
          } else {
            if (inAlarmPrev && (item.sudo_state[0].mct_alarm_log_id !== inAlarmPrev[prevIndex].sudo_state[0].mct_alarm_log_id)) {
              // a row for the site already exists, and the alarm being displayed for that site is new (i.e. a new item in the sudo_state[0] position)
              this.notifyAlarm(item);
              setTimeout(() => {
                const element = document.getElementById("id"+item.id);
                if (element) {
                  element.classList.remove("highlight-row");
                  // it seems to need a moment between removing the class and adding it again or else the animation doesn't happen
                  setTimeout(() => { element.classList.add("highlight-row"); }, 500);
                }
              }, 500);
            }
          }
        }
      });

      if (continuousSounds && !muted && (inAlarm !== inAlarmPrev)) { // play continuous sound if necessary
        try {
          this.playNextPrioritySound(null);
        } catch(error) {
          console.log("Error playing continuous sounds: " + error.message);
          // most likely this error will happen if the page has been refreshed and the event meta data hasn't loaded yet,
          // so just start a loop to keep retrying until it works
          if (!this.state.retryContinuousSoundsIntervalId) {
            const retryContinuousSoundsIntervalId = setInterval(() => {
              const { muted, continuousSounds } = this.props;
              if (muted) {
                // if the sound is set to muted, stop looping to retry sound, and remove the audio error toast
                clearInterval(this.state.retryContinuousSoundsIntervalId);
                this.setState({ retryContinuousSoundsIntervalId: null });
                if (this.state.audioErrorToast) ButterToast.dismiss(this.state.audioErrorToast);
              } else if (continuousSounds) {
                this.playNextPrioritySound(null);
              }
            }, 2000);
            this.setState({ retryContinuousSoundsIntervalId });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    console.log('Unmount');
    //On mount, unmount was getting called an throwing error so I included the logic below
    if (this.state.socket !== null) {
      // disconnect the socket
      console.log('Socket disconnect');
      this.state.socket.disconnect();
      this.props.dispatch(
        setSocketRoomConnected({ 
          socketRoomConnected: false, 
        })
      );
    }

    clearInterval(this.state.intervalId); //alarm demo

    clearInterval(this.state.removeOldAlarmsInterval);

    // cancel the back-up live refresh if it happens to be running
    this.props.dispatch(
      loadAlarmsRefreshCancel()
    );

    // finally, remove all alarms from the state
    this.props.dispatch(
      removeAllAlarms()
    );

    // if continous alarms are playing, pause them
    if (this.props.continuousSounds) {
      ALARM_SOUNDS.alertOrange.pause();
      ALARM_SOUNDS.failRed.pause();
    }
  }

  muteClick = () => {
    const { muted, continuousSounds } = this.props;

    if (continuousSounds) {
      if (muted) {
        try {
          this.playNextPrioritySound(null);
        } catch(ignore) {}
      } else {
        ALARM_SOUNDS.alertOrange.pause();
        ALARM_SOUNDS.failRed.pause();
      }
    }

    this.props.dispatch(
      setAlarmsMuted({
        muted: !muted
      })
    ); 
  }

  toggleMuted = () => {
    const { muted } = this.props;

    this.props.dispatch(
      setAlarmsMuted({
        muted: !muted
      })
    ); 
  }

  testAudioHandler() {
    const audioRestoredComponent = (
      <div 
        style={{
          display: "flex", 
          alignItems: "center", 
          color: COLOUR_CODES["restoreGreen"],
          fontFamily: '"Open Sans", sans-serif',
        }}
      >
        <span>Sound restored</span>
      </div>
    );
  
    const testAudioPromise = ALARM_SOUNDS["testGrey"].play();
    testAudioPromise
      .then(() => {
        // playing ok, so remove the audio error toast
        if (this.state.audioErrorToast) {
          ButterToast.dismiss(this.state.audioErrorToast);
          this.setState({ audioErrorToast: null});
        }
        ButterToast.raise({
          content: <Cinnamon.Crisp 
            title={audioRestoredComponent}
            scheme={Cinnamon.Crisp.SCHEME_GREY}
          />,
        });
      })
      .catch(() => {
        // still a problem with audio. 
        // leave the toast up - the user can dismiss via the x button if they like.
      })
  }

  playNextPrioritySound(sudoStateId) {
    const { inAlarm, getEventMetaById } = this.props;
    ALARM_SOUNDS.alertOrange.pause();
    ALARM_SOUNDS.failRed.pause();

    const unassignedInAlarm = [];
    
    inAlarm.forEach((inAlarmItem) => {
      // check if any of the priority events (sudo_state[0]) meet the criteria for having an alarm sound play
      if (
        !inAlarmItem.sudo_state[0].sop_action_assigned_user // not assigned
        && (inAlarmItem.sudo_state[0].id !== sudoStateId) // not the event that has triggered this, i.e. the event that has just been assigned
                                                          // (we're allowing for the recent assignment not showing up in the data yet)
        && (inAlarmItem.sudo_state[0].state === "A") // an alarm (i.e. not a restore event)
        && !inAlarmItem.sudo_state[0].actioned_complete // not completed (probably don't need this test? would never be unassigned and completed?)
      ) {
        unassignedInAlarm.push(inAlarmItem.sudo_state[0]);
      }
    });

    if (unassignedInAlarm.length > 0) {
      // still some unassigned alarms. need to work out which is the highest priority
      unassignedInAlarm.sort((alarmA, alarmB) => {
        if (alarmA.position && !alarmB.position) {
          return -1;
        } else if (!alarmA.position && alarmB.position) {
          return 1;
        } else if (alarmA.position !== alarmB.position) {
          return alarmA.position - alarmB.position;
        } else {
          if (alarmA.recvd > alarmB.recvd) {
            return -1;
          } else if (alarmA.recvd < alarmB.recvd) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      const eventMeta = getEventMetaById(unassignedInAlarm[0].alarm_event_id);
      if (eventMeta.colour_name) {
        const audioPromise = ALARM_SOUNDS[eventMeta.colour_name].play(); 
        audioPromise
          .then(() => {
            // playing, so all good

            // if there is an interval checking whether to retry continuous sounds (because event meta wasn't there initially), clear it
            if (this.state.retryContinuousSoundsIntervalId) {
              clearInterval(this.state.retryContinuousSoundsIntervalId);
              this.setState({ retryContinuousSoundsIntervalId: null });
            }

            // if there is a toast saying there is an issue playing sounds, remove it
            if (this.state.audioErrorToast) {
              ButterToast.dismiss(this.state.audioErrorToast);
              this.setState({ audioErrorToast: null});
            }
          })
          .catch(() => {
            // issue with playback, so raise notification
            if (!this.state.audioErrorToast) {
              const audioErrorToast = ButterToast.raise({
                content: (
                  <Cinnamon.Crisp 
                    title={audioErrorComponent}
                    content={
                      <div style={{fontFamily: '"Open Sans", sans-serif', marginTop: "4px"}}>
                        <Button 
                          onClick={() => {this.testAudioHandler()}}
                          variant="primary"
                          className="btn-sm"
                        > Play Test Sound </Button>
                      </div>
                    }
                    scheme={Cinnamon.Crisp.SCHEME_GREY}
                  />
                ),
                sticky: true,
              });
              this.setState({ audioErrorToast: audioErrorToast});
            }
          });
      } else {
        throw new Error("Event meta not found.");
      }
    }
  }

  notifyAlarm(siteAlarmData) {
    const { getEventMetaById, muted, continuousSounds } = this.props;

    if (siteAlarmData.sudo_state[0].show_toast) {
      let event_id = "";
      if (siteAlarmData.sudo_state[0].state === 'A') {
        event_id = siteAlarmData.sudo_state[0].alarm_event_id;
      } else if (siteAlarmData.sudo_state[0].state === 'R' || siteAlarmData.sudo_state[0].master_restore_event) {
        event_id = siteAlarmData.sudo_state[0].restore_event_id;
      }

      if (event_id !== "") {
        const eventMeta = getEventMetaById(event_id);

        const IconComponent = (
          <div 
            style={{display:"flex", justifyContent: "flex-start"}} 
            // onClick={()=>{
            //   const element = document.getElementById(siteAlarmData.id);
            //   if (element) {
            //     // element.classList.add("highlight-row");
            //     // element.scrollTop = 0;
            //     // document.querySelector(".page-content-wrapper").scrollTop = 0;
            //   }
            // }}
          >
            <div
              className="svg-container-alarm"
              style={{
                display:"inline-block",
                padding: "5px",
                minWidth: "25px",
                height: "25px",
                background: eventMeta.colour,
                borderRadius: "15px",
                margin: "0 7px 0 0",
              }}
            >
              <div
                id={eventMeta.icon}
                className={`svg-icon`}
                style={{
                  width: "15px",
                  height: "15px",
                  margin: "0 auto",
                  position: "relative",
                  top: "-1px",
                }}
              >
                <SVGIcon type={eventMeta.icon} color={"#ffffff"} />
              </div>
            </div>
            <div 
              style={{
                display: "flex", 
                alignItems: "center", 
                color:eventMeta.colour,
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <span>{eventMeta.caption}</span>
            </div>
          </div>
        );

        ButterToast.raise({
          content: <Cinnamon.Crisp 
            title={IconComponent}
            content={<div style={{fontFamily: '"Open Sans", sans-serif', color: "#212529", fontSize: "12px"}}>{siteAlarmData.enterprise_site_name}</div>}
            scheme={Cinnamon.Crisp.SCHEME_GREY}
          />,
        });

        // eventMeta.colour_name && !muted && ALARM_SOUNDS[eventMeta.colour_name].play();
        if (eventMeta.colour_name && !muted) {
          if (!continuousSounds || (continuousSounds && siteAlarmData.sudo_state[0].state === 'R')) {
            const audioPromise = ALARM_SOUNDS[eventMeta.colour_name].play();
            audioPromise
              .then(() => {
                // playing, so all good

                // if there is a toast saying there is an issue playing sounds, remove it
                if (this.state.audioErrorToast) {
                  ButterToast.dismiss(this.state.audioErrorToast);
                  this.setState({ audioErrorToast: null});
                }
              })
              .catch(() => {
                // issue with playback, so raise notification
                if (!this.state.audioErrorToast) {
                  const audioErrorToast = ButterToast.raise({
                    content: (
                      <Cinnamon.Crisp 
                        title={audioErrorComponent}
                        content={
                          <div style={{fontFamily: '"Open Sans", sans-serif', marginTop: "4px"}}>
                            <Button 
                              onClick={() => {this.testAudioHandler()}}
                              variant="primary"
                              className="btn-sm"
                            > Play Test Sound </Button>
                          </div>
                        }
                        scheme={Cinnamon.Crisp.SCHEME_GREY}
                      />
                    ),
                    sticky: true,
                  });
                  this.setState({ audioErrorToast: audioErrorToast});
                }
              });
          }
        }
      }
    }
  }

  injectDummyAlarm() {
    const { sites } = this.props;

    // make every other dummy alarm a high priority one, and choose the others from a selection of dummy alarms
    let dummyAlarmIndex = 0;
    if (this.state.dummyAlarmCount % 3 !== 0) {
      dummyAlarmIndex = Math.floor(Math.random() * (DUMMY_ALARM_DATA.dummyInAlarmSite.length-1)) + 1;
    }

    const randomSiteRange = sites.length >= 10 ? 10 : sites.length;
    const randomSite = sites[Math.floor(Math.random() * randomSiteRange)];
    
    if (randomSite) {
      this.setState({ dummyAlarmCount: (this.state.dummyAlarmCount + 1) });

      const dummyInAlarmSite = { 
        ...DUMMY_ALARM_DATA.dummyInAlarmSite[dummyAlarmIndex] ,
        id: Number(new moment().format("HHmmssS")),
        enterprise_site_id: randomSite.id,
        enterprise_site_name: randomSite.name,
        site_ref: randomSite.site_ref,
        open_close_state: ((Math.floor(Math.random() * 2) === 0) ? "O" : "C" ),
        sudo_state: DUMMY_ALARM_DATA.dummyInAlarmSite[dummyAlarmIndex].sudo_state.map((element)=>{
            return { ...element, recvd: new moment().toDate() }
          }),
      };
  
      this.props.dispatch(
        addDummyInAlarmSite({
          dummyInAlarmSite: { ...dummyInAlarmSite }
        })
      );
  
      this.notifyAlarm(dummyInAlarmSite);
    }
  }

  render() {
    const { enterprise, group, user, muted, liveRefreshAlarms, lastRefresh, nextRefresh } = this.props;

    const enterpriseId = enterprise.id;
    const groupId = (group && group.id) || enterprise.root_group_id;

    let showDemoToggle = false;
    let showSOP = false;
    if (enterprise.in_alarm) {
      if (
        enterprise.in_alarm === "D" // Demo - show priority events with demo toggle
        || enterprise.in_alarm === "L" // Live - show priority events without demo toggle
        || enterprise.in_alarm === "N" // Disabled - priority events disabled
        || enterprise.in_alarm === "T" // Test - show priority events with demo toggle
        || enterprise.in_alarm === "S" // SOP - show priority events with SOP fields and without demo toggle
        || enterprise.in_alarm === "C" // SOP - show priority events with SOP fields and without demo toggle (and play continous sounds)
      ) {
        // new style where in_alarm contains a string
        showDemoToggle = (enterprise.in_alarm === "D" || enterprise.in_alarm === "T");
        showSOP = (enterprise.in_alarm === "S" || enterprise.in_alarm === "C");
      } else {
        // old style where in_alarm contains a boolean that just indicates 
        // whether to offer the Priority Events page or not
        showDemoToggle = (
          (window.location.hostname === "localhost" || window.location.hostname === "neptune.conxtd.com" || window.location.hostname === "pro-staging.conxtd.com")
          || (user.environment === "mars" || user.environment === "staging" )
        )    
      }
    }

    const demoToggle = showDemoToggle
      ? (
          <div className="d-flex justify-content-start mb-3">
            <ToggleButton
              id="toggle-inject"
              size="xs"
              style={{ backgroundColor: "#DEE2E6" }}
              active={false}
              onClick={(e) => {
                // e.stopPropagation();
                this.setState({ injectAlarms: !this.state.injectAlarms });
              }}
            />
          </div>
        )
      : null
    
    const mutedIcon = (muted)
      ? <Icon 
          className="fas fa-volume-mute" 
          style={{
            position: "relative",
            right: "4px", 
            bottom: "4px",
          }} 
        />
      : <Icon 
          className="fas fa-volume-up" 
          style={{
            position: "relative",
            right: "4px", 
            bottom: "4px",
          }} 
        />

    return (
      <div>
        {/* //alarm demo */}
        {demoToggle}
        {/*  // //alarm demo */}

        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-start align-items-center flex-wrap mx-2">
            <div className="d-flex justify-content-start align-items-center"  style={{ marginBottom: "8px" }}>
              <div>
                <Button
                  variant={muted ? "outline-danger" : "outline-primary"}
                  style={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    height: "26px",
                    width: "26px",
                    padding: "8px",
                    borderRadius: "50%",
                  }}
                  onClick={() => this.muteClick()}
                >
                  {mutedIcon}
                </Button>
              </div>

              { 
                !liveRefreshAlarms && // status showing connected to live websocket
                  <div style={{margin: "0 1rem", minWidth: "150px"}}>
                    <Tooltip 
                      description="Page updated as events are received."
                      placement="right"
                    >
                      <span style={{ 
                        whiteSpace: "nowrap", 
                        float: "right",
                        paddingRight: "5px",
                      }}>Real-time connection&nbsp;&nbsp;&nbsp;<StatusIndicator Positive Pulse /></span>
                    </Tooltip>
                  </div>
              }

              {
                liveRefreshAlarms && // status showing not connected to live websocket
                  <div style={{margin: "0 1rem", minWidth: "150px"}}>
                    <Tooltip 
                      description="Issue receiving real time events &amp; notifications - page has defaulted to live auto-refresh."
                      placement="right"
                    >
                      <span style={{ 
                        whiteSpace: "nowrap", 
                        float: "right",
                        paddingRight: "5px",
                      }}>Real-time connection&nbsp;&nbsp;&nbsp;<StatusIndicator Negative /></span>
                    </Tooltip>
                  </div>
              }
            </div>

            {
              liveRefreshAlarms //live refresh progress bar - only shows when live refresh is running (when not connected to websocket)
                ? <div className="d-flex justify-content-center flex-grow-1"  style={{ marginBottom: "8px", minHeight: "44px" }}>
                    <div
                      style={{ fontSize: "12px" }}
                      className="text-center">
                      <ProgressBar
                        placement="right"
                        nextRefresh={nextRefresh}
                        lastRefresh={lastRefresh}
                      />
                    </div>
                  </div>
                : // if websocket is connected ok and live refresh not running, 
                  // add empty divs that reflect the structure of the live refresh progress bar
                  // so that if it has to fall back to live refresh, 
                  // the other elements on the page don't change in position when the progress bar shows  
                  <div   style={{ marginBottom: "8px", minHeight: "44px" }}>
                    <div style={{padding: "0.25rem"}}>
                      <div style={{height: "12px"}}></div>
                    </div>
                    <div style={{margin: "-10px auto 0px", height: "10px"}}>
                      <div style={{height: "1px", marginTop: "1.5rem"}}></div>
                    </div>
                  </div>
            }
            
          </div>
          {showSOP &&
            <div>
              <Link
                to={{
                  pathname: `/enterprise/${enterpriseId}/${groupId}/alarms/completed-report`,
                }}
              >
                <div>
                  <Button
                    variant="success"
                  >Completed Report</Button> 
                </div>
              </Link>
            </div>
          }
        </div>
        <div>
          <ButterToast position={{ vertical: POS_TOP,  horizontal: POS_RIGHT }} style={{ top: "80px" }} /> 
        </div>
        <div>
          <DataTable
            showSOP={showSOP}
            playNextPrioritySound={this.playNextPrioritySound}
            muteClick={this.muteClick}
            toggleMuted={this.toggleMuted}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    user: state.user,
    socketRoomConnected: state.alarmResponse.socketRoomConnected,
    liveRefreshAlarms: state.alarmResponse.liveRefreshAlarms,
    lastRefresh: state.alarmResponse.lastRefresh,
    nextRefresh: state.alarmResponse.nextRefresh,
    muted: state.alarmResponse.muted,
    continuousSounds: state.alarmResponse.continuousSounds,
    inAlarm: state.alarmResponse.data.inAlarm,
    latestEventMCT: state.alarmResponse.latestEventMCT,
    getEventMetaById: createGetEventMetaById(state, props),
    sites: getSites(state),
    sop: state.sop,
  };
};
export default connect(mapStateToProps)(AlarmResponse);