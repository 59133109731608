import React from "react";
import { connect } from "react-redux";

// import { Row, Col, Form, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { withT } from "services/translation/";

class Features extends React.Component {
  render() {
    const { users, user } = this.props;

    const { features } = users.current.options;
    return features.map(({ id, name }, index) => (
      <Form.Check
        defaultChecked={
          user && user.features.some(feature => feature.id === id)
        }
        className="feature-checkbox"
        key={index}
        type="checkbox"
        id={id}
        name={`feature-${id}`}
        label={name}
        onChange={this.props.onChange}
      />
    ));
  }
}

const mapStateToProps = state => {
  return {
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(Features),
  "user_management.user_invite"
);
