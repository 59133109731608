import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Form } from "react-bootstrap";

import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";

class AddAppModal extends React.Component {

  clipboardControl = (id, controlText) => {
    return (
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Form.Control
          type="text"
          name="client_id"
          readOnly={true}
          value={controlText}
        />
        <div
          className="pl-2"
          style={{cursor: "pointer"}}
          onClick={() => {
            navigator.clipboard.writeText(controlText);
  
            const clipboardIcon = document.getElementById(`clipboard_${id}`);
  
            clipboardIcon.classList.remove("fa-clipboard");
            clipboardIcon.classList.remove("far");
            clipboardIcon.classList.add("fa-clipboard-check");
            clipboardIcon.classList.add("fas");
            
            setTimeout( function() {
              clipboardIcon.classList.remove("fa-clipboard-check");
              clipboardIcon.classList.remove("fas");
              clipboardIcon.classList.add("fa-clipboard");
              clipboardIcon.classList.add("far");
            }, 200);
          }}
        >
          <Icon
            id={`clipboard_${id}`}
            className="far fa-clipboard fa-lg text-primary"
            title="Copy to clipboard"
          />
        </div>
      </div>
    )
  }

  render() {
    const { appDetails } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Created
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Client Id</Form.Label>
            {this.clipboardControl("added_client_id", (appDetails && appDetails.client_id))}
            <Form.Label>Client Secret</Form.Label>
            {this.clipboardControl("added_client_secret", (appDetails && appDetails.client_secret))}
            <div className="font-14 font-weight-bold">Please ensure you store the Client Secret in a safe space. This will only be shown once. Close modal ONLY once saved.</div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex justify-content-end">
                <Button
                  className="p-2"
                  variant="primary"
                  onClick={() => {
                    Alert({
                      text: "Have you saved your Client Secret?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then(result => {
                      if (result.value) {
                        // confirmed - close
                        this.props.onHide(); 
                      }
                    });
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal.Footer>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(AddAppModal);