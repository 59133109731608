import { call, takeLatest } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";
import * as links from "services/redux/sagas/links";

import {
  getEventPairListStart,
  getEventPairListSuccess,
  getEventPairListFailed,
  getEventPairListCancel,
  getEventPairMapsStart,
  getEventPairMapsSuccess,
  getEventPairMapsFailed,
  getEventPairMapsCancel,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

// one-off saga to load the list of events pairs that could be added to priority events

export function* watchGetEventPairList() {
  yield actionWatcher({
    actions: {
      start: getEventPairListStart,
      failed: getEventPairListFailed,
      success: getEventPairListSuccess,
      cancel: getEventPairListCancel,
    },
    saga: getEventPairListRe,
  });
}

// https://mars.conxtd.com/out_api/EventPairs/index
export function* getEventPairListRe(action) {
  try {
    const url = "/EventPairs/index";

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
    
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

//---

// one-off saga to load mapped events

export function* watchGetEventPairMaps() {
  // Link alarms loading to event meta
  yield takeLatest(
    ["GET_EVENT_PAIR_MAPS_START"],
    links.toEventMetaBackgroundLoader
  );

  yield actionWatcher({
    actions: {
      start: getEventPairMapsStart,
      failed: getEventPairMapsFailed,
      success: getEventPairMapsSuccess,
      cancel: getEventPairMapsCancel,
    },
    saga: getEventPairMapsRe,
  });
}

// https://mars.conxtd.com/out_api/sop/event-pair-maps/event-pairs/{enterprise_id}
export function* getEventPairMapsRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id) 
      throw new Error(
        "enterprise_id is required for loading events pairs"
      );

    const url = `/sop/event-pair-maps/event-pairs/${enterprise_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;

  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}