import { useEffect } from "react";
import Alert from "components/Common/Alert";
import { saveAs } from "file-saver";
import conxtdOut from "apis/conxtdOut";

const Export = (props) => {
  useEffect(() => {
    async function fetchData() {
      try {
        Alert({
          title: "Downloading...",
          text: "Please wait",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        let path = "";
        if (props.path) {
          path = `${props.path}${props.enterpriseId ? "/"+props.enterpriseId : ""}${props.groupId ? "/"+props.groupId : ""}`
        } else {
          //default to group structure sites export
          path = `/EnterpriseGroups/export/${props.groupId}/${props.site_count}`;
        }

        let response = await conxtdOut.get(
          path,
          {
            responseType: "arraybuffer",
          }
        );
        const data = response.data;
        var blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        props.reset && props.reset();

        const exportName = props.exportName || "Groups Structure";
        if (blob.size === 77) {
          Alert({
            text:
              "We are processing your request. The file will be emailed shortly..",
            icon: "success",
            timerProgressBar: true,
            timer: 5000,
          });
        } else if (blob.size === 27) {
          Alert({
            text:
              "No contacts for the selected group.",
            icon: "warning",
            timerProgressBar: true,
            timer: 5000,
          });
        } else {
          saveAs(blob, props.enterpriseName + " - " + exportName + ".xlsx");
          Alert({
            text: exportName + " has been exported.",
            icon: "success",
            timerProgressBar: true,
            timer: 5000,
          });
        }
        // wait 5 secs before closing side pane
        await new Promise((resolve) => setTimeout(resolve, 1500));
      } catch (error) {
        console.log(error.message);
        Alert({
          text: "Export failed, please try again later.",
          icon: "error",
          timerProgressBar: true,
          timer: 5000,
        });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default Export;
