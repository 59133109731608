import React from "react";

import OverviewCard from "components/Enterprise/Overview/OverviewCard";
import GroupList from "components/Enterprise/Overview/GroupList";
import { SiteList } from "components/Enterprise/Overview/SiteList/";

import ReportsOverview from "../Reports/ReportsOverview";

/**
 * Default enterprise dashboard template
 *
 * The purpose of this is to provide a flexible,
 * high level view of the layout of the enterprise
 * overview page so fundamental layout changes can
 * be made easily
 */

class Group extends React.Component {
  // On click of stats, scroll down to element with id="group-card"
  handleClick() {
    let element = document.getElementById("group-card");
    element.scrollIntoView();
  }

  render() {
    const { current } = this.props;
    const loading = this.props.loading === true ? "loading" : "";

    return (
      <>
        {/* <div className="row">
          <h4 className="mt-0 px-3 pb-2 header-title w-100">
            <GroupTitle />
          </h4>
        </div> */}
        <div className={`row ${loading}`}>
          <div className="col-md-7 col-lg-7 col-xl-7 order-sm-2 order-2">
            <div className="row">
              <div className="col-md-12" onClick={this.handleClick}>
                <OverviewCard />
              </div>
            </div>
          </div>
          {current.showReports && (
            <div className="col-md-5 col-xl-5 order-1 order-md-2 order-xl-2 reports-column">
              <ReportsOverview />
            </div>
          )}
        </div>
        <div className={`row ${loading}`}>
          <div className="col-md-12 m-b-30">
            <section id="group-card">
              <GroupList />
            </section>
            <SiteList />
          </div>
        </div>
      </>
    );
  }
}

export default Group;
