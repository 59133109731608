import React from "react";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";
import COL_DIVISION from "constants/COL_DIVISION";

import {
  // changeScheduledReportIntervalFilter,
  changeScheduledReportEventTypeFilter
} from "services/redux/actions/";

import { getPieChartTotals } from "services/redux/selectors/reports/common/pie";

import { getReportType } from "services/redux/selectors/reports/router";

import _ from "lodash";

class FilterCircles extends React.Component {
  changeFilter = show => {
    const { reportType, disableFilter } = this.props;

    if (disableFilter) return;

    this.props.dispatch(
      changeScheduledReportEventTypeFilter({
        show,
        reportType
      })
    );
  };
  render() {
    const { totals, filter } = this.props;

    return (
      <div className="row filter-widgets-row">
        {totals.data.map((item, index) => {
          if (!_.get(item, "name")) return null;
          // const show = "show";
          const show =
            Object.keys(filter).length === 0
              ? "show"
              : _.get(filter, item.name) === true
              ? "show"
              : "";
          // Note: COL_DIVISON shows appropriate columns based on length of items available
          const col =
            _.get(COL_DIVISION, totals.data.length + "/" + index) ||
            _.get(COL_DIVISION, totals.data.length);
          return (
            <div
              id={item.id}
              className={`${col} text-center text-light`}
              key={index}>
              <div
                className={`filter-event-type ${show}`}
                onClick={() => {
                  const label = item.name;
                  this.changeFilter(label);
                }}
                style={{
                  backgroundColor: totals.colors[index],
                  display: "inline-block",
                  borderRadius: "50% 50%"
                }}>
                <h5 className="widget-title">{item.value}</h5>
                <p>
                  <T>{item.name}</T>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    totals: getPieChartTotals(state, props)(state, props)
  };
};
export default connect(mapStateToProps)(FilterCircles);
