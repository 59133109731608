/**
 * Site camera integrations reducer
 */

const integrations = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_SITE_INTEGRATIONS_START":
      return {
        ...state,
        loading: true
      };
    case "LOAD_SITE_INTEGRATIONS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case "LOAD_SITE_INTEGRATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        // No integrations returns null, fallback to []
        data: action.data || []
      };
    case "LOAD_SITE_INTEGRATIONS_CANCEL":
      return {
        ...state,
        loading: null,
        data: []
      };

    case "REQUEST_TETHER_CONNECT_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectTether: {
            ...state.actions.connectTether,
            success: true
          }
        }
      };
    case "REQUEST_TETHER_CONNECT_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectTether: {
            success: false,
            error: action.error
          }
        }
      };

      //Eagle Eye Connect
    case "REQUEST_EAGLE_EYE_CONNECT_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectEagleEye: {
            ...state.actions.connectEagleEye,
            loading: true,
            error: {}
          }
        }
      };

    case "REQUEST_EAGLE_EYE_CONNECT_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectEagleEye: {
            ...state.actions.connectEagleEye,
            loading: false,
            success: true,
          }
        }
      };

    case "REQUEST_EAGLE_EYE_CONNECT_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectEagleEye: {
            ...state.actions.connectEagleEye,
            loading: false,
            error: action.error
          }
        }
      };
            //VideoLoft Connect
    case "REQUEST_VIDEO_LOFT_CONNECT_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectVideoLoft: {
            ...state.actions.connectVideoLoft,
            loading: true,
            error: {}
          }
        }
      };

    case "REQUEST_VIDEO_LOFT_CONNECT_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectVideoLoft: {
            ...state.actions.connectVideoLoft,
            loading: false,
            success: true,
          }
        }
      };

    case "REQUEST_VIDEO_LOFT_CONNECT_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          connectVideoLoft: {
            ...state.actions.connectVideoLoft,
            loading: false,
            error: action.error
          }
        }
      };

    // Cameras
    case "LOAD_SITE_CAMERAS_START":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          loading: true
        }
      };
    case "LOAD_SITE_CAMERAS_FAILED":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          loading: null
        }
      };
    case "LOAD_SITE_CAMERAS_SUCCESS":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          data: action.data,
          loading: false
        }
      };

    case "REQUEST_ADD_SITE_CAMERA_START":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            add: {
              loading: true
            }
          }
        }
      };
    case "REQUEST_ADD_SITE_CAMERA_FAILED":
    case "REQUEST_ADD_SITE_CAMERA_SUCCESS":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            add: {
              loading: false
            }
          }
        }
      };

    //Locations
    case "LOAD_INTEGRATION_LOCATIONS_START":
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: true
        }
      };
    case "LOAD_INTEGRATION_LOCATIONS_FAILED":
      return {
        ...state,
        locations: {
          ...state.locations,
          data: action.data,
          loading: false
        }
      }
    case "LOAD_INTEGRATION_LOCATIONS_SUCCESS":
      return {
        ...state,
        locations: {
          ...state.locations,
          data: action.data,
          loading: false,
        }
      }
      case "LOAD_INTEGRATION_LOCATIONS_CANCEL":
        return {
          ...state,
          locations: {
            loading: null,
            data: []
          }
        };

      // Locations add
      case "REQUEST_ADD_INTEGRATION_LOCATION_FAILED":
      case "REQUEST_ADD_INTEGRATION_LOCATION_START":
        return {
          ...state,
          locations: {
            ...state.locations,
            actions: {
              ...state.locations.actions,
              add: {
                loading: true
              }
            }
          },
        };
        case "REQUEST_ADD_INTEGRATION_LOCATION_SUCCESS":
          return {
            ...state,
            locations: {
              ...state.locations,
              actions: {
                ...state.locations.actions,
                add: {
                  loading: true
                }
              }
            },
          };

    //Edit Camera
    case "REQUEST_EDIT_SITE_CAMERA_START":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            add: {
              loading: true,
            }
          }
        },
      };
    case "REQUEST_EDIT_SITE_CAMERA_SUCCESS":
      return {
        ...state,
        data: action.data,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            add: {
              loading: false,
            }
          }
        },
      };
    case "REQUEST_EDIT_SITE_CAMERA_FAILED":
    case "REQUEST_EDIT_SITE_CAMERA_CANCEL":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            add: {
              loading: false,
            }
          }
        },
      };

    //Remove camera  
    case "REQUEST_REMOVE_SITE_CAMERA_START":
      return {
        ...state,
        cameras: {
          ...state.cameras,
          actions: {
            ...state.cameras.actions,
            remove: {
              loading: true
            }
          }
        },
      };
    case "REQUEST_REMOVE_SITE_CAMERA_SUCCESS":
      return {
        ...state,
        data: action.data,
        cameras: {
          ...state.cameras,
          actions:{
              ...state.cameras.actions,
            remove: {
              loading: false
            }
          }
        },
      };
      case "REQUEST_REMOVE_SITE_CAMERA_FAILED":
  default:
    return state;
  }
};

export default integrations;