import React from "react";

export const ProgressBar = ({
  color,
  percentage,
  height = "8px",
  className = ""
}) => {
  return (
    <div
      className={"progress " + className}
      style={{
        height,
        marginTop: "7px"
      }}>
      <div
        className="progress-bar progress-bar-animated"
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          width: percentage + "%",
          height,
          backgroundColor: color
        }}
      />
    </div>
  );
};
