import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Col, Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import { withT } from "services/translation/";

import { requestAddIntegrationLocationStart } from "services/redux/actions";
import { getCurrentSite } from "services/redux/selectors/sites";

import SelectLocation from "./SelectLocation";


function AddLocation({
    camera_integration_site_id,
    auth_token,
    site_id,
    addLocation,
    loading,
    t
}) {
    const [view, setView] = useState("button");
    const [selectedLocation, selectLocation] = useState(null);

    const submit = e => {
        e.preventDefault();
        if (!selectedLocation) return;

        const location = {
            location_id: selectedLocation.location_id,
            location_name: selectedLocation.location_name,
        }; 

        addLocation({ location, site_id, camera_integration_site_id});
        selectLocation(null);
        setView("button");
    };

    if (view === "button") {
        return (
            <Button
            block
            variant="outline-success"
            onClick={() => {
                setView("form");
            }}>
            <Icon className="fas fa-plus" /> {t("add_location_to_site")}
            </Button>
        );
    }

    return (
        <Form onSubmit={submit}>
            <Form.Row>
            <SelectLocation
                camera_integration_site_id={camera_integration_site_id}
                selectLocation={selectLocation}
                selectedLocation={selectedLocation}
                auth_token={auth_token}
            />
            </Form.Row>
            {selectedLocation && (
            <Col>
                <p>{selectedLocation.location_address}</p>
            </Col>
            )}
            <Form.Row>
                <Form.Group controlId="formGroupSubmit" as={Col} md="6">
                    <Button
                        block
                        variant="danger"
                        type="submit"
                        onClick={() => {
                            setView("button");
                            selectLocation(null);
                        }}>
                            <Icon className="fas fa-times" /> {t("cancel_add_location")}
                    </Button>
                </Form.Group>
                <Form.Group controlId="formGroupSubmit" as={Col} md="6">
                    <Button block variant="success" type ="submit" disabled={loading}>
                        {loading ? (
                            <Loading center />
                        ) : (
                            <>
                            <Icon className="fas fa-plus" /> {t("add_location")}
                            </>
                        )}
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
}
AddLocation.propTypes = {
    /**
     * @param camera_integration_site_id sudo site id
     */
    camera_integration_site_id: PropTypes.number.isRequired,
  
    /**
     * @param auth_token integration auth token
     */
    auth_token: PropTypes.string.isRequired,
  
    /**
     * @param site_id id of the site we are adding to for api refresh
     */
    site_id: PropTypes.any.isRequired,
  
    /**
     * @param loading loading api status
     */
    loading: PropTypes.any
  };

const mapStateToProps = (state, props) => {
    return {
        loading: state.sites.current.integrations.cameras.actions.add.loading,
        site_id: getCurrentSite(state, props).id
    };
};
const mapDispatchToProps = { addLocation: requestAddIntegrationLocationStart}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withT(
        AddLocation, 
        "site_overview.side_pane.manage_integrations.providers.tether"
        )
    );