import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { Row, Col, Form, Button } from "react-bootstrap";

import Select from 'react-select';

import DOMPurify from 'dompurify';

import Loading from "components/Loading";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

import Alert from "components/Common/Alert";

import {
  setEmPriorityEventsManagementTabContent,
  getEventPairMapsStart,
} from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getStepTypeOptions, getEventPairMaps } from "services/redux/selectors/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

import find from "lodash/find";

import "./Common.scss";
import "./ConfigureEventWorkflow.scss";

class ConfigureEventWorkflow extends React.Component {
  state={
    openCloseType: "all",
    sopMapType: "enterprise_sop_maps", // when configuring the workflow, it's always the enterprise sop map
    changeTracking: { // keep track of if the user has made changes so they can be warned if they try to return to the list without saving
      allChanged: false,
      openChanged: false,
      closeChanged: false,
    },
    // workflowDetails and workflowSteps are initially populated with data from the api,
    // then updated as the user makes changes.
    workflowDetails: {
      all: {},
      open: {},
      close: {},
    },
    workflowSteps: {
      all: [],
      open: [],
      close: [],
    },
    sopMaps: {}, // this should always reflect what is stored in the backend for the event pair.
                 // it's used to check whether to use create or edit when saving changes
    saving: false,
    deleting: false,
  }

  componentDidMount() {
    const { sopMapType, changeTracking, workflowDetails, workflowSteps } = this.state;
    const { eventPairMaps, currentEventPairMap } = this.props;

    let sopMaps = {};
    if (eventPairMaps && eventPairMaps[currentEventPairMap.type]) {
      const eventPairMap = eventPairMaps[currentEventPairMap.type].find((eventPairMap) => eventPairMap.id === currentEventPairMap.eventPairMapId);
      if (eventPairMap) {
        sopMaps = eventPairMap.sop_maps[sopMapType] || {}
      }
    }

    let openCloseType = "all"; // default if there is nothing configured
    if (sopMaps.open) {
      openCloseType = "open"
    } else if (sopMaps.close) {
      openCloseType = "close";
    }

    const sopMapAll = sopMaps.all || { sop_action_libraries: [] };
    const sopMapOpen = sopMaps.open || { sop_action_libraries: [] };
    const sopMapClose = sopMaps.close || { sop_action_libraries: [] };

    this.setState({
      openCloseType: openCloseType,
      changeTracking: {
        ...changeTracking,
      },
      workflowDetails: {
        ...workflowDetails,
        all: {
          id: sopMapAll.id || null,
          name: sopMapAll.name || "",
          description: sopMapAll.description || "",
        },
        open: {
          id: sopMapOpen.id || null,
          name: sopMapOpen.name || "",
          description: sopMapOpen.description || "",
        },
        close: {
          id: sopMapClose.id || null,
          name: sopMapClose.name || "",
          description: sopMapClose.description || "",
        }
      },
      workflowSteps: {
        ...workflowSteps,
        all: sopMapAll.sop_action_libraries.map((step, index) => {
          return {
            ...step,
            id: index + 1,
            position: index + 1,
            stepTypeOption: { label: step.step, value: step.sop_action_library_id },
          }
        }),
        open: sopMapOpen.sop_action_libraries.map((step, index) => {
          return {
            ...step,
            id: index + 1,
            position: index + 1,
            stepTypeOption: { label: step.step, value: step.sop_action_library_id },
          }
        }),
        close: sopMapClose.sop_action_libraries.map((step, index) => {
          return {
            ...step,
            id: index + 1,
            position: index + 1,
            stepTypeOption: { label: step.step, value: step.sop_action_library_id },
          }
        }),
      },
      sopMaps: sopMaps,
    })
  }

  componentDidUpdate(prevProps) {
    const { sopMapType } = this.state;
    const { eventPairMaps, currentEventPairMap } = this.props;

    if (eventPairMaps !== prevProps.eventPairMaps) {
      let sopMaps = {};
      if (eventPairMaps && eventPairMaps[currentEventPairMap.type]) {
        const eventPairMap = eventPairMaps[currentEventPairMap.type].find((eventPairMap) => eventPairMap.id === currentEventPairMap.eventPairMapId);
        if (eventPairMap) {
          sopMaps = eventPairMap.sop_maps[sopMapType] || {}
        }
      }

      this.setState({
        sopMaps: sopMaps,
      })
    }
  }

  updateChangeTracking(openCloseType, isChanged) {
    const { changeTracking } = this.state;

    if (openCloseType === "all") {
      this.setState({
        changeTracking: {
          ...changeTracking,
          allChanged: isChanged,
        }
      })
    } else if (openCloseType === "open") {
      this.setState({
        changeTracking: {
          ...changeTracking,
          openChanged: isChanged,
        }
      })
    } else if (openCloseType === "close") {
      this.setState({
        changeTracking: {
          ...changeTracking,
          closeChanged: isChanged,
        }
      })
    }
  }

  render() {
    const {
      openCloseType,
      workflowDetails,
      workflowSteps,
      changeTracking,
      sopMaps,
      saving,
      deleting
    } = this.state;

    const {
      enterprise,
      group,
      currentEventPairMap,
      stepTypeList,
      stepTypeOptions
    } = this.props;

    const currentWorkflowDetails = workflowDetails[openCloseType] || {};
    
    const currentWorkflowSteps = workflowSteps[openCloseType].sort((a, b) => {
      return a.position - b.position;
    })

    return (
      <div>
        {/* Back Link */}
        <Row>
          <div className="btn btn-default border-none side-pane-back text-dark">
            <Link
              to="?tab=priority-events-management"
              onClick={() => {
                if (changeTracking.allChanged || changeTracking.openChanged || changeTracking.closeChanged) {
                  Alert({
                    text: "Unsaved changes",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Close anyway",
                    cancelButtonText: "Cancel",
                  }).then(result => {
                    if (result.value) {
                      // confirmed - close
                      this.props.dispatch(
                        setEmPriorityEventsManagementTabContent({
                          tabContent: "pe-management",
                        })
                      ) 
                    }
                  });
                } else {
                  this.props.dispatch(
                    setEmPriorityEventsManagementTabContent({
                      tabContent: "pe-management",
                    })
                  )
                }
              }
            }
            >
              <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> Back to Events</span>
            </Link>
          </div>
        </Row>

        {/* Workflow */}
        <Row>
          <Col>
            {/* Heading - Event Name */}
            <h6 className="mt-4 mb-4 font-weight-bold">{`Configure Event Workflow${currentEventPairMap.name ? " - " + currentEventPairMap.name : ""}`}</h6>

            {/* Form Submit - Save Workflow */}
            <Form onSubmit={(e) => {
              e.preventDefault();

              if (currentWorkflowSteps.length === 0) {
                Alert({
                  text: "Please add at least 1 step.",
                  icon: "warning",
                  showConfirmButton: true,
                });
              } else {
                this.setState({ saving: true });

                const groupId = (group && group.id) || enterprise.root_group_id;

                let url = "";
                if (sopMaps[openCloseType]) {
                  // there was existing data for this workflow, so use the edit endpoint
                  url = `/sop/sop-action-maps/edit/${enterprise.id}/${groupId}/${sopMaps[openCloseType].id}`
                } else {
                  // use the create endpoint because it's a new workflow
                  url = `/sop/sop-action-maps/create/${enterprise.id}/${groupId}`
                }

                const params = {
                  name: e.target.workflow_name.value,
                  description: e.target.workflow_description.value,
                  event_pair_id: currentEventPairMap.eventPairId,
                  op_cl: openCloseType.charAt(0).toUpperCase(),
                  sop_action_libraries: currentWorkflowSteps.map((step) => {
                    return {
                      id: step.stepTypeOption.value,
                      custom_description: step.custom_description,
                    }
                  })
                }

                const saveWorkflow = conxtdOut.post(url, params);

                saveWorkflow.then(() => {
                  this.props.dispatch(
                    getEventPairMapsStart({
                      enterprise_id: enterprise.id,
                    })
                  );
                  this.updateChangeTracking(openCloseType, false);
                  this.setState({ saving: false });
                  Alert({
                    text: "Workflow has been saved.",
                    icon: "success",
                    timerProgressBar: true,
                    timer: 1500,
                  });
                }).catch((error) => {
                  console.log("Error details", error);
                  this.setState({ saving: false });
                  Alert({
                    text: "Issue saving workflow. Please try again later.",
                    icon: "warning",
                    showConfirmButton: true,
                  });
                })
              }
            }}>

              {/* Radio Buttons */}
              {
                <div>
                  <Form.Label>Configure:</Form.Label>
                  <div className="mt-1 ml-4">
                    <input
                      type="radio"
                      id="all"
                      name="open_close_type"
                      value="A"
                      checked={openCloseType==="all"}
                      required
                      onChange={() => {
                        this.setState({
                          openCloseType: "all",
                        })
                      }}
                    />
                    <label htmlFor="all" className="ml-1">Single workflow</label>
                  </div>
                  <div className="ml-4">
                    <input
                      type="radio"
                      id="open_close"
                      name="open_close_type"
                      value="OC"
                      checked={openCloseType==="open" || openCloseType==="close"}
                      required
                      onChange={() => {
                        this.setState({
                          openCloseType: "open",
                        })
                      }}
                    />
                    <label htmlFor="open_close" className="ml-1">Separate workflows for Open and Close</label>
                  </div>
                </div>
              }

              {/* Open/Close Tabs */}
              {
                (openCloseType === "open" || openCloseType === "close") ?
                  <div className="d-flex justify-content-end">
                    <Button
                      className="open-close-button"
                      variant={ openCloseType === "open" && "active"}
                      onClick={() => {
                        this.setState({
                          openCloseType: "open",
                        })
                      }}
                    >
                      Open
                    </Button>
                    <Button
                      className="open-close-button ml-1 mr-3"
                      variant={ openCloseType === "close" && "active"}
                      onClick={() => {
                        this.setState({
                          openCloseType: "close",
                        })
                      }}
                    >
                      Close
                    </Button>
                  </div>
                : <div style={{ height: "38px" }}></div>
              }

              {/* Workflow Details */}
              <div className="workflow-container mb-3">
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Label>Workflow Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="workflow_name"
                        className="mb-3"
                        defaultValue={currentWorkflowDetails.name}
                        required
                        onChange={() => {
                          this.updateChangeTracking(openCloseType, true);
                        }}
                      />

                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="workflow_description"
                        defaultValue={currentWorkflowDetails.description}
                        onChange={() => {
                          this.updateChangeTracking(openCloseType, true);
                        }}
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>

                  {/* Workflow Steps */}
                  {
                    currentWorkflowSteps.map((step) => {
                      const stepTypeDetails = find(stepTypeList, ['id', step.stepTypeOption.value]);
                      const stepTypeCode = (stepTypeDetails && stepTypeDetails.code) ? stepTypeDetails.code : "";
                      return <div
                        key={step.position}
                        className="row"
                      >
                        <div className="col-md-6 mt-4"> 
                          <div className="step-container">
                            {/* Step Header Row */}
                            <div className="mt-2 d-flex justify-content-between">

                              {/* Step Heading - Number */}
                              <h6>Step {step.position}</h6>
                              <div>

                                {/* Move Up */}
                                {
                                  <Tooltip
                                    description="Move up"
                                    placement="top"
                                  >
                                    <Button
                                      style={{ fontSize: "20px" }}
                                      variant="light"
                                      onClick={() => {
                                        if (step.position > 1) {
                                          this.setState({
                                            workflowSteps: {
                                              ...workflowSteps,
                                              [openCloseType]: currentWorkflowSteps.map((stepItem) => {
                                                if (stepItem.position === step.position) {
                                                  return {
                                                    ...stepItem,
                                                    position: step.position - 1
                                                  }
                                                } else if (stepItem.position === step.position - 1) {
                                                  return {
                                                    ...stepItem,
                                                    position: step.position
                                                  }
                                                } else {
                                                  return stepItem
                                                }
                                              })
                                            },
                                          });

                                          this.updateChangeTracking(openCloseType, true);
                                        }
                                      }}
                                    >
                                      <Icon className="fas fa-arrow-circle-up" />
                                    </Button>
                                  </Tooltip>
                                }

                                {/* Move Down */}
                                {
                                  <Tooltip
                                    description="Move down"
                                    placement="top"
                                  >
                                    <Button
                                      style={{ fontSize: "20px" }}
                                      variant="light"
                                      className="ml-2"
                                      onClick={() => {
                                        if (step.position < currentWorkflowSteps.length) {
                                          this.setState({
                                            workflowSteps: {
                                              ...workflowSteps,
                                              [openCloseType]: currentWorkflowSteps.map((stepItem) => {
                                                if (stepItem.position === step.position) {
                                                  return {
                                                    ...stepItem,
                                                    position: step.position + 1
                                                  }
                                                } else if (stepItem.position === step.position + 1) {
                                                  return {
                                                    ...stepItem,
                                                    position: step.position
                                                  }
                                                } else {
                                                  return stepItem
                                                }
                                              })
                                            },
                                          });

                                          this.updateChangeTracking(openCloseType, true);
                                        }
                                      }}
                                    >
                                      <Icon className="fas fa-arrow-circle-down" />
                                    </Button>
                                  </Tooltip>
                                }

                                {/* Delete Step */}
                                {
                                  <Tooltip
                                    description="Delete step"
                                    placement="top"
                                  >
                                    <Button
                                      style={{ fontSize: "20px" }}
                                      variant="light"
                                      className="ml-2"
                                      onClick={() => {
                                        this.setState({
                                          workflowSteps: {
                                            ...workflowSteps,
                                            [openCloseType]: currentWorkflowSteps.map((stepItem) => {
                                              if (stepItem.position === step.position) {
                                                return null
                                              } else if (stepItem.position > step.position) {
                                                return {
                                                  ...stepItem,
                                                  position: stepItem.position - 1
                                                }
                                              } else {
                                                return stepItem
                                              }
                                            }).filter((stepItem) => !!stepItem)
                                          },
                                        });

                                        this.updateChangeTracking(openCloseType, true);
                                      }}
                                    >
                                      <Icon className="fas fa-trash-alt" />
                                    </Button>
                                  </Tooltip>
                                }
                              </div>
                            </div>

                            {/* Step Details */}
                            <div className="d-flex justify-content-between">
                              <div className="ml-2 flex-grow-1">
                                {/* Step Type */}
                                <Form.Label>Type</Form.Label>
                                <Select
                                  name={`step_type_${step.id}`}
                                  id={`step_type_${step.id}`}
                                  options={stepTypeOptions}
                                  value={step.stepTypeOption}
                                  classNamePrefix="configure-event-select"
                                  className="mb-3"
                                  maxMenuHeight={400}
                                  onChange={(e) => {
                                    this.setState({
                                      workflowSteps: {
                                        ...workflowSteps,
                                        [openCloseType]: currentWorkflowSteps.map((stepItem) => {
                                          if (stepItem.id === step.id) {
                                            return {
                                              ...stepItem,
                                              stepTypeOption: find(stepTypeOptions, ['value', e.value])
                                            }
                                          } else {
                                            return stepItem
                                          }
                                        })
                                      }
                                    });

                                    this.updateChangeTracking(openCloseType, true);
                                  }}
                                />

                                {/* Step Description/Instruction */}
                                {
                                  stepTypeCode !== "C3" && stepTypeCode !== "C4" &&
                                    <div>
                                      <Form.Label>Instruction</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name={`step_description_${step.id}`}
                                        id={`step_description_${step.id}`}
                                        className="mb-3"
                                        value={step.custom_description || ""}
                                        required={stepTypeCode === "C1"}
                                        onChange={(e) => {
                                          this.setState({
                                            workflowSteps: {
                                              ...workflowSteps,
                                              [openCloseType]: currentWorkflowSteps.map((stepItem) => {
                                                if (stepItem.id === step.id) {
                                                  return {
                                                    ...stepItem,
                                                    custom_description: e.target.value,
                                                  }
                                                } else {
                                                  return stepItem
                                                }
                                              })
                                            }
                                          });

                                          this.updateChangeTracking(openCloseType, true);
                                        }}
                                      />
                                    </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-4 d-flex"> 
                          <div
                            className="step-help-text-container flex-grow-1"
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(find(stepTypeList, ['id', step.stepTypeOption.value]).help_text)}}
                          />
                        </div>
                      </div>
                    })
                  }

                  {/* Add Step */}
                  {
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-3 d-flex justify-content-end">
                          <Button
                            style={{ width: (openCloseType === "open" || openCloseType === "close") ? "96px": "85px" }}
                            onClick={() => {
                              const nextId = currentWorkflowSteps.length + 1;
                              this.setState({
                                workflowSteps: {
                                  ...workflowSteps,
                                  [openCloseType]: [
                                    ...currentWorkflowSteps,
                                    {
                                      id: nextId,
                                      position: nextId,
                                      custom_description: "",
                                      stepTypeOption: {
                                        ...stepTypeOptions[0],
                                      },
                                    }
                                  ]
                                },
                              });

                              this.updateChangeTracking(openCloseType, true);
                            }}
                          >Add Step</Button>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  }
                </div>

                <hr style={{ marginTop: "0" }} />

                {
                  <div className="row pt-2 pb-4" style={{ margin: 0 }}>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between align-items-center" >
                        {/* Delete */}
                        <div>
                          <Button
                            variant="danger"
                            className="mr-3"
                            style={{ width: (openCloseType === "open" || openCloseType === "close") ? "108px": "70px" }}
                            onClick={() => {
                              Alert({
                                text: "Are you sure you wish to delete this Workflow?",
                                icon: "warning",
                                showCancelButton: true
                              }).then(result => {
                                if (result.value) {
                                  // confirmed - remove
                                  this.setState({ deleting: true });
              
                                  const groupId = (group && group.id) || enterprise.root_group_id;
                  
                                  const deleteWorkflow = conxtdOut.post(`/sop/sop-action-maps/remove/${enterprise.id}/${groupId}/${sopMaps[openCloseType].id}`);
                  
                                  deleteWorkflow.then(() => {
                                    this.props.dispatch(
                                      getEventPairMapsStart({
                                        enterprise_id: enterprise.id,
                                      })
                                    );

                                    this.setState({
                                      workflowDetails: {
                                        ...workflowDetails,
                                        [openCloseType]: {
                                          id: null,
                                          name: "",
                                          description: "",
                                        },
                                      },
                                      workflowSteps: {
                                        ...workflowSteps,
                                        [openCloseType]: [],
                                      }
                                    });
                                    this.updateChangeTracking(openCloseType, false);
                                    this.setState({ deleting: false });
                                    Alert({
                                      text: "Workflow has been deleted.",
                                      icon: "success",
                                      timerProgressBar: true,
                                      timer: 3000,
                                    });
                                  }).catch((error) => {
                                    console.log("Error details", error);
                                    this.setState({ deleting: false });
                                    Alert({
                                      text: "Issue deleting workflow. Please try again later.",
                                      icon: "warning",
                                      showConfirmButton: true,
                                    });
                                  })
                                }
                              });
                            }}
                          >{
                            openCloseType === "all"
                              ? "Delete"
                              : openCloseType === "open"
                                ? "Delete Open"
                                : "Delete Close"
                          }</Button>
                          {deleting && <Loading right />}
                        </div>

                        {/* Save */}
                        <div>
                          {saving && <Loading right />}
                          <Button
                            type="submit"
                            variant="success"
                            className="ml-3"
                            style={{ width: (openCloseType === "open" || openCloseType === "close") ? "96px": "85px" }}
                          >{
                            openCloseType === "all"
                              ? "Save"
                              : openCloseType === "open"
                                ? "Save Open"
                                : "Save Close"
                          }</Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                }
              </div>

            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    currentEventPairMap: state.enterpriseManager.priorityEventsManagement.currentEventPairMap,
    eventPairMaps: getEventPairMaps(state, props),
    stepTypeList: state.enterpriseManager.priorityEventsManagement.stepTypeList,
    stepTypeOptions: getStepTypeOptions(state),
  };
};
export default connect(mapStateToProps)(ConfigureEventWorkflow);