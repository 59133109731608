import React from "react";
import { OverlayTrigger, Tooltip as RBTooltip } from "react-bootstrap";

/**
 * Simplified tooltip
 *
 * Minimal usage example:
 * <Tooltip description="Tooltip explanation">
 *  <Button .../>
 * </Tooltip>
 *
 * <Tooltip
 *  TooltipComponent={<Component/>}
 *  placement="left"
 * />
 *
 */
export const Tooltip = ({
  children,
  placement = "top",
  fontSize = "12px",
  title = "",
  description = "",
  TooltipComponent = null,
  white = false,
  tooltipStyle = {},
  tooltipClassName = ""
}) => {
  const TooltipContent = TooltipComponent || (
    <div>
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{title}</div>
      <div className="py-1">{description}</div>
    </div>
  );

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <RBTooltip
          id={`tooltip-${placement}`}
          className={(white ? "tooltip-white tooltip-wide" : "") + tooltipClassName}
          style={{
            pointerEvents: "none",
            fontSize,
            ...tooltipStyle
          }}>
          {TooltipContent}
        </RBTooltip>
      }>
      {children}
    </OverlayTrigger>
  );
};
