/**
 * Get the formatted recording url
 */
export function formatUrl(recording_id) {
    let url = 'https://my.timeline.is/recordings/';
    // let d = new Date(acked),
    //     month = '' + (d.getMonth() + 1),
    //     day = '' + d.getDate(),
    //     year = d.getFullYear(),
    //     hour = '' + (d.getHours()),
    //     minute = '' + d.getMinutes(),
    //     seconds = '' + d.getSeconds();

    // if (month.length < 2) 
    //     month = '0' + month;
    // if (day.length < 2) 
    //     day = '0' + day;
    // if (hour.length < 2)
    //     hour = '0' + hour;
    // if (minute.length < 2)
    //     minute = '0' + minute;
    // if (seconds.length < 2)
    //     seconds = '0' + seconds;

    // let at = 'at=' + [year, month, day].join('-') + ' ' + [hour, minute, seconds].join(':') + '&';
    // let zone = 'zone=' + camera_ref + '&';
    let return_url = '?return_url=' + window.location.href;

    return url + recording_id + return_url;
  };