import React from "react";
import { Link } from "react-router-dom";

import CONXTDLogo from "../Layout/CONXTDLogo";

import { default as T } from "services/i18n/Translate";

const PageNotFound = () => {
  const Logo =
    localStorage.getItem("darkMode") === "true" ? (
      <CONXTDLogo />
    ) : (
      <CONXTDLogo dark />
    );
  return (
    <div className="account-pages">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card">
              <div className="card-block">
                <div className="ex-page-content text-center">
                  <h1 className="text-primary">404</h1>
                  <h6 className="px-4">
                    <p>
                      <T>
                        This page was not found, it may have been moved or
                        deleted.
                      </T>
                    </p>
                    <p>
                      <T>Please check the address and try again.</T>
                    </p>
                    <div>{Logo}</div>
                  </h6>
                  <br />

                  <Link
                    className="btn btn-primary mb-5 waves-effect waves-light"
                    to="/enterprise">
                    <T>Back to Dashboard</T>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
