import React from "react";
import SVGIcon from "components/Icons/SVGIcon";

const COLORS = {
  unsetLightblue: "#3498db",
  restoreGreen: "#27ae60",
  alertOrange: "#e67e22",
  failRed: "#c0392b",
  infoPurple: "#8e44ad",
  testGrey: "#777777",
  setBlue: "#29498b",
};

const LatestAlarmStatus = ({ alarmLastIcon, alarmLastColour }) => {
  const colour = COLORS[alarmLastColour] || "#666666";

  const IconComponent = (
    <div
      className="svg-container"
      style={{
        padding: "4px",
        background: colour,
        borderRadius: "20px",
        margin: "auto",
      }}
    >
      <div
        id={alarmLastIcon}
        className={`svg-icon`}
        style={{
          width: "40px",
          height: "20px",
          margin: "0 auto",
          position: "relative",
          top: "-1px",
        }}
      >
        <SVGIcon type={alarmLastIcon} color={"#ffffff"} />
      </div>
    </div>
  );

  return <div className="site-status-container">{IconComponent}</div>;
};

export { LatestAlarmStatus };
