import React from "react";
import { connect } from "react-redux";

// import StatisticsList from "./StatisticsList";
import StatisticWidgets from "./StatisticWidgets";

import {
  getCurrentGroup,
  getGroupStats
} from "services/redux/selectors/groups";

class Statistics extends React.Component {
  render() {
    const { stats, loading } = this.props;

    // const widgets = stats[0];
    // const progressBars = [stats[1], stats[2]];

    return (
      <div className={"visual-data" + (loading !== false ? " loading" : "")}>
        <StatisticWidgets stats={stats} />

        {/* {progressBars.map((props, index) => (
          <StatisticsList key={index} {...props} />
        ))} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const currentGroup = getCurrentGroup(state);

  return {
    loading: currentGroup.loading,
    stats: getGroupStats(currentGroup)
  };
};
export default connect(mapStateToProps)(Statistics);
