import { call, put , takeLatest } from "redux-saga/effects";

import {
    loadOpenCloseByAreaStart,
    loadOpenCloseByAreaSuccess,
    loadOpenCloseByAreaFailed,
    loadOpenCloseByAreaCancel,
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* loadOpenClose({ site_id }) {
    try {
        const result = yield call(conxtdOut.get, `/EventPairs/byArea/${site_id}`);
        return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* loadOpenCloseWatcher() {
    yield actionWatcher({
        actions: {
            start: loadOpenCloseByAreaStart,
            success: loadOpenCloseByAreaSuccess,
            failed: loadOpenCloseByAreaFailed,
            cancel: loadOpenCloseByAreaCancel,
        },
        saga: loadOpenClose
    })
}

/**
 * Link loading of site view to integrations
 * but only if there are camera events available
 */
export function* linksToSiteView() {
    yield takeLatest("LOAD_SITE_VIEW_SUCCESS", function*(action) {
      // const hasIntegration = action.site.alarmBundles.some(bundle =>
      //   bundle.events.some(event => event.camera_events.length > 0)
      // );
  
      // if (hasIntegration) {
      const site_id = action.startAction.id;
  
      yield put(loadOpenCloseByAreaStart({ site_id }));
      // }
    });
  }