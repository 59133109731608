import { createSelector } from "reselect";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getSites, getSiteById } from "services/redux/selectors/sites";

import moment from "services/locale/momentInit.js";

export const getSOPCompletedReport = (state) => state.sopReports.completedReport;

export const getSOPCloseDownOptionsData = state => state.sop.closeDownOptions;

export const getSOPCloseDownOptionById = (closeDownOptionsData, closeDownOptionId) => {
  return closeDownOptionsData.find((closeDownOption) => closeDownOption.id === closeDownOptionId) || {};
};

export const createGetSOPCloseDownOptionById = createSelector(
  [getSOPCloseDownOptionsData],
  closeDownOptionsData => closeDownOptionId => getSOPCloseDownOptionById(closeDownOptionsData, closeDownOptionId)
);

export const getSOPCompletedReportData = createSelector(
  [getSOPCompletedReport, createGetEventMetaById, getSites, createGetSOPCloseDownOptionById],
  (completedReport, getEventMetaById, sites, getSOPCloseDownOptionById) => {
    const resultingCompletedReportData = (completedReport && completedReport.data && completedReport.data.events 
                                       && completedReport.data.events.map((event) => {
      // get the event meta data for the report item
      const completedReportItemEventMeta = getEventMetaById(event.mct_alarm_log.alarm_event_id);

      // get the site data for the report item
      const completedReportItemSite = getSiteById(sites, event.enterprise_site_id);

      // get the close down option for the report item
      const completedReportItemCloseDownOption = getSOPCloseDownOptionById(event.sop_close_down_log.sop_close_down_option_id);

      const completedReportItemTime = (event.mct_alarm_log && event.mct_alarm_log.acked) ? new Date(event.mct_alarm_log.acked) : null;
      const completedReportItemActioned = event.created ? new Date(event.created) : null;
      const completedReportItemCompleted = (event.sop_close_down_log && event.sop_close_down_log.created) ? new Date(event.sop_close_down_log.created) : null;

      const completedReportItemResponse = (completedReportItemActioned && completedReportItemTime) ? completedReportItemActioned - completedReportItemTime : 0;
      const completedReportItemDuration = (completedReportItemCompleted && completedReportItemTime) ? completedReportItemCompleted - completedReportItemTime : 0;

      return {
        ...event,
        eventMeta: { ...completedReportItemEventMeta },
        site: { ...completedReportItemSite },
        closeDownOption: { ...completedReportItemCloseDownOption },
        response: completedReportItemResponse,
        duration: completedReportItemDuration,
      };
    })) || [];

    return resultingCompletedReportData;
  }
);

export const getSOPCloseDownOptions = createSelector(
  [getSOPCloseDownOptionsData],
  (closeDownOptionsData) => {

    const createOption = (closeDownOption) => {
      return {
        label: closeDownOption.option,
        value: closeDownOption.id,
      }
    }

    const falseAlarmOptionsData = closeDownOptionsData
      .filter(closeDownOption => closeDownOption.type === "F")
      .map(createOption)

    const realAlarmOptionsData = closeDownOptionsData
    .filter(closeDownOption => closeDownOption.type === "R")
    .map(createOption)

    const otherAlarmOptionsData = closeDownOptionsData
    .filter(closeDownOption => closeDownOption.type === "U")
    .map(createOption)

    let closeDownOptions = [];

    if (falseAlarmOptionsData.length > 0) {
      closeDownOptions.push({
        label: "False Alarm",
        value: "F",
        children: falseAlarmOptionsData,
      })
    }

    if (realAlarmOptionsData.length > 0) {
      closeDownOptions.push({
        label: "Real Alarm",
        value: "R",
        children: realAlarmOptionsData,
      })
    }

    if (otherAlarmOptionsData.length > 0) {
      closeDownOptions.push({
        label: "Other",
        value: "U",
        children: otherAlarmOptionsData,
      })
    }

    return closeDownOptions;
  }
);

export const getActualWithTimezone = (occurrence) => {
  const localDate = new moment(occurrence.acked);

  if (occurrence.timezone) {
    const timezoneDate = new moment.utc(localDate).tz(occurrence.timezone);
    const localTimezoneText = moment.tz.guess();
    const timezoneText = occurrence.timezone === localTimezoneText ? "" : occurrence.timezone;
  
    return `${timezoneDate.format(occurrence.format)} ${timezoneText}`
  } else {
    return `${localDate.format(occurrence.format)}`
  }
};