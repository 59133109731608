import React from "react";

import SVGIcon from "components/Icons/SVGIcon";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const EventCell = ({ event, onClick, focus, underline }) => {
  if (typeof event === "undefined") {
    return null;
  } else {
    const { icon, label, colour, description } = event;
    const Icon = icon ? (
      <div
        id={icon}
        className={`svg-icon text-white mr-1`}
        style={{
          display: "inline-block",
          width: "14px",
          height: "14px",
          position: "relative",
          top: "-1px",
        }}
      >
        <SVGIcon type={icon} color={"#ffffff"} />
      </div>
    ) : null;

    const EventComponent = (
      <div
        style={{
          backgroundColor: colour,
          cursor: "pointer",
          padding: "0.3rem",
        }}
        onClick={onClick}
        className={`inner text-light ${underline ? "underline" : ""} ${
          focus ? "focus" : ""
        }`}
      >
        {Icon}
        {label}
      </div>
    );

    const Cell = description ? (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="tooltip-left" style={{ pointerEvents: "none" }}>
            <div style={{ fontSize: "12px" }} className="py-1">
              {description || ""}
            </div>
          </Tooltip>
        }
      >
        {EventComponent}
      </OverlayTrigger>
    ) : (
      EventComponent
    );

    return Cell;
  }
};

export default EventCell;