import { createSelector } from "reselect";

export const getCompanyIdFromRoute = (state, props) => {
  const { company_id } = state.app.router.params || props.match.params;
  return company_id;
};

export const getSiteRefFromRoute = (state, props) => {
  const { site_ref } = state.app.router.params || props.match.params;
  return site_ref;
};

export const getFilterMobileApps = (state) => state.company.filterData.mobileApps;
export const getFilterArcMaps = (state) => state.company.filterData.arcmaps;
export const getFilterInstallers = (state) => state.company.filterData.installers;
export const getFilterEnterprises = (state) => state.company.filterData.enterprises;

export const getMobileAppOptions = createSelector(
  [getFilterMobileApps],
  (mobileApps) => {
    return mobileApps
      ? Object.entries(mobileApps)
        .filter((mobileApp) => {
          return (!!mobileApp[1])
        })
        .map((mobileApp) => {
          return (
            {
              label: mobileApp[1],
              value: mobileApp[0]
            }
          )
        })
      : []
  }
);

export const getArcMapOptions = createSelector(
  [getFilterArcMaps],
  (arcMaps) => {
    return arcMaps
      ? Object.entries(arcMaps)
        .filter((arcMap) => {
          return (!!arcMap[1])
        })
        .map((arcMap) => {
          return (
            {
              label: arcMap[1],
              value: arcMap[0]
            }
          )
        })
      : []
  }
);

export const getInstallerOptions = createSelector(
  [getFilterInstallers],
  (installers) => {
    return installers
      ? Object.entries(installers)
        .filter((installer) => {
          return (!!installer[1])
        })
        .map((installer) => {
          return (
            {
              label: installer[1],
              value: installer[0]
            }
          )
        })
      : []
  }
);

export const getEnterpriseOptions = createSelector(
  [getFilterEnterprises],
  (enterprises) => {
    return enterprises
      ? Object.entries(enterprises)
        .filter((enterprise) => {
          return (!!enterprise[1])
        })
        .map((enterprise) => {
          return (
            {
              label: enterprise[1],
              value: enterprise[0]
            }
          )
        })
      : []
  }
);