// SITES / BACKGROUND
export const loadSitesBackgroundStart = params => {
  return { type: "LOAD_SITES_BACKGROUND_START", ...params };
};
export const loadSitesBackgroundFailed = error => {
  return { type: "LOAD_SITES_BACKGROUND_FAILED", ...error };
};
export const loadSitesBackgroundSuccess = response => {
  return { type: "LOAD_SITES_BACKGROUND_SUCCESS", ...response };
};
export const loadSitesBackgroundRestore = response => {
  return { type: "LOAD_SITES_BACKGROUND_RESTORE", ...response };
};
export const loadSitesBackgroundCancel = () => {
  return { type: "LOAD_SITES_BACKGROUND_CANCEL" };
};
// SITE / CURRENT
export const loadSiteViewStart = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_START"
  };
};
export const loadSiteViewRefresh = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_REFRESH"
  };
};
export const loadSiteViewSuccess = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_SUCCESS"
  };
};
export const loadSiteViewFailed = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_FAILED"
  };
};
export const loadSiteViewCancel = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_CANCEL"
  };
};
export const loadSiteViewLiveRefreshStart = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_LIVE_REFRESH_START"
  };
};
// note: the live refresh functionality uses LOAD_SITE_VIEW_SUCCESS as it's success action type
export const loadSiteViewLiveRefreshFailed = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_LIVE_REFRESH_FAILED"
  };
};
export const loadSiteViewLiveRefreshCancel = params => {
  return {
    ...params,
    type: "LOAD_SITE_VIEW_LIVE_REFRESH_CANCEL"
  };
};
export const setSiteViewRefreshCount = params => {
  return {
    ...params,
    type: "SET_SITE_VIEW_REFRESH_COUNT"
  };
};
export const addLinkedComment = params => {
  return {
    ...params,
    type: "ADD_LINKED_COMMENT"
  };
};
export const loadNotificationHistoryStart = params => {
  return {
    ...params,
    type: "LOAD_NOTIFICATION_HISTORY_START"
  };
};
export const loadNotificationHistorySuccess = params => {
  return {
    ...params,
    type: "LOAD_NOTIFICATION_HISTORY_SUCCESS"
  };
};
export const loadNotificationHistoryFailed = params => {
  return {
    ...params,
    type: "LOAD_NOTIFICATION_HISTORY_FAILED"
  };
};
export const loadNotificationHistoryCancel = params => {
  return {
    ...params,
    type: "LOAD_NOTIFICATION_HISTORY_CANCEL"
  };
};
export const clearNotificationHistories = params => {
  return {
    ...params,
    type: "CLEAR_NOTIFICATION_HISTORIES"
  };
};
// SITE SET/UNSET
export const requestChangeSiteSetStart = params => {
  return {
    type: "REQUEST_CHANGE_SITE_SET_START",
    ...params
  };
};
export const requestChangeSiteSetSuccess = params => {
  return {
    type: "REQUEST_CHANGE_SITE_SET_SUCCESS",
    ...params
  };
};
export const requestChangeSiteSetFailed = params => {
  return {
    type: "REQUEST_CHANGE_SIT_SETE_FAILED",
    ...params
  };
};
export const requestChangeSiteSetCancel = params => {
  return {
    type: "REQUEST_CHANGE_SITE_SET_CANCEL",
    ...params
  };
};
// SITE TIMEFRAME
export const changeGroupTimeframe = params => {
  return {
    type: "CHANGE_CURRENT_GROUP_TIMEFRAME",
    ...params
  };
};

// Site Engineer/Test Mode sessions
export const loadSiteModeSessionStart = params => {
  return {
    type: "LOAD_SITE_MODE_SESSION_START",
    ...params
  };
};
export const loadSiteModeSessionSuccess = params => {
  return {
    type: "LOAD_SITE_MODE_SESSION_SUCCESS",
    ...params
  };
};
export const loadSiteModeSessionFailed = params => {
  return {
    type: "LOAD_SITE_MODE_SESSION_FAILED",
    ...params
  };
};

export const loadSiteModeSessionCancel = params => {
  return {
    type: "LOAD_SITE_MODE_SESSION_CANCEL",
    ...params
  };
};

// Site Engineer/Test Mode events
export const loadSiteModeEventsStart = params => {
  return {
    type: "LOAD_SITE_MODE_EVENTS_START",
    ...params
  }
}

export const loadSiteModeEventsSuccess = params => {
  return {
    type: "LOAD_SITE_MODE_EVENTS_SUCCESS",
    ...params
  }
}

export const loadSiteModeEventsCancel = params => {
  return {
    type: "LOAD_SITE_MODE_EVENTS_CANCEL",
    ...params
  }
}

export const loadSiteModeEventsFailed = params => {
  return {
    type: "LOAD_SITE_MODE_EVENTS_Failed",
    ...params
  }
}

export * from "./keyholders";
export * from "./integrations/";

//OpenClose by Area
export const loadOpenCloseByAreaStart = params => {
  return {
    type: "LOAD_OPEN_CLOSE_BY_AREA_START",
    ...params
  };
};
export const loadOpenCloseByAreaSuccess = params => {
  return {
    type: "LOAD_OPEN_CLOSE_BY_AREA_SUCCESS",
    ...params
  };
};
export const loadOpenCloseByAreaFailed = params => {
  return {
    type: "LOAD_OPEN_CLOSE_BY_AREA_FAILURE",
    ...params
  };
};
export const loadOpenCloseByAreaCancel = params => {
  return {
    type: "LOAD_OPEN_CLOSE_BY_AREA_CANCEL",
    ...params
  };
};