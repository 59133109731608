import { takeLeading, all } from "redux-saga/effects";

import * as enterprises from "./enterprises";

import cacheIndexes from "../cacheIndexes/";

export function* rootSaga() {
  // Enable caching
  yield takeLeading("LOAD_ENTERPRISES_SUCCESS", cacheIndexes.cacheMe);

  yield all([enterprises.rootSaga()]);

  // When user logs in (or login is restored) we start loading/restoring enterprises
  // Deprecated: we are now storing the enterprises list
  //   from the login request as this halves app loading time...
  // yield takeLatest("LOGIN_SUCCESS", enterprises.loadStart);
}
