import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import FilterButton from "./FilterButton";

import { Card } from "react-bootstrap";

import {
  getConfig,
  getFiltered,
} from "services/redux/selectors/reports/common/";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import { getScheduledOccurrencesInTimeRange } from "services/redux/selectors/reports/scheduled";

import "./SuggestedFilters.scss";

class SuggestedFilters extends React.Component {
  render() {
    const { filtered, config, getEventMetaById, occurrences } = this.props;
    const title = config.title;
    if (title.includes("People")) {
      return null;
    } else {
      if (config.dataTable.suggestedFilters.length === 0) return null;

      // Get only filters that apply to the "result" column
      const filters = filtered.filter((filter) => filter.id === "result");

      let activeFilters = 0;

      const FilterButtons = config.dataTable.suggestedFilters.map(
        (event_id, index) => {
          const event = getEventMetaById(event_id);

          const eventCount = occurrences.filter(
            (occurrence) => occurrence.alarm_id === event_id
          ).length;

          // This filter is currently applied
          if (
            filters.length > 0 &&
            eventCount > 0 &&
            filters.filter((filter) => filter.value === event.caption).length >
              0
          ) {
            activeFilters++;
            return (
              <FilterButton key={index} event={event} eventCount={eventCount} />
            );
          }

          return (
            <FilterButton
              className="inactive"
              key={index}
              event={event}
              eventCount={eventCount}
            />
          );
        }
      );

      return (
        <Card.Body>
          <h6
            className={
              "my-0 suggested-filters" +
              (activeFilters > 0 ? " active-filters" : "")
            }
          >
            <T>Suggested Filters</T>:{FilterButtons}
          </h6>
        </Card.Body>
      );
    }
  }
}
SuggestedFilters.propTypes = {
  config: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    // reportPath: getReportPath(state, props),
    config: getConfig(state, props),
    getEventMetaById: createGetEventMetaById(state, props),
    filtered: getFiltered(state, props),
    occurrences: getScheduledOccurrencesInTimeRange(state, props),
  };
};
export default connect(mapStateToProps)(SuggestedFilters);
