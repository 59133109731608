// REQUEST NOTIFICATIONS REMOVE RULE
export const requestNotificationsRemoveRuleStart = params => {
  return { type: "REQUEST_NOTIFICATIONS_REMOVE_RULE_START", ...params };
};
export const requestNotificationsRemoveRuleSuccess = params => {
  return { type: "REQUEST_NOTIFICATIONS_REMOVE_RULE_SUCCESS", ...params };
};
export const requestNotificationsRemoveRuleFailed = params => {
  return { type: "REQUEST_NOTIFICATIONS_REMOVE_RULE_FAILED", ...params };
};
export const requestNotificationsRemoveRuleCancel = params => {
  return { type: "REQUEST_NOTIFICATIONS_REMOVE_RULE_CANCEL", ...params };
};
export const requestNotificationsRemoveRuleNotified = params => {
  return { type: "REQUEST_NOTIFICATIONS_REMOVE_RULE_NOTIFIED", ...params };
};
