import React, { useState } from "react";

import { Button } from "react-bootstrap";

import EagleEyeLiveView from "components/Common/Cameras/CameraList/EagleEyeLiveView";

function EagleEyeButtonView({
    subdomain,
    account_id,
    auth_token,
    camera_ref,
    t
}) {

    const [showEagleEyeState, setShowEagleEyeState] = useState(null);
    return (
        <>
            <Button
                className="btn-block"
                variant="outline-primary"
                size="sm"
                style={{ marginTop: '26px', fontSize: '14px', padding: '8px 16px' }}
                onMouseEnter={() => (setShowEagleEyeState(true))}
                onMouseLeave={() => (setShowEagleEyeState(false))}
            >
                Live Camera View
        </Button>
            {showEagleEyeState && (
                <div className="eagle-eye-live-view-bv">
                    <EagleEyeLiveView
                        subdomain={subdomain}
                        account_id={account_id}
                        auth_token={auth_token}
                        camera_ref={camera_ref}
                        src="buttonview"
                    />
                </div>
            )}
        </>
    );
}

export default EagleEyeButtonView;