// import React, { useEffect } from "react";
import React from "react";

export default function ReauthorisationMessage({ integration_name }) {
    return (
        <div style={{width: "100%", textAlign: "center"}}>
            <h5>Authorisation has Expired</h5>
            <p>Please go to Manage Integrations to Reauthorise for {integration_name}</p>
        </div>
    );
}