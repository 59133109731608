import React from "react";

import moment from "services/locale/momentInit.js";

import { Tooltip } from "components/Common/Tooltip";

import COLOUR_CODES from "constants/COLOUR_CODES.json";

export const AssignedUser = (props) => {
  const  { assignedUser, closed, omitTooltip, tooltipPlacement } = props;

  const userIcon = (
    <div
      style={
        closed ? // closed
          {
            display: "flex",
            justifyContent: "center",
            width: "32px",
            height: "32px",
            padding: "4px 3px",
            borderRadius: "20px",
            margin: "2px",
            background: COLOUR_CODES["restoreGreen"],
            color: "white",
            fontWeight: "bold",
            fontSize: "16px",
          }
        : // not closed
          assignedUser ? // the assigned user has a value
            assignedUser === "none" ? // assigned user has a value of "none", 
                                      // meaning it has recently been reset and we are likely in the process of loading the user data
              {
                width: "32px",
                height: "32px",
                margin: "2px",
              }
            : // assigned user contains a value other than "none", i.e. it has proper user data
              {
                display: "flex",
                justifyContent: "center",
                width: "32px",
                height: "32px",
                padding: "6px",
                borderRadius: "20px",
                margin: "2px",
                background: "#2c3e50",
                color: "white",
                fontWeight: "bold",
              }
          : // no assigned user, i.e. null or empty object, i.e this is specifically a new item
            {
              display: "flex",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              padding: "8px 3px",
              borderRadius: "20px",
              margin: "2px",
              background: COLOUR_CODES["failRed"],
              color: "white",
              fontWeight: "bold",
              fontSize: "11px",
            }
      }
    >
      <span>{
        closed ? // closed, so show a tick
          <span>&#10003;</span>
        : // not closed
          assignedUser ? // the assigned user has a value
            assignedUser === "none" ? // assigned user has a value of "none", 
                                      // meaning it has recently been reset and we are likely in the process of loading the user data
                                      // so show nothing 
              ""
            : // assigned user contains a value other than "none", i.e. it has proper user data, so extract the user's initials and show them
              `${
                (assignedUser.first_name && assignedUser.first_name.length > 0) ? String(assignedUser.first_name[0]).toUpperCase() : ""
              }${
                (assignedUser.last_name && assignedUser.last_name.length > 0) ? String(assignedUser.last_name[0]).toUpperCase() : ""
              }` 
          : // no assigned user, i.e. null or empty object, i.e this is specifically a new item
            "NEW"
      }</span>
    </div>
  )

  return (
    <div className="d-flex justify-content-center">
      {
        omitTooltip
          ? <div>{userIcon}</div>
          : <Tooltip
              placement={tooltipPlacement || "left"}
              TooltipComponent={
                <div className="text-left p-1">
                  {
                    closed
                      ? <span>Closed</span>
                      : assignedUser 
                          ? (<div>
                                <div><span className="font-14 font-weight-bold pr-2">{`${assignedUser.first_name} ${assignedUser.last_name}`}</span></div>
                                <div>Assigned:</div>
                                <div>{(new moment(assignedUser.assigned)).format("HH:mm DD/MM/YYYY")}</div>
                            </div>)
                          : <span>Unassigned Event</span>
                  }
                </div>
              }
              className="text-left"
            >
              {userIcon}
            </Tooltip>
      }
    </div>
  );
};