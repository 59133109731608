import React from "react";

import { connect } from "react-redux";

import { Row } from "react-bootstrap";

// import Loading from "components/Loading";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import OpenCloseCard from "./OpenCloseCard";

import "./OpenClose.scss";

import {
    getCurrentSite,
} from "services/redux/selectors/sites";

class View extends React.Component {

    render() {
        const { data, onBackToOverview, open_close_state, timezone, t } = this.props;
        const partialOpCl = data.partial;

        let fullOpCl = null;

        if (data.full.length > 0) {
            const mostRecentDate = new Date(Math.max.apply(null, data.full.map(e => {
                return new Date(e.recvd);
            })));
            fullOpCl = data.full.filter(e => {
                var d = new Date(e.recvd);
                return d.getTime() === mostRecentDate.getTime();
            })[0];
        }

        const linkBack = (
            <div className="row my-2">
                <div className="col-12">
                    <div
                        className="btn btn-default border-none side-pane-back text-dark"
                        onClick={onBackToOverview}>
                        <Icon className="fas fa-chevron-left mr-2" /> {t("back_to_overview")}
                    </div>
                </div>
            </div>
        );

        let randomKey = 1 + (Math.random() * (1 - 9999));

        return (
            <>
                {linkBack}
                <div>
                    <div key={randomKey}>
                        <OpenCloseCard data={fullOpCl} area_names={data.area_names} open_close_state={open_close_state} timezone={timezone} index={randomKey} key={randomKey} type="full" />
                    </div>
                    <Row>
                        <span className="status-title">Areas</span>
                    </Row>
                    {partialOpCl.map((partialOpCl, index) => (
                        <div key={index} style={{ marginBottom: "5px" }}>
                            <OpenCloseCard data={partialOpCl} area_names={data.area_names} open_close_state={open_close_state} timezone={timezone} index={index} key={index} type="partial" />
                        </div>
                    ))}
                    {fullOpCl === [] && partialOpCl === [] && (
                        <div className="no-partials">
                            There are no partial opens or closes
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const site_id = parseInt(getCurrentSite(state, props).id);
    const site = getCurrentSite(state, props);

    return {
        site_id,
        open_close_state: site.open_close_state,
        timezone: site.timezone,
        data: state.sites.current.event_pairs.data
    };
};

export default withT(
    connect(mapStateToProps)(View),
    "site_overview.side_pane.event_pairs"
);