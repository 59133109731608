import React from "react";

import Icon from "components/Icons/Icon";
import { Button } from "react-bootstrap";

import "./ExpandColumn.scss";

import { getExpanded, getExpandedHourly } from "services/redux/selectors/reports/scheduled";

import { setExpandSite } from "services/redux/actions/";

export function ExpandColumn({
  columns,
  eventTextFilter,
  reportType,
  dispatch
}) {
  const column = columns["EXPAND"];
  return {
    ...column,
    Cell: ({ original }) => {
      const { id } = original;
      let expanded;
      if (reportType === "peoplecounthourly") {
        // 08/21 the new people count hourly report needs to be expandable on the hourly data,
        // not the occurrences data as is the case with the other reports.
        const { hourly } = original;
        expanded = getExpandedHourly(original, eventTextFilter);

        const expandable = hourly.length > 0;

        if (!expandable) {
          return null;
        }
      } else {
        const { occurrences } = original;

        expanded = getExpanded(original, eventTextFilter);

        const expandable = occurrences.length > 1;

        if (!expandable) {
          return null;
          //   <Button variant="outline-secondary" disabled size="sm">
          //     <Icon className="fas fa-caret-square-down" />
          //   </Button>
          // );
        }
      }

      return (
        <Button
          className="expandIconButton"
          variant={`${expanded ? "" : "outline-"}primary`}
          size="sm"
          // style={{ maxWidth: "80px", marginLeft: "auto" }}
          onClick={() => {
            // If event text filter is active,
            //  explicitly set expand
            // if (eventTextFilter) {
            dispatch(
              setExpandSite({
                id,
                reportType,
                expanded: !expanded
              })
            );
            // }
            // this.props.dispatch(toggleExpandSite({ id, reportType }));
          }}>
          {expanded ? (
            <Icon className="fas fa-chevron-up" />
          ) : (
            <Icon className="fas fa-chevron-down" />
          )}
        </Button>
      );
    }
  };
}
