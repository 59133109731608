// https://reports.facewatch.co.uk/api/v1/report

// https://cors-anywhere.herokuapp.com/

import axios from "axios";
// import testData from "./__mocks__/facewatch.json";

// Same as https://reports.facewatch.co.uk/api/v1/
const baseURL = "https://pro.conxtd.com/api/facewatch";

const config = {
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": "9b5a1ee6-a865-4919-b840-7ab9ed414913"
  },
  withCredentials: false
};

let facewatch = new axios.create(config);
facewatch.interceptors.request.use(
  function(request) {
    // console.log(request);
    // request.headers["x-api-key"] = "9b5a1ee6-a865-4919-b840-7ab9ed414913";
    // console.log(request);
    return request;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

facewatch.fetchPost = async (url, params) => {
  try {
    const res = await fetch(baseURL + url, {
      credentials: "omit",
      headers: {
        accept: "application/json, text/plain, */*",
        "content-type": "application/json",
        "x-api-key": "9b5a1ee6-a865-4919-b840-7ab9ed414913"
      },
      body: JSON.stringify(params),
      method: "POST",
      mode: "cors"
    });
    return { data: await res.json() };
  } catch (err) {
    return err;
  }
};

// facewatch.testResponse = () => {
//   return testData;
// };

export default facewatch;
