// TODO: see https://redux.js.org/recipes/computing-derived-data

import _ from "lodash";

export {
  getEvents,
  getEventMetaForBundle,
  getEventMetaForEventPair,
  getBundle,
  getEventTotals,
  getDaysAgoLevel,
  getOutstandingEventsFiltered,
  getOutstandingReportTitle
} from "./reports/outstanding/bundle";
export {
  getOpenCloseByAreaIndex,
  getOpenCloseByAreaReport,
  getOpenCloseByAreaReportTitle,
  getOpenCloseByAreaReportList,
  getSudoStates,
} from "./reports/openclosebyarea"; // 09/21 - open close by area
export { getLoading } from "./loading";
export { getSites, getSiteById } from "./sites";
export {
  getGroups,
  getGroupById,
  getGroupPathById,
  getGroupPathFromSite
} from "./groups";
export * from "./enterprises";
export * from "./company";
export { getExceptionReportTitle } from "./reports/exception";
export { getEventMetaList, getEventMetaById } from "./eventMeta";
export * from "./user";
export {
  getInAlarm,
  getInAlarmSites,
} from "./alarmresponse"; //alarm-response
export {
  getCustomReportData
} from "./customReports"
export {
  getSOPCompletedReportData
} from "./sopReports"

export const NA = () => {
  return process.env.NODE_ENV === "production" ? "" : "N/A";
};

export const conxtdAdmin = props => {
  const email = _.get(props, "user.email") || "";
  return email.indexOf("@conxtd.com") !== -1;
};

export const devMode = () => {
  return (
    window.location.hostname === "pro-staging.conxtd.com" ||
    window.location.hostname === "stonehenge.local"
  );
};

export function mockEnabled() {
  return sessionStorage.getItem("mockEnabled") !== null;
}
