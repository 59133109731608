import {
  getEventText,
  getRepresentativeOccurrence
} from "services/redux/selectors/reports/scheduled";

export function EventTextColumn({ columns, eventTextFilter, getEventMeta }) {
  const column = columns["EVENT_TEXT"];
  return {
    ...column,

    accessor: site => {
      const occurrence = getRepresentativeOccurrence({
        occurrences: site.occurrences,
        eventTextFilter,
        getEventMeta
      });

      return getEventText(occurrence);
    }
  };
}
