// Add Site - gets company custom fields
export const loadCompanyFieldsStart = (payload) => {
  return { type: "LOAD_COMPANY_FIELDS_START", payload }
};

export const loadCompanyFieldsSuccess = (response) => {
  return { type: "LOAD_COMPANY_FIELDS_SUCCESS", response }
};

export const loadCompanyFieldsFailed = (error) => {
  return { type: "LOAD_COMPANY_FIELDS_FAILED", error }
};

export const loadCompanyFieldsReset = () => {
  return { type: "LOAD_COMPANY_FIELDS_RESET" }
};

// Add Site - gets enterprise custom fields
export const loadEnterpriseFieldsStart = (payload) => {
  return { type: "LOAD_ENTERPRISE_FIELDS_START", payload }
};

export const loadEnterpriseFieldsSuccess = (response) => {
  return { type: "LOAD_ENTERPRISE_FIELDS_SUCCESS", response }
};

export const loadEnterpriseFieldsFailed = (error) => {
  return { type: "LOAD_ENTERPRISE_FIELDS_FAILED", error }
};

export const loadEnterpriseFieldsReset = () => {
  return { type: "LOAD_ENTERPRISE_FIELDS_RESET" }
};

// Add Site - gets enterprise groups
export const loadEnterpriseGroupsStart = (payload) => {
  return { type: "LOAD_ENTERPRISE_GROUPS_START", payload }
};

export const loadEnterpriseGroupsSuccess = (response) => {
  return { type: "LOAD_ENTERPRISE_GROUPS_SUCCESS", response }
};

export const loadEnterpriseGroupsFailed = (error) => {
  return { type: "LOAD_ENTERPRISE_GROUPS_FAILED", error }
};

export const loadEnterpriseGroupsReset = () => {
  return { type: "LOAD_ENTERPRISE_GROUPS_RESET" }
};

// -- Asset Editor --

// Get sudo site id (using site ref and company id)
export const loadSudoSiteIdStart = (payload) => {
  return { type: "LOAD_SUDO_SITE_ID_START", payload }
};

export const loadSudoSiteIdSuccess = (response) => {
  return { type: "LOAD_SUDO_SITE_ID_SUCCESS", response }
};

export const loadSudoSiteIdFailed = (error) => {
  return { type: "LOAD_SUDO_SITE_ID_FAILED", error }
};

export const loadSudoSiteIdReset = () => {
  return { type: "LOAD_SUDO_SITE_ID_RESET" }
};

// Pin Mapping
export const loadPinMapsStart = (params) => {
  return { type: "LOAD_PIN_MAPS_START", ...params };
};

export const loadPinMapsSuccess = (params) => {
  return { type: "LOAD_PIN_MAPS_SUCCESS", ...params, };
};

export const loadPinMapsFailed = (params) => {
  return { type: "LOAD_PIN_MAPS_FAILED", ...params };
};

export const loadPinMapsCancel = (params) => {
  return { type: "LOAD_PIN_MAPS_CANCEL", ...params };
};

export const loadPinMapsReset = () => {
  return { type: "LOAD_PIN_MAPS_RESET" };
};

// Enterprise Associations
export const loadEnterpriseAssociationsStart = (payload) => {
  return { type: "LOAD_ENTERPRISE_ASSOCIATIONS_START", payload };
};

export const loadEnterpriseAssociationsSuccess = (response) => {
  return { type: "LOAD_ENTERPRISE_ASSOCIATIONS_SUCCESS", response };
};

export const loadEnterpriseAssociationsFailed = (error) => {
  return { type: "LOAD_ENTERPRISE_ASSOCIATIONS_FAILED", error };
};

// export const loadEnterpriseAssociationsCancel = (params) => {
//   return { type: "LOAD_ENTERPRISE_ASSOCIATIONS_CANCEL", ...params };
// };

// export const loadEnterpriseAssociationsReset = () => {
//   return { type: "LOAD_ENTERPRISE_ASSOCIATIONS_RESET" };
// };

// Unlink Enterprise Association
// export const unlinkEnterpriseAssociationStart = (payload) => {
//   return { type: "UNLINK_ENTERPRISE_ASSOCIATION_START", payload };
// };

// export const unlinkEnterpriseAssociationSuccess = (response) => {
//   return { type: "UNLINK_ENTERPRISE_ASSOCIATION_SUCCESS", response };
// };

// export const unlinkEnterpriseAssociationFailed = (error) => {
//   return { type: "UNLINK_ENTERPRISE_ASSOCIATION_FAILED", error };
// };
