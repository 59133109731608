import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "services/locale/momentInit.js";

class MonthSelect extends React.Component {
  changeMonth = date => {
    // .format("MM") always adds a month because Jan == 0
    const month = moment(date).format("MM");
    const year = moment(date).format("YYYY");
    this.props.changeMonth(month, year);
  };
  render() {
    const { selectedMonth, selectedYear } = this.props;

    const selected = moment()
      .year(selectedYear)
      // - 1 due to MM formatting
      .month(parseInt(selectedMonth) - 1)
      .toDate();
    return (
      <DatePicker
        //locale="en-GB"
        // Disable dates
        // filterDate={date => {
        //   return moment() > date;
        //   // && date > new moment(new Date(2019, 6, 1));
        // }}
        maxDate={new moment().subtract(1, "month").toDate()}
        minDate={new Date(2019, 4, 1)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        inline
        selected={selected}
        onChange={this.changeMonth}
      />
    );
  }
}
export default MonthSelect;
