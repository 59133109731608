import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserResetPasswordSuccess,
  requestUserResetPasswordFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

// Reset password
export function* watchResetPassword() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_RESET_PASSWORD_START", function*(action) {
    yield race({
      task: call(requestResetPassword, action),
      cancel: take([
        "REQUEST_USER_RESET_PASSWORD_CANCEL",
        "REQUEST_USER_RESET_PASSWORD_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestResetPassword(action) {
  const { token, password, password_confirm } = action;
  if (!token) {
    throw new Error("Invalid value supplied for token: " + token);
  }
  if (!password) {
    throw new Error("Invalid value supplied for new password: " + password);
  }
  if (!password_confirm) {
    throw new Error(
      "Invalid value supplied for password confirm: " + password_confirm
    );
  }

  try {
    // Create a temporary cookie for environment
    conxtdOut.updateSession({ environment: conxtdOut.defaultEnv });

    const response = yield call(conxtdOut.post, "/users/resetPassword", {
      resetToken: token,
      password,
      password_confirm
    });

    yield put(requestUserResetPasswordSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestUserResetPasswordFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
