/**
 * 09/21 - open close by area
 */

import React from "react";

import { default as T } from "services/i18n/Translate";

const openclosebyarea = {
  index: {
    title: "Open Close by Area",
    overview: {
      // Show on index page
      index: 7,
      className: "col-md-6",
    },
    config: {

    },
    noDataAvailableRenderer: () => {
      return (
        <div className="col-xl-12">
          <T>No events.</T>
        </div>
      );
    },
  },
  openclosebyareafull: {
    title: "Open Close by Area Full Report",
    iconIds: [10, 9],
    noDataAvailableRenderer: () => {
      return (
        <div className="col-xl-12">
          <T>No events.</T>
        </div>
      );
    },
    dataTable: {
      suggestedFilters: [9, 10, 455, 454],
      suggestedFiltersAlternates: {
        mars: [9, 10, 396, 395],
        staging: [9, 10, 408, 407],
      },
      areaFilters: [455, 454],
      areaFiltersAlternates: {
        mars: [396, 395],
        staging: [408, 407],
      },
    },
  },
  openclosedareas: {
    title: "Open With Closed Areas",
    iconIds: [10, 396],
    noDataAvailableRenderer: () => {
      return (
        <div className="col-xl-12">
          <T>No events.</T>
        </div>
      );
    },
    dataTable: {
      suggestedFilters: [],
      suggestedFiltersAlternates: {
        mars: [],
        staging: [],
      },
      areaFilters: [],
      areaFiltersAlternates: {
        mars: [],
        staging: [],
      },
    },
  },
  closedopenareas: {
    title: "Closed With Open Areas",
    iconIds: [9, 395],
    noDataAvailableRenderer: () => {
      return (
        <div className="col-xl-12">
          <T>No events.</T>
        </div>
      );
    },
    dataTable: {
      suggestedFilters: [],
      suggestedFiltersAlternates: {
        mars: [],
        staging: [],
      },
      areaFilters: [],
      areaFiltersAlternates: {
        mars: [],
        staging: [],
      },
    },
  },
};

export default openclosebyarea;