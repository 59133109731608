import { call, takeLatest } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";
import * as links from "services/redux/sagas/links";

import {
  loadCustomReportStart,
  loadCustomReportSuccess,
  loadCustomReportFailed,
  loadCustomReportCancel,
  loadCustomReportBundlesStart,
  loadCustomReportBundlesSuccess,
  loadCustomReportBundlesFailed,
  loadCustomReportBundlesCancel,
} from "services/redux/actions/customReports";
  
import conxtdOut from "apis/conxtdOut";

// one-off saga for loading report

export function* watchLoadCustomReport() {
  // Link custom report loading to event meta
  yield takeLatest(
    ["LOAD_CUSTOM_REPORT_START"],
    links.toEventMetaBackgroundLoader
  );

  // Link custom report loading to site data
  yield takeLatest(
    ["LOAD_CUSTOM_REPORT_START"],
    links.toSitesBackgroundLoader
  );

  yield actionWatcher({
    actions: {
      start: loadCustomReportStart,
      failed: loadCustomReportFailed,
      success: loadCustomReportSuccess,
      cancel: loadCustomReportCancel,
    },
    saga: loadCustomReportRe,
  });
}

// https://mars.conxtd.com/out_api/analyticx/events-report/generate/:enterprise_id/:enterprise_group_id
export function* loadCustomReportRe(action) {
  try {
    const { enterprise_id, group_id } = action;

    if (!enterprise_id || !group_id) 
      throw new Error(
        "group_id and enterprise_id are required for loading custom report"
      );

    const url = `/analyticx/events-report/generate/${enterprise_id}/${group_id}`;

    const result = yield call(conxtdOut.post, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// one-off saga for loading bundle data

export function* watchLoadCustomReportBundles() {
  // Link bundle loading to event meta
  yield takeLatest(
    ["LOAD_CUSTOM_REPORT_BUNDLES_START"],
    links.toEventMetaBackgroundLoader
  );

  yield actionWatcher({
    actions: {
      start: loadCustomReportBundlesStart,
      failed: loadCustomReportBundlesFailed,
      success: loadCustomReportBundlesSuccess,
      cancel: loadCustomReportBundlesCancel,
    },
    saga: loadCustomReportBundlesRe,
  });
}

// https://mars.conxtd.com/out_api/ConxtdEvents/alarmBundles
export function* loadCustomReportBundlesRe(action) {
  try {
    const url = `/ConxtdEvents/alarmBundles`;

    const result = yield call(conxtdOut.get, url);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}