import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';

import EditElement from './EditElement';

import { loadPinMapsStart } from 'services/redux/actions/serviceDesk';

import Alert from "components/Common/Alert";
import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip/Tooltip";

import conxtdOut from "apis/conxtdOut";

import "./EditPin.scss";

const EditPin = ({ siteName, sudoSiteId, loadPinMapsStart, pinType, mappedData, enterprise_id }) => {
  const dispatch = useDispatch();

  const [existingRecords, setExistingRecords] = useState([]);
  const [initRecords, setInitRecords] = useState([]);
  const [changeDetected, setChangeDetected] = useState(false);
  const [showModalMain, setShowModalMain] = useState(false);

  const organiseExistingRecords = useCallback(() => {
    let tempArr = [];
    [...mappedData].sort((a, b) => a.pin - b.pin).forEach(element => {
      const modifiedObj = {...element, isNull: false, action: "edit", custom_text: element.custom_text === null ? "" : element.custom_text}
      tempArr.push(
        modifiedObj
      );
    });
    setExistingRecords(tempArr);
  }, [mappedData]);

  useEffect(() => {
    let tempArr = [];
    [...mappedData].sort((a, b) => a.pin - b.pin).forEach(element => {
      const modifiedObj = {...element, isNull: false, action: "edit", custom_text: element.custom_text === null ? "" : element.custom_text}
      tempArr.push(
        modifiedObj
      );
    });
    setInitRecords(tempArr);
  }, [mappedData])

  const detectRecordChanges = useMemo(() => {
    for (let i = 0; i < existingRecords.length; i++) {
      const existingRecord = existingRecords[i];
      const initRecord = initRecords[i];

      const keys = Object.keys(existingRecord);
      for (let key of keys) {
        if (existingRecord[key] !== initRecord[key]) {
          return true;
        }
      }
    }
    return false;
  }, [existingRecords, initRecords]);

  useEffect(() => {
    setChangeDetected(detectRecordChanges)
  }, [existingRecords, initRecords, detectRecordChanges]);

  useEffect(() => {
    organiseExistingRecords();
  }, [mappedData, organiseExistingRecords]);

  const handleClose = () => {
    if (changeDetected) {
      Alert({
        text: `Close this window? Any unsaved changes will be lost.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Close",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value) {
          setShowModalMain(false);
          organiseExistingRecords();
          setChangeDetected(false);
        } else {
          return
        }
      })
    } else {
      setShowModalMain(false);
      organiseExistingRecords();
      setChangeDetected(false);
    }
  };

  // handle submit function for form
  const handleSubmit = () => {
    Alert({
      html: "<div><p>Are you sure you want to save these new changes?</p><hr /><p>Note: Changes to pins may have unexpected results with third-party alarm receiving centres.</p></div>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.value) {
          if (sudoSiteId && existingRecords) {
            const recordsToEdit = () => {
              const tempEditArr = [];
              if (existingRecords.length > 0) {
                [...existingRecords].forEach(element => {
                  if (element.action === 'edit') {
                    let modifiedObj = {
                      id: element.id,
                      sudo_site_id: sudoSiteId,
                      [pinType === "SIA" ? "pin" : "channel"]: pinType === "SIA" ? element.pin : element.pin - 1,
                      event_pair_id: element.event_pair_id,
                      custom_text: element.custom_text ? element.custom_text : null,
                      reverse: element.reverse,
                    }
                    tempEditArr.push(modifiedObj);
                  } else {
                    return [];
                  }
                });
              }
              return tempEditArr;
            }
            
            const recordsToDelete = () => {
              const tempDelArr = [];
              if (existingRecords.length > 0) {
                [...existingRecords].forEach(element => {
                  if (element.action === 'delete') {
                    tempDelArr.push(element.id);
                  }
                });
              } else {
                return [];
              }
              return tempDelArr;
            }
  
            if (existingRecords.length > 0 && sudoSiteId) {

              // EDIT Pins
              const toBeEdited = recordsToEdit();
              const editPromise = new Promise((resolve, reject) => {
                if (toBeEdited.length > 0) {
                  const editPins = conxtdOut.put(`/SudoPins/edit/${sudoSiteId}/${pinType}?enterpriseId=${enterprise_id ?? null}`, JSON.stringify({
                    pin_data: toBeEdited,
                    edited_total: toBeEdited.length,
                    site_name: siteName,
                  }));

                  editPins
                    .then((response) => {
                      if (response.status === 200) {
                        resolve();
                      } else {
                        reject(new Error(`Edit pins failed with status ${response.status}`));
                      }
                    })
                    .catch((error) => {
                      console.error("Edit pins error:", error.message);
                      reject(error);
                    });
                }
              })
            
              // DELETE Pins
              const toBeDeleted = recordsToDelete();
              const deletePromise = new Promise((resolve, reject) => {
                if (toBeDeleted.length > 0) {

                  const deletePins = conxtdOut.post(`/SudoPins/delete/${sudoSiteId}/${pinType}?enterpriseId=${enterprise_id ?? null}`, JSON.stringify({
                    pin_data: toBeDeleted,
                    deleted_total: toBeDeleted.length,
                    site_name: siteName,
                  }));

                  deletePins
                    .then((response) => {
                      if (response.status === 200) {
                        resolve();
                      } else {
                        reject(new Error(`Delete pins failed with status ${response.status}`));
                      }
                    })
                    .catch((error) => {
                      console.error("Delete pins error:", error.message);
                      reject(error);
                    });
                }
              });
            
              // Execute correct alerts and dispatch action only after both api calls have resolved or rejected
              const decidePromiseArguments = () => {
                const promises = [];
                if (toBeEdited.length > 0) {
                    promises.push(editPromise);
                }
                if (toBeDeleted.length > 0) {
                    promises.push(deletePromise);
                }
                return promises;
              };

              Promise.allSettled(decidePromiseArguments())
              .then((results) => {
                const isSuccess = results.every((result) => result.status === "fulfilled");
                if (isSuccess) {
                  Alert({
                    text: "Changes saved successfully",
                    icon: "success",
                    timerProgressBar: true,
                    timer: 5000,
                  });
                  if (sudoSiteId) {
                    dispatch(
                      loadPinMapsStart({
                        sudo_site_id: sudoSiteId,
                      })
                    );
                  }

                  setShowModalMain(false);
                  setChangeDetected(false); 
                } else {
                  Alert({
                    text: "Failed to save changes",
                    icon: "error",
                    timerProgressBar: true,
                    timer: 5000,
                  });
                }
              })
              .catch((error) => {
                console.error("Promise error:", error.message);
              });
            }
          }

      } else {
        return
      }

    });
  };

  return (
    <>
      <Tooltip
        description="Edit Pins"
      >
        <Button variant="primary" data-intercom-target={"open-edit-pin-modal"} onClick={() => setShowModalMain(true)}>
          <Icon
            className="fas fa-edit"
          />
        </Button>
      </Tooltip>

      {/* Main add pin modal */}
      <div>
        <Modal
          show={showModalMain}
          onHide={handleClose}
          id="modal-main-edit"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Pins</Modal.Title>
          </Modal.Header>
          <Modal.Body id="modal-body-edit">
            <div
              className='container' 
              id='modal-body-container'
            >
              <div
                className='container'
                id="form-label-edit"
              >
                <div className='row flex-nowrap'>
                  <Form.Label className="col-2 label-title justify-content-start">Pin</Form.Label>
                  <Form.Label className="col-3 label-title justify-content-start">Alarm</Form.Label>
                  <Form.Label className="col-xl-5 col-lg-5 col-md-4 col-sm-4 label-title justify-content-start">Custom Text</Form.Label>
                    <div className='container m-0' id='outer-wrapper-edit'>
                      <div className="d-flex justify-content-between m-0 p-0" id="outer-wrapper-child-edit">
                        <Form.Label className="text-center child-content-element">Reverse</Form.Label>
                        <Form.Label className="text-center" id="label-border"></Form.Label>
                        <Form.Label className="text-center child-content-element">Delete</Form.Label>
                      </div>
                    </div>
                </div>
              </div>
              <Form noValidate onSubmit={handleSubmit}>
                <ul className='container'>
                  <li className='row'>
                      {
                      existingRecords.map((element) => {
                        return <EditElement
                          key={element.id}
                          id={element.id}
                          element={element}
                          existingRecords={existingRecords}
                          setExistingRecords={setExistingRecords}
                          setChangeDetected={setChangeDetected}
                          mappedData={mappedData}
                        />
                      })
                    }
                    
                  </li>
                </ul>
            </Form>
            </div>
          </Modal.Body>
          <Modal.Footer 
            className={`${pinType ? "" : "d-flex justify-content-between"}`}
          >
            {changeDetected
              ?
                <div className='d-flex justify-content-end'>
                  <Button
                    data-intercom-target={"close-edit-pins-button"}
                    variant="secondary" 
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                    <Button
                      data-intercom-target={"submit-edit-pins-button"}
                      variant="primary"
                      className="ml-2"
                      type="submit"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Save Changes
                  </Button>
                </div>
              :
                <div className='d-flex justify-content-end'>
                  <Button
                    data-intercom-target={"close-edit-pins-button-2"}
                    variant="secondary" 
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Tooltip
                    description="No changes made"
                    placement='top'
                  >
                    <Button
                      data-intercom-target={"save-changes-edit-pins-button"}
                      variant="primary"
                      className="ml-2 disabled"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Tooltip>
                </div>
            }
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    loadPinMapsStart,
    loadingPinMapsData: state.enterpriseManager.loading,
  };
};

export default connect(mapStateToProps, null)(EditPin);