/**
 * Select bundles to attach to cameras
 *
 * Used by CameraCard
 */

import React, { useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Form, Button } from "react-bootstrap";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { requestEditSiteCameraStart } from "services/redux/actions";

import { getCurrentSite } from "services/redux/selectors/sites";

function SelectBundle({
  key = "typeaheadInput",
  availableBundles,
  currentBundleIds,
  // camera_ref,
  // camera_name,
  camera_sudo_site_id,
  camera_integration_site_id,
  loading,
  site_id,
  editCamera,
  t
}) {
  const [selectedBundles, setSelectedBundles] = useState(null);

  /**
   * Checks if bundle is currently selected on back end
   *
   * @param {*} id id to compare with currentBundleIds
   */
  const isCurrentBundle = id =>
    currentBundleIds.some(bundleId => bundleId === id);

  /**
   * Converts bundles into Typeahead friendly object array
   *
   * @param {*} bundles bundles object with id and title
   */
  const convertToOptions = bundles =>
    bundles.map(({ id, title }) => ({ id, name: title }));

  const currentBundles = availableBundles.filter(({ id }) =>
    isCurrentBundle(id)
  );
  // console.log(currentBundles);

  return (
    <>
      <Form.Label>{t("label")}</Form.Label>

      <Typeahead
        // ref={r => {
        //   this.Typeahead = r;
        // }}
        clearButton
        id="typeahead"
        placeholder={t("no_events_captured")}
        inputProps={{
          key,
          id: "typeahead-input",
          autoComplete: "off"
        }}
        defaultSelected={convertToOptions(currentBundles)}
        options={convertToOptions(availableBundles)}
        // inputProps={{
        //   value
        // }}
        // value={value}
        // selected={options[0]}
        // defaultSelected={[options[0]]}
        //onChange={event => onChange(event.value)}
        // defaultInputValue={options[0]}
        // onInputChange={text => {
        //   onChange(text);
        // }}
        onChange={options => {
          setSelectedBundles(options);
          // onChange(get(e, "0.name") || "");
        }}
        // defaultSelected={options.slice(0, 5)}
        renderMenuItemChildren={(option, props, index) => {
          // console.log(props);
          return (
            <div>
              {isCurrentBundle(option.id) && (
                <Icon className="fas fa-check-circle" />
              )}
              <span className="pl-2">{option.name}</span>
            </div>
          );
        }}
        labelKey="name"
        multiple
      />
      <Button
        className="mt-2 float-right"
        disabled={loading}
        onClick={() => {
          // Selected is in default state
          if (!selectedBundles) return;
          const selectedIds = selectedBundles.map(({ id }) => id);

          const camera = {
            // camera_id: camera_ref,
            // camera_name: camera_name,
            conxtd_alarm_bundles: selectedIds
          };

          editCamera({
            camera,
            site_id,
            camera_integration_site_id,
            camera_sudo_site_id,
            body: selectedIds
          });
        }}>
        {loading ? (
          <Loading center />
        ) : (
          <>
            <Icon className="fas fa-check" /> {t("save_settings")}
          </>
        )}
      </Button>
    </>
  );
}
SelectBundle.propTypes = {
  /**
   * @param availableBundles Available availableBundles for selection
   */
  availableBundles: PropTypes.array.isRequired,

  /**
   * @param currentBundleIds The ids for currently active/saved bundle assignments
   */
  currentBundleIds: PropTypes.array.isRequired,

  /**
   * @param editCamera api dispatch to save selected bundle assignment
   */
  editCamera: PropTypes.func.isRequired,

  /**
   * @param loading api loading status
   */
  loading: PropTypes.any,

  /**
   * @param site_id id site to use for api integrations refresh
   */
  site_id: PropTypes.any.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    availableBundles: state.sites.current.alarmBundles,
    loading: state.sites.current.integrations.cameras.actions.add.loading,
    site_id: getCurrentSite(state, props).id
  };
};
const mapDispatchToProps = {
  editCamera: requestEditSiteCameraStart
};
export default withT(
  connect(mapStateToProps, mapDispatchToProps)(SelectBundle),
  "site_overview.side_pane.manage_integrations.select_bundle"
);
