import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

// import { InputGroup, Form } from "react-bootstrap";
import { Form } from "react-bootstrap";

class RoleSelect extends React.Component {
  render() {
    const { t, users, user, onSelect = () => {} } = this.props;
    const { roles } = users.current.options;

    const currentRole = user && roles.find(role => role.name === user.role);

    return (
      <>
        <Form.Control
          required
          defaultValue={(currentRole && currentRole.id) || ""}
          onChange={onSelect}
          className="pl-1"
          // ref={r => (this.domain = r)}
          name="role"
          as="select">
          {[{ name: t("select_role"), id: "" }, ...roles].map((role, index) => (
            <option value={role.id} key={index}>
              {role.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {t("select_role_validation_feedback")}
        </Form.Control.Feedback>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(RoleSelect),
  "user_management.common.role_select"
);
