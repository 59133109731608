import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  sendUserResetPasswordEmailSuccess,
  sendUserResetPasswordEmailFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

// Reset password
export function* watchResetPasswordEmail() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("SEND_USER_RESET_PASSWORD_EMAIL_START", function*(action) {
    yield race({
      task: call(sendUserResetPasswordEmail, action),
      cancel: take([
        "SEND_USER_RESET_PASSWORD_EMAIL_CANCEL",
        "SEND_USER_RESET_PASSWORD_EMAIL_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* sendUserResetPasswordEmail(action) {
  const { email } = action;
  if (!email) {
    throw new Error("Invalid value supplied for email: " + email);
  }

  try {
    // Create a temporary cookie for environment
    conxtdOut.updateSession({ environment: conxtdOut.defaultEnv });

    const response = yield call(conxtdOut.post, "/users/requestResetPassword", {
      email
    });

    yield put(sendUserResetPasswordEmailSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      sendUserResetPasswordEmailFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
