// import React, { useEffect } from "react";
import React from "react";
import { connect } from "react-redux";

// import { Button } from "react-bootstrap";

import { withT } from "services/translation/";

import ReauthorisationMessage from "./ReauthorisationMessage";

class EagleEyeView extends React.Component {

    componentDidMount() {
        // const { integration, camera, event, t } = this.props;
        const { integration, event } = this.props;

        if (!integration.camera_integration_site.auth_expired) {

            try {
              const EE = window.EEN.MediaPlayer;

              // function eagleEyeVideo(integration, event) {
              let videoElement = document.getElementById(event.mct_alarm_log_id);
              if (videoElement !== null) {

                  let flvjs = EE.flvjs;
                  if (flvjs.isSupported()) {

                      let camera = integration.camera_integration_site.camera_sudo_sites.find(camera_sudo_site => camera_sudo_site.id === event.camera_id);
                      // let flvjs = EE.flvjs,
                      //     esn = camera.camera_ref,
                      let esn = camera.camera_ref,
                          auth_key = integration.auth_token,
                          start_timestamp = event.start_timestamp,
                          end_timestamp = event.end_timestamp;

                      var config = new EE.MediaItem(esn);
                      config.setDomain(integration.subdomain);
                      config.setAuthKey(auth_key);
                      config.setStartTime(start_timestamp);
                      config.setEndTime(end_timestamp);
                      EE.startPlayback(config, videoElement);
                  }
              }
            } catch (e) {
              console.log("error: ", e.message);
            }
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {

        // const { integration, camera, event, t } = this.props;
        const { integration, event } = this.props;

        return (
            <>
            {integration.camera_integration_site.auth_expired ? <ReauthorisationMessage integration_name={integration.integration_name}/> : 
                <video
                    id={event.mct_alarm_log_id}
                    style={{ height: "375px", marginTop: "10px", marginBottom: "10px" }}
                    controls
                ></video>
        }
                {/* <Button
                                variant="primary"
                                size="lg"
                                onClick={() => {
                                    eagleEyeVideo(integration, event)
                                }}>
                                    {t("load_video")}
                                </Button> */}
            </>
        );

    }
}

export default connect()(
    withT(
        EagleEyeView,
        "site_overview.side_pane.manage_integrations.providers.eagle_eye"

    )
)