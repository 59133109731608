import { watchLoadCompanies } from "./companies";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCompanies(),
    ],
  );
}