import { createSelector } from "reselect";

// export const getSites = state => state.sites.data;

export const getEnterprises = createSelector(
  state => state.enterprises.data,
  enterprises => enterprises
);

export const getEnterprisesLoading = state => state.enterprises.loading;

const getEnterpriseIdFromRoute = (state, props) => {
  const { enterprise_id } = state.app.router.params || props.match.params;
  return enterprise_id;
};

// #unmemoized
export const getEnterpriseFromRoute = (state, props) => {
  const enterprise_id = getEnterpriseIdFromRoute(state, props);

  return getEnterpriseById(getEnterprises(state, props), enterprise_id);
};

export const getEnterpriseAvailable = createSelector(
  [getEnterpriseIdFromRoute, getEnterpriseFromRoute, getEnterprisesLoading],
  (enterprise_id, enterprise, loading) => {
    // An enterprise id is in the url, we have finished loading all enterprises
    // and the current enterprise is null - we must be trying
    // to access an enterprise that we don't have access to
    // therefor, the reverse means the enterprise is available
    return !(enterprise_id && loading === false && enterprise === null);
  }
);

export const getEnterpriseById = (enterprises, id) => {
  return enterprises.find(e => e.id === parseInt(id)) || null;
};
