import { watchLoadSOPActionMap, watchLoadSOPCloseDownOptions } from "./sop";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadSOPActionMap(),
      watchLoadSOPCloseDownOptions(),
    ],
  );
}