import React from "react";
import { connect } from "react-redux";

import { getCustomRef } from "services/redux/selectors/groups";

import { Tooltip } from "components/Common/Tooltip";

class SiteGroup extends React.Component {

  render() {
    const  { siteGroup, placement } = this.props;

    const customGroupRef = siteGroup ? getCustomRef({ custom_group_ref: siteGroup.custom_group_id, name: siteGroup.name}) : "";

    const groupIcon = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "32px",
          height: "32px",
          padding: customGroupRef.length === 4 ? "8px" : "6px",
          borderRadius: "20px",
          margin: "2px",
          background: "#2c3e50",
          color: "white",
          fontWeight: "bold",
          fontSize: customGroupRef.length === 4 ? "11px" : "13px",
        }}
      >
        <span>{customGroupRef}</span>
      </div>
    )

    return (
      <div className="d-flex justify-content-center">
        <Tooltip
          placement={placement || "left"}
          TooltipComponent={
            <div className="text-left p-1">
              <div className="font-14 font-weight-bold pr-2">Group</div>
              <div className="pb-1">
                {siteGroup && siteGroup.name}
              </div>
            </div>
          }
          className="text-left"
        >
          {groupIcon}
        </Tooltip>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(SiteGroup);