import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Row, Col, Card } from "react-bootstrap";

import { UserDetailsForm } from "./UserDetailsForm";
import { UpdatePasswordForm } from "./UpdatePasswordForm";

import { requestChangePasswordStart } from "services/redux/actions/";

class Profile extends React.Component {
  submitUpdatePassword = params => {
    this.props.dispatch(requestChangePasswordStart(params));
  };
  render() {
    const { t, user } = this.props;
    return (
      <>
        <Row>
          <h4 className="mt-0 pl-3 pb-2 header-title">{t("edit_profile")}</h4>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <Row className="mx-0">
                <Card.Body className="col-sm-6 border-sm-right">
                  <Card.Title>{t("user_details.title")}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {/* {t("user_name_full", user.firstName, user.lastName)} */}
                    {user.email}
                  </Card.Subtitle>

                  <UserDetailsForm t={t} />
                </Card.Body>
                <Card.Body className="col-sm-6">
                  <Card.Title>{t("update_password.title")}</Card.Title>

                  <UpdatePasswordForm
                    onSubmit={this.submitUpdatePassword}
                    loading={user.actions.changePassword.loading}
                  />
                </Card.Body>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
export default connect(mapStateToProps)(withT(Profile, "user.profile"));
