/**
 * Timeframe select dropdown, used in multiple places to change
 * group timeframe. It also changes site timeframe because sites
 * inherit group timeframe (see redux state groups.current.timeframe)
 */
import React from "react";
import moment from "services/locale/momentInit.js";

import { Form, ButtonGroup } from "react-bootstrap";

import { withT } from "services/translation/";

export const SelectTimeframe = withT(
  ({ changeTimeframe, timeframe, t, className = "" }) => {
    const options = [
      // {
      //   value: 12,
      //   label: t("hours", 12)
      // },
      {
        value: 24,
        label: t("hours", 24)
      },
      {
        value: 48,
        label: t("days", 2)
      },
      {
        value: 120,
        label: t("days", 5)
      },
      {
        value: 168,
        label: t("weeks", 1)
      },
      {
        value: 336,
        label: t("weeks", 2)
      },
      {
        value: 672,
        label: t("weeks", 4)
      }
    ];
    const endTime = new moment();
    const startTime = new moment().subtract(timeframe, "hours");

    let timeframeDuration = t(
      "hours",
      Math.round(endTime.diff(startTime, "hours", true))
    );
    if (timeframe > 24) {
      timeframeDuration = t(
        "days",
        Math.round(endTime.diff(startTime, "days", true))
      );
    }
    if (timeframe >= 168) {
      timeframeDuration = t(
        "weeks",
        Math.round(endTime.diff(startTime, "weeks", true))
      );
    }

    // If no presets are selected, create one
    if (!options.some(({ value }) => value === parseInt(timeframe))) {
      options.unshift({
        value: Math.round(timeframe),
        label: timeframeDuration
      });
    }

    return (
      <ButtonGroup className={className} size="sm">
        {/* <Tooltip
        description={t("side_pane.events.timeline_button_tooltip")}
        placement="bottom"> */}

        <Form.Control
          as="select"
          data-testid="select-timeframe"
          className="h-100"
          value={
            options.find(({ value }) => parseInt(timeframe) === value).value
          }
          onChange={e => changeTimeframe(e.target.value)}>
          {options.map(({ value, label }, index) => (
            <option value={value} key={index}>
              {label}
            </option>
          ))}
        </Form.Control>
        {/* </Tooltip> */}
      </ButtonGroup>
    );
  },
  "common.timeframe_select"
);
