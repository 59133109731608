import React from "react";

import UsingSystemDefault from "components/Common/DataTable/UsingSystemDefault/";

import {
  getExpectedTime,
  getRepresentativeOccurrence
} from "services/redux/selectors/reports/scheduled";

export function ExpectedColumn({ columns, eventTextFilter, getEventMeta }) {
  const column = columns["EXPECTED"];
  return {
    ...column,
    accessor: site => {
      const occurrence = getRepresentativeOccurrence({
        occurrences: site.occurrences,
        eventTextFilter,
        getEventMeta
      });

      return getExpectedTime({
        site: site,
        occurrence
      });
    },
    Cell: props => {
      return (
        <>
          <span>{props.value}</span>
          <span>
            <UsingSystemDefault {...props} />
          </span>
        </>
      );
    }
  };
}
