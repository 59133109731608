import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import Icon from "components/Icons/Icon";

import EagleEyeLiveView from "components/Common/Cameras/CameraList/EagleEyeLiveView";
import VideoLoftLiveView from "components/Common/Cameras/CameraList/VideoLoftLiveView";

import { Tooltip } from "components/Common/Tooltip/";

import { Button } from "react-bootstrap";

import "./EagleEyeLiveView.scss";

// import { formatDate } from "../FormatDate";

function LiveViewButton({
  camera_ref,
  stream_name,
  imaging_server,
  data_server,
  integration,
  className = "",
  children,
  selfInitialising = false,
  auth_token = null,
  ...rest
}) {

  const [showEagleEyeState, setShowEagleEyeState] = useState(false);

  const [showVideoLoftState, setShowVidoeLoftState] = useState(false);

  const expiredToolTip = (
    <Tooltip
      title="Authorisation Expired"
      description="Please go to the Manage Integrations page to Reauthorise"
      placement="bottom"
    >
      <Button
        variant="outline-danger"
        data-testid="live-view-button"
        size="sm"
        style={{ 'marginRight': '5px' }}
      >
        <Icon className="fas fa-video" />
      </Button>
    </Tooltip>
  );

  useEffect(() => {
    if (_.get(integration, 'integration_name') !== undefined && integration.integration_name === 'Xanview') {
      if (selfInitialising && auth_token) {
        try {
          new window.TetherX(auth_token).tooltips();
        } catch (ignore) {}
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth_token]);

  if (_.get(integration, 'integration_name') === undefined) {
    return null;
  }

  if (integration.integration_name === 'Eagle Eye') {
    return (
      <>
        {integration.camera_integration_site.auth_expired ? (expiredToolTip) :
          <>
            <Button
              variant="outline-primary"
              data-testid="live-view-button"
              size="sm"
              style={{ 'marginRight': '5px' }}
              // onClick={() => (setShowEagleEyeState(true))}
              onMouseEnter={() => (setShowEagleEyeState(true))}
              onMouseLeave={() => (setShowEagleEyeState(false))}
            >

              <Icon className="fas fa-video" />
            </Button>
            {showEagleEyeState && (
              <>
                <Tooltip>
                  <EagleEyeLiveView
                    subdomain={integration.subdomain}
                    account_id={integration.account_id}
                    auth_token={integration.auth_token}
                    camera_ref={camera_ref}
                    auth_expired={integration.camera_integration_site.auth_expired}
                  />
                </Tooltip>
              </>
            )}
          </>
        }
      </>
    )
  }

  if (integration.integration_name === 'Xanview') {
    return (
      <>
        <Button
          // onClick={() => window.open(formatDate(camera_ref))}
          data-testid="live-view-button"
          variant="outline-primary"
          size="sm"
          className={`tetherx-live-tooltip ${className}`}
          tetherx-zone-id={camera_ref}
          {...rest}>
          {children || <Icon className="fas fa-video" />}
        </Button>
      </>
    )
  }

  if (integration.integration_name === 'Videoloft') {
    return (
      <>
        {integration.camera_integration_site.auth_expired ? (expiredToolTip) :
          <>
            <Button
              variant="outline-primary"
              data-testid="live-view-button"
              size="sm"
              style={{ 'marginRight': '5px' }}
              // onClick={() => (setShowEagleEyeState(true))}
              onMouseEnter={() => (setShowVidoeLoftState(true))}
              onMouseLeave={() => (setShowVidoeLoftState(false))}
            >
              <Icon className="fas fa-video" />
            </Button>
            {showVideoLoftState && (
              <>
                <Tooltip>
                  <VideoLoftLiveView
                    subdomain={integration.subdomain}
                    account_id={integration.account_id}
                    auth_token={integration.auth_token}
                    camera_ref={camera_ref}
                    stream_name={stream_name}
                    imaging_server={imaging_server}
                    data_server={data_server}
                    auth_expired={integration.camera_integration_site.auth_expired}
                  />
                </Tooltip>
              </>
            )}
          </>
        }
      </>
    )

  }

  if (integration.integration_name === 'ChubbView') {
    return (
      <>
        {integration.camera_integration_site.auth_expired ? (expiredToolTip) :
          <>
            <Button
              variant="outline-primary"
              data-testid="live-view-button"
              size="sm"
              style={{ 'marginRight': '5px' }}
              // onClick={() => (setShowEagleEyeState(true))}
              onMouseEnter={() => (setShowVidoeLoftState(true))}
              onMouseLeave={() => (setShowVidoeLoftState(false))}
            >
              <Icon className="fas fa-video" />
            </Button>
            {showVideoLoftState && (
              <>
                <Tooltip>
                  <VideoLoftLiveView
                    subdomain={integration.subdomain}
                    auth_token={integration.auth_token}
                    camera_ref={camera_ref}
                    stream_name={stream_name}
                    imaging_server={imaging_server}
                    data_server={data_server}
                    account_id={integration.account_id}
                  />
                </Tooltip>
              </>
            )}
          </>
        }
      </>
    )
  } else {
    return null;
  }
}
LiveViewButton.propTypes = {
  /**
   * @param camera_ref the zone id for the camera
   */
  camera_ref: PropTypes.string.isRequired
};
export default LiveViewButton;
