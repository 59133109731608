import axios from "axios";
import Cookies from "js-cookie";

import _ from "lodash";

import caching from "services/caching";

// import groupsManager from "./groupsManager";

const BASE_URLS = {
  live: "https://admin.conxtd.com/out_api",
  staging: "https://staging.conxtd.com/out_api",
  saturn: "https://pro-staging.conxtd.com/conxtd/out_api",
  mars: "https://mars.conxtd.com/out_api",
  sevenbarrows: "https://sevenbarrows:8443/conxtd/out_api",
};

const domain = window.location.hostname;

const ENABLE_PROXY = false;
// domain.includes("pro-staging") ||
// domain.includes("stonehenge") ||
// domain.includes("vscode.conxtd.com");

const dev = domain.includes("stonehenge.local");

// const proxy = ENABLE_PROXY ? "https://vscode.conxtd.com/" : "";
const proxy = ENABLE_PROXY ? "https://pro-staging.conxtd.com:8443/" : "";

const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: false,
};

let conxtdOut = new axios.create(config);
conxtdOut.dispatch = null;
conxtdOut.environment = null;
conxtdOut.defaultEnv =
  //domain === "pro-staging.conxtd.com" ||
  domain === "stonehenge.local" ? "mars" : "live";
conxtdOut.apiErrors = 0;
conxtdOut.BASE_URLS = BASE_URLS;
conxtdOut.defaultCookieConfig = {
  // HTTPS
  secure: true,
  // Only allow it to be sent with request to the same origin
  // Note: we don't send cookies as part of authentication
  sameSite: "strict",
};

// const LIVE = true; //window.location.toString().indexOf("conxtd.com") !== -1;

// conxtdOut.environment = LIVE ? "live" : "staging";

let baseURL = () => proxy + BASE_URLS[conxtdOut.environment];

// Add a response interceptor
conxtdOut.interceptors.response.use(
  function (response) {
    const status = _.get(response, "status");

    if (status === 200) {
      // We have made a succcessful request, reset api error counter
      conxtdOut.apiErrors = 0;
    }
    // Do something with response data
    return response;
  },
  function (error) {
    const status = _.get(error.response, "status");

    // console.log(status);

    // TODO: remove this in favour of working CORS
    const corsError = error.message.indexOf("Network Error") !== -1;
    // 401 unauthorized - log user out
    // TODO: review this process
    // User should really be shown an access error if they don't have access
    //  or a 'session expired' error otherwise.
    //  However we would need to differentiate between access errors and
    //  invalid/expired session IDs

    // Prevent log out on 504
    if (status === 504) return;

    if (status === 401 || status === 403 || corsError) {
      const user = Cookies.get("user", { domain });
      console.log("user", user);

      // Not logged in - this is most likely a login fail
      // (note: this has changed to 422, see above)
      // if (!user) {
      //   throw new Error("Incorrect credentials");
      // } else {
      if (user) {
        // We can access the status and it's 401 unauthorized
        // and an invite user doesn't exist
        // this session is likely invalid, logout
        if (status === 401 && Object.keys(JSON.parse(user)).length !== 1) {
          conxtdOut.logout().then(() => {
            // conxtdOut.dispatch({ type: "LOGOUT_REQUEST" });
            window.location = "/";
          });
        }
        // Session should be valid but not able to get a status code
        else {
          conxtdOut.apiErrors++;
          if (
            !dev &&
            // Log out after two errors
            conxtdOut.apiErrors > 1
          ) {
            // Simply log out on any cors "network" error
            conxtdOut.logout().then(() => {
              // conxtdOut.dispatch({ type: "LOGOUT_REQUEST" });
              window.location = "/";
            });
          }
        }
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
// Auth manager
conxtdOut.interceptors.request.use(
  function (request) {
    if (
      conxtdOut.environment === null ||
      typeof conxtdOut.environment === "undefined"
    ) {
      // Session cannot be valid, destroy it
      conxtdOut.logout();
      conxtdOut.dispatch({ type: "LOGOUT_REQUEST" });
      throw new Error("Can't make API request: no environment is specified");
    }
    request.url = baseURL() + request.url;
    if (typeof conxtdOut["Authorization"] !== "undefined") {
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: conxtdOut["Authorization"],
        },
      };
    } else if (request.url.includes("/users/logout") && (typeof conxtdOut["LogoutAuthorization"] !== "undefined")) {
      // This is a log out call, so use the temporarily stored LogoutAuthorization
      // (note: conxtdOut["Authorization"] will already have been deleted by the time this executes)
      const returnRequest = {
        ...request,
        headers: {
          ...request.headers,
          Authorization: conxtdOut["LogoutAuthorization"],
        },
      };
      delete conxtdOut["LogoutAuthorization"]; // delete the temporary LogoutAuthorization 
      return returnRequest;
    }
    return request;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// Get the current session or return null
conxtdOut.getSession = () => {
  // Check for valid user session - must pass domain
  let user = Cookies.get("user", { domain });

  // hide console log
  // console.log = () => {};

  if (user) {
    user = JSON.parse(user);
    conxtdOut["Authorization"] = user.sessionId;

    // Update current environment
    conxtdOut.environment = user.environment;

    return user;
  }
  return null;
};

// hide console log in production
if (conxtdOut.defaultEnv === "live") console.log = () => {};

// Get the current session or return null
conxtdOut.updateSession = (user) => {
  // Check for valid user session - must pass domain
  let userCookie = Cookies.get("user", { domain }) || "{}";

  // if (userCookie) {
  userCookie = JSON.parse(userCookie) || {};
  conxtdOut["Authorization"] = user.sessionId;

  // Update current environment
  conxtdOut.environment = user.environment;

  const cookieConfig = {
    ...conxtdOut.defaultCookieConfig,
    domain,
  };

  // Save user into cookie
  Cookies.set(
    "user",
    JSON.stringify({
      ...userCookie,
      // Overwrite previous cookie
      ...user,
    }),
    cookieConfig
  );
  // }
  // console.warn("Couldn't update user cookie");

  // return null;
};

// Logout (destroy session)
conxtdOut.logout = () => {
  const cookieConfig = {
    ...conxtdOut.defaultCookieConfig,
    domain,
  };
  
  // Destroy session cookies
  Cookies.remove("user", cookieConfig);
  caching.flush();

  // Temporarily store the Authorisation just to pass on this logout call, so the backend knows which session to log out
  // It will be deleted in the request interceptor after it's been passed on the call
  conxtdOut["LogoutAuthorization"] = conxtdOut["Authorization"];

  // Destroy session ID
  delete conxtdOut["Authorization"];
  // Destroy axios session ID
  // delete conxtdOut.defaults.headers.common["Authorization"];

  // Invalid environment means we can't tell the server we're logging out
  if (!conxtdOut.environment) {
    delete conxtdOut["LogoutAuthorization"]; // we won't be using it after all
    return false;
  }

  // Attempt logout call
  // const response = await conxtdOut.post(`/users/logout`);
  conxtdOut.post(`/users/logout`).then((response) => {
    // Return true if logout was successful
    if (response.request.status === 200) {
      return true;
    } else {
      // Technically logout has failed, but we have still safely destroyed the session
      return false;
    }
  }).catch(() => {
    delete conxtdOut["LogoutAuthorization"]; // just in case it wasn't done in the interceptor
    return false;
  });
};

conxtdOut.login = async (credentials, environment) => {
  // Set current environment
  conxtdOut.environment = environment || null;

  let response = await conxtdOut.post(
    `/users/login`,
    _.pick(credentials, ["email", "password"])
  );
  const { user, enterprises } = response.data;

  const cookieConfig = {
    ...conxtdOut.defaultCookieConfig,
    domain,
  };
  // Custom set cookie
  // let expires = "";

  // Defaults to no expiry - this will destroy the cookie on the end of the user's session/browser close
  if (credentials.persist) {
    // 5 day expiry
    cookieConfig.expires = 5;

    // Custom set cookie
    // var date = new Date();
    // date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    // expires = "; " + date.toUTCString();
  }

  // Persist session environment
  user.environment = environment;

  // Save user into cookie
  Cookies.set("user", JSON.stringify(user), cookieConfig);

  // Custom set cookie
  // We use our own cookie setting method here
  // document.cookie = "user=" + JSON.stringify(user) + expires;

  // Instead of throwing an error, force a page reload when the cookie is not being set
  // after a successful log in.
  // This is a short-term fix for GitLab issue #378
  if (!Cookies.get("user", cookieConfig)) {
    window.location.reload();
    // throw new Error("Unable to save session");
  }

  // Omit the config in the response as it holds user auth info
  if (_.get(response, "config")) {
    response = _.omit(response, "config");
  }

  // conxtdOut.defaults.headers.common["Authorization"] = user.sessionId;

  // Login was successful, flush cache as a safety mechanism
  caching.flush();

  return { user, enterprises };
};

// Deprecated
// conxtdOut.loadGroupTree = async enterpriseId => {
//   if (isNaN(enterpriseId)) {
//     throw new Error(
//       `Invalid enterprise ID for /EnterpriseStructure/treeList call: ${enterpriseId}  (${typeof enterpriseId})`
//     );
//   }
//   let response = await conxtdOut.get(
//     `/EnterpriseStructure/treeList?enterprise_id=${enterpriseId}`
//   );
//   const treeList = _.map(response.data.treeList, (key, value) => {
//     return { name: key, id: value };
//   });
//   return groupsManager.mapTree(treeList);
// };

export const getEnvironment = () => {
  return conxtdOut.environment || "mars";
};

export default conxtdOut;
