import React, { useEffect } from "react";

import LiveViewButton from "./LiveViewButton";

function LiveViewToolbar({ integration }) {
  useEffect(() => {
    try {
      new window.TetherX(integration.auth_token).tooltips();
    } catch (ignore) {}
  }, [integration.auth_token]);

  // <ButtonGroup style={{ width: "100%" }}>
  return integration.camera_integration_site.camera_sudo_sites.map(
    (camera, index) => (
      <LiveViewButton {...camera} integration={integration} className="mr-2" key={index} />
    )
  );
  // </ButtonGroup>
}

export default LiveViewToolbar;
