import React from "react";

import moment from "services/locale/momentInit.js";

import CircleIcon from "components/Icons/CircleIcon";

import TimezoneDate from "components/Common/TimezoneDate";

class BundleItem extends React.Component {
  render() {
    const {
      eventMeta,
      title,
      description,
      event,
      bundle_id,
      onSelectBundle,
      timezone,
    } = this.props;

    const ackedMoment = new moment(event.acked);

    // If it's in the last 24 hours, show "fromNow" or timezone tooltip
    const acked = ackedMoment.isAfter(new moment().subtract(1, "day")) ? (
      ackedMoment.fromNow()
    ) : (
      <TimezoneDate date={ackedMoment} timezone={timezone} />
    );

    let iconBadge = null;
    // TODO: consider a video badge like below to indicate
    // there is footage on this event
    // if (event.camera_events && event.camera_events.length > 0) {
    //   iconBadge = (
    //     <div style={{ position: "absolute", top: "-3px" }}>
    //       <Icon className="fas fa-video" />
    //     </div>
    //   );
    // }

    return (
      <div
        className="latest-message-list waves-effect w-100 px-2"
        onClick={() => onSelectBundle(bundle_id)}
      >
        <div className="position-relative mt-3">
          <div className="float-left user mr-3 d-flex">
            {iconBadge}
            <CircleIcon
              size="44"
              type={eventMeta.icon}
              color={eventMeta.colour}
            />
          </div>
          <div className="message-time">
            <p className="m-0 text-muted">{acked}</p>
          </div>
          <div className="message-desc">
            <div
              className={`${description ? "" : "d-flex align-items-center"} m-0 p-0`}
            >
              <h5
                className="font-14 mt-0 text-dark"
                style={{ minHeight: "16px" }}
              >
                {title}
              </h5>
              <p
                className="text-muted" 
                style={description ? { whiteSpace: "pre-wrap" } : { marginBottom: "55.5px" }}
              >
                {decodeURIComponent(description)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BundleItem;
