/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";

import FilterOverviewWidgetFullReport from "./FilterOverviewWidgetFullReport";
import FilterOverviewWidget from "./FilterOverviewWidget";

import { CardDeck } from "react-bootstrap";

import {
  loadReportsOverviewStart,
  loadReportsOverviewCancel,
} from "services/redux/actions";

import { getRouterParams } from "services/redux/selectors/app/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getOpenCloseByAreaIndex } from "services/redux/selectors";

const COLUMNS = [
  "",
  "col-md-4",
  "col-xl-8 col-lg-10 col-md-12",
  "col-xl-10 col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12"
];

class FilterOverviewWidgetGroup extends React.Component {
  loadReports = (props) => {
    const reportsLoading = props.reports.overview.all.loading === true;

    if (!props.enterprise) {
      return;
    }

    const params = {
      enterprise_id: props.enterprise.id,
      group_id: props.enterprise.root_group_id || props.router.params.group_id,
      key: "all",
      refresh: true,
      interval: 60 * 1000,
    };

    if (!reportsLoading) {
      this.props.dispatch(loadReportsOverviewStart(params));
    }
  };
  componentWillUnmount() {
    this.props.dispatch(loadReportsOverviewCancel());
  }
  componentDidMount() {
    if (!this.props.reportIndex.data) {
      // no data in the index, e.g. after a refresh of the page
      this.loadReports(this.props);
    }
  }

  render() {
    const { reportIndex } = this.props;

    const fullReport = [
      <FilterOverviewWidgetFullReport 
        title1={reportIndex.data ? reportIndex.data.title_top : "All Perimeter"} 
        title2={reportIndex.data ? reportIndex.data.title_bottom : "Open & Closed"} 
        iconId1={10}
        iconId2={9} 
        total={reportIndex.data && reportIndex.data.sites_count}
        report="openclosebyareafull" 
        key={0}
      />
    ];

    let filterReports = [];
    if (reportIndex.data) {
      filterReports = reportIndex.data.reports.filter((report) => {
          if (report.total_count > 0) {
            return true;
          } else {
            return false;
          }
        }).map((report, index) => {
          return <FilterOverviewWidget 
          title1={report.title_top} 
          title2={report.title_bottom}
          iconId1={report.alarm_id_top} 
          iconId2={report.alarm_id_bottom} 
          total={report.total_count}
          report={report.summary_parameter} 
          key={index+1}
        />
      });
    } 

    const reports = fullReport.concat(filterReports);

    // column class based on number of reports (including the full report)
    const column = COLUMNS[reportIndex.data ? reports.length : 1];

    return (
      <div className={`${column} pl-4 my-3`}>
        <CardDeck className="col-md-12 px-2">{reports}</CardDeck>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    router: { params: getRouterParams(state, props) },
    reports: state.reports,
    reportIndex: getOpenCloseByAreaIndex(state, props),
  };
};
export default connect(mapStateToProps)(FilterOverviewWidgetGroup);