import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Modal, Row, Col, Form, Card } from "react-bootstrap";
import Alert from "components/Common/Alert";
import conxtdOut from "apis/conxtdOut";

import TimezoneDate from "components/Common/TimezoneDate";
import moment from "services/locale/momentInit.js";

import "./ConfirmedAlarmViewModal.scss";
import { Keyholders } from "../Site/SiteView/SiteOverview/Tabs/Keyholders";
import { loadSiteKeyholdersStart } from "services/redux/actions";

class ConfirmedAlarmViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      key: "bundles",
      dispatched: false,
    };
    this.clearData = this.clearData.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  loadKeyholders = (props) => {
    const { site } = props;

    this.setState({ dispatched: true }, () => {
      if (!site.id) {
        return;
      }

      this.props.dispatch(
        loadSiteKeyholdersStart({
          id: site.id,
        })
      );
    });
  };

  clearData() {
    this.setState({
      text: "",
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCancel(e) {
    e.preventDefault();
    this.clearData();
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { zone, site_id } = this.props;
    const time = moment.utc().format();

    const event = "C155";
    try {
      await conxtdOut.post(`/Events/add/${site_id}`, {
        event,
        zone,
        text: this.state.text,
        site: null,
        time,
      });

      this.setState({
        event: "",
        zone: "",
        text: "",
        time: "",
      });

      Alert({
        text: "Comments have been submitted.",
        icon: "success",
        timerProgressBar: true,
        timer: 2000,
      });
      this.props.hideModal();
    } catch (error) {
      console.log("Error details", error);
    }
  };

  componentDidMount() {
    this.loadKeyholders(this.props);
  }

  render() {
    const { time, timezone } = this.props;

    const acked = <TimezoneDate date={time} timezone={timezone} />;

    // styles
    const buttonDivStyle = {
      margin: "10px auto",
    };

    const textDivStyle = {
      flex: "1 0 20%",
      margin: "5px",
    };

    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.hideModal}
          dialogClassName="custom-dialog"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Row>
            <Col md={{ order: 2 }}>
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">Site Contacts</Card.Title>
                  <Keyholders
                    style={{ padding: "20px" }}
                    site={this.props.site}
                    viewAs="table"
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col xs md lg={{ order: 1 }}>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "spaceBetween",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={textDivStyle}>
                    <h6>Site: {this.props.site_name}</h6>
                  </div>

                  <div style={textDivStyle}>
                    <h6> Time: {acked}</h6>
                  </div>

                  <div style={textDivStyle}>
                    <h6>Zone: {this.props.zone}</h6>
                  </div>
                </div>
                <h6 style={{ display: "flex" }}>
                  SOP: {this.props.event_text}
                </h6>
              </div>
              <Modal.Body onClick={(e) => {}}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      className="comment-box"
                      as="textarea"
                      rows="6"
                      required
                      type="text"
                      name="text"
                      placeholder="Add your comments here..."
                      onChange={this.changeHandler}
                      value={this.state.text}
                      style={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "15px",
                      }}
                      maxLength="1000"
                    />
                  </Form.Group>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "spaceBetween",
                    }}
                  >
                    <div style={buttonDivStyle}>
                      <Button
                        variant="primary"
                        onClick={this.onCancel}
                        size="lg"
                      >
                        Clear
                      </Button>
                    </div>
                    <div style={buttonDivStyle}>
                      <Button variant="success" type="submit" size="lg">
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    site: state.sites.current,
    user: state.user.loading,
  };
};

export default connect(mapStateToProps, null)(ConfirmedAlarmViewModal);
