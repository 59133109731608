/**
 * 08/21 Customisations for child rows for hourly report, 
 * i.e. expansion of hours
 */

import { NA } from "services/redux/selectors";

import { getGroupPathFromSite } from "services/redux/selectors";

const formatHour = (hour) => {
  if (Number(hour) < 12) {
    hour = Number(hour) + " am";
  } else {
    if (Number(hour) > 12) {
      hour = Number(hour) - 12;
    }
    hour = hour + " pm";
  }
  return hour;
}

const ChildRowColumnsHourly = [
  {
    key: "CUSTOM_ID",
    content: ({ site }) => site.custom_id || NA()
  },
  {
    key: "NAME",
    content:  ({ site }) => site.name,
    className: "text-muted"
  },
  {
    key: "GROUP",
    content: ({ site, groups }) => getGroupPathFromSite(groups, site) || NA(),
    className: "text-muted"
  },
  {
    key: "SITE_REF",
    content: ({ site }) => site.site_ref || site.wwo_id,
    className: "text-muted"
  },
  {
    key: "PERIOD",
    content: ({ hour }) => formatHour(hour.hour),
    className: "text-muted text-right",
    style: {
      paddingRight: "1em"
    }
  },
  {
    key: "COUNT",
    content: ({ hour }) => hour.value.toString(),
    className: "text-muted text-right",
    style: {
        paddingRight: "1em"
    }
  },
  {
    key: "VIEW_CHART",
    content: null,
    className: "text-muted"
  },
  {
    key: "EXPAND",
    content: null
  }
];

export default ChildRowColumnsHourly;