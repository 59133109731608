import { watchLoadCustomReport, watchLoadCustomReportBundles } from "./customReports";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCustomReport(),
      watchLoadCustomReportBundles(),
    ],
  );
}