import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Card, Col, Row, Button } from "react-bootstrap";

import LiveViewButton from "components/Common/Cameras/CameraList/LiveViewButton";
import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip/";
import Loading from "components/Loading";

import { withT } from "services/translation/";

import { requestRemoveSiteCameraStart } from "services/redux/actions";

import {
  getCurrentSite,
  getSites,
  getSiteById
} from "services/redux/selectors/sites";

import SelectBundle from "../SelectBundle";

import Alert from "components/Common/Alert";

function CameraCard({ site, removeCamera, site_id, site_name, integration, loading, integration_id, t }) {
  function removeCameraHelper() {
    Alert({
      title: t("remove_camera_confirm", site.camera_name),
      icon: "warning",

      showCancelButton: true
    }).then(result => {
      if (result.value) {
        removeCamera({
          camera_sudo_site_id: site.id,
          site_id,
          integration_id,
          body: {
            active: false
          }
        });
      }
    });
  }

  return (
    <Col md="12">
      <Card className="border-dark bg-light">
        <Card.Body>
          <Row>
            <Col>
              <div className="float-left font-16 pr-2">
                <LiveViewButton camera_ref={site.camera_ref} integration={integration} />{" "}
                {site.camera_name}
              </div>
              <div className="float-right">
                {loading ? (
                  <Loading center />
                ) : (
                <Tooltip
                  placement="left"
                  description={t("remove_camera_tooltip")}>
                  <Button
                    disabled={loading}
                    onClick={removeCameraHelper}
                    size="sm"
                    variant="danger"
                    className="px-2">
                    <Icon className="fas fa-times" />
                  </Button>
                </Tooltip>
                )}
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <SelectBundle
                currentBundleIds={site.conxtd_alarm_bundles.map(({ id }) => id)}
                camera_ref={site.camera_ref}
                camera_name={site.camera_name}
                camera_integration_site_id={site.camera_integration_site_id}
                camera_sudo_site_id={site.id}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
CameraCard.propTypes = {
  /**
   * @param removeCamera api dispatch to remove camera from site
   */
  removeCamera: PropTypes.func.isRequired,

  /**
   * @param loading api loading status (null|true|false)
   */
  loading: PropTypes.any,

  /**
   * @param site_id id site to use for api integrations refresh
   */
  site_id: PropTypes.any.isRequired,

  /**
   * @param site_name Name of the site for confirm remove modal
   */
  site_name: PropTypes.string.isRequired,

  /**
   * @param integration_id Id of the integration
   */
  integration_id: PropTypes.number.isRequired,

};

const mapStateToProps = (state, props) => {
  const site_id = parseInt(getCurrentSite(state, props).id);
  const site = getSiteById(getSites(state, props), site_id);

  return {
    loading: state.sites.current.integrations.cameras.actions.remove.loading,
    site_id,
    site_name: site.name,
  };
};
const mapDispatchToProps = {
  removeCamera: requestRemoveSiteCameraStart
};

export default withT(
  connect(mapStateToProps, mapDispatchToProps)(CameraCard),
  "site_overview.side_pane.manage_integrations.providers.tether"
);
