import React from "react";

const spanner = props => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 303.477 303.477"
    width="100%"
    height="100%"
    className="">
    <g>
      <path
        d="M298.604,64.209l-49.978,49.979L204.907,98.57L189.29,54.852l49.979-49.979c-32.791-10.97-70.418-3.42-96.529,22.692  c-25.629,25.629-33.373,62.349-23.281,94.704c-1.359,1.07-2.676,2.222-3.93,3.476L12.884,228.389  c-17.178,17.177-17.178,45.027,0,62.205c17.178,17.178,45.029,17.178,62.207,0l102.645-102.645c1.254-1.254,2.404-2.57,3.475-3.929  c32.355,10.092,69.074,2.347,94.703-23.282C302.024,134.626,309.575,97.001,298.604,64.209z"
        data-original="#000000"
        className="active-path"
        fill={props.color}
      />
    </g>
  </svg>
);

export default spanner;