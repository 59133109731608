import React from "react";
import PropTypes from "prop-types";

// Disable auto play for now for performance
// should re-enable to activate when appears
// on visible scrolling area

// componentDidMount = () => {
//   const { index } = this.props;

//   setTimeout(() => {
//     const videos = document.getElementsByClassName("clipview-" + index);

//     for (const video of videos) {
//     }
//   }, 3000);
// };
// play = () => {
//   const { index } = this.props;
//   const videos = document.getElementsByClassName("clipview-" + index);
//   video.play();

// }

/**
 * HTML5 video that uses props.camera_events[x].clip_url
 * for video source
 */
function UrlClipView(props) {
  const { event, index } = props;
  const { clip_url } = event;

  return (
    <div
      data-testid="clip-view-url"
      className="mt-2 clipview-preview"
      // onClick={e => {
      //   e.stopPropagation();
      //   e.preventDefault();
      //   Alert({
      //     animation: false,
      //     customClass: "col-6 timeline-modal",
      //     showCloseButton: true,
      //     showConfirmButton: false,
      //     html: `<video
      // className="${"clipview-" + index}"
      // style="width: 100%; height: auto;"
      // controls
      // autoplay
      // loop
      // muted>
      // <source src="${event.clip_url}" type="video/mp4" />
      // </video>`
      //   });
      // }}
    >
      <video
        // ref={input => {
        //   this.video = input;
        // }}
        className={"clipview-" + index}
        style={{ width: "100%", height: "auto" }}
        controls
        // autoplay
        loop
        muted>
        <source src={clip_url} type="video/mp4" />
      </video>
    </div>
  );
}
UrlClipView.propTypes = {
  event: PropTypes.shape({
    clip_url: PropTypes.string.isRequired
  }).isRequired
};

export default UrlClipView;
