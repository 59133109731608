import React from "react";

import PropTypes from "prop-types";

import Icon from "components/Icons/Icon";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ReactAlert = withReactContent(Swal);

class Button extends React.Component {
  render() {
    const { Content, title } = this.props;
    return (
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          ReactAlert.fire({
            icon: "info",
            title,
            html: <Content />
            // onAfterClose: () => {}
          });
        }}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          paddingRight: "14px",
          paddingTop: "14px"
        }}>
        <Icon className="fas fa-info-circle font-16" />
      </div>
    );
  }
}
Button.propTypes = {
  Content: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default Button;
