const initialState = {
  sudoStateId: null,
  assignedUser: "none",
  contactStatus: {},
  sopMap: {},
  closeDownOptions: [],
  currentContactStatus: null,
};

export default function sop(state = initialState, action) {
  switch (action.type) {
    case "LOAD_SOP_ACTION_MAP_START":
      return {
        ...state,
      };

    case "LOAD_SOP_ACTION_MAP_SUCCESS":
      return {
        ...state,
        sudoStateId: action.startAction.sudo_state_id,
        assignedUser: action.data.assigned_user,
        contactStatus: action.data.contact_status,
        sopMap: action.data.sop_map, 
      };

    case "LOAD_SOP_ACTION_MAP_FAILED":
      return {
        ...state,
      };

    case "LOAD_SOP_ACTION_MAP_CANCEL":
      return {
        ...state,
      };

    case "CLEAR_SOP_ACTION_MAP":
      return {
        ...state,
        assignedUser: "none",
        contactStatus: {},
        sopMap: {},
      };

    case "SET_SOP_CURRENT_CONTACT_STATUS":
      return {
        ...state,
        currentContactStatus: action.currentContactStatus,
      }

    case "LOAD_SOP_CLOSE_DOWN_OPTIONS_START":
      return {
        ...state,
      };

    case "LOAD_SOP_CLOSE_DOWN_OPTIONS_SUCCESS":
      return {
        ...state,
        closeDownOptions: action.data.close_down_options, 
      };

    case "LOAD_SOP_CLOSE_DOWN_OPTIONS_FAILED":
      return {
        ...state,
      };

    case "LOAD_SOP_CLOSE_DOWN_OPTIONS_CANCEL":
      return {
        ...state,
      };

    default:
      return state;
  }
}