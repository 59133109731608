/**
 * Remove camera from a site
 */
import { call } from "redux-saga/effects";

import {
    requestRemoveSiteCameraStart,
    requestRemoveSiteCameraFailed,
    requestRemoveSiteCameraSuccess,
    requestRemoveSiteCameraCancel,
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* requestRemoveCamera({
    camera_sudo_site_id,
    site_id,
    body,
}) {
    try {
        const result = yield call(conxtdOut.post, `/CameraSudoSites/edit/${camera_sudo_site_id}`, body);

        return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* requestRemoveCameraWatcher() {
    yield actionWatcher({
        actions: {
            start: requestRemoveSiteCameraStart,
            failed: requestRemoveSiteCameraFailed,
            success: requestRemoveSiteCameraSuccess,
            cancel: requestRemoveSiteCameraCancel
        },
        saga: requestRemoveCamera
    });
}