import React from "react";

import Icon from "components/Icons/Icon";

import { Button } from "react-bootstrap";

import { default as T } from "services/i18n/Translate";

import GroupList from "./GroupList";

import WaveLink from "components/Layout/WaveLink";

import NavIcon from "components/Layout/Nav/NavIcon";

class GroupItem extends React.Component {
  state = {
    open: null,
    parentOfSelected: null
  };
  componentDidMount() {
    const { selectedGroupId } = this.props;
    const { root, children } = this.props.group;

    // TODO: extend this into a selector (reducer fn) that
    // traverses into children
    const parentOfSelected =
      children.filter(g => g.id === selectedGroupId).length > 0;
    // Make the group that's already selected
    //  open by default (and also the root group)
    //  or it's a parent of the selected group
    this.setState({
      open: root || parentOfSelected,
      parentOfSelected
    });
  }
  render() {
    const { onSelect, selectedGroupId } = this.props;
    const { name, id, children, role_name } = this.props.group;
    const { parentOfSelected } = this.state;

    const listClasses = [];
    const linkClasses = [];

    const childrenCount = children.length || 0;

    let childGroupList = null;

    const active =
      // Been clicked or is set to open by default
      this.state.open &&
      // Has children
      childrenCount > 0;
    const selected = selectedGroupId === id;

    if (childrenCount > 0) {
      listClasses.push("has_sub");
      // const liHeight = 58;

      // const style = active ? { maxHeight: children.length * liHeight + "px" } : {};
      childGroupList = (
        <GroupList
          // style={style}
          groups={children}
          onSelect={onSelect}
          selectedGroupId={selectedGroupId}
        />
      );
    }
    // Active means it's been opened and is also blue
    if (active) {
      listClasses.push("active nav-active");
    }
    // Selected means the group is the currently selected one
    if (selected) {
      listClasses.push("selected");
    }
    if (parentOfSelected) {
      listClasses.push("parent-of-selected");
    }

    // const label = "label";
    // const badge = childrenCount === 0 ? null : (
    //     <span className="badge badge-success badge-pill float-right">
    //       {childrenCount}
    //     </span>
    //   );

    const subArrow =
      childrenCount === 0 ? null : (
        <span className="menu-arrow">
          <Icon className="fas fa-chevron-right" />
        </span>
      );

    return (
      <li className={listClasses.join(" ")}>
        <WaveLink
          to={"#"}
          onClick={({ target }) => {
            // Short circuit for button
            if (target.className.includes("select-group")) return;

            this.setState({ open: !this.state.open });
          }}
          className={linkClasses.join(" ")}>
          {this.props.icon && <NavIcon className={this.props.icon} />}
          <span>{name}</span>
          {subArrow}
          {role_name && (
            <>
              <Button
                variant={`${selected ? "" : "outline-"}primary`}
                disabled={selected}
                className="float-right select-group"
                size="sm"
                onClick={() => {
                  onSelect(id);
                }}>
                <T>{selected ? "Selected" : "Select Group"}</T>
              </Button>

              <span className="float-right text-muted pr-3">{role_name}</span>
            </>
          )}
        </WaveLink>
        {childGroupList}
      </li>
    );
  }
}

export default GroupItem;
