import React from "react";
import { connect } from "react-redux";

import moment from "services/locale/momentInit.js";

import TimezoneDate from "components/Common/TimezoneDate";

class ActualTimezoneDate extends React.Component {
  render() {
    const { occurrence } = this.props;

    const localTimezoneText = moment.tz.guess();

    return <TimezoneDate 
      date={occurrence.acked} 
      timezone={occurrence.timezone} 
      format={occurrence.format} 
      hideTimezone={occurrence.timezone === localTimezoneText}
    />;
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(ActualTimezoneDate);