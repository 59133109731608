import React from "react";
// import { connect } from "react-redux";

import { Link } from "react-router-dom";

// import { Row, Col, Card } from "react-bootstrap";

import ModeCard from "./ModeCard";

import Icon from "components/Icons/Icon";

import ModeEvents from "./ModeEvents";

// import moment from "services/locale/momentInit.js";

import "./Modes.scss";

export default class CardList extends React.Component {
    state = {
        view: "mode_session_overview",
        id: "no_selection",
        year: null
    };

    changeView = (childSelection) => {
        this.setState({
            view: "mode_session_events",
            id: childSelection
        })
    }

    setDefaultState = () => {
        this.setState({
            view: "mode_session_overview",
            id: "no_selection"
        })
    }

    setYear = (parsedYear) => {
        this.setState({
            year: parsedYear
        })
    }

    render() {
        const { view, id, year } = this.state;

        const { data, mode_type, t, site } = this.props;

        // let chosen_mode_type = mode_type === 'E' ? 'engineer_sessions' : 'test_sessions';

        // const sudo_state = data[chosen_mode_type];

        const linkBack = (
            <div className="row px-2">
                <div className="col-md-12">
                    <Link to="#" onClick={() => { this.setDefaultState() }}>
                        <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> Back to Modes</span>
                    </Link>
                </div>
            </div>
        );

        if (view === "mode_session_events") {
            return (
                <>
                    <div className="bg-lightgrey w-100 pt-2 pb-2 mode-cards">
                        {linkBack}
                        <ModeEvents id={id} site={site} />
                    </div>
                </>
            )
        }

        if (data === null || data.mode_sessions === null || data.mode_sessions.length === 0) {
            let cmt = mode_type === 'E' ? 'Engineer Sessions' : 'Test Sessions';
            return (
                <div className="mode-cards center">
                    <div >No {cmt} found</div>
                </div>
            )
        }

        return (
            <>
                <div className="mode-cards">
                    {data.mode_sessions.map((mode_session, index) => (
                        <div key={index}>
                            <ModeCard
                                mode_session={mode_session}
                                sudo_state={data}
                                key={index}
                                mode_type={mode_type}
                                t={t}
                                changeView={this.changeView}
                                year={year}
                                changeYear={this.setYear}
                                site={site}
                            />
                        </div>
                    ))}
                </div>
            </>
        );
    }
}