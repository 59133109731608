import panic from "./panic";
import bolt from "./bolt";
import burglary from "./burglary";
import cogs from "./cogs";
import engineer from "./engineer";
import fire from "./fire";
import freezer from "./freezer";
import pstn from "./pstn";
import radio from "./radio";
import spanner from "./spanner";
import tests from "./tests";
import thermo from "./thermo";
import wifi from "./wifi";
import lock from "./lock";
import unlock from "./unlock";
import door from "./door";
import bell from "./bell";
import building from "./building";

import exit from "./exit";
import groups from "./groups";
import location from "./location";
import network from "./network";
import refuse from "./refuse";
import sprinkler from "./sprinkler";
import dash from "./dash";
import comment from "./comment";
import smoke from "./smoke";
import camera from "./camera";

const svg = {
  panic,
  bolt,
  burglary,
  cogs,
  engineer,
  fire,
  freezer,
  pstn,
  radio,
  spanner,
  tests,
  thermo,
  wifi,
  lock,
  unlock,
  door,
  bell,
  building,
  exit,
  groups,
  location,
  network,
  refuse,
  sprinkler,
  dash,
  comment,
  smoke,
  camera,
};

export default svg;