import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserManagementSearchSuccess,
  requestUserManagementSearchFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchSearch() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_MANAGEMENT_SEARCH_START", function*(action) {
    yield race({
      task: call(requestSearch, action),
      cancel: take([
        "REQUEST_USER_MANAGEMENT_SEARCH_CANCEL",
        "REQUEST_USER_MANAGEMENT_SEARCH_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestSearch(action) {
  const { email } = action;
  if (!email) {
    throw new Error("Invalid value supplied for email: " + email);
  }

  try {
    // const response = yield call(() => {
    //   return { data: {} };
    // });
    const response = yield call(conxtdOut.get, `/users/view/${email}`);

    yield put(
      requestUserManagementSearchSuccess({ user: { email, ...response.data } })
    );
  } catch (error) {
    yield put(
      requestUserManagementSearchFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
