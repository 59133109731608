import React from "react";
import { Link } from "react-router-dom";

class CONXTDLogo extends React.Component {
  render() {
    const suffix = this.props.dark ? "_dark" : "";
    const linkClass = this.props.className || "";

    return (
      <Link
        to="/"
        className={`logo ${linkClass}`}
        style={this.props.wrapperStyle}>
        <img
          src={`/assets/images/logo${suffix}.png`}
          alt="CONXTD Logo"
          className="d-inline-block align-top conxtd-logo"
          style={{
            height: "60px",
            marginTop: "10px",
            ...this.props.style
          }}
        />
      </Link>
    );
  }
}

export default CONXTDLogo;
