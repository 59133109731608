/** 
 * Site integration location loader
 * 
 * Load available location for attaching to a site
 */
import { call } from "redux-saga/effects";

import {
    loadIntegrationLocationsStart,
    loadIntegrationLocationsFailed,
    loadIntegrationLocationsSuccess,
    loadIntegrationLocationsCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* loadIntegrationLocations({ camera_integration_site_id }) {
    try {
        const result = yield call (
            conxtdOut.get,
            `/CameraIntegrationSites/add/${camera_integration_site_id}`
        );
        
        return result;
    } catch(e) {
        throw new Error(e);
    } 
}

export function* loadIntegrationLocationsWatcher() {
    yield actionWatcher({
        actions: {
            start: loadIntegrationLocationsStart,
            failed: loadIntegrationLocationsFailed,
            success: loadIntegrationLocationsSuccess,
            cancel: loadIntegrationLocationsCancel
        },
        saga: loadIntegrationLocations
    });
}