import { createSelector } from "reselect";

import { getReport, getConfig } from "./index";

export const getEventCountFilter = createSelector(
  [getReport],
  report => {
    return report.filter.eventCount.show;
  }
);
export const getEventTypeFilter = createSelector(
  [getReport],
  report => report.filter.eventType.show
);

const filters = {
  eventType: getEventTypeFilter,
  eventCount: getEventCountFilter,
  none: () => {
    return {};
  }
};
export const getFilter = createSelector(
  [getConfig],
  config => {
    return filters[config.charts.pie.filter.type];
  }
);
