import React from "react";

import get from "lodash/get";

import { NA } from "services/redux/selectors";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";

const scheduledDefault = {
  // TODO: translate
  CUSTOM_ID: {
    Header: "Custom ID",
    width: 90,
    show: true,

    className: "custom-id",
    id: "custom-id",
    accessor: (row) => row.custom_ref || NA(),
  },
  NAME: {
    Header: "Site Name",
    minWidth: 180,
    show: true,
    Cell: SiteNameCell,
    className: "site-name",
    style: {
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
    id: "site-name",
    accessor: "name",
  },
  GROUP: {
    Header: "Group",
    width: 180,
    show: true,
    className: "group",
    id: "group",
  },
  SITE_REF: {
    Header: "Site Ref",
    width: 80,
    show: true,
    className: "site-ref",
    id: "site-ref",
    accessor: (row) => {
      return (
        get(row, "site_ref") ||
        get(row, "occurrences.0.site_ref") ||
        get(row, "wwo_id") ||
        get(row, "occurrences.0.wwo_id") ||
        NA()
      );
    },
  },
  TYPE: {
    Header: "Type",
    width: 120,
    show: false,
  },
  RESULT: {
    Header: "Result",
    width: 120,
    show: true,
    filterType: "contains",

    className: "alarm-type",
    headerStyle: {
      overflow: "visible",
    },
    id: "result",
    accessor: "occurrences",
  },

  EXPECTED: {
    Header: "Expected Time",
    width: 120,
    show: true,

    className: "expected",
    style: {
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
    id: "expected-time",
  },
  ACTUAL: {
    Header: "Actual Time",
    width: 120,
    show: true,

    className: "actual",
    id: "actual-time",

    accessor: "occurrences",
  },
  DIFFERENCE: {
    Header: "Difference",
    width: 120,
    show: true,

    id: "difference",
    className: "difference",
    accessor: "occurrences",
  },

  EVENT_TEXT: {
    Header: "Event Text",
    width: 120,
    show: true,

    className: "event-text",
    id: "event-text",
    Cell: ({ value }) => <span>{value}</span>,
  },
  OCCURRENCES: {
    Header: "Occurrences",
    minWidth: 120,
    show: true,

    className: "occurrences text-center",
    id: "occurrences",
  },
  // 08/21 add columns to support hourly report
  PERIOD: { 
    // Header: "Period",
    Header: ()=><div className="header-right">Period</div>,
    width: 90,
    show: false,

    filterable: false,
    className: "text-right",
    id: "period",
    style: {
      paddingRight: "1em"
    }
  },
  COUNT: { 
    // Header: "Count",
    Header: ()=><div className="header-right">Count</div>,
    width: 80,
    show: false,

    filterable: false,
    className: "text-right",
    id: "count",
    accessor: (row) => row.day ? row.day.value || NA() : NA(), //total count for day
    style: {
      paddingRight: "1em"
    }
  },
  VIEW_CHART: { 
    Header: "Chart",
    width: 90,
    show: false,

    filterable: false,
    className: "text-center",
    id: "view-chart",
  },
  // /08/21
  EXPAND: {
    Header: "Expand",
    minWidth: 65,
    show: true,

    filterable: false,
    className: "expander",
    id: "expander",
    accessor: "occurrences",

    style: {
      fontSize: 25,
      padding: "0",
      userSelect: "none",
    },
    sortMethod: (a, b) => {
      if (a.length === b.length) {
        return a > b ? 1 : -1;
      }
      return a.length > b.length ? 1 : -1;
    },
  },
  buildExportArray: ({
    data,
    getEventMeta,
    // getActualFromOccurrence,
    getDiffFromOccurrence,
    getActualWithTimezone,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Occurrences",

      "Result",
      "Expected Time",
      "Actual Time",
      "Difference",
      "Event Text",
      // "Occurrence"
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);

      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"] || "",
        row.group || "",
        row["site-ref"] || "",
        row.occurrences || "0",

        event.label || "",
        row["expected-time"] || "",
        // getActualFromOccurrence(get(row["actual-time"], 0)) || "",
        getActualWithTimezone(get(row["actual-time"], 0)) || "",
        getDiffFromOccurrence(get(row["difference"], 0)) || "",
        row["event-text"] || "",
        // "#" + (index + 1)
      ]);

      // And show occurrences below
      // const occurrences = get(row, "result");
      // occurrences.forEach((occurrence, index) => {
      //   const event = getEventMeta(occurrence);

      //   result.push([
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",

      //     event.label || "",
      //     row["expected-time"] || "",
      //     getActualFromOccurrence(get(row["actual-time"], 0)) || "",
      //     getDiffFromOccurrence(get(row["difference"], 0)) || "",
      //     row["event-text"] || "",
      //     "#" + (index + 1)
      //   ]);
      // });
    });

    return result;
  },
};

export default scheduledDefault;