/**
 * 09/21 - open close by area
 */

import { all } from "redux-saga/effects";

import * as openclosebyarea from "./openclosebyarea";

export function* rootSaga() {
  yield all([
    openclosebyarea.watchLiveRefresh(),
  ]);
}
