import React from "react";

import { withT } from "services/translation/";

import Loading from "components/Loading";
import ToggleButton from "components/Common/Buttons/Toggle";
import CircleIcon from "components/Icons/CircleIcon";
import { Tooltip } from "components/Common/Tooltip";

const COLORS = {
  setBlue: "#28498A",
  unsetLightblue: "#3397DB",
  noStateMidBlue: "#2B3E4E",
};

const SiteStatus = withT(({ value, original, onChangeSiteSet, t }) => {
  const { id, zoneState, open_close } = original;

  // toggle for remote open/close
  const remoteToggle = window.localStorage.getItem("remoteToggle");
  const siteId = window.localStorage.getItem("siteId");
  const parseSiteId = parseInt(siteId);

  const showSetToggle = open_close === "Y";

  const closed = value === "Locked";

  const color = COLORS[zoneState.colour] || "#666666";

  const site_ref = original.site_ref || original.wwoId;

  // If this property exists, it indicates a site's set state is changing
  if (open_close === "Y" && remoteToggle === "true" && id === parseSiteId) {
    return <Loading center />;
  }

  const iconType =
    value === "Locked" ? "lock" : value === "Unlocked" ? "unlock" : "dash";
  const Icon = (
    <span className="status-value">
      <CircleIcon
        type={iconType}
        color="transparent"
        size={48}
        foregroundColor={color}
      />
    </span>
  );

  const StatusIcon =
    value === "Unknown" ? (
      <Tooltip placement="left" description={t("unknown_site_status")}>
        {Icon}
      </Tooltip>
    ) : value === "Locked" || value === "Unlocked" ? (
      <Tooltip placement="left" description={t("padlock_site_status")}>
        {Icon}
      </Tooltip>
    ) : (
      Icon
    );

  return (
    <div className="site-status-container">
      {showSetToggle ? (
        <span className="remote-set-toggle">
          <ToggleButton
            size="sm"
            style={{ backgroundColor: color }}
            active={closed}
            onClick={(e) => {
              e.stopPropagation();
              // Toggle site set

              onChangeSiteSet({ set: closed, site_ref, id });
            }}
          />
        </span>
      ) : (
        StatusIcon
      )}
    </div>
  );
}, "site_list.data_table");

export { SiteStatus };
