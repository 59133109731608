import React, { useState } from "react";

import { Button } from "react-bootstrap";

import VideoLoftLiveView from "components/Common/Cameras/CameraList/VideoLoftLiveView";

function VideoLoftButtonView({
    imaging_server,
    data_server,
    stream_name,
    auth_token,
    camera_ref,
    subdomain,
    account_id,
    t
}) {

    const [showEagleEyeState, setShowEagleEyeState] = useState(null);

    return (
        <>
            <Button
                className="btn-block"
                variant="outline-primary"
                size="sm"
                style={{ marginTop: '26px', fontSize: '14px', padding: '8px 16px' }}
                onMouseEnter={() => (setShowEagleEyeState(true))}
                onMouseLeave={() => (setShowEagleEyeState(false))}
            >
                Live Camera View
        </Button>
            {showEagleEyeState && (
                <div className="eagle-eye-live-view-bv">
                    <VideoLoftLiveView
                        subdomain={subdomain}
                        auth_token={auth_token}
                        camera_ref={camera_ref}
                        stream_name={stream_name}
                        imaging_server={imaging_server}
                        data_server={data_server}
                        account_id={account_id}
                        src="buttonview"
                    />
                </div>
            )}
        </>
    );
}

export default VideoLoftButtonView;