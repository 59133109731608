import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PieChart from "components/Enterprise/Reports/Charts/PieChart";
import FilterCircles from "components/Analytics/Filters/FilterCircles";
import FilterLegend from "components/Analytics/Filters/FilterLegend";
import FilterException from "components/Analytics/Filters/FilterException";

import Loading from "components/Loading";
import _ from "lodash";

import { getFilter } from "services/redux/selectors/reports/common/filters";
import { getPieChartTotals } from "services/redux/selectors/reports/common/pie";
import {
  getConfig,
  getLoading
} from "services/redux/selectors/reports/common/";
import { getReportPath } from "services/redux/selectors/reports/router";

import { changeCurrentReportFilter } from "services/redux/actions/";

class Pie extends React.Component {
  render() {
    // const data = this.props.chart;
    const {
      totals,
      filterType,
      reportPath,
      pieType,
      noDataAvailableRenderer,
      noDataAvailableText
    } = this.props;

    const loading = this.props.loading === null;

    // First data hasn't arrived
    if (loading) {
      return <Loading center />;
    }

    const total = totals.data
      .map(item => item.value)
      .reduce((item, acc) => item + acc, 0);

    if (total === 0) {
      return noDataAvailableRenderer(noDataAvailableText);
    }

    // const chart = this.normalizeData(data);

    const { filter } = this.props;

    const filterComponent =
      this.props.filterComponent === "circles" ? (
        <FilterCircles
          filter={filter}
          reportPath={reportPath}
          disableFilter={this.props.overview}
        />
      ) : this.props.filterComponent === "legend" ? (
        <FilterLegend
          reportPath={reportPath}
          chart={totals}
          disableFilter={this.props.overview}
          filter={filter}
        />
      ) : this.props.filterComponent === "exception" ? (
        <FilterException reportPath={reportPath} />
      ) : null;

    const pie = (
      <PieChart
        pie={totals}
        filter={filter}
        pieType={pieType}
        changeFilter={label => {
          // Filtering isn't enabled on this report
          if (filterType === "none") {
            return;
          }
          this.props.dispatch(
            changeCurrentReportFilter({
              label,
              filterType,
              reportType: reportPath[1]
            })
          );
        }}
        showPct
        hideLegend={this.props.hideLegend}
      />
    );

    if ((this.props.overview && reportPath[1] === "overnight") || (this.props.overview && reportPath[1] === "suspicious")) {
      return this.props.config.overview.charts.pie.renderer({ filterComponent, pie });
    } else {
      return this.props.renderer({ filterComponent, pie });
    }
  }
}
Pie.propTypes = {
  totals: PropTypes.shape({
    data: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired
  }),
  reportPath: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  const config = props.config || getConfig(state, props);

  return {
    filter: getFilter(state, props)(state, props),
    pieType: config.charts.pie.type,
    filterComponent: config.charts.pie.filter.component,
    renderer: config.charts.pie.renderer,
    hideLegend: props.overview
      ? config.overview.charts.pie.hideLegend
      : props.hideLegend || config.charts.pie.hideLegend,
    filterType: config.charts.pie.filter.type,
    totals: getPieChartTotals(state, props)(state, props),
    loading: getLoading(state, props),
    reportPath: getReportPath(state, props),
    // Select a different renderer for the overview
    //  vs the actual report
    noDataAvailableRenderer: _.get(props, "overview")
      ? config.overview.noDataAvailableRenderer
      : config.noDataAvailableRenderer,
    noDataAvailableText: _.get(props, "overview")
      ? config.overview.noDataAvailableText
      : config.noDataAvailableText
  };
};
export default connect(mapStateToProps)(Pie);
