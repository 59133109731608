import React from "react";
import { connect } from "react-redux";

import Card from "./Card";

import { requestRemoveKeyholderNotified } from "services/redux/actions";

import Alert from "components/Common/Alert";

class CardList extends React.Component {
  componentDidUpdate() {
    const { dispatch, actions, t } = this.props;

    if (actions.remove.success) {
      dispatch(requestRemoveKeyholderNotified());

      Alert({
        text: t("edit.remove_success_message"),
        icon: "success"
      });
    }
  }
  render() {
    const { data, enterprise_site_id, t } = this.props;

    return data.length > 0 ? (
      data
        .sort(({ posA }, { posB }) => (posA < posB ? -1 : 1))
        .map((keyholder, index) => (
          <Card
            {...keyholder}
            keyholders={data}
            key={keyholder.uuid}
            enterprise_site_id={enterprise_site_id}
          />
        ))
    ) : (
      <div>{t("no_keyholders")}</div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    actions: state.sites.current.keyholders.actions
  };
};
export default connect(mapStateToProps)(CardList);
