import React from "react";

import SVGIcon from "./SVGIcon";

class CircleIcon extends React.Component {
  render() {
    const { color, size, foregroundColor = "#ffffff" } = this.props;
    return (
      <span
        className="circle-svg-icon d-inline-flex"
        style={{
          background: color,
          borderRadius: parseInt(size) + 50 + "px",
          width: size + "px",
          height: size + "px",
          margin: "auto"
        }}>
        <span
          className={`svg-icon conxtd-icon-${this.props.type || "default"}`}
          style={{
            display: "inline-block",
            width: "50%",
            height: "50%",
            margin: "auto"
            // width: "20px",
            // height: "20px",
            // padding: "4px",
            // position: "relative",
            // top: "-1px"
          }}>
          <SVGIcon
            {...this.props}
            color={foregroundColor}
            style={{ display: "block" }}
          />
        </span>
      </span>
    );
  }
}
export default CircleIcon;
