import React from "react";
import { connect } from "react-redux";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getUserActionsStart } from "services/redux/actions/enterpriseManager";

import DataTable from "./DataTable";

import moment from "services/locale/momentInit.js";

/**
 * Enterprise Management User Actions Tab
 *
 **/

class UserActions extends React.Component {

  componentDidMount() {

    const today = new moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0, });
    const lastMonth = new moment().subtract(1, "months").set({ hour: 0, minute: 0, second: 0, millisecond: 0, });

    this.props.dispatch(
      getUserActionsStart({
        enterprise_id: this.props.enterprise.id,
        params: {
          "start_date": lastMonth.format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
          "end_date": today.format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
        }
      })
    );
  }

  render() {

    return (
      <>
        <DataTable />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default connect(mapStateToProps)(UserActions);