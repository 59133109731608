import React from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { AssignedUser } from "./Cells/";

import get from "lodash/get";

import "./FilterSelect.scss";

class FilterSelect extends React.Component {
  render() {
    const { options, value, key = "typeaheadInput", onChange } = this.props;
    return (
      <Typeahead
        ref={r => {
          this.Typeahead = r;
        }}
        clearButton
        id="typeahead"
        inputProps={{
          key,
          id: "typeahead-input",
          autoComplete: "off"
        }}
        value={value}
        selected={(value && [{ name: value }]) || []}
        onChange={e => {
          onChange(get(e, "0.name") || "");
        }}
        renderMenuItemChildren={(option, props, index) => {
          if (option.underline) {
            return (
              <div className="d-flex align-items-center filter-item-undeline">
                <AssignedUser assignedUser={option.assignedUser} omitTooltip={true} />
                <span className="pl-2">{option.name}</span>
              </div>
            );
          } else {
            return (
              <div className="d-flex align-items-center">
                <AssignedUser assignedUser={option.assignedUser} omitTooltip={true} />
                <span className="pl-2">{option.name}</span>
              </div>
            );
          }
        }}
        labelKey="name"
        options={options}
        allowNew={true}
      />
    );
  }
}
export default FilterSelect;
