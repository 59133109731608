import React from "react";
import { connect } from "react-redux";

import Map from "./MarkerCluster";

import Loading from "components/Loading";

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { selectSite, user } = this.props;

    return (
      <div className="App">
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA-jbFCGQf6y9kL6s9wLkr3gVCRYV6hJjw&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={
            <div style={{ height: "100%" }}>
              {" "}
              <Loading centre />
            </div>
          }
          containerElement={<div style={{ height: `70vh`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          selectSite={selectSite}
          user={user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(MapView);