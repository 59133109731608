//alarm-response
import { watchLoadAlarms, watchAlarmsLiveRefresh } from "./alarms";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadAlarms(),
      watchAlarmsLiveRefresh(),
    ],
  );
}