// LOAD NOTIFICATIONS RESOURCES
export const loadNotificationsResourcesStart = params => {
  return { type: "LOAD_NOTIFICATIONS_RESOURCES_START", ...params };
};
export const loadNotificationsResourcesSuccess = params => {
  return { type: "LOAD_NOTIFICATIONS_RESOURCES_SUCCESS", ...params };
};
export const loadNotificationsResourcesFailed = params => {
  return { type: "LOAD_NOTIFICATIONS_RESOURCES_FAILED", ...params };
};
export const loadNotificationsResourcesCancel = params => {
  return { type: "LOAD_NOTIFICATIONS_RESOURCES_CANCEL", ...params };
};
