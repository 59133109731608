import { call, take, race } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import conxtdOut from "apis/conxtdOut";

/**
 * Event meta background loader
 */
export function* watchLoadBackground() {
  while (true) {
    const action = yield take("LOAD_EVENT_META_BACKGROUND_START");

    yield race({
      task: call(loadBackground, action),
      cancel: take([
        "LOAD_EVENT_META_BACKGROUND_CANCEL",
        "LOAD_EVENT_META_BACKGROUND_FAILED",
        "CANCEL_ALL"
      ])
    });
  }
}
export function* loadBackground() {
  const params = {
    reducerKey: "eventMeta",
    apiCall: call(conxtdOut.get, `/ConxtdEvents/index`),
    successAction: actions.loadEventMetaBackgroundSuccess,
    failedAction: actions.loadEventMetaBackgroundFailed,
    restoreAction: actions.loadEventMetaBackgroundRestore
  };

  yield cacheIndexes.restoreOrLoad(params);
}
