import React from "react";

import moment from "services/locale/momentInit.js";

import {
  getAcked,
  getTimeFormat,
  getRepresentativeOccurrence,
  // getActualFromOccurrence,
  sortNumber
} from "services/redux/selectors/reports/scheduled";

import ActualTimezoneDate from "components/Enterprise/Reports/Types/Scheduled/DataTable/ActualTimezoneDate.js";

import get from "lodash/get";

export function ActualColumn({ columns, eventTextFilter, getEventMeta }) {
  const column = columns["ACTUAL"];

  return {
    ...column,

    Cell: row => {
      const occurrence = getRepresentativeOccurrence({
        occurrences: row.original.occurrences,
        eventTextFilter,
        getEventMeta
      });

      // const value = getActualFromOccurrence(occurrence);

      // return <span>{value}</span>;

      return <ActualTimezoneDate occurrence={occurrence} />
    },
    filterMethod: (filter, row) => {
      const occurrence = get(row[filter.id], "0");
      // const acked = getAcked(occurrence);
      // const rowText = moment(acked).format(getTimeFormat());
      
      let rowText;
      if (occurrence.timezone) {
        const localDate = new moment(occurrence.acked);
        const timezoneDate = new moment.utc(localDate).tz(occurrence.timezone);
        const localTimezoneText = moment.tz.guess();
        const timezoneText = occurrence.timezone === localTimezoneText ? "" : occurrence.timezone;
        rowText = `${timezoneDate.format(getTimeFormat())} ${timezoneText}`
      } else {
        const acked = getAcked(occurrence);
        rowText = moment(acked).format(getTimeFormat());
      }
      
      return rowText.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
    },
    sortMethod: (siteA, siteB) => {
      // const ackedA = getAcked(get(siteA, "0"));
      // const ackedB = getAcked(get(siteB, "0"));

      const siteA0 = get(siteA, "0");
      const siteB0 = get(siteB, "0");

      let ackedA;
      let ackedB;
      
      if (siteA0.timezone) {
        const localDate = new moment(siteA0.acked);
        ackedA = new moment.utc(localDate).tz(siteA0.timezone);
      } else {
        ackedA = getAcked(siteA0);
      }

      if (siteB0.timezone) {
        const localDate = new moment(siteB0.acked);
        ackedB = new moment.utc(localDate).tz(siteB0.timezone);
      } else {
        ackedB = getAcked(siteB0);
      }

      // #204, put sites with no acked at bottom of sorting
      // Both have no acked
      if (!ackedA && !ackedB) return 0;
      // A has no acked but B is valid
      if (!ackedA && ackedB) return -1;
      // B has no acked but A is valid
      if (ackedA && !ackedB) return 1;

      // const a = moment(ackedA).unix();
      // const b = moment(ackedB).unix();
      const a = moment(ackedA).format("YYYYMMDDHHmm");
      const b = moment(ackedB).format("YYYYMMDDHHmm");
      return sortNumber(a, b);
    }
  };
}
