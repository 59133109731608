import { call, take, race, takeLeading, takeEvery } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import conxtdOut from "apis/conxtdOut";

import * as links from "./links";

import { liveRefreshFor, actionWatcher } from "services/redux/sagas/common";

import { mockEnabled } from "services/redux/selectors/";
import SiteViewMock from "./__mocks__/SiteViewMock";

/**
 * Sites background loader
 */
export function* watchLoadBackground() {
  while (true) {
    const action = yield take("LOAD_SITES_BACKGROUND_START");
    yield race({
      task: call(loadBackground, action),
      cancel: take([
        "LOAD_SITES_BACKGROUND_CANCEL",
        "LOAD_SITES_BACKGROUND_FAILED",
        "CANCEL_ALL"
      ])
    });
  }
}
export function* linksLoadStart() {
  // Groups - when site loading is finished, start loading groups
  yield takeLeading(
    // Pre-loading
    ["LOAD_SITES_BACKGROUND_START"],
    links.toGroupsBackgroundLoader
  );
}

export function* loadBackground(action) {
  if (!action.enterprise_id)
    throw new Error("enterprise_id is required for loading sites");
  const params = {
    action,
    reducerKey: "sites",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseSites/index/${action.enterprise_id}`
    ),
    successAction: actions.loadSitesBackgroundSuccess,
    failedAction: actions.loadSitesBackgroundFailed,
    restoreAction: actions.loadSitesBackgroundRestore,
    extraParams: { cacheSubKey: action.enterprise_id }
  };

  yield cacheIndexes.restoreOrLoad(params);
}

export function* watchLoadSiteView() {
  // while (true) {
  yield takeLeading("LOAD_SITE_VIEW_START", function*(action) {
    // const action = yield take("LOAD_SITE_VIEW_START");
    yield race({
      task: call(loadSiteView, action),
      cancel: take([
        "LOAD_SITE_VIEW_CANCEL",
        "LOAD_SITE_VIEW_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}
export function* loadSiteView(action) {
  if (!action.id) {
    throw new Error("site id is required for loading site view");
  }

  const params = {
    startAction: actions.loadSiteViewStart,
    startActionParams: action,
    successAction: actions.loadSiteViewSuccess,
    failedAction: actions.loadSiteViewFailed,
    apiCall: mockEnabled()
      ? call(() => {
          return {
            data: SiteViewMock
          };
        })
      : call(
          conxtdOut.get,
          `/EnterpriseSites/view/${action.id}/${action.timeframe || "24"}`
        ),
    interval: 30000,
    key: "site",
    refresh: false
  };

  yield liveRefreshFor(params);
}

export function* watchLoadNotificationHistory() {
  yield actionWatcher({
    actions: {
      start: actions.loadNotificationHistoryStart,
      failed: actions.loadNotificationHistoryFailed,
      success: actions.loadNotificationHistorySuccess,
      cancel: actions.loadNotificationHistoryCancel,
    },
    saga: loadNotificationHistoryRe,
  });
}

export function* loadNotificationHistoryRe(action) {
  try {
    const { enterprise_site_id, mct_alarm_log_id } = action;

    if (!enterprise_site_id || !mct_alarm_log_id) 
      throw new Error(
        "enterprise_site_id and mct_alarmLog_id are required for loading notification history"
      );

    const url = `/EnterpriseSites/notificationHistory/${enterprise_site_id}/${mct_alarm_log_id}`;

    const result = yield call(conxtdOut.get, url);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// live refresh saga

export function* watchLoadSiteViewLiveRefresh() {
  yield takeEvery("LOAD_SITE_VIEW_LIVE_REFRESH_START", function*(action) {
    yield race({
      task: call(LoadSiteViewLiveRefreshStart, action),
      cancel: take([
        "LOAD_SITE_VIEW_LIVE_REFRESH_CANCEL",
      ])
    });
  });
}

export function* LoadSiteViewLiveRefreshStart(action) {
  try {
    if (!action.id) {
      throw new Error("site id is required for loading site view");
    }

    const params = {
      startAction: actions.loadSiteViewLiveRefreshStart,
      startActionParams: action,
      // successAction: actions.loadSiteViewLiveRefreshSuccess,
      successAction: actions.loadSiteViewSuccess,
      failedAction: actions.loadSiteViewLiveRefreshFailed,
      apiCall: call(
        conxtdOut.get,
        `/EnterpriseSites/view/${action.id}/${action.timeframe || "24"}`
      ),
      interval: 30000,
      key: "site",
      refresh: true,
    };

    const result = yield liveRefreshFor(params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}