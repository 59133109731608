import React from "react";
import { connect } from "react-redux";

import { Row, Col, Card, Button, Form, FormGroup } from "react-bootstrap";

import ReactTable from "react-table";

import { loadDeveloperAppsStart } from "services/redux/actions/developers";

import Icon from "components/Icons/Icon";

import Alert from "components/Common/Alert";

import CopyToClipboard from "components/Site/SiteView/SiteOverview/Tabs/CopyToClipboard";

import AddAppModal from "./AddAppModal";
import EditAppModal from "./EditAppModal";

import conxtdOut from "apis/conxtdOut";

class Developers extends React.Component {
  state = {
    editAppModalShow: false,
    selectedAppDetails: null,
    addAppModalShow: false,
    addedAppDetails: null,
  }

  componentDidMount() {
    this.props.dispatch(
      loadDeveloperAppsStart()
    );
  }

  // clipboardCell = (id, cellText) => {
  //   return (
  //     <div className="d-flex justify-content-between">
  //       <div style={{ overflow: "hidden"}}>{cellText}</div>
  //       <div
  //         className="px-1"
  //         style={{cursor: "pointer"}}
  //         onClick={() => {
  //           navigator.clipboard.writeText(cellText);
  
  //           const clipboardIcon = document.getElementById(`clipboard_${id}`);
  
  //           clipboardIcon.classList.remove("fa-clipboard");
  //           clipboardIcon.classList.remove("far");
  //           clipboardIcon.classList.add("fa-clipboard-check");
  //           clipboardIcon.classList.add("fas");
            
  //           setTimeout( function() {
  //             clipboardIcon.classList.remove("fa-clipboard-check");
  //             clipboardIcon.classList.remove("fas");
  //             clipboardIcon.classList.add("fa-clipboard");
  //             clipboardIcon.classList.add("far");
  //           }, 200);
  //         }}
  //       >
  //         <Icon
  //           id={`clipboard_${id}`}
  //           className="far fa-clipboard fa-lg text-primary"
  //           title="Copy to clipboard"
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  addApp = (appNameElement, redirectURIElement) => {
    const registerApp = conxtdOut.post("/Oauth/registerApp", {
      app_name: appNameElement.value,
      redirect_uri: redirectURIElement.value,
    });

    registerApp.then((response) => {
      this.setState({
        addedAppDetails: response.data,
        addAppModalShow: true
      });

      appNameElement.value = "";
      redirectURIElement.value = "";

      this.props.dispatch(
        loadDeveloperAppsStart()
      );
      
    }).catch((error) => {
      console.log("Error details", error);
      Alert({
        text: "Issue creating App. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
    })
  }

  render() {
    const { apps } = this.props;

    const loadingMsg = "Complete the form to create your first app";

    const numberOfApps = (apps && apps.length) || 0;

    let editAppModalClose = () => this.setState({ editAppModalShow: false });

    let addAppModalClose = () => this.setState({ addAppModalShow: false });

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        show: false,
      },
      {
        Header: "Name",
        accessor: "app_name",
        width: 160,
      },
      {
        Header: "Client Id",
        accessor: "client_id",
        Cell: ({original}) => {
          return <CopyToClipboard id={`client_id_${original.id}`} cellText={original.client_id} />
          // return this.clipboardCell(`client_id_${original.id}`, original.client_id);
        }
      },
      {
        Header: "Redirect URI",
        accessor: "redirect_uri",
        Cell: ({original}) => {
          return <CopyToClipboard id={`redirect_uri_${original.id}`} cellText={original.redirect_uri} />
          // return this.clipboardCell(`redirect_uri_${original.id}`, original.redirect_uri);
        }
      },
      {
        Header: "Options",
        width: 75,
        filterable: false,
        sortable: false,
        Cell: ({ original }) => {
          return (
            <div className="d-flex justify-content-around">
              <Button
                style={{ width: "37px" }}
                onClick={() => {
                  this.setState({
                    selectedAppDetails: original,
                    editAppModalShow: true
                  });
                }}
              >
                <Icon className="fas fa-edit" title="Edit" style={{ textAlign: "center" }} />
              </Button>
            </div>
          )
        }
      }
    ];

    return (
      <>
        <Row>
          <h4 className="mt-0 pl-3 pb-2 header-title">Developers</h4>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <Row className="mx-0">
                <Card.Body className="col-sm-12">
                  <Card.Title>OAuth Apps</Card.Title>
                  
                  <ReactTable
                    columns={columns}
                    data={apps}
                    filterable
                    defaultFilterMethod={(filter, row) => {
                      return (
                        String(row[filter.id])
                          .toLowerCase()
                          .indexOf(filter.value.toLowerCase()) !== -1
                      );
                    }}
                    defaultPageSize={5}
                    showPagination={false}
                    noDataText={loadingMsg}
                    defaultSorted={[
                      {
                        id: "id",
                        desc: false,
                      },
                    ]}
                  />

                  <Card.Title className="mt-4">Create New OAuth App</Card.Title>
                  <Card.Subtitle className="mb-3 text-muted">{`Maximum of 5${numberOfApps >= 5 ? " reached" : ""}`}</Card.Subtitle>
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    this.addApp(e.target.app_name, e.target.redirect_uri);
                  }}>
                    <FormGroup controlId="app_name">
                      <Form.Label>App Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="App_name"
                        className="mb-3"
                        required
                        disabled={numberOfApps >= 5}
                      />
                    </FormGroup>
                    <FormGroup controlId="redirect_uri">
                      <Form.Label>Redirect URI</Form.Label>
                      <Form.Control
                        type="text"
                        name="Redirect_uri"
                        className="mb-3"
                        required
                        disabled={numberOfApps >= 5}
                      />
                    </FormGroup>
                    <Button
                      type="submit"
                      disabled={numberOfApps >= 5}
                    >
                      Create
                    </Button>
                  </Form>
                </Card.Body>
              </Row>
            </Card>
          </Col>
        </Row>
        <EditAppModal 
          show={this.state.editAppModalShow}
          onHide={editAppModalClose}
          appDetails={this.state.selectedAppDetails}
        />
        <AddAppModal 
          show={this.state.addAppModalShow}
          onHide={addAppModalClose}
          appDetails={this.state.addedAppDetails}
        />
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    apps: state.developers.apps,
  };
};

export default connect(mapStateToProps)(Developers);