import React from "react";
import { Row } from "react-bootstrap";

import moment from "services/locale/momentInit.js";

// import CircleIcon from "components/Icons/CircleIcon";

import "./OpenClose.scss";
import SVGIcon from "components/Icons/SVGIcon.js";

import TimezoneDate from "components/Common/TimezoneDate";

export default function AreaView({ icon, colour, area, status, day, ordinal, alarmDate, alarmTime, alarmTimeStamp, timezone, partial, open_close_state, text, area_names }) {

    // if (_.isEmpty(_.keys(noFull))) {
    //     return null;
    // }

    const close = status === 'Closed' ? 'Close' : 'Open';

    const partialTag = partial === true ? `Partial ${close}` : null;

    const alarmStatus = (
        (partialTag === null ? status : partialTag)
    );

    if (open_close_state !== false) {
        return (
            <>
                <div>
                    <Row className="open-close-row">
                        <div className="status-col">
                            <div className="status-icon-no-opcl">
                                <SVGIcon
                                    type={icon}
                                    color={colour}
                                />
                            </div>
                        </div>
                        <div className="time-col">
                            <Row style={{ paddingTop: "33px", paddingBottom: "33px" }}>
                                <span className="alarm-status">{status}</span>
                            </Row>
                        </div>
                    </Row>
                </div>
            </>
        )
    }

    const timeNow = new moment();
    const today = alarmTimeStamp.isSame(timeNow, "day");
    const yesterday = alarmTimeStamp.isSame(timeNow.subtract(1, "day"), "day");

    let date = null;
    if (today) {
        date = 'Today';
    }

    if (yesterday) {
        date = 'Yesterday';
    }

    if (today === false && yesterday === false) {
        date = day + ordinal + ' ' + alarmDate;
    }

    // const alarmTimezone = alarmTimeStamp && timezone && (new moment.utc(alarmTimeStamp).tz(timezone));
    // const alarmTimezoneTime = alarmTimezone ? alarmTimezone.format("HH:mm") : alarmTime;

    let area_name = area_names[area];

    let diplayText = text.substring(0, 30);

    return (
        <>
            <Row className="open-close-row">
                <div className="status-col">
                    <div className="status-icon">
                        <SVGIcon
                            type={icon}
                            color={colour}
                        />
                        {/* <CircleIcon
                            size="86"
                            type={icon}
                            foregroundColor={colour}
                            color="transparent"
                            className="responsive-circle-icon"
                        /> */}
                    </div>
                    <div className="area-number">{area}</div>
                </div>
                <div className="time-col">
                    <Row className="alarm-status-area-name-row">
                        <span className="alarm-status">{alarmStatus}</span>
                        <span className="area-name">{area_name}</span>
                    </Row>
                    <Row className="alarm-time-alarm-text-row">
                        <span className="alarm-date">
                          <TimezoneDate 
                            date={alarmTimeStamp} 
                            timezone={timezone} 
                            format="HH:mm" 
                            hideTimezone={true}
                          />
                        <span style={{ paddingLeft: "15px" }}>{diplayText}</span></span>
                    </Row>
                    <Row>
                        <span className="alarm-time">{date}</span>
                    </Row>
                </div>
            </Row>
        </>
    );
}