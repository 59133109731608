// import { call, takeLeading, put, delay } from "redux-saga/effects";
import { call, takeLeading, put } from "redux-saga/effects";

import conxtdOut from "apis/conxtdOut";

import {
  requestMoveKeyholderStart,
  requestMoveKeyholderFailed,
  requestMoveKeyholderSuccess,
  requestMoveKeyholderCancel,
  loadSiteKeyholdersStart
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

function* watchMoveKeyholder() {
  // Reload keyholders on successful move
  yield takeLeading("REQUEST_MOVE_KEYHOLDER_SUCCESS", function*(action) {
    yield put(
      loadSiteKeyholdersStart({
        id: action.startAction.params.enterprise_site_id
      })
    );
  });

  yield actionWatcher({
    actions: {
      start: requestMoveKeyholderStart,
      failed: requestMoveKeyholderFailed,
      success: requestMoveKeyholderSuccess,
      cancel: requestMoveKeyholderCancel
    },
    saga: function*(action) {
      yield call(
        conxtdOut.post,
        // moveUp or moveDown
        `/EnterpriseKeyholders/move${action.direction}`,
        action.params
      );
    }
  });
}

export { watchMoveKeyholder };
