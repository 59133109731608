import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withT } from "services/translation/";

import Loading from "components/Loading";

import { Row, Col } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import DataTable from "./DataTable";

import {
  loadUserManagementUserListStart,
  loadUserManagementUserListCancel
} from "services/redux/actions/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

/**
 * User Management UserList
 *
 * Loads and displays a list of users that have access
 * to an enterprise or a group for management
 *
 */
class UserList extends React.Component {
  loadUserList = props => {
    const { users, group, enterprise } = props;

    const newGroup = group.id !== this.props.group.id;
    const newEnterprise = enterprise.id !== this.props.enterprise.id;

    // Already loading
    if (users.loading === true) {
      return;
    }

    // Only load on new group
    if (newGroup || newEnterprise || users.loading === null) {
      this.props.dispatch(
        loadUserManagementUserListStart({
          enterprise_id: enterprise.id,
          group_id: group.id
        })
      );
    }
  };
  componentDidMount() {
    this.loadUserList(this.props);
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      this.loadUserList(nextProps);
    }
  };
  componentWillUnmount = () => {
    this.props.dispatch(loadUserManagementUserListCancel());
  };
  render() {
    const { users, roleId, t } = this.props;

    const loading = users.loading === true;

    return (
      <>
        <Row id="UserList">
          <Col>
            <h4 className="mt-0 pb-2 header-title">
              <Icon className="fas fa-users pr-1" />
              {t("title")}
              {loading && <Loading />}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable roleId={roleId} />
          </Col>
        </Row>
      </>
    );
  }
}
UserList.propTypes = {
  group: PropTypes.object.isRequired,
  enterprise: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(UserList),
  "user_management.user_list"
);
