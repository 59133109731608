import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { ButtonToolbar, DropdownButton, Dropdown  } from "react-bootstrap";

import { Link } from "react-router-dom";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import { Tooltip } from "components/Common/Tooltip/";

import Alert from "components/Common/Alert";

import { 
  loadSOPActionMapStart,
  clearSOPActionMap,
} from "services/redux/actions/sop";
import { 
  loadSOPCompletedReportStart,
  clearSOPCompletedReport,
  setSOPCompletedReportDisplayPageIndex,
} from "services/redux/actions/sopReports";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import {
  getGroupFromRoute,
  getGroups,
  getGroupById,
  getCustomRef,
} from "services/redux/selectors/groups";
import { getSOPCompletedReportData } from "services/redux/selectors";
// import { getActualWithTimezone } from "services/redux/selectors/sopReports";
// import { sortNumber } from "services/redux/selectors/reports/scheduled";
import { formatMstoMS } from "services/date/date";

import { AssignedUser, SiteGroup } from "../../Cells/";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import EventCell from "components/Common/DataTable/EventCell/";
// import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import SiteView from "components/Site/SiteView/";

import SOPSidePane from "components/Enterprise/AlarmResponse/SOPView/SOPSidePane";
import SOPView from "components/Enterprise/AlarmResponse/SOPView/SOPView";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import Icon from "components/Icons/Icon";

import { saveAs } from "file-saver";

import conxtdOut from "apis/conxtdOut";

import "./DataTable.scss";

// import moment from "services/locale/momentInit.js";

import _ from "lodash";

class DataTable extends React.Component {
  state = {
    showSidePane: false,
    selectedSite: null,
    bundle_id: null,
    selectedSOPRow: null,
    previousDataTableState: {
      page: 0,
      sorted: [],
      filtered: [],
    }
  };

  onSelectSite = site => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");
      this.setState({
        showSidePane: true,
        selectedSite: site,
        bundle_id: null,
      });
    }
    // Clicked a site that's already being viewed, close
    else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          showSidePane: false,
          selectedSite: null,
          bundle_id: null,
        });
      }, 600);
    }
  };

  onClickViewSOP = (row, sudo_state_id) => {
    const { enterprise, group } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;

    this.setState({
      selectedSOPRow: { 
        ...row,
      },
    });

    document.body.classList.add("sop-sidepane-active");
    document.body.classList.remove("sidepane-active");

    this.props.dispatch(
      loadSOPActionMapStart({
        enterprise_id: this.props.enterprise.id,
        group_id: group_id,
        sudo_state_id: sudo_state_id,
        sop_actioned_event_id: row.id,
        sop_action_map_id: row.sop_action_map_id || null,
      })
    );
  }

  onClickExport = () => {
    const { enterprise, group, requestParams } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;

    const getExport = conxtdOut.post(
      `/sop/sop-actions/actions-report-export/${enterprise.id}/${group_id}`,
      {
        ...requestParams,
      },
      {
          responseType: "arraybuffer"
      }

    );

    getExport.then((response) => {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      if (blob.size === 70) {
        Alert({
          text:
            "You will be sent an email which will contain your export",
          icon: "success",
          timerProgressBar: true,
          timer: 5000,
        });
      } else {
        saveAs(blob, "Completed Report.xlsx");
      }

    }).catch((error) => {
      console.log("error", error.message);
        Alert({
          text: "Export failed, please try again later.",
          icon: "error",
          timerProgressBar: true,
          timer: 5000,
        });
    })
  }

  // sortOccurrence = (occurrenceA, occurrenceB) => {
  //   let ackedA;
  //   let ackedB;
    
  //   if (occurrenceA.timezone) {
  //     const localDate = new moment(occurrenceA.acked);
  //     ackedA = new moment.utc(localDate).tz(occurrenceA.timezone);
  //   } else {
  //     ackedA = occurrenceA.acked;
  //   }

  //   if (occurrenceB.timezone) {
  //     const localDate = new moment(occurrenceB.acked);
  //     ackedB = new moment.utc(localDate).tz(occurrenceB.timezone);
  //   } else {
  //     ackedB = occurrenceB.acked;
  //   }

  //   const a = moment(ackedA).format("YYYYMMDDHHmm");
  //   const b = moment(ackedB).format("YYYYMMDDHHmm");
  //   return sortNumber(a, b);
  // }

  // dispatchLoadSOPCompletedReportStart(params) {
  //   this.props.dispatch(loadSOPCompletedReportStart(params));
  // }

  componentWillUnmount() {
    this.props.dispatch(
      clearSOPCompletedReport({})
    );
  }

  render() {

    const { selectedSite, selectedSOPRow, loading } = this.state;

    const { 
      enterprise,
      group,
      groups,
      selectedGroupOption,
      requestParams,
      completedReport,
      pagination,
      displayPageIndex,
    } = this.props;

    const { sortMap } = pagination;

    let group_id = selectedGroupOption || (group && group.id) || enterprise.root_group_id;
    if (group_id === "root") {
      group_id = enterprise && enterprise.root_group_id;
    }

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "CUSTOM_REF",
        Header: "Ref",
        id: "custom_ref",
        className: "text-center",
        width: 60,
        accessor: (original) => {
          if (!_.isEmpty(original.site)) {
            return getCustomRef(original.site);
          } 
        },
        sortable: false,
      },
      {
        key: "SITE_NAME",
        Header: "Site Name",
        id: "site_name",
        className: "site-name",
        accessor: original => original.site.name,
        sortable: false,
        Cell: (props) => {
          if (!_.isEmpty(props.original.site)) { 
            return (
              <SiteNameCell
                value={props.row.site_name}
                selectSite={() => {
                  this.onSelectSite(props.original.site);
                }}
              />
            )
          } 
        }
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        width: 85,
        accessor: "site_ref",
        sortable: false,
      },
      {
        key: "SITE_GROUP",
        Header: "Group",
        id: "site_group",
        className: "text-center",
        width: 65,
        sortable: false,
        accessor: ( props ) => {
          if (props.site && props.site.enterprise_groups && props.site.enterprise_groups.length > 0) {
            const siteGroup = getGroupById(groups, props.site.enterprise_groups[0].id);  // use the first group in the list, which should be the lowest in the group tree
            return {
              ...siteGroup,
              site_group_custom_ref: siteGroup ? getCustomRef({ custom_group_ref: siteGroup.custom_group_id, name: siteGroup.name}) : "",
            }
          } else {
            return null;
          }
        },
        Cell: ( {row} ) => {
          const { site_group } = row;
          if (site_group) {
            return (
              <SiteGroup
                siteGroup={site_group}
                placement="left"
              />
            )
          } else {
            return <></>
          }
        },
      },
      {
        key: "EVENT",
        Header: "Event",
        id: "event",
        className: "alarm-type",
        width: 190,
        accessor: original => original.eventMeta.caption,
        Cell: ({ original }) => {
          return <EventCell
            event={original.eventMeta}
          />
        }
      },
      {
        key: "TIME",
        Header: "Time",
        id: "time",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: original.mct_alarm_log.acked,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.time} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
        },
        // sortMethod: this.sortOccurrence,
        // filterMethod: (filter, row) => {
        //   return (
        //     String(getActualWithTimezone(row.time)) //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
        //       .toLowerCase()
        //       .indexOf(filter.value.toLowerCase()) !== -1
        //   );
        // },
      },
      {
        key: "ASSIGNED",
        Header: "Assigned",
        id: "assigned",
        className: "completed-assigned text-center",
        width: 80,
        sortable: false,
        accessor: (original) => original.sop_action_assigned_user,
        Cell: ({ row }) => {
          return <AssignedUser
            assignedUser={row.assigned}
            tooltipPlacement="top"
          />
        },
        // filterMethod: (filter, row) => {
        //   const { assigned } = row;
        //   if (
        //     String(assigned.first_name + " " + assigned.last_name)
        //       .toLowerCase()
        //       .indexOf(filter.value.toLowerCase()) !== -1
        //   ) {
        //     return true;
        //   } else if (
        //     String(assigned.first_name[0] + assigned.last_name[0])
        //       .toLowerCase()
        //       .indexOf(filter.value.toLowerCase()) !== -1
        //   ) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // },
        // sortMethod: (a, b) => {
        //   // sort by initials
        //   const initialsA = String(a.first_name[0] + a.last_name[0]).toLowerCase();
        //   const initialsB = String(b.first_name[0] + b.last_name[0]).toLowerCase();
        //   const compareInitials = initialsA.localeCompare(initialsB);
        //   if (compareInitials !== 0) {
        //     return compareInitials;
        //   } else {
        //     // if initials are the same, sort by full name
        //     const fullNameA = String(a.first_name + a.last_name).toLowerCase();
        //     const fullNameB = String(b.first_name + b.last_name).toLowerCase();
        //     return fullNameA.localeCompare(fullNameB);
        //   }
        // },
      },
      {
        key: "ACTIONED",
        Header: "Actioned",
        id: "actioned",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: original.created,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.actioned} />
        },
        // sortMethod: this.sortOccurrence,
        // filterMethod: (filter, row) => {
        //   return (
        //     String(getActualWithTimezone(row.actioned))
        //       .toLowerCase()
        //       .indexOf(filter.value.toLowerCase()) !== -1
        //   );
        // },
      },
      {
        key: "COMPLETED",
        Header: "Completed",
        id: "completed",
        width: 160,
        accessor: original => {
          const occurrence = {
            acked: (original.sop_close_down_log && original.sop_close_down_log.created) || null,
            timezone: original.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ( props ) => {
          const { original } = props;
          const rowData = { // re-format the data to be in a more similar format to that of the alarm response report
                            // so the SOP View slider can use it in the same way
            ...original,
            sudo_state: [
              {
                ...original.sudo_state,
                id: original.sudo_state_id,
                mct_alarm_log_id: original.mct_alarm_log_id,
                event_id: original.eventMeta.id,
                recvd: original.created,
                alarm_text: original.eventMeta.caption,
              }
            ]

          }
          return (
            <Link
              to="#"
              onClick={() => {
                this.onClickViewSOP(rowData, props.original.sudo_state_id); //change this back so it passes the sudo state array or whatever so same as in AR datatable
              }}
            >
              <ActualTimezoneDate occurrence={props.row.completed} />
            </Link>
          )
        },
        // sortMethod: this.sortOccurrence,
        // filterMethod: (filter, row) => {
        //   return (
        //     String(getActualWithTimezone(row.completed))
        //       .toLowerCase()
        //       .indexOf(filter.value.toLowerCase()) !== -1
        //   );
        // },
      },
      {
        key: "CLOSURE",
        Header: "Closure",
        id: "closure",
        width: 90,
        accessor: original => original.closeDownOption,
        sortable: false,
        Cell: ({ row }) => {
          let CloseDownType = "Other";
          if (row.closure.type === "R") {
            CloseDownType = "Real Alarm"
          } else if (row.closure.type === "F") {
            CloseDownType = "False Alarm"
          }
          return <Tooltip 
            description={row.closure.option}
            placement="left"
          >
            <span>{CloseDownType}</span>
          </Tooltip>
        },
        // filterMethod: (filter, row) => {
        //   const { closure } = row;
        //   if (
        //     closure.option
        //     .toLowerCase()
        //     .indexOf(filter.value.toLowerCase()) !== -1
        //   ) {
        //     return true;
        //   } else if (closure.type === "R") {
        //     if (
        //       String("Real Alarm")
        //         .toLowerCase()
        //         .indexOf(filter.value.toLowerCase()) !== -1
        //     ) {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   } else if (closure.type === "F") {
        //     if (
        //       String("False Alarm")
        //         .toLowerCase()
        //         .indexOf(filter.value.toLowerCase()) !== -1
        //     ) {
        //       return false;
        //     }
        //   } else if (closure.type === "U") {
        //     if (
        //       String("Other")
        //         .toLowerCase()
        //         .indexOf(filter.value.toLowerCase()) !== -1
        //     ) {
        //       return false;
        //     }
        //   }
        // },
        // sortMethod: (a, b) => {
        //   // sort by type
        //   const compareType = a.type.localeCompare(b.type);
        //   if (compareType !== 0) {
        //     return compareType;
        //   } else {
        //     // if type is the same, sort by option
        //     return a.option.localeCompare(b.option);
        //   }
        // },
      },
      {
        key: "RESPONSE",
        Header: "Response",
        id: "response",
        className: "text-right",
        width: 90,
        accessor: original => original.response,
        sortable: false,
        Cell: ({ row }) => {
          const response = row.response;
          // return <div className="mr-2">{(response >= 3600000) ? formatMstoDHMS(response): formatMstoHMS(response)}</div>
          // return <div className="mr-2">{formatMstoHMS(response)}</div>
          return <div className="mr-2">{formatMstoMS(response)}</div>
        }
      },
      {
        key: "DURATION",
        Header: "Duration",
        id: "duration",
        className: "text-right",
        width: 90,
        accessor: original => original.duration,
        sortable: false,
        Cell: ({ row }) => {
          const duration = row.duration;
          // return <div className="mr-2">{(duration >= 3600000) ? formatMstoDHMS(duration): formatMstoHMS(duration)}</div>
          // return <div className="mr-2">{formatMstoHMS(duration)}</div>
          return <div className="mr-2">{formatMstoMS(duration)}</div>
        }
      }
    ]

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    return (
      <div>
        <ErrorBoundary message="Error displaying completed action report">
          {/* <div className="card visual-data site-list"> */}
          <div className="card visual-data">
            {
              !mobile && <ButtonToolbar className="p-2">
                <DropdownButton
                  id="dropdown-export"
                  title="Export"
                  disabled={loading || (completedReport.length === 0)}
                >
                  <Dropdown.Item onClick={() => this.onClickExport()}>
                    <Icon className="far fa-file-excel" /> Excel
                  </Dropdown.Item>
                </DropdownButton>
                {/* <ExportDropdown
                  disabled={loading || (completedReport.length === 0)}
                  reportTitle={"Completed Action Report"}
                  dataResolver={() => {
                    const data = this.reactTable.getResolvedState().sortedData;
        
                    let result = data.map(row => {
                      // console.log(row);
                      const time = {
                        acked: row._original.mct_alarm_log.acked,
                        timezone: row._original.site.timezone,
                        format: "HH:mm ddd DD/MM/YYYY",
                      }
                      const actioned= {
                        acked: row._original.created,
                        timezone: row._original.site.timezone,
                        format: "HH:mm ddd DD/MM/YYYY",
                      }
                      const completed= {
                        acked: row._original.sop_close_down_log.created,
                        timezone: row._original.site.timezone,
                        format: "HH:mm ddd DD/MM/YYYY",
                      }
                      let closure = "Other";
                      if (row["closure"].type === "R") {
                        closure = "Real Alarm"
                      } else if (row["closure"].type === "F") {
                        closure = "False Alarm"
                      }
                      return [
                        row["custom_ref"] || "",
                        row["site_name"] || "",
                        row["site_ref"] || "",
                        row["event"] || "",
                        getActualWithTimezone(time), //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
                        row["assigned"] ? row["assigned"].first_name + " " + row["assigned"].last_name : "",
                        getActualWithTimezone(actioned),
                        getActualWithTimezone(completed),
                        closure
                      ];
                    });
                    result.unshift([
                      "Custom Ref",
                      "Site Name",
                      "Site Ref",
                      "Event",
                      "Time",
                      "Assigned",
                      "Actioned",
                      "Completed",
                      "Closure"
                    ]);
        
                    return result;
                  }}
        
                /> */}
              </ButtonToolbar>
            }
            <ReactTable
              ref={r => (this.reactTable = r)}
              columns={columns}
              data={completedReport}
              manual
              page={displayPageIndex}
              pages={pagination.pageCount}
              onPageChange={(pageIndex) => {
                this.props.dispatch(
                  setSOPCompletedReportDisplayPageIndex({
                    displayPageIndex: pageIndex, // keeps track of the page index that the table component displays (0-indexed)
                  })
                )
              }}
              sortable={completedReport.length > 0}
              onFetchData={_.debounce((state, instance) => {
              // onFetchData={(state, instance) => {
                if (state.data && state.data.length > 0) {

                  // when I started maintaining the page number using displayPageIndex,
                  // onFetchData somehow started being called twice each time you changed the page:
                  // once with the current page index and once with the new page index.
                  // to prevent hitting the api unnecessarily, first check that there has actually been a change in state
                  const dataTableState = {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered, 
                  }
                  if (!_.isEqual(dataTableState, this.state.previousDataTableState)) {
                    let sortMapField = null;
                    let sortDirection = null;

                    if (state.sorted && state.sorted[0]) {
                      sortMapField = sortMap[state.sorted[0].id] || null;
                      sortDirection = state.sorted[0].desc ? "desc" : "asc";
                    } 

                    // console.log("before debounce");//!!!
                    // _.debounce(() => {
                    //   console.log("in debounce");///!!!
                    //   this.props.dispatch.loadSOPCompletedReportStart({ 
                    //     enterprise_id: enterprise.id,
                    //     group_id,
                    //     params: requestParams,
                    //     pageSize: state.pageSize,
                    //     requestPageNumber: state.page + 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                    //     sortMapField,
                    //     sortDirection,
                    //   })
                    // }, 300);

                    // _.debounce(this.dispatchLoadSOPCompletedReportStart({ 
                    //     enterprise_id: enterprise.id,
                    //     group_id,
                    //     params: requestParams,
                    //     pageSize: state.pageSize,
                    //     requestPageNumber: state.page + 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                    //     sortMapField,
                    //     sortDirection,
                    //   }), 300);

                    this.props.dispatch(
                      loadSOPCompletedReportStart({ 
                        enterprise_id: enterprise.id,
                        group_id,
                        params: requestParams,
                        pageSize: state.pageSize,
                        requestPageNumber: state.page + 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                        sortMapField,
                        sortDirection,
                      })
                    );

                    this.setState({
                      previousDataTableState: dataTableState,
                    })
                  }
                }
              }, 300)} // debounce period
              // }}
              // filterable
              // defaultFilterMethod={(filter, row) => {
              //   return (
              //     String(row[filter.id])
              //       .toLowerCase()
              //       .indexOf(filter.value.toLowerCase()) !== -1
              //   );
              // }}
              defaultPageSize={20}
              noDataText={noDataMsg}
              // defaultSorted={[
              //   {
              //     id: "time",
              //     desc: true,
              //   },
              // ]}
            />
            <SiteView
              site={selectedSite}
              selectSite={this.onSelectSite}
              bundle_id={this.state.bundle_id}
              onSelectBundle={bundle_id => {
                // Bundle selected
                if (bundle_id) {
                  this.setState({ bundle_id });
                }
                // Invalid bundle id, deselect
                else {
                  this.setState({ bundle_id: null });
                }
              }}
            />
          </div>
        </ErrorBoundary>
        <div className="sopSidePane">
          <SOPSidePane
            title={selectedSOPRow ? selectedSOPRow.site.name : "View SOP"}
            onHide={() => {
              document.body.classList.remove("sop-sidepane-active");
              this.props.dispatch(
                clearSOPActionMap()
              );
            }}
            componentProps={{
              row: selectedSOPRow,
              enterpriseId: enterprise.id,
              groupId: group_id,
              closedAction: true,
            }}
            currentPage={0}
            Pages={[
              {
                Component: SOPView,
              },
              {
                backButtonText: "back",
                Component: SOPView,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groups: getGroups(state, props),
    completedReport: getSOPCompletedReportData(state, props),
    pagination: state.sopReports.completedReport.data.pagination,
    displayPageIndex: state.sopReports.completedReport.displayPageIndex,
    loading: state.sopReports.completedReport.loading,
  };
};
export default connect(mapStateToProps)(DataTable);