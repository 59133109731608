import moment from "services/locale/momentInit.js";

/**
 * roundTimeToNearest
 *
 * acked: moment,
 * roundingMethod: (string: "hour"|"15 mins")
 */
export const roundTimeToNearest = (acked, roundingMethod) => {
  // Round down to the nearest hour
  if (roundingMethod === "1 hour") {
    return moment(acked).startOf("hour");
  }
  // Round down to the nearest 15 minutes
  else {
    const time = acked;
    const roundedMinutes = Math.floor(time.clone().minute() / 15) * 15;
    return time
      .clone()
      .minute(roundedMinutes)
      .second(0);
  }
};
export const roundTimeToNearestOffset = (acked, roundingMethod) => {
  const timezoneOffset = (new Date()).getTimezoneOffset();
  const timezoneOffsetMinutes = Math.abs(timezoneOffset) % 60;

  const time = acked;

  if (time.minute() < timezoneOffsetMinutes) {
    return time
      .clone()
      .minute(timezoneOffsetMinutes)
      .second(0)
      .subtract(1, "h");
  } else {
    return time
      .clone()
      .minute(timezoneOffsetMinutes)
      .second(0);
  }
};
// Returns format string for moment().format method based
//  on interval rounding
export const getFormatMethod = (roundingMethod, userTimezoneUTCOffset) => {
  return (userTimezoneUTCOffset && userTimezoneUTCOffset.minutes && userTimezoneUTCOffset.minutes !== 0)
    ? "hh:mmA"
    : roundingMethod === "1 hour" ? "hhA" : "hh:mmA";
};
// Returns a human sensical string for the filter "tag"
export const showingOnlyText = ({ timeSegment, roundingMethod, userTimezoneUTCOffset }) => {
  // const format = getFormatMethod(roundingMethod);
  const format = getFormatMethod(roundingMethod, userTimezoneUTCOffset);
  
  const from = new moment(timeSegment);
  const to =
    (roundingMethod === "1 hour")
      ? new moment(timeSegment, format).add(1, "hour")
      : new moment(timeSegment, format).add(15, "minutes");

  if (userTimezoneUTCOffset.sign < 0) {
    from.subtract(userTimezoneUTCOffset.hours, "h").subtract(userTimezoneUTCOffset.minutes, "m");
    to.subtract(userTimezoneUTCOffset.hours, "h").subtract(userTimezoneUTCOffset.minutes, "m");
  } else {
    from.add(userTimezoneUTCOffset.hours, "h").add(userTimezoneUTCOffset.minutes, "m");
    to.add(userTimezoneUTCOffset.hours, "h").add(userTimezoneUTCOffset.minutes, "m");
  }
  return from.format(format) + " - " + to.format(format);
};

// Returns interval filter as a formatted string
export const getIntervalFilter = filter => {
  if (filter.timeSegment === null) return null;

  const format = getFormatMethod(filter.roundingMethod, filter.userTimezoneUTCOffset);
  return new moment(filter.timeSegment).format(format);
};
