import scheduled from "./scheduled/";
import defaultConfig from "./default/";
import exception from "./exception/";
import outstanding from "./outstanding/";
import openclosebyarea from "./openclosebyarea/"; // 09/21 - open close by area

const config = {
  default: defaultConfig,
  scheduled,
  exception,
  outstanding,
  openclosebyarea,
};

export default config;