import React from "react";
import { connect } from "react-redux";

import { Row, Col, Button } from "react-bootstrap";

import { Tooltip } from "components/Common/Tooltip/";
import Alert from "components/Common/Alert";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getEventPairMapsStart } from "services/redux/actions/enterpriseManager";

import DataTable from "./DataTable";

import AddEventPairModal from "./AddEventPairModal";

import conxtdOut from "apis/conxtdOut";

class PriorityEventsManagement extends React.Component {
  state = {
    forceUseEnterpriseEventPairs: false,
    addEventPairModalShow: false,
  }

  componentDidMount() {
    const { enterprise } = this.props;

    this.props.dispatch(
      getEventPairMapsStart({
        enterprise_id: enterprise.id,
      })
    )
  }

  render() {
    const { forceUseEnterpriseEventPairs } = this.state;

    const { enterprise, eventPairMaps } = this.props;

    const usingEnterpriseEventPairs = forceUseEnterpriseEventPairs || eventPairMaps.enterprise_event_pair_maps.length > 0;

    const addEventPairModalClose = () => this.setState({ addEventPairModalShow: false });

    const setForceUseEnterpriseEventPairs = (forceUseEnterpriseEventPairs) => {
      this.setState({ forceUseEnterpriseEventPairs: forceUseEnterpriseEventPairs });
    }

    return (
      <div>
        <Row className="align-items-center mt-2 mb-4 card-subtitle h6">
          <Col className="font-weight-bold">{usingEnterpriseEventPairs ? "Custom Priority Event list" : "Default Priority Event list" }</Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                this.setState({
                  addEventPairModalShow: true,
                });
              }}
              disabled={!usingEnterpriseEventPairs}
            >
              Add
            </Button>
            {
              !usingEnterpriseEventPairs &&
              <Tooltip
                description="Create a Custom list of Events"
                placement="top"
              >
                <Button
                  className="ml-2"
                  onClick={() => {
                    this.props.dispatch(
                      getEventPairMapsStart({
                        enterprise_id: enterprise.id,
                      })
                    );
                    setForceUseEnterpriseEventPairs(true)
                  }}
                >
                  Create Custom list
                </Button>
              </Tooltip>
            }
            {
              !usingEnterpriseEventPairs &&
                <Tooltip
                  description="Start a Custom list using the defaults"
                  placement="top"
                >
                  <Button
                    className="ml-2"
                    onClick={() => {
                      const addDefaultList = conxtdOut.post(`/sop/event-pair-maps/add-defaults/${enterprise.id}`);
        
                      addDefaultList.then(() => {
                        this.props.dispatch(
                          getEventPairMapsStart({
                            enterprise_id: enterprise.id,
                          })
                        );

                      }).catch((error) => {
                        console.log("error", error.message);
                        Alert({
                          text: "Duplicating default list failed, please try again later.",
                          icon: "error",
                          timerProgressBar: true,
                          timer: 5000,
                        });
                      });
                    }}
                  >
                    Duplicate list
                  </Button>
                </Tooltip>
            }
            {
              usingEnterpriseEventPairs && eventPairMaps.enterprise_event_pair_maps.length === 0 &&
                <Button
                  className="ml-2"
                  onClick={() => {
                    setForceUseEnterpriseEventPairs(false)
                  }}
                >
                  Back to Defaults
                </Button>
            }
          </Col>
        </Row>
        <div className="mt-4">
          <DataTable
            forceUseEnterpriseEventPairs={forceUseEnterpriseEventPairs}
            setForceUseEnterpriseEventPairs={setForceUseEnterpriseEventPairs}
          />
        </div>
        <AddEventPairModal 
          show={this.state.addEventPairModalShow}
          onHide={addEventPairModalClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    eventPairMaps: state.enterpriseManager.priorityEventsManagement.eventPairMaps,
  };
};

export default connect(mapStateToProps)(PriorityEventsManagement);