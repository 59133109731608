import _ from "lodash";

let actionLog = [];

const errorsReducer = (state = [], action) => {
  const message = _.get(action, "error.message") || "N/A";
  const stack = _.get(action, "error.stack") || "N/A";

  // Record action
  actionLog.unshift({ type: action.type });
  // Limit action log to 5
  if (actionLog.length > 5) {
    actionLog = actionLog.slice(0, 5);
  }

  switch (action.type) {
    case "UNDEFINED_ERROR":
      // Record undefined errors
      if (
        window.hostname === "pro.conxtd.com" ||
        window.hostname === "app.conxtd.com" ||
        action.testError
      ) {
        const metadata = {
          address: window.location.toString(),
          error_message: message,
          error_stack: stack,
          UA: window.navigator.userAgent,
          // Intercom doesn't allow nested data
          actionLog: JSON.stringify(actionLog)
        };
        window.Intercom("trackEvent", action.type, metadata);
      }
      return state;
    case "LOGIN_FAILED":
      return [
        ...state,
        {
          message: action.error.message
        }
      ];
    case "LOAD_ENTERPRISES_FAILED":
      // We cannot pass the whole error and still see it in redux dev tools
      //  so we just pass the message (TODO: get better error handling/logging - morgan?)
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_EXCEPTION_REPORT_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_OPEN_REPORT_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_SCHEDULED_REPORT_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_OUTSTANDING_REPORT_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_OUTSTANDING_REPORT_BUNDLE_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "LOAD_SITES_BACKGROUND_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    case "TRANSLATION_ERROR":
      return [...state, action];
    case "LOAD_GROUPS_FAILED":
      return [
        ...state,
        {
          message,
          stack
        }
      ];
    default:
      return state;
  }
};

export default errorsReducer;