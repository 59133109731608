import React from "react";

import SVGIcon from "components/Icons/SVGIcon";

// import { OverlayTrigger, Tooltip } from "react-bootstrap";

import moment from "services/locale/momentInit.js";

import { Tooltip } from "components/Common/Tooltip";

export const LatestAlarm = (props) => {
  const { t } = props;
  const { colour, icon, acked, caption, alarmDescription } = props.row
    .LatestAlarm || {
    icon: "",
    caption: "",
    colour: "#ffffff",
    acked: "",
  };

  const linkedCommentCount = (props.row.LatestAlarm && props.row.LatestAlarm.linkedCommentCount) || 0;

  const ackedMoment = acked && new moment(acked);
  const ackedMomentFromNow = acked && ackedMoment.fromNow();
  // const monthYear = acked && ackedMoment.format("LL");
  // const time = acked && ackedMoment.format("HH:mm");

  const timezone = props.original.timezone;
  const ackedTimezone = acked && (new moment.utc(ackedMoment).tz(timezone));
  const timezoneMonthYear = acked && ackedTimezone.format("LL")
  const timezoneTime = acked && ackedTimezone.format("HH:mm");

  const IconComponent = (
    <div
      className="svg-container"
      style={{
        padding: "4px",
        background: colour,
        borderRadius: "20px",
        margin: "auto",
        position: "relative",
      }}
    >
      <div
        id={icon}
        className={`svg-icon`}
        style={{
          width: "40px",
          height: "20px",
          margin: "0 auto",
          position: "relative",
          top: "-1px",
        }}
      >
        <SVGIcon type={icon} color={"#ffffff"} />
      </div>
      {(linkedCommentCount !== 0) && 
        <div>
          <div
            id={icon}
            className={`svg-icon`}
            style={{
              width: "40px",
              height: "20px",
              margin: "0 auto",
              position: "absolute",
              top: "-9px",
              right: "-20px",
              filter: "drop-shadow(3px 3px 3px rgb(0 0 0 / 0.5))",
            }}
          >
            <SVGIcon type={"comment"} color={"#ffffff"} />
          </div>
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-13px",
              width: "25px",
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            {linkedCommentCount}
          </div>
        </div>
      }
    </div>
  );

  if (!acked) {
    return (
      <Tooltip placement="left" description={t("no_alarms_in_timeframe")}>
        {IconComponent}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      placement="left"
      TooltipComponent={
        <div className="text-left p-1">
          <div className="pb-1">
            <span className="font-14 font-weight-bold pr-2">{caption} </span>
            <span
              className="float-right"
              style={{
                lineHeight: "24px",
              }}
            >
              {ackedMomentFromNow}
            </span>
          </div>
          {/* <div>{time}</div> */}
          <div>{timezoneTime}</div>
          {/* <div>{monthYear}</div> */}
          <div>{timezoneMonthYear}</div>
          <div style={{whiteSpace: "pre-wrap"}}>{decodeURIComponent(alarmDescription)}</div>
        </div>
      }
      className="text-left"
    >
      {IconComponent}
    </Tooltip>
  );
};
