import { call, put, takeLatest } from "redux-saga/effects";

import {
  requestIntegrationConnectFailed,
  requestIntegrationConnectSuccess
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchIntegrationConnect() {
  yield takeLatest("REQUEST_INTEGRATION_CONNECT_START", function*(action) {
    try {
      yield call(conxtdOut.post, "/integrations/add", action.params);

      yield put(requestIntegrationConnectSuccess());
    } catch (error) {
      yield put(
        requestIntegrationConnectFailed({
          error: {
            message: error.message,
            status: error.response && error.response.status,
            statusText: error.response && error.response.statusText
          },
          startAction: action
        })
      );
    }
  });
}