const exception = {
  panic: {
    overview: {
      index: 5,
    },

    type: "Panic",
    title: "Panic Exception",
    description: "Panic alarm test statistics.",

    charts: {
      pie: {
        type: "half",
        filter: {
          component: "exception",
        },
      },
    },
  },
  fire: {
    overview: {
      index: 6,
    },
    type: "Fire",
    title: "Fire Exception",
    description: "Fire alarm test statistics.",

    charts: {
      pie: {
        type: "half",
        filter: {
          component: "exception",
        },
      },
    },
  },
};

export default exception;