/**
 * Watcher for adding notification rules
 */

import { call } from "redux-saga/effects";

import {
  requestNotificationsAddRuleStart,
  requestNotificationsAddRuleFailed,
  requestNotificationsAddRuleSuccess,
  requestNotificationsAddRuleCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* addRule(action) {
  try {
    // yield call(() => {
    //   console.log(action.params);
    // });

    const result = yield call(
      conxtdOut.post,
      "/notifications/rules/save",
      action.params
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
}

export function* watchAddRule() {
  yield actionWatcher({
    actions: {
      start: requestNotificationsAddRuleStart,
      failed: requestNotificationsAddRuleFailed,
      success: requestNotificationsAddRuleSuccess,
      cancel: requestNotificationsAddRuleCancel
    },
    saga: addRule
  });
}
