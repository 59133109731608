import {
  call,
  takeLatest,
  takeEvery,
  race,
  take,
  all
} from "redux-saga/effects";

import * as actions from "services/redux/actions";

import { liveRefreshFor } from "services/redux/sagas/common";

import conxtdOut from "apis/conxtdOut";

import * as links from "./links";

/**
 * OUTSTANDING BUNDLE - watchLiveRefresh
 */
export function* watchLiveRefresh() {
  yield all([
    linksLoadStart(),
    takeEvery("LOAD_OUTSTANDING_REPORT_BUNDLE_START", function*(action) {
      yield race({
        task: call(loadStart, action),
        cancel: take([
          "LOAD_OUTSTANDING_REPORT_BUNDLE_CANCEL",
          "LOAD_OUTSTANDING_REPORT_BUNDLE_FAILED", //#failedRemoved
          "CANCEL_ALL_LIVE_REFRESH",
          "CANCEL_ALL"
        ])
      });
    })
  ]);
}

export function* linksLoadStart() {
  // Sites - when bundle starts loading, start a background task to load sites
  yield takeLatest(
    [
      // Depends on sites
      "LOAD_OUTSTANDING_REPORT_BUNDLE_START"
    ],
    links.toSitesBackgroundLoader
  );

  // Event meta data - when bundle starts loading, start a background task to load event meta
  yield takeLatest(
    [
      // Depends on event meta
      "LOAD_OUTSTANDING_REPORT_BUNDLE_START"
    ],
    links.toEventMetaBackgroundLoader
  );
}

/**
 * OUTSTANDING BUNDLE / loadStart (action)
 *
 * Bundle view for outstanding reports
 */
export function* loadStart(action) {
  const { interval, enterprise_id, group_id, bundle_id, key, refresh } = action;

  const params = {
    startAction: actions.loadOutstandingReportBundleStart,
    startActionParams: action,
    successAction: actions.loadOutstandingReportBundleSuccess,
    failedAction: actions.loadOutstandingReportBundleFailed,
    apiCall: call(
      conxtdOut.get,
      `/Reports/Outstanding/view/${enterprise_id}/${group_id}/${bundle_id}`
    ),
    interval,
    key,
    refresh: refresh || true
  };

  yield liveRefreshFor(params);
}
