import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import {
  getCountForMainPageSuccess,
  getCountForMainPageFailed,
  getCountForMainPageRestore,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchGetCounts() {
  yield takeLatest("GET_COUNT_FOR_MAIN_PAGE_START", function* (action) {
    yield race({
      task: call(getCountForMainPageStartRe, action),
      cancel: take([
        "GET_COUNT_FOR_MAIN_PAGE_CANCEL",
        "GET_COUNT_FOR_MAIN_PAGE_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* getCountForMainPageStartRe(action) {
  const { enterpriseId } = action;

  if (!action.enterpriseId)
    throw new Error("enterpriseId is required for this api call.");

  const params = {
    successActionParams: {
      enterpriseId,
    },
    reducerKey: "enterpriseManager",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseGroups/getGroupCounts/${enterpriseId}`
    ),
    successAction: getCountForMainPageSuccess,
    failedAction: getCountForMainPageFailed,
    restoreAction: getCountForMainPageRestore,
    extraParams: { cacheSubKey: action.enterpriseId },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
