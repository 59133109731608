import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

// import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Row, Col, Button, Card } from "react-bootstrap";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

import "./KeyholderCard.scss";

class KeyholderCard extends React.Component {

  render() {

    const { 
      enterprise,
      siteName, 
      siteId, 
      customRef, 
      name, 
      phone, 
      job_title, 
      uuid, 
      t, 
      initiateCall,
      callStatus,
    } = this.props;

    const callIcon = (
      <Icon 
        className="fas fa-phone" 
        style={{
          position: "relative",
          right: "4px", 
          bottom: "4px",
        }}
      />
    )

    return (
      <Card bg="light">
        <Row className="no-gutters">
          <Col xs="12" className="pl-0">
            <Card.Body className="py-2">
              <Row className="mb-2 no-gutters align-items-center">
                <Col sm="11">
                  <span className="keyholder-icon"><Icon className="fas fa-address-card fa-lg" /></span>
                  <span className="keyholder-name">{name}</span>
                </Col>
                <Col sm="1" className="call-button-container">
                  {
                    ((callStatus === "idle" || callStatus === "ended") && (enterprise.call_limits !== 100) && (enterprise.call_limits !== null))
                      ? <Button
                          className="button-call"
                          variant="success"
                          onClick={() => {
                            // initiateCall is passed down in the props from VoiceCallOptions via Keyholders
                            initiateCall(
                              phone, 
                              uuid, 
                              name, 
                              siteName, 
                              siteId, 
                              customRef
                            )
                          }}
                        >
                          {callIcon}
                        </Button>
                      : enterprise.call_limits === 100
                        ?
                          <Tooltip 
                            placement="top"
                            description="Top up required"
                          >
                            <div>
                              <Button
                                className="button-call"
                                variant="success"
                                style={{pointerEvents: "none"}}
                                disabled
                              >
                                {callIcon}
                              </Button>
                            </div>
                          </Tooltip>
                        : enterprise.call_limits === null
                          ?
                            <Tooltip 
                              placement="top"
                              description="Setup required"
                            >
                              <div>
                                <Button
                                  className="button-call"
                                  variant="success"
                                  style={{pointerEvents: "none"}}
                                  disabled
                                >
                                  {callIcon}
                                </Button>
                              </div>
                            </Tooltip>
                          :
                            <Button
                              className="button-call"
                              variant="success"
                              disabled
                            >
                              {callIcon}
                            </Button>
                  }
                </Col>
              </Row>
              <Row className="border-bottom mb-0 font-14 no-gutters">
                <Col sm="3">{t("job_title")}</Col>
                <Col sm="9" className="text-right text-muted">
                  {job_title}
                </Col>
              </Row>
              <Row className="mb-2 font-14 no-gutters">
                <Col sm="3">{t("phone")}</Col>
                <Col sm="9" className="text-right text-muted">
                  {phone}
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    actions: state.sites.current.keyholders.actions,
    loading: state.sites.current.keyholders.loading === true,
    callStatus: state.voiceCalls.callStatus,
  };
};
export default connect(mapStateToProps)(
  withT(KeyholderCard, "site_overview.side_pane.keyholders.edit")
);