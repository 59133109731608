import { all } from "redux-saga/effects";

import * as bundle from "./bundle";
import * as outstanding from "./outstanding";
import * as eventPairAcknowledgement from "./ eventPairAcknowledgement";

export function* rootSaga() {
  yield all([
    bundle.watchLiveRefresh(),
    outstanding.watchLiveRefresh(),
    eventPairAcknowledgement.watchEventPairAcknowledgement(),
  ]);
}
