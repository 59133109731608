import { createSelector } from "reselect";

import {
  getReportPath,
  getReportType,
} from "services/redux/selectors/reports/router";

import get from "lodash/get";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import merge from "deepmerge";

import DataTableConfig from "constants/DataTable/Reports/";

// const parentReportTypes = {
//   default: "default",
//   overnight: "scheduled",
//   open: "scheduled",
//   close: "scheduled",
//   fire: "scheduled",
//   bundle: "outstanding",
//   outstanding: "outstanding"
// };
// export const getParentReportType = createSelector(
//   [getReportType],
//   reportType => parentReportTypes[reportType]
// );

export const getReports = (state) => state.reports;

export const selectByPath = (reportPath, reports) => {
  return reportPath.reduce((accumulator, pathKey) => {
    return accumulator[pathKey];
  }, reports);
};
export const getReport = createSelector(
  [getReportPath, getReports],
  // Keep selecting into reports while we have paths
  (a, b) => {
    return selectByPath(a, b);
  }
  // return reports[parentType][reportType];
);

export const getColumns = createSelector([getReportType], (reportType) => {
  const columns = DataTableConfig[reportType] || {};
  // Overwrite defaults with any report specific data
  return merge(DataTableConfig["default"], columns);
});

export const getConfig = createSelector([getReport], (report) => {
  return report.config;

  // const defaultConfig = getLocalConfig(["default", "default"]);

  // return merge(defaultConfig, getLocalConfig(reportPath) || {});
});

export const getLoading = createSelector(
  [getReport],
  (report) => report.loading
);

export const getFiltered = createSelector(
  [getReport],
  (report) => get(report, "DataTable.filtered") || null
);

export const getFilteredActive = createSelector(
  [getFiltered, getConfig, createGetEventMetaById],
  (filtered, config, getEventMetaById) => {
    return config.dataTable.suggestedFilters.filter((event_id) => {
      return (
        filtered.filter((filter) => {
          const event = getEventMetaById(event_id);
          // Keep only filters that match "result" column
          //  and exactly match our suggested events
          return filter.id === "result" && event.caption === filter.value;
        }).length > 0
      );
    });
  }
);

/**
 * getReportsOverview
 *
 * Gets and sorts the reports overview based on the report's
 * config.overview.position
 *
 */
export const getReportsOverview = createSelector([getReports], (reports) => {
  // Monthly/daily section ordering
  let sectionOrder = 0;
  let reportPaths = [...reports.overview.all.reportPaths].sort((a, b) => {
    // Get reports from path
    const reportA = reports[a[0]][a[1]];
    const reportB = reports[b[0]][b[1]];

    // Get position from report config
    const positionA = reportA.config.overview.position;
    const positionB = reportB.config.overview.position;

    // if (a[0] === "exception" && b[0] !== "exception") {
    //   // Exception report is less than another - reverse

    //   if (positionA < positionB) {
    //     console.log("reversing", a, b);
    //     console.log("reversing", positionA, positionB);
    //     sectionOrder = -1;
    //   }
    //   if (positionA > positionB) {
    //     console.log("unreversing", a, b);
    //     console.log("unreversing", positionA, positionB);
    //     sectionOrder = 1;
    //   }
    // }

    if (positionA < positionB) {
      return -1;
    }
    if (positionA > positionB) {
      return 1;
    }
    return 0;
  });

  // When first report is monthly, reverse section order,
  // making "Monthly"" appear at the top
  if (reportPaths.length > 0 && reportPaths[0][0] === "exception")
    sectionOrder = -1;

  return {
    all: {
      ...reports.overview.all,
      // Overwrite sorted report paths
      reportPaths,
      // Add section order
      sectionOrder,
    },
  };
});
