import React from "react";
import PropTypes from "prop-types";

import TetherEmbedded from "./TetherEmbedded";

/**
 * List of integrations, key is a UUID
 * to allow for selecting component independent of integration id
 */
const integrationComponents = {
  "6e0d66c3-f008-404c-8f7b-3eab97d82ec7": TetherEmbedded
};

/**
 * Enhanced/embedded player based on integration
 */
function EnhancedClipView({ event, integrations }) {
  const { integration_id = 2 } = event;
  // Find corresponding integration data
  const integration = integrations.find(
    integration => integration.integration_id === integration_id
  );

  // No integration available
  if (!integration) return null;

  const noComponent = () => null;
  // Select appropriate integration component, defaulting to no component
  const ViewComponent = integrationComponents[integration.key] || noComponent;

  return <ViewComponent event={event} integration={integration} />;
}
EnhancedClipView.propTypes = {
  event: PropTypes.shape({
    integration_id: PropTypes.number.isRequired
  }),
  integrations: PropTypes.array.isRequired
};
export default EnhancedClipView;
