import React from "react";

import Loading from "components/Loading";

// import { Button } from "react-bootstrap";

import { withT } from "services/translation/";

import { connect } from "react-redux";

import CardList from "./CardList";

import {
    loadSiteModeSessionStart,
    // loadSiteModeSessionCancel,
} from "services/redux/actions";

class EngineersMode extends React.Component {

    loadModeSessions = (props) => {
        const { site } = props;

        this.props.dispatch(
            loadSiteModeSessionStart({
                id: site.id,
                mode_type: "E"
            })
        );
    }

    componentDidMount() {
        this.loadModeSessions(this.props);
    }

    render() {
        // const { site, data, loading, t } = this.props;
        const { data, loading, t, site } = this.props;

        if (loading !== false) {
            return (<Loading center />)
        }
        return (
            <>
                <CardList
                    data={data}
                    mode_type="E"
                    t={t}
                    site={site}
                />
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        data: state.sites.current.modes_view.data,
        loading: state.sites.current.modes_view.loading
    };
}

export default withT(
    connect(mapStateToProps)(EngineersMode),
    "site_overview.side_pane.mode_view"
)