// import REPORTS_CONFIG from "constants/Reports/config/";

const defaultState = {
  loading: null,
  data: {},
  // The paths to the visible reports in this overview
  reportPaths: [],
  templateId: "reports-overview",
  // Note: no config - add one later if necessary
  config: {}
};
const initialState = {
  default: defaultState,
  all: defaultState
};

const overview = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_REPORTS_OVERVIEW_CANCEL":
      return initialState;
    case "LOAD_REPORTS_OVERVIEW_START":
      let overview = action.refreshing
        ? {
            ...state[action.key],
            loading: true
          }
        : {
            ...defaultState,
            loading: true
          };
      return {
        ...state,
        [action.key]: overview
      };
    case "LOAD_REPORTS_OVERVIEW_FAILED":
      return {
        ...state,
        // Reset overview when failed, otherwise
        // we will show data from another group/report
        [action.key]: {
          ...initialState[action.key],
          loading: false
        }
      };
    case "LOAD_REPORTS_OVERVIEW_SUCCESS":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false
        }
      };
    default:
      return state;
  }
};

export default overview;