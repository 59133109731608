import React from "react";

import { Form, Col } from "react-bootstrap";

class NameField extends React.Component {
  render() {
    const { label, defaultValue, name, ...rest } = this.props;
    return (
      <Form.Group {...rest} controlId={"formGroup" + name} as={Col} md="6">
        <Form.Label>{label}</Form.Label>
        <Form.Control defaultValue={defaultValue} name={name} />
      </Form.Group>
    );
  }
}
export default NameField;
