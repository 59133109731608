import { all, takeLeading, takeLatest, put } from "redux-saga/effects";

import cacheIndexes from "../cacheIndexes";

import * as groups from "./groups";

import { loadSiteViewRefresh } from "services/redux/actions";

import { toSitesBackgroundLoader } from "services/redux/sagas/links";

export function* rootSaga() {
  // Enable caching
  yield takeLeading("LOAD_GROUPS_BACKGROUND_SUCCESS", cacheIndexes.cacheMe);
  // yield takeLeading("LOAD_GROUP_OVERVIEW_START", groups.loadGroupOverviewStart);

  // Link group stats to event meta
  yield takeLatest(
    ["LOAD_GROUP_OVERVIEW_START"],
    groups.links.toEventMetaBackgroundLoader
  );

  // Link group overview to site view refresh
  yield takeLatest(["LOAD_GROUP_OVERVIEW_START"], function*(action) {
    yield put(loadSiteViewRefresh(action));
  });

  // Link group overview to sites (and indirectly groups)
  yield takeLatest(["LOAD_GROUP_OVERVIEW_SUCCESS"], toSitesBackgroundLoader);

  yield all([
    groups.watchLoadBackground(),
    groups.watchLoadOverviewBackground()
  ]);
}
