import React from "react";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import { ButtonToolbar, Row, Col } from "react-bootstrap";

import { changeGroupTimeframe } from "services/redux/actions/";

import { SelectTimeframe } from "components/Common/Timeframe/SelectTimeframe";

import Timeline from "./Timeline";
import DataTable from "./DataTable";

import ViewAs from "components/Common/ViewAs/";
import LoadMore from "components/Common/LoadMore/";

/**
 * Site Events timeline/datatable bundle view
 */
class Events extends React.Component {
  state = {
    viewAs: "timeline"
  };
  changeViewAs = viewAs => {
    this.setState({ viewAs });
  };
  render() {
    const { t, site, bundle_id, dispatch } = this.props;
    const { alarmBundles } = site;
    const { viewAs } = this.state;

    const bundle = alarmBundles.find(({ id }) => id === parseInt(bundle_id));

    const loading = site.loading === null;

    if (!site || !alarmBundles || loading)
      return <Loading className="py-4" center />;

    const ViewComponent = viewAs === "timeline" ? Timeline : DataTable;
    let eventList;

    if (!bundle || bundle.events.length === 0) {
      eventList = (
        <>
          <div>{t("no_events_in_timeframe")}</div>
          <div className="mt-2">
            <LoadMore
              loading={site.loading}
              timeframe={site.timeframe}
              onLoadMore={timeframe => {
                dispatch(changeGroupTimeframe({ timeframe }));
              }}
            />
          </div>
        </>
      );
    } else {
      eventList = (
        <ViewComponent bundle={bundle} site={site} bundle_id={bundle_id} />
      );
    }

    return (
      <>
        <Row className="my-2">
          <Col md={12} className="d-flex">
            <h4 className="text-uppercase header-title float-left">
              {(bundle && bundle.title) || ""}
            </h4>

            <ButtonToolbar
              style={{
                margin: "auto 0 auto auto"
              }}>
              <SelectTimeframe
                className="mr-2"
                timeframe={site.timeframe}
                changeTimeframe={timeframe => {
                  dispatch(changeGroupTimeframe({ timeframe }));
                }}
              />
              <ViewAs
                options={[
                  {
                    key: "timeline",
                    Icon: <Icon className="fas fa-bars" />,
                    description: t("side_pane.events.timeline_button_tooltip")
                  },
                  {
                    key: "table",
                    Icon: <Icon className="fas fa-table" />,
                    description: t("side_pane.events.table_button_tooltip")
                  }
                ]}
                viewAs={viewAs}
                changeViewAs={this.changeViewAs}
              />
            </ButtonToolbar>
          </Col>
        </Row>

        <Row>
          <Col md={12}>{eventList}</Col>
        </Row>
      </>
    );
  }
}

export default withT(Events, "site_overview");
