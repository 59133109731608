import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BarStack from "./BarStack";
import Pie from "./Pie";
import Hourly from "./Hourly";
import DataTable from "./DataTable/";
import DayPicker from "components/Enterprise/Reports/Common/DayPicker/";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import ProgressBar from "components/Enterprise/Reports/Common/ProgressBar";

import TimeRange from "components/Common/TimelineRangeSlider";
import { format } from 'date-fns'

import moment from "services/locale/momentInit.js";

import { default as T } from "services/i18n/Translate";

import { Card } from "react-bootstrap";

import ActiveFilters from "components/Analytics/Filters/ActiveFilters/ActiveFilters";
import SuggestedFilters from "components/Analytics/Filters/SuggestedFilters/index";

import {
  // changeScheduledReportIntervalFilter,
  changeScheduledReportRoundingMethod,
  changeScheduledReportTimeSegment,
  changeCurrentReportFilter,
  setScheduledReportSelectedTimeRange,
} from "services/redux/actions/";

import {
  // showingOnlyText,
  getFormatMethod
  // getIntervalFilter
} from "services/redux/selectors/reports/scheduled/interval";
import {
  getScheduledReport,
  getAlarmTypes,
  getScheduledFilter,
} from "services/redux/selectors/reports/scheduled/";
import { getReportType } from "services/redux/selectors/reports/router";
import { getConfig } from "services/redux/selectors/reports/common/";

import "./PageLayout.scss";

// const FILTER_TYPES = {
//   open: {
//     "On time": true,
//     Early: true,
//     Late: true,
//     Failed: true,
//     Abnormal: true
//   },
//   close: {
//     "On time": true,
//     Early: true,
//     Late: true,
//     Pending: true,
//     Abnormal: true
//   }
// };

class PageLayout extends React.Component {

  state = {  
    timeRangeError: false,  
  }

  componentWillUnmount() {
    const { reportType } = this.props;
    this.props.dispatch(
      setScheduledReportSelectedTimeRange({
        reportType,
        selectedTimeRange : [],
      })
    );
  }
	
  timeRangeErrorHandler = ({ timeRangeError }) => {
    this.setState({ timeRangeError })  
  }

  changeTimeRange = selectedTimeRange => {
    const { reportType } = this.props;
    if ((selectedTimeRange.length === 2) 
        && (selectedTimeRange[0] instanceof Date && !isNaN(selectedTimeRange[0])) 
        && (selectedTimeRange[1] instanceof Date && !isNaN(selectedTimeRange[1])) 
    ) {
      this.props.dispatch(
        setScheduledReportSelectedTimeRange({
          reportType,
          selectedTimeRange,
        })
      );
    }
  } 

  changeFilter = label => {
    const { reportType } = this.props;
    const filterType = this.props.config.charts.pie.filter.type;

    this.props.dispatch(
      changeCurrentReportFilter({
        label,
        filterType,
        reportType
      })
    );
  };
  changeRoundingMethod = roundingMethod => {
    this.props.dispatch(
      changeScheduledReportRoundingMethod({
        roundingMethod,
        reportType: this.props.reportType
      })
    );
  };
  changeIntervalFilter = intervalFilterText => {
    // Convert text to moment
    const format = getFormatMethod(this.props.filter.interval.roundingMethod, this.props.filter.interval.userTimezoneUTCOffset);
    // Allows intervalFilter to be set to "no filter" state (null)
    const timeSegment =
      intervalFilterText && new moment(intervalFilterText, format);

    this.props.dispatch(
      changeScheduledReportTimeSegment({
        timeSegment,
        reportType: this.props.reportType
      })
    );
    // this.setState({ intervalFilter });
  };
  render() {
    const {
      report,
      reportType,
      ALARM_TYPES,
      loading,
      filter,
      config,
      timeRange,
    } = this.props;

    const { timeRangeError } = this.state 

    const { nextRefresh, lastRefresh } = report || {
      nextRefresh: null,
      lastRefresh: null
    };
    const { title, description } = config;

    const { roundingMethod } = filter.interval;

    const pie = (
      <ErrorBoundary message="This report currently cannot be shown. Please select another date.">
        <Pie />
      </ErrorBoundary>
    );
    const hourly = (
      <ErrorBoundary message="This report currently cannot be shown. Please select another date.">
        <Hourly />
      </ErrorBoundary>
    );
    const progressBar = (
      <ProgressBar
        className="pt-3"
        nextRefresh={nextRefresh}
        lastRefresh={lastRefresh}
      />
    );
    const dayPicker = (
      <DayPicker
        changeDate={this.props.changeDate}
        selectedDay={this.props.selectedDay}
      />
    );
    const selectedTimeRangeLabel = (
      <div className="text-center pt-2">
        <span>Selected Time Range: </span>
        {timeRange.selectedTimeRange[0]
          ? <span key={0}>{format(timeRange.selectedTimeRange[0], "d MMM, HH:mm")} - </span>
          : timeRange.startTime 
            ? <span key={0}>{format(timeRange.startTime, "d MMM, HH:mm")} - </span> 
            : ""}
        {timeRange.selectedTimeRange[1]
          ? <span key={1}>{format(timeRange.selectedTimeRange[1], "d MMM, HH:mm")}</span>
          : timeRange.endTime 
            ? <span key={1}>{format(timeRange.endTime, "d MMM, HH:mm")}</span> : ""}
      </div>
    );
    const timeRangeSlider = (
      <div className="row time-range-row">
        <TimeRange
          error={timeRangeError}  
          ticksNumber={24}
          // step={900000} // 15 min
          step={300000}  // 5 min
          // step={60000}  // 1 min
          selectedInterval={[
            (timeRange.selectedTimeRange[0] ? timeRange.selectedTimeRange[0] : timeRange.startTime),
            (timeRange.selectedTimeRange[1] ? timeRange.selectedTimeRange[1] : timeRange.endTime)
          ]}  
          timelineInterval={[timeRange.startTime, timeRange.endTime]}  
          onUpdateCallback={this.timeRangeErrorHandler}  
          onChangeCallback={this.changeTimeRange}
        />
      </div>
    )

    return (
      <div className={`${loading ? "loading" : ""}`}>
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-30">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="mt-0 header-title">
                      <T>{title}</T>
                    </h4>
                    <p className="text-muted m-b-30 d-inline-block text-truncate w-100">
                      <T>{description}</T>
                    </p>
                  </div>
                  <div className="col-lg-6 text-right">
                    <p>
                      {moment(this.props.selectedDay).format("dddd Do MMM")}
                    </p>
                  </div>
                </div>

                {/* 
                  08/21 Add new report type of 'hourly'
                  Previously all scheduled reports were pie 
                  This supports the new people counting hourly report
                */}
                {this.props.config.chartType === "hourly" ? (
                    config.firstSection.renderer({
                      hourly,
                      progressBar,
                      dayPicker,
                      report
                    })
                  ) : (
                    config.firstSection.renderer({
                      pie,
                      progressBar,
                      dayPicker,
                      report
                    })
                  )
                }
              </div>
            </div>
          </div>
        </div>

        {config.charts.barStack.show && (
          <div className="row">
            <div className="col-md-12 visual-data">
              <Card className="m-b-30">
                <BarStack
                  changeFilter={this.changeFilter}
                  reportType={reportType}
                  changeIntervalFilter={this.changeIntervalFilter}
                  changeRoundingMethod={this.changeRoundingMethod}
                />
              </Card>
            </div>
          </div>
        )}

        {config.dataTable.show && (
          <div className="row">
            <div className="col-md-12 visual-data">
              <Card className="m-b-30">
                {!loading && config.timeRangeSelection && selectedTimeRangeLabel}
                {!loading && config.timeRangeSelection && timeRangeSlider}
                <SuggestedFilters />
                <ActiveFilters
                  changeIntervalFilter={this.changeIntervalFilter}
                />
                <DataTable
                  key="sites-datatable"
                  getReportTitle={this.props.getReportTitle}
                  reportType={reportType}
                  filter={filter.eventType}
                  changeFilter={this.changeFilter}
                  roundingMethod={roundingMethod}
                  intervalFilter={filter.interval}
                  ALARM_TYPES={ALARM_TYPES}
                />
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PageLayout.propTypes = {
  reportType: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired
};

// data={report}
// reportType={reportType}
// ALARM_TYPES={ALARM_TYPES[reportType]}
const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    report: getScheduledReport(state, props),
    loading: getScheduledReport(state, props).loading,
    ALARM_TYPES: getAlarmTypes(state, props),
    filter: getScheduledFilter(state, props),
    config: getConfig(state, props),
    timeRange: getScheduledReport(state, props).timeRange,
  };
};
export default connect(mapStateToProps)(PageLayout);
