/**
 * 08/21 hourly type report 
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MixedChart from "components/Enterprise/Reports/Charts/MixedChart";

import Loading from "components/Loading";
import _ from "lodash";

import { getCombinedData, getSelectedChartSiteData, getSelectedChartSiteName } from "services/redux/selectors/reports/common/hourly";
import {
  getConfig,
  getLoading
} from "services/redux/selectors/reports/common/";
import { getReportPath, getReportType } from "services/redux/selectors/reports/router";

import { removeScheduledReportHourlySelectedChartSite } from "services/redux/actions/";

class Hourly extends React.Component {
  render() {
    // const data = this.props.chart;
    const {
      lineData,
      noDataAvailableRenderer,
      noDataAvailableText
    } = this.props;

    const loading = this.props.loading === null;

    // First data hasn't arrived
    if (loading) {
      return <Loading center />;
    }

    const total = lineData.data
      .map(item => item.value) 
      .reduce((item, acc) => item + acc, 0);

    if (total === 0) {
      return noDataAvailableRenderer(noDataAvailableText);
    }

    if (this.props.overview) {
      // remove the selected site data
      this.props.dispatch(
        removeScheduledReportHourlySelectedChartSite({
          reportType: this.props.reportType
        })
      );
    }

    // use a mixed-type chart - it will render both a line graph and a bar chart
    const hourly = ( 
      <MixedChart  {...this.props} />
    );

    return this.props.renderer({ hourly }); 
  }
}
Hourly.propTypes = {
  lineData: PropTypes.shape({
    data: PropTypes.array.isRequired
  }),
  reportPath: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  const config = props.config || getConfig(state, props);

  return {
    renderer: config.charts.hourly.renderer,
    hideLegend: props.overview
      ? config.overview.charts.hourly.hideLegend
      : props.hideLegend || config.charts.hourly.hideLegend,
    hideAxisLabels: props.overview
      ? config.overview.charts.hourly.hideAxisLabels
      : props.hideAxisLabels || config.charts.hourly.hideAxisLabels,
    lineData: getCombinedData(state, props)(state, props), 
    barData: getSelectedChartSiteData(state, props)(state, props),
    siteName: getSelectedChartSiteName(state, props)(state, props),
    loading: getLoading(state, props),
    reportPath: getReportPath(state, props),
    reportType: getReportType(state, props),
    // Select a different renderer for the overview
    //  vs the actual report
    noDataAvailableRenderer: _.get(props, "overview")
      ? config.overview.noDataAvailableRenderer
      : config.noDataAvailableRenderer,
    noDataAvailableText: _.get(props, "overview")
      ? config.overview.noDataAvailableText
      : config.noDataAvailableText
  };
};
export default connect(mapStateToProps)(Hourly);