import { NA } from "services/redux/selectors";

import { getGroupPathFromSite } from "services/redux/selectors";

export function GroupColumn({ columns, groups }) {
  const column = columns["GROUP"];

  return {
    ...column,
    accessor: site => getGroupPathFromSite(groups, site) || NA()
  };
}
