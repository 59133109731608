import React from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

import Loading from "components/Loading";

import Alert from "components/Common/Alert";

import { withT } from "services/translation/";

import {
  // Row,
  // Col,
  InputGroup,
  FormControl,
  Form,
  Button
} from "react-bootstrap";

import { requestUserManagementSearchStart } from "services/redux/actions";

function validateEmail(email) {
  // eslint-disable-next-line no-control-regex
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
    email
  );
}

class UserSearch extends React.Component {
  searchUser = e => {
    e.preventDefault();

    const { users, t } = this.props;

    const email = this.getEmail();

    // Domain should be selected and isn't
    if (this.domain && this.domain.value === "-1") return;

    // User already invited
    if (users.current.users.some(user => email === user.email)) {
      Alert({
        text: t("user_already_invited", email),
        icon: "info"
      });
      return;
    }

    const emailValid = validateEmail(email);

    if (!emailValid) return;

    this.props.dispatch(
      requestUserManagementSearchStart({
        email
      })
    );
  };

  getEmail = () => {
    if (this.email_full) {
      return (this.email_full && this.email_full.value) || "";
    }
    const email = (this.email && this.email.value) || "";
    const domain = (this.domain && this.domain.value) || "";
    return `${email}@${domain}`;
  };

  render() {
    const { t, users } = this.props;

    const domains = [t("select_domain"), ...users.current.options.domains];

    const domainSelect = (
      <Form.Control
        className="custom-select pl-1"
        ref={r => (this.domain = r)}
        name="domain"
        as="select"
        style={{
          borderRadius: "0 4px 4px 0",
          borderLeft: "none"
        }}>
        {domains.map((domain, index) => (
          <option value={index === 0 ? "-1" : domain} key={index}>
            {domain}
          </option>
        ))}
      </Form.Control>
    );

    // Empty first entry means text input for domains
    const emailComponent =
      (!users.current.options.domains || users.current.options.domains.length === 0 || users.current.options.domains[0] === "") ? (
        <FormControl
          className="px-1"
          name="email-prefix"
          ref={r => (this.email_full = r)}
          type="text"
          style={{
            maxWidth: "260px",
            borderRadius: "4px"
          }}
        />
      ) : (
        <>
          <FormControl
            onChange={() => {
              if (this.email.value.includes("@")) {
                this.email.value = this.email.value.split("@")[0];
              }
            }}
            className="px-1"
            name="email-prefix"
            ref={r => (this.email = r)}
            type="text"
            style={{
              textAlign: "right",
              borderRight: "none",
              maxWidth: "160px"
            }}
          />

          <InputGroup.Text
            className="px-0"
            style={{
              fontSize: "13px",
              background: "transparent",
              borderLeft: "none",
              borderRight: "none",
              borderRadius: "0"
            }}>
            @
          </InputGroup.Text>
          <InputGroup.Append>{domainSelect}</InputGroup.Append>
        </>
      );

    const { loading } = users.actions.search;

    return (
      <>
        <p>{t("search_description")}</p>

        <Form onSubmit={this.searchUser}>
          <Form.Label>{t("email_address_input_label")}</Form.Label>
          <InputGroup className="mb-3">
            {emailComponent}
            <Button
              variant="primary"
              type="submit"
              className="ml-3"
              disabled={loading}
              // disabled={resetPassword.loading === true || !emailValid}
            >
              {t("button_search_user")}
              {loading && <Loading />}
            </Button>
          </InputGroup>
        </Form>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    users: state.users
  };
};

export default withT(
  connect(mapStateToProps)(UserSearch),
  "user_management.user_invite"
);
