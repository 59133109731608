import React from "react";
import { connect } from "react-redux";

import {
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { Button, Form } from "react-bootstrap";

import Alert from "components/Common/Alert";

import moment from "services/locale/momentInit.js";

import "./Steps.scss";

class DispatchKeyholderStep extends React.Component {
  state = {
    updatingAction: false,
    escalatingAction: false,
    deEscalatingAction: false,
    dispatchingKeyholder: false,
  };

  dispatchKeyholder(sopActionedEventId, sudoStateId, comment) {
    return new Promise(async (resolve, reject) => {

      const { handleAmendAction } = this.props;

      handleAmendAction(
        sopActionedEventId,
        sudoStateId,
        "A",
        "DI",
        null,
        comment,
        false
      ).then(() => {

        resolve();

      }).catch((error) => {
        reject(error);
      })
    })
  }

  render() {
    const {
      sop,
      sopActionedEventId,
      sudoStateId,
      step,
      stepNumber,
      activeStep,
      closedAction,
      firstStep,
      lastStep,
      isAssignedUser,
      handleAmendAction,
      handleEscalateAction,
      handleDeEscalateAction,
      darkMode,
    } = this.props;

    let lastLogStatus = "";
    if (step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0) {
      // has logs
      const lastLog = step.sop_action.sop_actioned_logs[step.sop_action.sop_actioned_logs.length - 1];
      lastLogStatus = lastLog.status;
    }

    return (
      <VerticalTimelineElement
        id={`step-${stepNumber}`}
        className="sop-action-map-timeline-element vertical-timeline-element--work py-4 my-2"
        contentStyle={
          activeStep
            ? darkMode
              ? { border: "1px solid #bbb", backgroundColor: "#0f1f3e" }
              : { border: "1px solid #bbb" }
            : darkMode
              ? { border: "1px solid #bbb", backgroundColor: "#233358" }
              : { border: "1px solid #bbb", backgroundColor: "#eee" }
        }
        contentArrowStyle={{ borderRight: "7px solid #bbb" }}
        icon={<div className="step-number-icon">{stepNumber}</div>}
        iconStyle={{ background: "#2c3e50", marginTop: "2em" }}
      > 
        {/* step title */}
        <h6 className="vertical-timeline-element-title">{(step.code === "C1") ? step.custom_desc : step.step}</h6> 

        {/* step description */}
        {/* <div className="my-1">{step.custom_desc}</div>   */}

        {(!step.can_dispatch) && <div className={`row my-1 py-1 ${activeStep ? "bg-warning" : "bg-muted"} text-white justify-content-center sop-keyholder-message`}>No property associated</div>}
        
        {/* status logs */}
        {step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0 &&
          <div>
            <div className="mt-2" style={{ fontWeight: "bold" }}>Logs</div>
            {
              step.sop_action.sop_actioned_logs.map((logItem, logItemIndex) => {

                const logContactStatusKey = logItem.status_key; // e.g. 'default'
                const logContactStatuses = sop.contactStatus[logContactStatusKey];  // the contact status object associated with the contact key,
                                                                                    // e.g. { {IP: 'In Progress}, {CO : 'Complete'} }

                const logContactStatus = logItem.status; // e.g. 'IP'

                const created = new moment(logItem.created);

                return (
                  <div key={logItemIndex}>
                    {/* status log */}
                    <div 
                      className="d-flex justify-content-between"
                      style={{borderBottom: "1px solid lightgray"}}
                    >
                      {/* show the text associated with the contact status of the log, e.g. 'In Progress' */}
                      <div>{logContactStatuses[logContactStatus]}</div>
                      <div 
                        className="text-muted"
                        style={{minWidth: "130px", textAlign: "right"}}
                      >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                    </div>

                    {/* job number for Dispatch Requested (Awaiting Confirmation) status */}
                    {logItem.status === "AC" &&
                      <div 
                        className="ml-2 d-flex justify-content-between"
                        style={{borderBottom: "1px solid lightgray"}}
                      >
                        <div>{`Job Number: ${logItem.system_data.number}`}</div>
                        <div 
                          className="text-muted"
                          style={{minWidth: "130px", textAlign: "right"}}
                        >{new moment(logItem.system_data.create_date).format("HH:mm:ss DD/MM/YYYY")}</div>
                      </div>
                    }

                    {/* any errors */}
                    {logItem.sop_contact_logs 
                      && logItem.sop_contact_logs.length > 0 
                      && logItem.sop_contact_logs.map((contactLog, contactLogIndex) => {
                        if (contactLog.status === "ER") {
                          const created = new moment(contactLog.created);

                          return (
                            <div 
                              key={logItemIndex + "-" + contactLogIndex}
                              className="ml-2 d-flex justify-content-between text-danger"
                              style={{borderBottom: "1px solid lightgray"}}
                            >
                              <div>{contactLog.error}</div>
                              <div 
                                className="text-danger"
                                style={{minWidth: "130px", textAlign: "right"}}
                              >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                            </div>
                          )
                        } else {
                          return null;
                        }
                      })
                    }

                    {/* associated comments */}
                    {logItem.sop_actioned_comments 
                      && logItem.sop_actioned_comments.length > 0 
                      && logItem.sop_actioned_comments.map((comment, commentIndex) => {

                        const created = new moment(comment.created);

                        return (
                          <div 
                            key={logItemIndex + "-" + commentIndex}
                            className="ml-2 d-flex justify-content-between"
                            style={{borderBottom: "1px solid lightgray"}}
                          >
                            <div>{decodeURIComponent(comment.comment)}</div>
                            <div 
                              className="text-muted"
                              style={{minWidth: "130px", textAlign: "right"}}
                            >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                          </div>
                        )
                      })}
                  </div>
                )
              })
            }
            {!closedAction && <hr />}
          </div>
        }

        
        {step.can_dispatch &&
          <div className="d-flex justify-content-center my-3">
            <Button 
              id={"sop-dispatch-button" + stepNumber} 
              className="sop-dispatch-button mx-1 btn-success"
              disabled={!activeStep || !isAssignedUser || (lastLogStatus === "AC" || this.state.dispatchingKeyholder)}
              onClick={() => {
                if (!this.state.dispatchingKeyholder) {

                  this.setState({ dispatchingKeyholder: true });

                  const dispatchButton = document.getElementById("sop-dispatch-button" + stepNumber);
                  dispatchButton.classList.add("cursor-progress");

                  const commentElement = document.getElementById("comment" + stepNumber);

                  this.dispatchKeyholder(
                    sopActionedEventId,
                    sudoStateId,
                    commentElement.value,
                  ).then(() => {

                    commentElement.value = "";
                    dispatchButton.classList.remove("cursor-progress");
                    this.setState({ dispatchingKeyholder: false });

                  }).catch((error) => {
                    console.log("Error on dispatch keyholder: ", error.message);
                    Alert({
                      text: "Issue updating action. Please try again later.",
                      icon: "warning",
                      showConfirmButton: true,
                    });

                    dispatchButton.classList.remove("cursor-progress");
                    this.setState({ dispatchingKeyholder: false });
                  })
                }
              }}
            >Dispatch</Button>
          </div>
        }
          

        {
          // form for amending action
          !closedAction && <Form onSubmit={(event) => {
            event.preventDefault();

            if (!this.state.updatingAction) {
              const commentElement = document.getElementById("comment" + stepNumber);
              const amendActionButton = document.getElementById("sop-add-comment-button" + stepNumber);

              if (commentElement.value !== "") {
                this.setState({ updatingAction: true });
                amendActionButton.classList.add("cursor-progress");

                handleAmendAction(
                  sopActionedEventId,
                  sudoStateId,
                  "A",
                  step.sop_action.status,
                  null,
                  event.target.comment.value,
                  true
                ).then(() => {

                  commentElement.value = ""; 
                  amendActionButton.classList.remove("cursor-progress");
                  this.setState({ updatingAction: false });

                }).catch((error) => {
                  console.log("Error on amend action: ", error.message);
                  Alert({
                    text: "Issue adding comment. Please try again later.",
                    icon: "warning",
                    showConfirmButton: true,
                  });

                  amendActionButton.classList.remove("cursor-progress");
                  this.setState({ updatingAction: false });

                })
              }
            }
          }}>

            {/* comment box */}
            <textarea
              id={"comment" + stepNumber} 
              name="comment" 
              rows="3"
              className="mt-1 form-control sop-comment"
              style={{width: "100%"}}
              disabled={!activeStep || !isAssignedUser}
              required
            ></textarea>

            {/* amend buttons */}
            <div className="d-flex justify-content-end mt-2">

              {/* add comment button */}
              <Button 
                id={"sop-add-comment-button" + stepNumber} 
                className="sop-add-comment-button mx-1 btn-primary"  
                type="submit"
                disabled={!activeStep || !isAssignedUser}
              >Add Comment</Button>

            </div>

            <div className="d-flex justify-content-between mt-2">
              {/* de-escalate (previous step) button */}
              <Button 
                id={"sop-previous-step-button" + stepNumber} 
                className={`sop-previous-step-button mx-1 btn-primary ${firstStep && "sop-button-hidden"}`}
                disabled={!activeStep || !isAssignedUser}
                onClick={() => {
                  const previousStepButton = document.getElementById("sop-previous-step-button" + stepNumber);
                  previousStepButton.disabled = true;
                  previousStepButton.classList.add("cursor-progress");

                  if (!this.state.deEscalatingAction) {
                    this.setState({ deEscalatingAction: true });

                    const commentElement = document.getElementById("comment" + stepNumber);

                    handleDeEscalateAction(
                      sopActionedEventId, 
                      commentElement.value,
                    ).then(() => {

                      commentElement.value = "";
                      previousStepButton.classList.remove("cursor-progress");
                      this.setState({ deEscalatingAction: false });
                      
                    }).catch((error) => {
                      console.log("Error on escalate action: ", error.message);
                      Alert({
                        text: "Issue updating action. Please try again later.",
                        icon: "warning",
                        showConfirmButton: true,
                      });
                      
                      previousStepButton.classList.remove("cursor-progress");
                      previousStepButton.disabled = false;
                      this.setState({ deEscalatingAction: false });

                    })
                  } else {
                    previousStepButton.classList.remove("cursor-progress");
                    previousStepButton.disabled = false;
                  }
                }}
              >Previous Step</Button>

              {/* escalate (next step) button */}
              <Button 
                id={"sop-next-step-button" + stepNumber} 
                className={`sop-next-step-button mx-1 btn-primary ${lastStep && "sop-button-hidden"}`}
                disabled={!activeStep || !isAssignedUser}
                onClick={() => {
                  const nextStepButton = document.getElementById("sop-next-step-button" + stepNumber);
                  nextStepButton.disabled = true;
                  nextStepButton.classList.add("cursor-progress");

                  if (!this.state.escalatingAction) {
                    this.setState({ escalatingAction: true });

                    const commentElement = document.getElementById("comment" + stepNumber);

                    handleEscalateAction(
                      sopActionedEventId, 
                      commentElement.value,
                    ).then(() => {

                      commentElement.value = "";
                      nextStepButton.classList.remove("cursor-progress");
                      this.setState({ escalatingAction: false });
                      
                    }).catch((error) => {
                      console.log("Error on escalate action: ", error.message);
                      Alert({
                        text: "Issue updating update action. Please try again later.",
                        icon: "warning",
                        showConfirmButton: true,
                      });
                      
                      nextStepButton.classList.remove("cursor-progress");
                      nextStepButton.disabled = false;
                      this.setState({ escalatingAction: false });

                    })
                  } else {
                    nextStepButton.classList.remove("cursor-progress");
                    nextStepButton.disabled = false;
                  }
                }}
              >Next Step</Button>
            </div>
          </Form>
        }
      </VerticalTimelineElement>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    sop: state.sop,
    darkMode: state.app.darkMode,
  };
};
export default connect(mapStateToProps)(DispatchKeyholderStep);