import React from "react";
import PropTypes from "prop-types";

import { withT } from "services/translation/";

import { TextFilter } from "components/Common/";

import FilterTags from "components/Analytics/Filters/Tags/FilterTags";

const textFilter = {
  filterGroup: "text",
  // 'name' (site name) property of the group child
  // or custom ref
  key: ["name", "custom_group_ref"],
  type: "contains",
  color: "white"
};

class Filter extends React.Component {
  onTextFilterChange = e => {
    this.props.changeFilter({
      filter: {
        ...textFilter,
        value: e.target.value
      }
    });
    // this.setState({
    //   filter: {
    //     text: e.target.value
    //   }
    // });
  };
  render() {
    const {
      filters,
      changeFilter,
      t,
      enableTextFilter,
      className = ""
    } = this.props;
    const filter =
      filters.find(
        f => f.filterGroup + f.key === textFilter.filterGroup + textFilter.key
      ) || {};

    const filterActive = filters.length > 0;

    return (
      <div className={"row visual-data " + className}>
        <div
          className={
            (filterActive ? "filter-active" : "") +
            " col-xs-12 col-sm-12 col-xl-12 d-flex filter-tags"
          }>
          {enableTextFilter && (
            <TextFilter
              onChange={this.onTextFilterChange}
              formStyle={{ minWidth: "240px" }}
              placeholder={t("groups.text_filter.placeholder")}
              formGroupClassName="w-100"
              value={filter.value || ""}
            />
          )}

          {filterActive && (
            <>
              <FilterTags
                filters={filters}
                onRemove={selectedFilter => {
                  changeFilter({ filter: selectedFilter });
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
Filter.propTypes = {
  filters: PropTypes.array.isRequired,
  changeFilter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
export default withT(Filter);
