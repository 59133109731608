import React from "react";

/**
 * Default/fallback report config
 *
 * This will be used while reports are loading
 * and all reports are extended from this. This
 * is a good place to look when building a new report
 * and can also be configured in responses from the
 * backend (config property)
 */
const configDefault = {
  /** Default report fallback config.
   *
   * All report configurations are extended from this
   */
  default: {
    /**
     * The report title as it appears on card previews and the report
     * view pages
     */
    title: "",
    /** The report description that appears below report title.
     * Only appears on report view page, not reports index
     */
    description: "",

    /**
     * Live refresh configuration object for advanced live refrehes
     * like scheduled reports which require different windows for
     * different lengths of interval
     */
    liveRefresh: null,

    /**
     * Default/fallback function
     * Returns the live refresh interval in seconds for reports
     * that have a single interval length but may need to calculate it
     * differently based on the report
     */
    getLiveRefreshInterval: function() {
      return 60;
    },

    /**
     * Day picker options to customise when live reports
     * are available
     */
    dayPicker: {
      // Related to below, by default live data
      //  ends at midnight
      yesterdayEndsAt: "00:00",
      // Disable clicking on today
      //  until report has been generated
      //  default to midnight (start of day)
      disableTodayFrom: "00:00",
      disableTodayUntil: "00:00"
    },

    dataTable: {
      show: false,
      clickableEventFilters: true,
      suggestedFilters: []
    },
    charts: {
      barStack: {
        show: false
      },
      pie: {
        clickableEventFilters: true,
        type: "",
        filter: {
          component: "circles",
          type: "none",
          keys: []
        },
        /**
         * Default renderer for pie chart and filters,
         * can be customised in other reports
         */
        renderer: props => {
          const { filterComponent, pie } = props;

          return (
            <div className="row pb-2 pie-row">
              <div className="col-xl-12">
                {filterComponent}
                {pie}
              </div>
            </div>
          );
        }
      }
    },

    /** Customisation options for "first section" of report,
     * which refers to the first row in the layout.
     */
    firstSection: {
      /**
       * props is passed pie, progressBar and dayPicker components
       * to allow for changing layout or disabling components
       */
      renderer: props => {
        const { pie, progressBar, dayPicker } = props;
        return (
          <div className="row">
            <div className="col-xl-4 pb-4 text-center">
              {dayPicker}

              {progressBar}
            </div>
            <div className="col-xl-8 pb-4 visual-data">{pie}</div>
          </div>
        );
      }
    },

    noDataAvailableText: "No site data available for chosen time-range.",
    noDataAvailableRenderer: text => (
      <div className="row pie-row no-data-available">
        <div className="col-sm-12 text-center">
          <h6>
            <span>{text}</span>
          </h6>
        </div>
      </div>
    ),

    /** Options to customise report overview page */
    overview: {
      /**
       * index should technically be renamed to position, which is
       * overidden by the backend's config
       */
      // Hide by default on index page
      position: -1,
      className: "col-md-6",
      charts: {
        pie: {
          // Whether to show the pie chart legend
          hideLegend: true
        },
        hourly: {
          // 08/21 Whether to show the hourly chart legend in the overview, if relevant
          hideLegend: true,
          // hideAxisLabels: true
        }
      },
      noDataAvailableText: "No site data available",
      // TODO: translate
      noDataAvailableRenderer: text => (
        <div className="row no-data-available">
          <div className="col-sm-12 text-center">
            <h6>
              <span>{text}</span>
            </h6>
          </div>
        </div>
      )
    }
  }
};

export default configDefault;