import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";

import Loading from "components/Loading";

import merge from "deepmerge";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import { default as T } from "services/i18n/Translate";
import { Card } from "react-bootstrap";

import ScheduledReportPie from "components/Enterprise/Reports/Types/Scheduled/Pie";
import ScheduledReportHourly from "components/Enterprise/Reports/Types/Scheduled/Hourly";

import { getConfig } from "services/redux/selectors/reports/common/";

import { getRouterLocation } from "services/redux/selectors/app/";

import OutstandingOverviewCard from "components/Enterprise/Reports/Types/Outstanding/Index/OutstandingOverviewCard";
import OpenCloseByAreaOverviewCard from "components/Enterprise/Reports/Types/OpenCloseByArea/Index/OpenCloseByAreaOverviewCard";
import { getReport } from "services/redux/selectors/reports/common/index";

class ReportOverview extends React.Component {
  render() {
    const { config, reportPath, report, linkTo, pathname, outstandingBundleNumber } = this.props;
    // Invalid report path
    if (!reportPath) return null;

    if (reportPath === ["default", "default"]) return <Loading center />;

    const loadingClassName = report.loading !== false ? "loading" : "";

    const component =
      reportPath[0] === "outstanding" 
      ? (
        <OutstandingOverviewCard outstandingBundleNumber={outstandingBundleNumber} />
      ) 
      : (
        reportPath[0] === "openclosebyarea" // 09/21 - open close by area
        ? (
          <OpenCloseByAreaOverviewCard />
        ) 
        : (
          // 08/21 Add new report type of 'hourly' to support the new people counting hourly report. 
          // Previously all scheduled reports were pie 
          this.props.config.chartType === "hourly" 
          ? (
            <ScheduledReportHourly 
              reportPath={reportPath}
              overview
              config={config}
            />
          ) 
          : (
            <ScheduledReportPie
              changeFilter={() => {}}
              reportPath={reportPath}
              overview
              config={config}
            />
          )
        )
      );

    // Don't add /index if we're linking to a
    //  report index
    const link = linkTo || pathname + "/" + reportPath.join("/");
    // (reportPath[1] === "index"
    //   ? pathname + "/" + reportPath[0]
    //   : pathname + "/" + reportPath.join("/"));

    return (
      <div className={config.overview.className}>
        <Card className={`waves-effect w-100 ${loadingClassName}`}>
          <LinkContainer to={link}>
            <Card.Body className="visual-data">
              <Card.Title className="text-uppercase text-center">
                <T>{(reportPath[0] === "openclosebyarea") ? config.overview.title : config.title}</T>
              </Card.Title>
              <ErrorBoundary message="There was a problem loading this report, please try again later.">
                {component}
              </ErrorBoundary>
            </Card.Body>
          </LinkContainer>
        </Card>
      </div>
    );
  }
}

ReportOverview.propTypes = {
  reportPath: PropTypes.any.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { pathname } = getRouterLocation(state, props);

  return {
    report: getReport(state, props),
    config: merge(getConfig(state, props), props.config || {}),
    pathname: pathname,
  };
};
export default connect(mapStateToProps)(ReportOverview);
