import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Col, Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import { withT } from "services/translation/";

import { requestAddIntegrationLocationStart } from "services/redux/actions";
import { getCurrentSite } from "services/redux/selectors/sites";

import SelectLocation from "./SelectLocation";

import { Tooltip } from "components/Common/Tooltip/";

import AuthorisationModal from "components/Common/Cameras/AuthorisationModal";

function AddLocation({
    camera_integration_site_id,
    auth_token,
    site_id,
    addLocation,
    loading,
    loading_location,
    auth_expired,
    integration,
    t
}) {
    const [view, setView] = useState("button");
    const [selectedLocation, selectLocation] = useState(null);
    const [showModal, setShowState] = useState({show: false});

    const submit = e => {
        e.preventDefault();
        if (!selectedLocation) return;

        const location = {
            location_id: selectedLocation.location_id,
            location_name: selectedLocation.location_name,
        };

        addLocation({ location, site_id, camera_integration_site_id });
        selectLocation(null);
    };

    let showModalFunction = () => {
        setShowState({show: true})
    }

    let hideModal = () => {
        setShowState({show: false})
    }

    const integration_type = 'eagle_eye';
    const integration_type_string = 'Eagle Eye';

    // integration.site_id = site_id;

    //show, hideModal, properties, integration_type, integration_type_string, authorise, error, callErrors 
    const expiredToolTip = (
        <>
        <Tooltip
            title="Authorisation Expired"
            description="Click Here to Reauthorise"
            placement="bottom"
        >
            <Button
                block
                variant="danger"
                onClick={showModalFunction}
            >
                Click Here to Reauthorise 
            </Button>
        </Tooltip>
        {showModal && 
        <AuthorisationModal 
            show={showModal.show}
            hideModal={hideModal}
            properties={integration}
            integration_type={integration_type}
            integration_type_string={integration_type_string}
        />
        }
        </>
    );

    if (view === "button") {
        return (
            <>
                {auth_expired ? (expiredToolTip) :
                    <>
                        <Button
                            block
                            variant="outline-success"
                            disabled={loading_location}
                            onClick={() => {
                                setView("form");
                            }}>
                            {loading_location ? (
                                <Loading center />
                            ) : (
                                <>
                                    <Icon className="fas fa-plus" /> {t("add_location_to_site")}
                                </>
                            )}
                        </Button>
                    </>
                }
            </>
        );
    }

    return (
        <Form onSubmit={submit}>
            <Form.Row>
                <SelectLocation
                    camera_integration_site_id={camera_integration_site_id}
                    selectLocation={selectLocation}
                    selectedLocation={selectedLocation}
                    auth_token={auth_token}
                />
            </Form.Row>
            {selectedLocation && (
                <Col>
                    <p>{selectedLocation.location_address}</p>
                </Col>
            )}
            <Form.Row>
                <Form.Group controlId="formGroupSubmit" as={Col} md="6">
                    <Button
                        block
                        variant="danger"
                        type="submit"
                        onClick={() => {
                            setView("button");
                            selectLocation(null);
                        }}>
                        <Icon className="fas fa-times" /> {t("cancel_add_location")}
                    </Button>
                </Form.Group>
                <Form.Group controlId="formGroupSubmit" as={Col} md="6">
                    <Button block variant="success" type="submit" disabled={loading}>
                        {loading ? (
                            <Loading center />
                        ) : (
                            <>
                                <Icon className="fas fa-plus" /> {t("add_location")}
                            </>
                        )}
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
}
AddLocation.propTypes = {
    /**
     * @param camera_integration_site_id sudo site id
     */
    camera_integration_site_id: PropTypes.number.isRequired,

    /**
     * @param auth_token integration auth token
     */
    auth_token: PropTypes.string.isRequired,

    /**
     * @param site_id id of the site we are adding to for api refresh
     */
    site_id: PropTypes.any.isRequired,

    /**
     * @param loading loading api status
     */
    loading: PropTypes.any,
    /**
 * @param loading_location loading api status
 */
    loading_location: PropTypes.any
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.sites.current.integrations.locations.actions.add.loading,
        loading_location: state.sites.current.integrations.loading,
        site_id: getCurrentSite(state, props).id
    };
};
const mapDispatchToProps = { addLocation: requestAddIntegrationLocationStart }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withT(
        AddLocation,
        "site_overview.side_pane.manage_integrations.providers.eagle_eye"
    )
);