import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { default as GroupSelector } from "components/Enterprise/GroupManagement/GroupSelector/GroupList";

import { getCurrentGroupFiltered } from "services/redux/selectors/groups";

import { getRouterParams } from "services/redux/selectors/app/";

// import { loadGroupsBackgroundStart } from "services/redux/actions";

import { GroupCards } from "./GroupCards/";

class GroupList extends React.Component {
  state = {
    // "cards" or "list"
    viewAs: "cards"
  };
  // loadGroups(props) {
  //   const { currentGroup, router, loading } = props;

  //   const { enterprise_id } = router.params;

  //   // Prevent double loading
  //   if (loading) return;

  //   // Groups haven't been loaded for this enterprise yet
  //   if (currentGroup.loading !== false) {
  //     // No enterprise_id available
  //     if (!enterprise_id) {
  //       return;
  //     }

  //     // this.props.dispatch(loadGroupsBackgroundStart({ enterprise_id }));
  //   }
  // }
  // componentWillMount() {
  //   this.loadGroups(this.props);
  // }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.loadGroups(nextProps);
  // }
  render() {
    const { currentGroup } = this.props;
    const { viewAs } = this.state;

    // If this group has sites, don't show Group List
    if (currentGroup.sites) {
      return null;
    }

    // Show loading if group total is 0 and groups are loading
    // we check total_children here because currentGroup.children.length
    // can be affected by filter
    const initialLoad =
      currentGroup.loading !== false &&
      typeof currentGroup.total_children === "undefined";

    if (initialLoad) return <Loading center />;

    // const rootGroup = [
    //   {
    //     name: "Root",
    //     root: true,
    //     id: 444,
    //     children: groups
    //   }
    // ];

    const Component =
      viewAs === "cards" ? (
        <GroupCards groups={currentGroup.children} />
      ) : (
        <div id="sidebar-menu" className="select-groups">
          <GroupSelector
            onSelect={() => {}}
            groups={currentGroup.children}
            selectedGroupId={444}
          />
        </div>
      );

    return Component;
  }
}

const mapStateToProps = (state, props) => {
  return {
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroupFiltered(state),
    // loading: getGroupsLoading(state, props),
    router: { params: getRouterParams(state, props) }
  };
};
export default connect(mapStateToProps)(GroupList);
