import React from "react";

import EnterpriseCard from "./Card";

class EnterpriseList extends React.Component {
  render() {
    const enterpriseCards = this.props.enterprises.map((enterprise, index) => {
      const { id, name, logo_url } = enterprise;
      return (
        <EnterpriseCard key={id} id={id} name={name} logo_url={logo_url} />
      );
    });

    return <div className="row enterprise-cards-list">{enterpriseCards}</div>;
  }
}

export default EnterpriseList;
