import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { Button } from "react-bootstrap";

import { getBrivoSitesWithLinks } from "services/redux/selectors/enterpriseManager";

import BrivoLinkSiteModal from "./BrivoLinkSiteModal";

class DataTable extends React.Component {
  state = {
    brivoLinkSiteModalShow: false,
    selectedBrivoSite: {
      id: "",
      name: ""
    },
    conxtdSite: {
      id: "",
      name: "",
      address: ""
    },
  }

  render() {
    const { brivoSitesWithLinks } = this.props;

    let loadingMsg = "No data available...";

    const columns = [
      {
        key: "ID",
        Header: "Brivo Site ID",
        id: "id",
        width: 100,
        accessor: "id"
      },
      {
        key: "NAME",
        Header: "Brivo Site Name",
        id: "name",
        width: 200,
        accessor: "name",
        style: {
          fontWeight: "bold",
        }
      },
      {
        key: "ADDRESS",
        Header: "Brivo Address",
        id: "address",
        // width: 200,
        accessor: ({ address }) => {
          let fullAddress = "";

          fullAddress = address.streetAddress1 ? address.streetAddress1 : "";
          fullAddress = `${fullAddress}${fullAddress && address.streetAddress2 ? ", " : ""}${address.streetAddress2 ? address.streetAddress2 : ""}`;
          fullAddress = `${fullAddress}${fullAddress && address.state ? ", " : ""}${address.state ? address.state : ""}`;
          fullAddress = `${fullAddress}${fullAddress && address.city ? ", " : ""}${address.city ? address.city : ""}`;
          fullAddress = `${fullAddress}${fullAddress && address.postalCode ? ", " : ""}${address.postalCode ? address.postalCode : ""}`;
          fullAddress = `${fullAddress}${fullAddress && address.country ? ", " : ""}${address.country ? address.country : ""}`;
          // return `${address.streetAddress1}, ${address.streetAddress2}, ${address.state}, ${address.city}, ${address.postalCode}, ${address.country}`
          return fullAddress;
        }
      },
      {
        key: "CONXTD_SITE_NAME",
        Header: "CONXTD Site Name",
        id: "conxtd_site_name",
        width: 200,
        accessor: "conxtd_site_name",
        style: {
          borderLeft: "3px solid #DEE2E6",
          fontWeight: "bold",
        },
        getHeaderProps: () => {
          return {
            style: {
              borderLeft: "3px solid #DEE2E6",

            }
          }
        },
      },
      {
        key: "CONXTD_ADDRESS",
        Header: "CONXTD Address",
        id: "conxtd_address",
        minWidth: 300,
        accessor: "conxtd_address"
      },
      {
        key: "SUDO_SITE_ID",
        Header: "CONXTD Site Id",
        id: "sudo_site_id",
        width: 30,
        accessor: "sudo_site_id",
        show: false
      },
      {
        key: "LINK_SITE_BUTTON",
        Header: "Link Site",
        id: "link_site_button",
        width: 120,
        accessor: ( data ) => {
          return data.sudo_site_id ? "Change Site" : "Link Site";
        },
        Cell: ( data ) => {
          const { original } = data;
          return <Button
            onClick={() => {
              this.setState({
                selectedBrivoSite: {
                  id: original.id,
                  name: original.name,
                  address: data.row.address,
                },
                conxtdSite: {
                  id: original.sudo_site_id,
                  name: original.conxtd_site_name,
                  address: original.conxtd_address,
                  sudo_site_associate_id: original.sudo_site_associate_id,
                },
                brivoLinkSiteModalShow: true
              })
            }}
            variant={original.sudo_site_id ? "primary" : "success"}
          >
            {data.row.link_site_button}
          </Button>
        },
        sortMethod: (a, b) => {
          if (a > b) {
            return -1
          } else if (b > a) {
            return 1
          } else {
            return 0
          }
        },
      },
    ];

    let brivoLinkSiteModalClose = () => this.setState({ brivoLinkSiteModalShow: false });

    return (
      <div>
        <div className="card visual-data">
          <ReactTable
            columns={columns}
            data={brivoSitesWithLinks}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            defaultPageSize={20}
            noDataText={loadingMsg}
            defaultSorted={[
              {
                id: "link_site_button",
                desc: false,
              },
            ]}
          />
        </div>
        <BrivoLinkSiteModal 
          show={this.state.brivoLinkSiteModalShow}
          onHide={brivoLinkSiteModalClose}
          selectedBrivoSite={this.state.selectedBrivoSite}
          conxtdSite={this.state.conxtdSite}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    brivoSitesWithLinks: getBrivoSitesWithLinks(state),
  };
};
export default connect(mapStateToProps)(DataTable);