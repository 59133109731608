import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { Col, Row, Form } from "react-bootstrap";

import Loading from "components/Loading";

import { withT } from "services/translation/";
import conxtdOut from "apis/conxtdOut";

function CustomBundleSelect({ bundle, selectBundle, t }) {
  const [defaults, setDefaults] = useState(null);
  useEffect(() => {
    async function loadDefaults() {
      const response = await conxtdOut.get(
        "/notifications/channels/loadDefaults"
      );
      setDefaults(response.data);
    }
    if (defaults === null) {
      loadDefaults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return defaults === null ? (
    <Loading center />
  ) : (
    <>
      <Row>
        <Col>
          <Form.Label>{t("bundle_label")}</Form.Label>

          <Form.Control
            data-testid="bundle-list"
            as="select"
            value={bundle.bundle_id}
            onChange={e => {
              const bundle_id = e.target.value;
              // console.log(bundle_id);

              selectBundle({ ...bundle, bundle_id });

              // User selected initial option, reset
              // selectResource(resource === "-1" ? null : resource);
            }}>
            <option value="-1">{t("select_bundle")}</option>
            {defaults.bundles.map(({ id, title, index }) => (
              <option key={index} value={id}>
                {title}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Label>{t("class_label")}</Form.Label>

          <Form.Control
            data-testid="class-list"
            as="select"
            value={bundle.class_id}
            onChange={e => {
              const class_id = parseInt(e.target.value);
              // console.log(class_id);

              selectBundle({ ...bundle, class_id });

              // User selected initial option, reset
              // selectResource(resource === "-1" ? null : resource);
            }}>
            <option value="-1">{t("select_class")}</option>

            {defaults.classes.map(({ id, name, index }) => (
              <option key={index} value={id}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </>
  );
}
CustomBundleSelect.propTypes = {
  /**
   * @param bundle currently selected bundle object. Contains colour and alarm
   * classs
   */
  bundle: PropTypes.object,

  /**
   * @param selectBundle bundle select function
   */
  selectBundle: PropTypes.func
};

export default withT(
  CustomBundleSelect,
  "site_overview.side_pane.manage_notifications.add_rule.select_bundle.custom_bundle"
);
