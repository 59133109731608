import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import get from "lodash/get";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Icon from "components/Icons/Icon";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import FilterSelect from "components/Common/Dropdown/FilterSelect";

import { contains, exact } from "services/filters/";

import { withT } from "services/translation/";

import {
  getCustomRef,
  getCurrentGroupFiltered,
} from "services/redux/selectors/groups";

import {
  getRouterParams,
  getRouterLocation,
} from "services/redux/selectors/app/";

import { LatestAlarm, SiteName, SiteStatus } from "./Cells/";

import { requestChangeSiteSetStart } from "services/redux/actions";

const COLORS = {
  setBlue: "#28498A",
  unsetLightblue: "#3397DB",
  noStateMidBlue: "#2B3E4E",
};

class SiteList extends React.Component {
  state = {
    filtered: [],
  };
  // onFilteredChangeCustom = (value, accessor) => {
  //   let filtered = [...this.state.filtered];
  //   let insertNewFilter = 1;

  //   // Filter already exists
  //   if (filtered.length) {
  //     filtered = filtered
  //       .map((filter, i) => {
  //         if (filter["id"] === accessor) {
  //           insertNewFilter = 0;

  //           // Filter text value is empty
  //           if (value === "" || !value.length) {
  //             // filtered = filtered.slice(i, 1);
  //             // To be removed (see remove falsey below)
  //             return false;
  //           } else {
  //             return {
  //               ...filter,
  //               value
  //             };
  //             // filter["value"] = value;
  //           }
  //         }
  //         return filter;
  //       })
  //       // Remove falsey values
  //       .filter(falsey => falsey);
  //   }

  //   // Filter doesn't exist - add
  //   if (insertNewFilter) {
  //     // filtered.push({ id: accessor, value: value });
  //     filtered = [...filtered, { id: accessor, value: value }];
  //   }

  //   this.setState({ filtered });
  // };
  render() {
    const mediaType = this.props.browser.mediaType;

    const {
      t,
      currentGroup,
      getEventMetaById,
      selectedSite,
      selectSite,
      dispatch,
    } = this.props;

    const columns = [];
    /**  Dynamically hide columns on a small screen **/
    let hideOrShowCol = null;
    let unset = { whiteSpace: "unset" };
    let set = {};
    let width = null;
    let styleResponsive = null;
    let widthMobile = 70;
    let widthLarge = 100;

    if (mediaType === "extraSmall") {
      hideOrShowCol = false;
      styleResponsive = unset;
      width = widthMobile;
    } else if (
      mediaType === "medium" ||
      mediaType === "large" ||
      mediaType === "infinity"
    ) {
      hideOrShowCol = true;
      styleResponsive = set;
      width = widthLarge;
    } else if (mediaType === "small") {
      hideOrShowCol = false;
      styleResponsive = set;
      width = widthLarge;
    }
    /*****************************************/

    columns.push({
      Header: t("headers.site_name"),
      accessor: "name",
      className: "site-name",
      style: styleResponsive,
      id: "site-name",
      Placeholder: t("headers.filter_x_sites", currentGroup.sites.length),
      Cell: ({ value, original }) => {
        const custom_ref = getCustomRef(original);

        return <SiteName custom_ref={custom_ref} value={value} />;
      },
      // Filter: ({ filter, onChange, column }) => (
      //   <div className="w-100 d-block" style={{ height: "38px" }}>
      //     <Select
      //       className="site-list-filter-select"
      //       autosize={false}
      //       value={filter ? filter.value : ""}
      //       styles={{
      //         option: (provided, state) => ({
      //           ...provided,
      //           borderBottom: "1px dotted pink",
      //           color: state.isSelected ? "red" : "blue"
      //           // padding: 20
      //         }),
      //         control: base => ({
      //           ...base,
      //           height: 38,
      //           minHeight: 38
      //         }),
      //         singleValue: (provided, state) => {
      //           const opacity = state.isDisabled ? 0.5 : 1;
      //           const transition = "opacity 300ms";

      //           return { ...provided, opacity, transition };
      //         }
      //       }}
      //       options={[{ value: "fds", label: "fdss" }]}
      //       onChange={event => onChange(event.value)}
      //     />
      //   </div>
      // )

      // <input
      //   type="text"
      //   style={{
      //     width: '100%',
      //   }}
      //   placeholder={column.Placeholder}
      //   value={filter ? filter.value : ''}
      //   onChange={event => onChange(event.target.value)}
      // />
    });

    // Build available headers
    const siteFieldsHeaders = [];

    // Gathers and re-orders site fields based on their sequence
    currentGroup.sites.forEach(({ site_fields }) => {
      site_fields.forEach((field) => {
        if (field.enterprise_user_field && field.enterprise_user_field.sequence) {
          siteFieldsHeaders[field.enterprise_user_field.sequence - 1] = field;
        }
      });
    });

    // Limit total fields to first three
    if (siteFieldsHeaders.length > 3) siteFieldsHeaders.length = 3;

    // Gather all header fields
    siteFieldsHeaders.forEach((colField, index) => {
      columns.push({
        // Header: colField.name,
        Header: colField.enterprise_user_field.name,
        id: "custom-field-" + index,
        show: hideOrShowCol,
        width: 150,
        //accessor: "site_fields." + index + ".value"
        accessor: ({ site_fields }) => {
          const field = site_fields.find(
            (cellField) => {
              return (colField.enterprise_user_field 
                      && colField.enterprise_user_field.sequence
                      && (cellField.enterprise_user_field.sequence === colField.enterprise_user_field.sequence))
            }
          ) || { value: "" };
          // Only display if sequence matches this column
          return field.value;
        },
        className: "store-type",
      });
    });
    // columns.push({
    //   Header: "Store Type",
    //   accessor: "site_fields.1.value",
    //   className: "store-type"
    // });
    // columns.push({
    //   Header: "Contract No.",
    //   accessor: "site_fields.2.value",
    //   className: "contract-no"
    // });

    columns.push({
      Header: t("headers.site_status"),
      headerClassName: "wordwrap",
      // Filter: () => {
      //   return (
      //     <SiteStatusFilterToolbar
      //       filtered={this.state.filtered}
      //       changeFilter={this.onFilteredChangeCustom}
      //     />
      //   );
      // },
      id: "SiteStatus",
      accessor: "zoneState.state",
      className: "site-status bg-white",
      width: width,
      Cell: (props) => (
        <SiteStatus
          {...props}
          onChangeSiteSet={(params) => {
            dispatch(requestChangeSiteSetStart(params));
          }}
        />
      ),
      filterMethod: (filter, row) => {
        return exact({
          filter: filter.value,
          value: String(row[filter.id]),
          ignoreCase: true,
        });
      },
    });
    columns.push({
      Header: t("headers.latest_alarm"),
      headerClassName: "wordwrap",
      id: "LatestAlarm",
      className: "latest-alarm bg-white text-dark",
      width: width,
      accessor: ({ alarmLast }) => {
        // "alarmLast.conxtdAlarmId"

        return {
          ...alarmLast,
          ...getEventMetaById(get(alarmLast, "conxtdAlarmId")),
        };
      },
      Cell: (props) => <LatestAlarm {...props} t={t} />,
      Filter: ({ filter, onChange, column }) => {
        let collectedIds = {};
        const options = currentGroup.sites
          .map((site) => {
            if (!site.alarmLast) return null;
            const id = site.alarmLast.conxtdAlarmId;
            const event = getEventMetaById(id);

            if (collectedIds.hasOwnProperty(id)) {
              return null;
            }
            collectedIds[id] = true;
            return {
              name: event.caption,
              color: event.colour,
              id,
              icon: event.icon,
            };
          })
          .filter((i) => i);
        options.unshift({
          name: "With conversation",
          color: "#777777",
          id: -1,
          icon: "comment"
        });

        return (
          <FilterSelect
            onChange={onChange}
            value={filter ? filter.value : ""}
            options={options}
          />
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "With conversation") {
          return row.LatestAlarm && row.LatestAlarm.linkedCommentCount && (row.LatestAlarm.linkedCommentCount > 0);
        } else {
          const alarm = row[filter.id];

          return (
            contains({
              filter: filter.value,
              value: alarm.caption,
              ignoreCase: true,
            }) ||
            contains({
              filter: filter.value,
              value: alarm.alarmDescription || "",
              ignoreCase: false,
            })
          );
        }
      },
      sortMethod: ({ acked: a }, { acked: b }) => {
        // put sites that don't yet have alarms (i.e. no acked) below sites that do have alarms
        if (!a && !b) return 0;
        if (!a && b) return 1;
        if (a && !b) return -1;

        // otherwise order by acked time
        return new Date(a).getTime() < new Date(b).getTime() ? 1 : -1;
        // console.log(a.getTime());
        // console.log(a.getTime());
      },
    });
    columns.push({
      Header: t("headers.alarm_count"),
      id: "alarmCount",
      // accessor: "alarmCount",
      accessor: (data) => {
        return {
          alarmCount: data.alarmCount,
          alarmLast: data.alarmLast
        }
      },
      show: hideOrShowCol,
      className: "alarm-count bg-white text-dark",
      width: 100,
      Cell: ({ value }) => {
        return (
          <span className="d-block">
            <div
              className="text-center d-inline-block"
              style={{ width: "60%" }}
            >
              <Icon className="far fa-bell px-2" />
            </div>
            <div
              className="text-center d-inline-block"
              style={{ width: "50%" }}
            >
              {value.alarmCount}
            </div>
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const alarmCount = row[filter.id];

        return exact({
          filter: filter.value,
          value: String(alarmCount.alarmCount),
          ignoreCase: true,
        });
      },
      sortMethod: (a, b) => {
        // put sites that don't yet have alarms (i.e. no .alarmLast) above sites that do have alarms
        if (!a.alarmLast && !b.alarmLast) return 0;
        if (!a.alarmLast && b.alarmLast) return -1;
        if (a.alarmLast && !b.alarmLast) return 1;

        return a.alarmCount - b.alarmCount;
      }
    });

    return (
      <div>
        <div className="card visual-data site-list">
          <ReactTable
            className="test"
            filterable
            noDataText={t("no_data_available")}
            // filtered={this.state.filtered}
            // onFilteredChange={(filtered, column, value) => {
            //   this.onFilteredChangeCustom(value, column.id || column.accessor);
            // }}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "LatestAlarm",
                desc: false,
              },
            ]}
            defaultFilterMethod={(filter, row) => {
              return contains({
                filter: filter.value,
                value: String(row[filter.id]),
                ignoreCase: true,
              });
            }}
            data={currentGroup.sites}
            columns={columns}
            // Override row group class for sub rows
            getTrGroupProps={(state, rowInfo, column) => {
              if (!rowInfo) return { className: "empty-row" };

              // Sets background to latest alarm id colour
              // const { colour } = getEventMetaById(
              //   get(rowInfo.original, "alarmLast.conxtdAlarmId")
              // ) || { colour: "#007bff" };

              const site = currentGroup.sites[rowInfo.index];
              const isOpenClose = site.open_close;

              const selected =
                site && selectedSite && site.id === selectedSite.id
                  ? "selected"
                  : "";

              const colorKey = rowInfo.original.zoneState.colour;

              let color = COLORS[colorKey] || "#666666";

              // const loading = rowInfo.original.zoneState.loading;
              const remoteToggle = window.localStorage.getItem("remoteToggle");
              const siteId = window.localStorage.getItem("siteId");
              const parseSiteId = parseInt(siteId);

              // if remote set/unset toggle is pressed change the color to light grey
              if (
                isOpenClose === "Y" &&
                remoteToggle === "true" &&
                site.id === parseSiteId
              ) {
                color = "#9da5ad";
              } else {
                color = COLORS[colorKey];
              }

              return {
                className: [colorKey, selected, "waves-effect"].join(" "),
                style: {
                  background: color,
                  border: "1px solid " + color,
                },
                onClick: () => {
                  // const site = currentGroup.sites[rowInfo.index];
                  selectSite(site);
                  // console.log(rowInfo.index);
                },
              };
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroupFiltered(state),
    browser: state.browser,
    // loading: getGroupsLoading(state, props),
    router: {
      params: getRouterParams(state, props),
      location: getRouterLocation(state, props),
    },
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withRouter(
  withT(
    connect(mapStateToProps)(SiteList),

    "site_list.data_table"
  )
);
