/**
 * Watcher for deleting notification rules
 */

import { call } from "redux-saga/effects";

import {
  requestNotificationsRemoveRuleStart,
  requestNotificationsRemoveRuleFailed,
  requestNotificationsRemoveRuleSuccess,
  requestNotificationsRemoveRuleCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* removeRule(action) {
  try {
    // yield call(() => {
    //   console.log(action.params);
    // });

    const result = yield call(
      conxtdOut.delete,
      `/notifications/rules/delete?id=${action.params.id}`
      // action.params
    );
    return result;
  } catch (e) {
    throw new Error(e);
  }
}

export function* watchRemoveRule() {
  yield actionWatcher({
    actions: {
      start: requestNotificationsRemoveRuleStart,
      failed: requestNotificationsRemoveRuleFailed,
      success: requestNotificationsRemoveRuleSuccess,
      cancel: requestNotificationsRemoveRuleCancel
    },
    saga: removeRule
  });
}
