import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Col, Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import { withT } from "services/translation/";

import { requestAddSiteCameraStart } from "services/redux/actions";
import { getCurrentSite } from "services/redux/selectors/sites";

import SelectCamera from "./SelectCamera";

function AddCamera({
  camera_integration_site_id,
  auth_token,
  site_id,
  addCamera,
  loading,
  t
}) {
  const [view, setView] = useState("button");
  const [selectedCamera, selectCamera] = useState(null);

  const submit = e => {
    e.preventDefault();
    if (!selectedCamera) return;

    const camera = {
      camera_id: selectedCamera.camera_id,
      camera_name: selectedCamera.camera_name,
      conxtd_alarm_bundles: []
    };
    addCamera({ camera, site_id, camera_integration_site_id });
    selectCamera(null);
    setView("button");
  };

  if (view === "button") {
    return (
      <Button
        block
        variant="outline-success"
        onClick={() => {
          setView("form");
        }}>
        <Icon className="fas fa-plus" /> {t("add_camera_to_site")}
      </Button>
    );
  }

  return (
    <Form onSubmit={submit}>
      <Form.Row>
        <SelectCamera
          camera_integration_site_id={camera_integration_site_id}
          selectCamera={selectCamera}
          selectedCamera={selectedCamera}
          auth_token={auth_token}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="formGroupSubmit" as={Col} md="6">
          <Button
            block
            variant="danger"
            type="submit"
            onClick={() => {
              setView("button");
              selectCamera(null);
            }}>
            <Icon className="fas fa-times" /> {t("cancel_add_camera")}
          </Button>
        </Form.Group>
        <Form.Group controlId="formGroupSubmit" as={Col} md="6">
          <Button block variant="success" type="submit" disabled={loading}>
            {loading ? (
              <Loading center />
            ) : (
              <>
                <Icon className="fas fa-plus" /> {t("add_camera")}
              </>
            )}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
AddCamera.propTypes = {
  /**
   * @param camera_integration_site_id sudo site id
   */
  camera_integration_site_id: PropTypes.number.isRequired,

  /**
   * @param auth_token integration auth token
   */
  auth_token: PropTypes.string.isRequired,

  /**
   * @param site_id id of the site we are adding to for api refresh
   */
  site_id: PropTypes.any.isRequired,

  /**
   * @param addCamera api add camera dispatch
   */
  addCamera: PropTypes.func.isRequired,

  /**
   * @param loading loading api status
   */
  loading: PropTypes.any
};

const mapStateToProps = (state, props) => {
  return {
    loading: state.sites.current.integrations.cameras.actions.add.loading,
    site_id: getCurrentSite(state, props).id
  };
};
const mapDispatchToProps = { addCamera: requestAddSiteCameraStart };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withT(
    AddCamera,
    "site_overview.side_pane.manage_integrations.providers.tether"
  )
);
