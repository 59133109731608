import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Alert from 'components/Common/Alert';
import conxtdOut from 'apis/conxtdOut';
import { loadPinMapsReset } from 'services/redux/actions/serviceDesk';
import Loading from 'components/Loading';

function DeleteSite({ companyId, sudoSiteId, siteName }) {
  const history = useHistory();
  const dispatch = useDispatch();

  // Selectors
  const sudoPins = useSelector((state) => state.serviceDesk.pinMaps.sudo_pins);
  const fastFormat = useSelector((state) => state.serviceDesk.pinMaps.fast_formats);
  const associations = useSelector((state) => state.serviceDesk.enterpriseAssociations);

  // State
  const [pins, setPins] = useState(true);
  const [deleteActions, setDeleteActions] = useState([]);
  const [actionType] = useState({
    pins: { name: "pins", text: "pin mapping configurations"},
    associations: { name: "associations", text: "enterprise site associations"},
  });
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (sudoPins?.length > 0 || fastFormat?.length > 0) {
      setPins(true);
    } else {
      setPins(false);
    }
  }, [sudoPins, fastFormat]);

  useEffect(() => {
    const actions = [];
    pins && actions.push('pins');
    associations.length > 0 && actions.push('associations');
    setDeleteActions(actions);
  }, [pins, associations]);

  const handleDeleteSite = async () => {
    const mappedActions = deleteActions.map((key) => {
      const action = actionType[key];
      return `
        <li class='delete-action-row'>
          <span>${action.text}</span>
        </li>
      `;
    }).join('');
  
    const htmlContent = `
      <div class="delete-actions-wrapper">
      ${deleteActions.length > 0 ?
        `<span class="delete-actions-note">
          The following configurations for ${siteName ? `site <span class="font-weight-bold">${siteName}</span>` : "this site "} 
          will also be permanently deleted during this process:
        </span>
        <ul class='delete-actions'>
          ${mappedActions}
        </ul>`
        : 
        ''}
        <span class="delete-actions-note font-weight-bold">I understand that this process will permanently delete this site${deleteActions.length > 0 ? " and all configurations specified above." : " and cannot be undone."}</span>
      </div>`;
  
    const { value: result } = await Alert({
      title: 'Are you sure you want to delete this site?',
      html: htmlContent,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    });
  
    if (result) {
      const deleteSite = conxtdOut.post(`/ServiceDesk/deleteSite/${sudoSiteId}`, deleteActions.length > 0 ? deleteActions : []);
      setLoadingDelete(true);

      deleteSite.then(() => {
      setLoadingDelete(false);
        Alert({
          text: "Site deleted successfully",
          icon: "success",
          timerProgressBar: true,
          timer: 5000,
        });
        dispatch(loadPinMapsReset());
        history.push(`/companies/company/${companyId}`);
        }).catch((error) => {
          console.log(error);
          Alert({
            text: "An issue occurred. Please try again later.",
            icon: "error",
          });
        })
    }
  };

  return (
    <>
      <button className="btn btn-danger" onClick={handleDeleteSite} data-intercom-target={"asset-editor-delete-site-button"}>
        {loadingDelete ? <Loading /> : "Delete Site"}
      </button>
    </>
  );
}

export default DeleteSite;