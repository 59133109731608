import React from "react";

import ReactHlsPlayer from "react-hls";

import { connect } from "react-redux";

import { withT } from "services/translation/";

import ReauthorisationMessage from "./ReauthorisationMessage";

class VideoLoftView extends React.Component {

    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { integration, event } = this.props;

        let cameraData = integration.camera_integration_site.camera_sudo_sites.find(camera_sudo_site => camera_sudo_site.id === event.camera_id);

        const url = 'https://' + cameraData.data_server + '/alerturl.m3u8?'
            + 'uid=' + cameraData.camera_ref
            + '&startt=' + event.start_timestamp
            + '&endt=' + event.end_timestamp
            + '&rom=1'
            + '&id=' + event.recording_id
            + '&buildmp4=0'
            + '&token=' + integration.auth_token;

        return (
            <>
                {integration.camera_integration_site.auth_expired ?
                    <ReauthorisationMessage integration_name={integration.integration_name} />
                    :
                    <ReactHlsPlayer
                        url={url}
                        autoplay={false}
                        controls={true}
                        width='100%'
                        height='auto'
                    />
                }
            </>
        );
    }
}
// function VideoLoftView({ integration, camera, event, t }) {

//     const [urlState, setUrlState] = useState(null);

//     const changeState = (url) => (
//         setUrlState({urlState: url})
//     );

//     useEffect(() => {
//         console.log('_____');
//     }, []);

//     let cameraData = integration.camera_integration_site.camera_sudo_sites.find(camera_sudo_site => camera_sudo_site.id === event.camera_id);

//     if (event.start_timestamp === null) {
//         return null;
//     }

//     const url = 'https://' + cameraData.data_server + '/alerturl.m3u8?'
//         + 'uid=' + cameraData.camera_ref
//         + '&startt=' + event.start_timestamp
//         + '&endt=' + event.end_timestamp
//         + '&rom=1'
//         + '&id=' + event.recording_id
//         + '&buildmp4=0' 
//         + '&token=' + integration.auth_token;

//         if (urlState === null) {
//             changeState(url);
//         }

//         console.log(urlState);
//     return (
//         <>
//             <ReactHlsPlayer
//                 url={urlState}
//                 autoplay={false}
//                 controls={true}
//                 width='100%'
//                 height='auto'
//             />
//         </>
//     );
// }

export default connect()(
    withT(
        VideoLoftView,
        "site_overview.side_pane.manage_integrations.providers.video_loft"
    )
)