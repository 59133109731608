import { all } from "redux-saga/effects";

import {
  loadIntegrationsWatcher,
  linksToAddEagleEyeIntegration,
  linksToSiteView,
  linksToAddCamera,
  linksToAddLocation
} from "./integrations";
import cameraSagas from "./cameras/";
import locationSagas from "./locations/";
import { loadTetherConnectWatcher } from "./tetherConnect";
import { loadEagleEyeConnectWatcher } from "./eagleEyeConnect";


export function* rootSaga() {
  yield all([
    loadIntegrationsWatcher(),
    linksToSiteView(),
    linksToAddCamera(),
    linksToAddLocation(),
    linksToAddEagleEyeIntegration(),

    loadEagleEyeConnectWatcher(),
    loadTetherConnectWatcher(),
    cameraSagas(),
    locationSagas(),
  ]);
}
