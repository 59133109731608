import { createSelector } from "reselect";

import { getSites, getSiteById } from "services/redux/selectors/sites";
import { getGroups, getGroupPathById } from "services/redux/selectors/groups";
import {
  getEventMetaList,
  getEventMetaById,
} from "services/redux/selectors/eventMeta";

import { getEventCountFilter } from "../../common/filters";

import produce from "immer";

import moment from "services/locale/momentInit.js";

import DAYS_AGO_DESCRIPTIONS from "constants/DAYS_AGO_DESCRIPTIONS";

import _ from "lodash";

const today = new moment();

const COLORS = ["#f39c12", "#e67e22", "#d35400", "#c0392b", "#962d22"];

export const getBundle = (state) => state.reports.outstanding.bundle;

const getEventData = (state) => getBundle(state).data.events;

/**
 * getEvents - gets event data with site data attached for outstanding bundle view
 */
export const getEvents = createSelector(
  // Call the function with these selectors
  [getSites, getEventData, getGroups],
  (sites, events, groups) => {
    // Build site data into each event
    let resultingEvents = events.map((event) => {
      // Find the site for this event
      let filteredSite = getSiteById(sites, event.enterprise_site_id);

      // Make sure sites have loaded before building them into
      //   event data
      if (filteredSite !== null && filteredSite.enterprise_groups) {
        filteredSite = produce(filteredSite, (filteredSite) => {
          // Add path property to each group
          filteredSite.enterprise_groups = filteredSite.enterprise_groups.map(
            (group) => {
              return {
                ...group,
                path: getGroupPathById(groups, group.id),
              };
            }
          );
        });
      }

      return {
        ...event,
        ...filteredSite,
        eventId: event.id,
        // Add site timezone
        timezone: filteredSite.timezone,
      };
    });

    return resultingEvents;
  }
);

// export const getTimeInFailFilter = state =>
//   getBundle(state).filter.timeInFail.show;

export const getOutstandingEventsFiltered = createSelector(
  [getEvents, getEventCountFilter],
  (events, show) => {
    return (
      events
        // Remove events that aren't set to "show"
        .filter((event) => {
          // Default - show all
          if (Object.keys(show).length === 0) return true;

          const daysAgoLevel = getDaysAgoLevel(event);

          const daysAgoKey = DAYS_AGO_DESCRIPTIONS[daysAgoLevel];

          return show[daysAgoKey];
        })
    );
  }
);

export const getDaysAgoLevel = (event) => {
  const recvd = new moment(event.recvd);

  const daysAgo = -recvd.diff(today, "day");

  let daysAgoLevel = 0;
  // Over 1 month
  if (daysAgo > 28) {
    daysAgoLevel = 4;
  }
  // Less than 4 weeks
  if (daysAgo <= 28) {
    daysAgoLevel = 3;
  }
  if (daysAgo < 7) {
    daysAgoLevel = 2;
  }
  if (daysAgo < 2) {
    daysAgoLevel = 1;
  }
  if (daysAgo < 1) {
    daysAgoLevel = 0;
  }
  return daysAgoLevel;
};

/**
 * getEventTotals
 *
 * Gets event totals with colors in a format ready for ChartJS
 *
 * @returns {data, colors}
 */
export const getEventTotals = createSelector([getEvents], (data) => {
  let result = [],
    colors = [];

  data.forEach((site) => {
    let name = "";

    const daysAgoLevel = getDaysAgoLevel(site);
    name = DAYS_AGO_DESCRIPTIONS[daysAgoLevel];
    // Initialise the index
    if (!_.get(result, daysAgoLevel)) {
      colors[daysAgoLevel] = COLORS[daysAgoLevel];
      result[daysAgoLevel] = {
        name,
        value: 1,
      };
    }
    // Index already initialised, add to it
    else {
      colors[daysAgoLevel] = COLORS[daysAgoLevel];
      result[daysAgoLevel].value++;
    }
  });

  // Remove missing indexes while preserving colour keys
  return {
    data: result.filter((item) => {
      return typeof item !== "undefined";
    }),
    colors: colors.filter((item) => {
      return typeof item !== "undefined";
    }),
  };
});

const getEventIdForEventPair = (state, props) =>
  props.eventPair.conxtd_event_id;

export const getEventMetaForEventPair = createSelector(
  [getEventMetaList, getEventIdForEventPair],
  getEventMetaById
);

const getEventIdForBundle = (state) => getBundle(state).data.conxtd_event_id;
/**
 * getEventMetaForBundle - selects event meta for the current bundle based on conxtd_event_id
 */
export const getEventMetaForBundle = createSelector(
  [getEventMetaList, getEventIdForBundle],
  getEventMetaById
);

export const getOutstandingReportTitle = createSelector(
  [getEventMetaForBundle, getBundle],
  (eventMeta, bundle) => {
    const date = new moment().format("DD.MM.YY");
    // "Panic 01.06.2019"
    return bundle.data.name + " " + date;
  }
);
