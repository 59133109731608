/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import get from "lodash/get";

import SVGIcon from "components/Icons/SVGIcon";

import COL_DIVISION from "constants/COL_DIVISION"; 

import { getRouterLocation } from "services/redux/selectors/app/"; 

import { getOpenCloseByAreaIndex } from "services/redux/selectors";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

const FilterCards = function({
  reports,
  getEventMetaById,
}) {
  return reports.map((report, index) => {

    const col = get(COL_DIVISION, reports.length) || "col-12";

    const topEventMeta = getEventMetaById(report.alarm_id_top);
    const topIcon = topEventMeta.icon || "";
    const topColour = topEventMeta.colour || "#000";
    const topAlarmIcon = topEventMeta !== null ? <SVGIcon type={topIcon} color="#ffffff" /> : null;

    const bottomEventMeta = getEventMetaById(report.alarm_id_bottom);
    const bottomIcon = bottomEventMeta.icon || "";
    const bottomColour = bottomEventMeta.colour || "#000";
    const bottomAlarmIcon = bottomEventMeta !== null ? <SVGIcon type={bottomIcon} color={bottomColour} /> : null;

    return (
      <div
        className={`${col} text-center`}
        key={index}
      >
        <div id={topIcon} className="svg-icon text-white mb-1 openclose-overview-icon-top openclose-hide-small" style={{backgroundColor: topColour}}>
          {topAlarmIcon}
        </div>
        <div>
          <div className="h6 text-uppercase text-muted m-t-10 m-b-5 font-weight-normal openclose-overview-title">{report.title_top}</div>
          <hr className="mt-0 mb-0" style={{maxWidth: "90px"}}/>
          <div className="h6 text-uppercase text-muted m-b-10 m-t-5 font-weight-normal openclose-overview-title">{report.title_bottom}</div>
        </div>
        <div>
          <div id={topIcon} className="svg-icon text-white mb-1 openclose-show-small openclose-overview-icon-bottom-left" style={{backgroundColor: topColour}}>
            {topAlarmIcon}
          </div>
          <span className="h5 text-dark">{report.total_count}</span>
          <div id={bottomIcon} className="svg-icon mb-1 d-inline-block openclose-overview-icon-bottom" style={{color: bottomColour}}>
            {bottomAlarmIcon}
          </div>
        </div>
      </div>
    );
  });
};

class OpenCloseByAreaOverviewCard extends React.Component {

  render() {
    const {
      onReportView,
      reportIndex,
      getEventMetaById,
    } = this.props;

    const reports = reportIndex.data.reports.filter((report) => {
      if (report.total_count > 0) {
        return true;
      } else {
        return false;
      }
    });

    return (
      <div className="row pb-2 openclose-row">
        <div className="col-xl-12 d-flex flex-column justify-content-around" style={{minHeight: "200px"}}>
          <div className="row">
            <div className="col-12 text-center text-uppercase h5">
              <span className="text-dark" style={{fontSize: "22px"}}>{reportIndex.data.sites_count}</span><span className="text-muted"> Total</span>
            </div>
          </div>
          <div className="row">
            <FilterCards
              reports={reports}
              onReportView={onReportView}
              getEventMetaById={getEventMetaById}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { pathname } = getRouterLocation(state, props); 

  return {
    // Reports overview page
    onReportView: pathname.indexOf("/reports") !== -1,
    reportIndex: getOpenCloseByAreaIndex(state, props),
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withRouter(connect(mapStateToProps)(OpenCloseByAreaOverviewCard));