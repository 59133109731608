/**
 * Tether oauth connect page
 *
 * Calls backend to set up tether integrations
 * using site_id saved in sesisonStorage
 *
 * Once completed, redirects user to the site they
 * were viewing
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Loading from "components/Loading";

import { withRouter } from "react-router-dom";
import { withT } from "services/translation/";
import { requestTetherConnectStart } from "services/redux/actions";

function ConnectingTether({
  code,
  connectTether,
  connectSuccess,
  integration_id,
  site_id,
  originalPathname,
  jsonError,
  history,
  t
}) {
  useEffect(() => {
    // console.log({ code, integration_id, site_id });
    connectTether({
      params: { code, integration_id, enterprise_site_id: parseInt(site_id) }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (jsonError) {
    return <Redirect to={"/"} />;
  }

  if (connectSuccess) {
    // Redirect to original page
    // window.location = originalUrl;
    history.push(originalPathname);
    return null;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="mt-0 pb-2 header-title">{t("connecting_tether")}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Loading />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  let integration_id,
    site_id,
    originalPathname,
    jsonError = false;
  try {
    ({ integration_id, site_id, originalPathname } = JSON.parse(
      sessionStorage.getItem("tetherConnect")
    ));
  } catch (e) {
    jsonError = true;
  }

  return {
    code: state.app.router.params.code,
    connectSuccess:
      state.sites.current.integrations.actions.connectTether.success,
    integration_id,
    site_id,
    originalPathname,
    jsonError
  };
};
const mapDispatchToProps = {
  connectTether: requestTetherConnectStart
};
export default withRouter(
  withT(
    connect(mapStateToProps, mapDispatchToProps)(ConnectingTether),

    "site_overview.side_pane.manage_integrations.providers.tether"
  )
);
