import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadPinMapsStart,
  loadPinMapsSuccess,
  loadPinMapsFailed,
  loadPinMapsCancel,
  loadPinMapsReset,
} from "services/redux/actions/serviceDesk";

import conxtdOut from "apis/conxtdOut";

export function* watchLoadPinMaps() {

  yield actionWatcher({
    actions: {
      start: loadPinMapsStart,
      failed: loadPinMapsFailed,
      success: loadPinMapsSuccess,
      cancel: loadPinMapsCancel,
      reset: loadPinMapsReset,
    },
    saga: loadPinMapsRe,
  });
}

export function* loadPinMapsRe(action) {
  try {
    const { sudo_site_id } = action;

    if (!sudo_site_id) 
      throw new Error(
        "sudo_site_id is required for loading pin maps data"
      );

    const url = `/SudoPins/index/${sudo_site_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;

  } catch (e) {
    console.error(e);
  }
}
