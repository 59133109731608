import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect, useDispatch } from "react-redux";
import { Row } from 'react-bootstrap';
import ReactTable from "react-table";
import AddPin from 'components/Companies/Company/AssetEditor/PinMapping/AddPin';
import EditPin from 'components/Companies/Company/AssetEditor/PinMapping/EditPin';
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { loadEventMetaBackgroundStart } from "services/redux/actions/";
import { loadEventPairsStart } from "services/redux/actions/index";
import { 
  // loadSudoSiteIdStart,
  loadPinMapsStart
 } from 'services/redux/actions/serviceDesk';

import isEqual from 'lodash/isEqual';
import SVGIcon from "components/Icons/SVGIcon";
import Icon from "components/Icons/Icon";

function PinMapping({ 
  eventPairs,
  pinMapsData,
  loadingPinMaps, 
  loadingMetaData, 
  getEventMetaById, 
  sudoSite_id,
  enterprise_id,
  siteName,
  pinsLoaded,
  setPinsLoaded,
  eventMetaData,
 }) {

  const dispatch = useDispatch();

  const isMounted = useRef(false);

  const [mappedData, setMappedData] = useState([])
  const [pinType, setPinType] = useState("")
  const [sudoSiteId] = useState(sudoSite_id)

  useEffect(() => {
    if (eventMetaData.length < 1) {
      dispatch(loadEventMetaBackgroundStart());
    }
  }, [eventMetaData, dispatch])

  useEffect(() => {
    eventPairs.length <= 0 && dispatch(loadEventPairsStart());
    if (!pinsLoaded) {
      sudoSiteId && setPinsLoaded(true);
    }
  }, [eventPairs, dispatch, pinsLoaded, setPinsLoaded, sudoSiteId])

  useEffect(() => {
    if (sudoSiteId && Object.values(pinMapsData).length <= 0) {
      dispatch(loadPinMapsStart({
        sudo_site_id: sudoSiteId,
      }));
    }
  }, [sudoSiteId, sudoSite_id, pinMapsData, dispatch])

  // Memoize the logic for determining the mappedData based on pinMapsData
  const memoizedMappedData = useMemo(() => {
    if (pinMapsData && Object.keys(pinMapsData).length > 0) {
      if (pinMapsData['sudo_pins'].length > 0) {
        setPinType("SIA");
        setMappedData(pinMapsData['sudo_pins']);
        return pinMapsData['sudo_pins'];
      } else if (pinMapsData['fast_formats'].length > 0) {
        setPinType("Fast Format");
        setMappedData(pinMapsData['fast_formats']);
        return pinMapsData['fast_formats'];
      }
    }
    setPinType("");
    return []; // Return an empty array if conditions aren't met
  }, [pinMapsData]); // Recompute only when pinMapsData changes

  // Update mappedData based on the memoized data and loadingPinMaps
  useEffect(() => {
    if (!loadingPinMaps) {
      if (!isEqual(mappedData, memoizedMappedData)) {
        setMappedData(memoizedMappedData);
      }
    }
  }, [loadingPinMaps, memoizedMappedData, mappedData]); 

  // when component unmounts
  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (isMounted.current && mappedData?.[0] && !isEqual(mappedData, memoizedMappedData)) {
        setMappedData([]);
        setPinType("");
      }
      isMounted.current = false;
    }
  }, [
    mappedData, 
    loadingPinMaps, 
    pinMapsData, 
    pinType, 
    memoizedMappedData, 
    sudoSite_id, 
    sudoSite_id
  ])

    const columns = [
        {
          Header: "Pin",
          accessor: "pin",
          minWidth: 70,
          style: {
            textAlign: "left",
          },
        },
        {
          Header: "Alarm",
          minWidth: 200,
          style: {
            textAlign: "left",
          },
          Cell: ({ original }) => {
            let filteredAlarm = getEventMetaById(original.conxtd_alarm_id);
            return (
                <div
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    position: "relative",
                    top: "-1px",
                  }}
                >
                  <span
                    className="mr-2">
                    {loadingMetaData 
                    ?
                      <></>
                    :
                      <SVGIcon
                        type={filteredAlarm.icon}
                        color={filteredAlarm.colour}
                      />
                    }
                  </span>
                  <span>
                    {original.event_pair_name}
                  </span>
                </div>
            )
          }
        },
        {
          Header: "Custom Text",
          accessor: "custom_text",
          minWidth: 250,
          style: {
            textAlign: "left",
          },
        },
        {
          Header: "Reverse",
          accessor: "reverse",
          minWidth: 70,
          style: {
            textAlign: "center",
          },
          Cell: ({ original }) => {
            return (
                <div>
                  <Icon
                    className={`fas fa-${original.reverse === "Y" ? 'check' : 'times'}`}
                  />
                  
                </div>
            )
          }
        },
      ];

  return (
    <>
      <div>
        <Row>
          <div className="d-flex flex-column w-100">
            <div 
              id="pin-mapping-type"
            >
              <div className={`d-flex flex-row align-items-center text-dark ${pinType ? "" : "d-none"}`}>
                <Icon className={`text-left fas fa-bell mr-2 ${pinType ? "" : "d-none"}`}/>
                <span className="text-uppercase">{pinType ? pinType : ""}</span>
              </div>
              <div
                id="description-button-container"
                className={`d-flex`}
              >
                <span
                  id="table-description"
                  // className={`text-dark ${pinType ? "" : "d-none"}`}
                  className={`text-dark`}
                >
                  Current pin mapping configuration.
                </span>
                <div className="table-buttons">
                      {pinType 
                      ?
                      <div className="d-flex flex-row">
                        <div>
                          <EditPin siteName={siteName} sudoSiteId={sudoSiteId} enterprise_id={enterprise_id ?? null} pinType={pinType} mappedData={mappedData} />
                        </div>
                        <div className="ml-1">
                          <AddPin siteName={siteName} sudoSiteId={sudoSiteId} enterprise_id={enterprise_id ?? null} pinType={pinType} mappedData={mappedData} />
                        </div>
                      </div>
                      :
                      <div>
                        <AddPin siteName={siteName} sudoSiteId={sudoSiteId} enterprise_id={enterprise_id ?? null} pinType={pinType} mappedData={mappedData} />
                      </div>
                      }
                </div>
              </div>
            </div>
            <div className="col-12">
              <ReactTable
                className="pin-map-table"
                columns={columns}
                data={mappedData}
                defaultPageSize={10}
                noDataText={loadingPinMaps ? "Loading..." : "No data..."}
                defaultSorted={[
                  {
                    id: "pin",
                    desc: false,
                  },
                ]}
              />
            </div>
          </div>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    eventPairs: state.eventPairs.data,
    eventMetaData: state.eventMeta.data,
    loadingMetaData: state.eventMeta.loading,
    loadingPinMaps: state.serviceDesk.loadingPinMaps,
    pinMapsData: state.serviceDesk.pinMaps,
    darkMode: state.app.darkMode,
    sites: state.sites.all.data,
    siteDetail: state.enterpriseManager.siteDetail.enterprise_site,
    getEventMetaById: createGetEventMetaById(state, props),
  };
};

export default connect(mapStateToProps, null)(PinMapping);
