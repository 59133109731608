import React from "react";

import DefaultView from "./DefaultView";

/**
 * Enterprise dashboard template
 *
 * Returns the correct template/config based on user type (todo)
 */

class EnterpriseTemplate extends React.Component {
  render() {
    return <DefaultView {...this.props} />;
  }
}
export default EnterpriseTemplate;
