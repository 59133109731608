import React from "react";
import PropTypes from "prop-types";

import { Card, ListGroup } from "react-bootstrap";

function Fields({ fields }) {
  return fields.map(({ name, value }, index) => (
    <ListGroup.Item key={index} className="px-0">
      <span className="float-left col-4 px-0">{name}</span>
      <span className="float-right text-right col-8 px-0">{value}</span>
    </ListGroup.Item>
  ));
}

class GroupInfo extends React.Component {
  render() {
    const { group, noInfoAvailable } = this.props;

    const fields = [
      {
        name: group.custom_field_name_1 || null,
        value: group.custom_field_value_1 || null
      },
      {
        name: group.custom_field_name_2 || null,
        value: group.custom_field_value_2 || null
      },
      {
        name: group.custom_field_name_3 || null,
        value: group.custom_field_value_3 || null
      },
      {
        name: group.custom_field_name_4 || null,
        value: group.custom_field_value_4 || null
      }
    ]
      // Filter out invalid/null fields
      .filter(({ name, value }) => name && value);

    return (
      <Card className="text-left">
        <Card.Body>
          <ListGroup variant="flush">
            {fields.length === 0 ? noInfoAvailable : <Fields fields={fields} />}
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
}
GroupInfo.propTypes = {
  group: PropTypes.object.isRequired,
  noInfoAvailable: PropTypes.node.isRequired
};
export default GroupInfo;
