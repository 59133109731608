import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

import exception from "./exception";
import scheduled from "./scheduled";
import outstanding from "./outstanding";
import openclosebyarea from "./openclosebyarea"; 
import common from "./common";
import overview from "./overview";
import defaultReport from "./default";

export default reduceReducers(
  common,
  combineReducers({
    scheduled,
    outstanding,
    openclosebyarea, // 09/21 - open close by area
    exception,
    overview,
    default: defaultReport
  })
);
