import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Button, Form, Col } from "react-bootstrap";

import Loading from "components/Loading";

import Alert from "components/Common/Alert";

import NameField from "./NameField";

import {
  requestChangeProfileStart,
  requestChangeProfileNotified
} from "services/redux/actions/";

class UserDetailsForm extends React.Component {
  render() {
    const { user, t } = this.props;

    const { loading } = user.actions.changeProfile;

    if (user.actions.changeProfile.success === true) {
      Alert({
        text: t("user_details.profile_updated_successfully"),
        icon: "success"
      });
      // Reset success message
      this.props.dispatch(requestChangeProfileNotified());
    }

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          const firstName = e.target.firstName.value;
          const lastName = e.target.lastName.value;
          const locale = user.language;
          const timezone = user.timezone;

          this.props.dispatch(
            requestChangeProfileStart({
              firstName,
              lastName,
              locale,
              timezone
            })
          );
        }}>
        <Form.Row>
          <NameField
            name="firstName"
            label={t("user_details.first_name")}
            defaultValue={user.firstName}
          />
          <NameField
            name="lastName"
            label={t("user_details.last_name")}
            defaultValue={user.lastName}
          />
        </Form.Row>

        <Form.Row>
          <Form.Group controlId="formGroupSave" as={Col} md="12">
            <Button
              variant="primary"
              type="submit"
              className="float-right"
              disabled={loading ? 1 : 0}>
              {loading ? <Loading /> : null} {t("user_details.save_changes")}
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
UserDetailsForm = withT(
  connect(mapStateToProps)(UserDetailsForm),
  "user.profile"
);

export { UserDetailsForm };
