/**
 * Notification actions
 *
 * Add, delete, load
 */
import { all } from "redux-saga/effects";

import { watchAddRule } from "./add";
import { watchRemoveRule } from "./remove";
import { watchLoadRules } from "./load";

export default function* rootSaga() {
  yield all([watchAddRule(), watchRemoveRule(), watchLoadRules()]);
}
