import {
  call,
  take,
  race,
  takeEvery,
  put,
  takeLatest
} from "redux-saga/effects";

import * as actions from "services/redux/actions";

import { liveRefreshFor } from "../../common";

import conxtdOut from "apis/conxtdOut";

import { toGroupsBackgroundLoader } from "services/redux/sagas/links";

import { mockEnabled } from "services/redux/selectors/";
import reportsIndexMock from "./reportsIndex.mock.json";

const links = {
  toEventMetaBackgroundLoader: function*() {
    yield put(actions.loadEventMetaBackgroundStart());
  }
};

/**
 * Reports Index Live Refresh watcher
 */
export function* watchLiveRefresh() {
  // Link overview report loading to event meta
  yield takeLatest(
    ["LOAD_REPORTS_OVERVIEW_START"],
    links.toEventMetaBackgroundLoader
  );

  yield takeLatest(
    // Pre-loading for breadcrumbs
    ["LOAD_REPORTS_OVERVIEW_START"],
    toGroupsBackgroundLoader
  );

  yield takeEvery("LOAD_REPORTS_OVERVIEW_START", function*(action) {
    // const action =
    yield race({
      task: call(loadStart, action),
      cancel: take([
        "LOAD_REPORTS_OVERVIEW_CANCEL",
        "LOAD_REPORTS_OVERVIEW_FAILED", //#failedRemoved
        "CANCEL_ALL_LIVE_REFRESH",
        "CANCEL_ALL"
      ])
    });
  });
}

/**
 * Live reports index
 * const {
    startAction,
    successAction,
    failedAction,
    apiCall,
    interval,
    key
  } = params;
 *
 * @action {group_id: (int), [day: Date()], type...}
 */
export function* loadStart(action) {
  const { interval, refresh, group_id, key } = action;

  const useTestData = mockEnabled();

  const apiCall = useTestData
    ? call(() => {
        console.log(reportsIndexMock);
        return { data: reportsIndexMock };
      })
    : call(function*() {
        let response = yield conxtdOut.get("/Reports/index/" + group_id);

        //
        // Object.keys(response.data).forEach(key => {
        //   if (
        //     !get(response.data[key], "totals.0.name") &&
        //     response.data[key].config.overview.title !== "Outstanding report"
        //   ) {
        //     console.warn("Remapping exception report");
        //     const failedLabel =
        //       response.data[key].config.overview.title === "Panic Exception"
        //         ? "Panic Failed"
        //         : "Fire Failed";
        //     response.data[key].totals = [
        //       {
        //         name: "Total sites",
        //         value: response.data[key].totals[0].sites,
        //         colour: "#27AE5F"
        //       },
        //       {
        //         name: failedLabel,
        //         value: response.data[key].totals[0].exceptions,
        //         colour: "#C03A2B"
        //       }
        //     ];
        //     // throw new TypeError(
        //     //   "totals provided in reports overview is not an array"
        //     // );
        //   }
        // });
        return response;
      });

  const params = {
    startAction: actions.loadReportsOverviewStart,
    startActionParams: action,
    successAction: actions.loadReportsOverviewSuccess,
    failedAction: actions.loadReportsOverviewFailed,
    apiCall,
    // apiCall: call(() => {}, "/Reports/index/" + group_id),
    interval,
    refresh,
    key
  };

  yield liveRefreshFor(params);
}

// Currently calls:
//  Scheduled:
//    Open, Close, Fire, Overnight
//  Exception:
//    Panic, Fire
// export function* loadStart(params) {
//   const year = moment().format("YYYY");
//   const month = moment()
//     .subtract(1, "month")
//     .format("MM");
//   const { enterprise_id } = params;

//   yield all([
//     // SCHEDULED
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadScheduledReportStart({
//         interval: null,
//         enterprise_id,
//         reportType: "close",
//         refresh: false,
//         live: true
//       })
//     ),
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadScheduledReportStart({
//         interval: null,
//         enterprise_id,
//         reportType: "open",
//         refresh: false,
//         live: true
//       })
//     ),
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadScheduledReportStart({
//         interval: null,
//         enterprise_id,
//         reportType: "fire",
//         refresh: false,
//         live: true
//       })
//     ),
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadScheduledReportStart({
//         interval: null,
//         enterprise_id,
//         reportType: "overnight",
//         refresh: false,
//         live: true
//       })
//     ),

//     // EXCEPTION
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadExceptionReportStart({
//         year,
//         enterprise_id,
//         reportType: "fire",
//         month
//       })
//     ),
//     fork(
//       function*(action) {
//         yield put(action);
//       },
//       actions.loadExceptionReportStart({
//         year,
//         enterprise_id,
//         reportType: "panic",
//         month
//       })
//     )
//   ]);

//   /*
//   try {
//     const response = {};
//     response.data = reportsIndex;
//     yield put(actions.loadReportsOverviewSuccess({ index: response.data }));
//   } catch (error) {
//     yield put(actions.loadReportsOverviewFailed({ error }));
//   }*/
// }
