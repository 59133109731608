import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import Loading from "components/Loading";

import { requestChangePasswordNotified } from "services/redux/actions/";

import Alert from "components/Common/Alert";

const MIN_PASSWORD_LENGTH = 12;
const OLD_MIN_PASSWORD_LENGTH = 8;

const requirements = [
  {
    key: "one_capital_letter",
    label: t => t("update_password.one_capital_letter"),
    validate: value => {
      return /(?=.*[A-Z])/.test(value);
    }
  },
  {
    key: "one_number",
    label: t => t("update_password.one_number"),
    validate: value => {
      return /(?=.*\d)/.test(value);
    }
  },
  {
    key: "one_lower_case_letter",
    label: t => t("update_password.one_lower_case_letter"),
    validate: value => {
      return /(?=.*[a-z])/.test(value);
    }
  },
  // {
  //   key: "one_special_character",
  //   label: t => t("update_password.one_special_character"),
  //   validate: value => {
  //     return /(?=.*[^\da-zA-Z])/.test(value);
  //   }
  // },
  {
    key: "at_least_x_characters",
    label: t => t("update_password.at_least_x_characters", MIN_PASSWORD_LENGTH),
    validate: value => value.length >= MIN_PASSWORD_LENGTH
  }
];

class UpdatePasswordForm extends React.Component {
  state = {
    current_password: "",
    current_password_valid: false,
    current_password_invalid: false,
    new_password: "",
    confirm_password: ""
  };
  getCurrentPassword = () =>
    (this.current_password && this.current_password.value) || "";
  getNewPassword = () => (this.new_password && this.new_password.value) || "";
  getConfirmNewPassword = () =>
    (this.confirm_password && this.confirm_password.value) || "";
  onChange = () => {
    this.setState({
      current_password: this.getCurrentPassword(),
      new_password: this.getNewPassword(),
      confirm_password: this.getConfirmNewPassword()
    });
  };
  componentDidUpdate() {
    const { user, t } = this.props;
    if (user.actions.changePassword.success === true) {
      Alert({
        text: t("update_password.password_updated_successfully"),
        icon: "success",
        didClose: () => {
          // Reset password fields
          this.setState({
            current_password: "",
            new_password: "",
            confirm_password: "",
            current_password_valid: false,
            current_password_invalid: false
          });
          this.current_password.value = "";
          this.new_password.value = "";
          this.confirm_password.value = "";
        }
      });
      // Reset success message
      this.props.dispatch(requestChangePasswordNotified());
    }
    if (user.actions.changePassword.error.status === 422) {
      Alert({
        text: t(
          "update_password.error_" + user.actions.changePassword.error.status
        ),
        icon: "error",
        didClose: () => {
          // this.confirm_password.blur();
          this.current_password.focus();
          this.current_password.setSelectionRange(
            0,
            this.current_password.value.length
          );
          this.setState({
            // Flag input invalid
            current_password_invalid: true,
            // Clear previous valid
            current_password_valid: false
          });
        }
      });
      // Reset success message
      this.props.dispatch(requestChangePasswordNotified());
    }
  }
  render() {
    const {
      t,
      showCurrentPasswordField = true,
      submitButtonText = t("update_password.update_password_button"),
      loading
    } = this.props;

    const {
      current_password,
      new_password,
      confirm_password,
      current_password_invalid,
      current_password_valid
    } = this.state;

    const newPasswordMeetsRequirements = requirements.reduce(
      (acc, { validate }) => {
        return acc && validate(new_password);
      },
      true
    );

    const currentPasswordMeetsRequirements =
      !showCurrentPasswordField ||
      current_password.length >= OLD_MIN_PASSWORD_LENGTH;

    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          const current_password = this.getCurrentPassword();
          const password = this.getNewPassword();
          const password_confirm = this.getConfirmNewPassword();

          this.props.onSubmit({
            current_password,
            password,
            password_confirm
          });
        }}>
        {this.props.children}
        <Form.Group controlId="formGroupRequirements">
          <Form.Label>{t("update_password.password_requirements")}</Form.Label>

          {requirements.map(({ key, validate, label }) => {
            const valid = validate(new_password);

            return (
              <Row key={key}>
                <Col>
                  <span style={valid ? { color: "#27AE60" } : {}}>
                    <Icon className={`fas fa-${valid ? "check" : "times"}`} />{" "}
                    {label(t)}
                  </span>
                </Col>
              </Row>
            );
          })}
        </Form.Group>

        {showCurrentPasswordField && (
          <Form.Row>
            <Form.Group controlId="formGroupCurrentPassword" as={Col} md="6">
              <Form.Label>{t("update_password.current")}</Form.Label>
              <Form.Control
                isValid={current_password_valid}
                isInvalid={current_password_invalid}
                type="password"
                autoComplete="current-password"
                name="current_password"
                required
                onChange={e => {
                  this.setState({
                    // Clear invalid on input
                    current_password_invalid: false,
                    // Show valid on input over min password length
                    current_password_valid:
                      e.target.value.length >= OLD_MIN_PASSWORD_LENGTH
                  });
                  return this.onChange(e);
                }}
                ref={r => (this.current_password = r)}
              />
            </Form.Group>
          </Form.Row>
        )}

        <Form.Row>
          <Form.Group controlId="formGroupNewPassword" as={Col} md="6">
            <Form.Label>{t("update_password.new_password")}</Form.Label>
            <Form.Control
              isValid={newPasswordMeetsRequirements}
              type="password"
              autoComplete="new-password"
              name="new_password"
              required
              onChange={this.onChange}
              ref={r => (this.new_password = r)}
            />
          </Form.Group>

          <Form.Group controlId="formGroupConfirmPassword" as={Col} md="6">
            <Form.Label>{t("update_password.confirm")}</Form.Label>
            <Form.Control
              isValid={
                newPasswordMeetsRequirements &&
                confirm_password === new_password
              }
              type="password"
              autoComplete="new-password"
              name="confirm_password"
              required
              onChange={this.onChange}
              ref={r => (this.confirm_password = r)}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formGroupSave">
          <Button
            variant="primary"
            type="submit"
            className="float-right"
            disabled={
              loading !== true &&
              currentPasswordMeetsRequirements &&
              newPasswordMeetsRequirements &&
              confirm_password === new_password
                ? 0
                : 1
            }>
            {loading ? <Loading /> : null} {submitButtonText}
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
UpdatePasswordForm = withT(
  connect(mapStateToProps)(UpdatePasswordForm),
  "user.profile"
);

export { UpdatePasswordForm };
