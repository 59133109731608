import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, Row, Col, Form, FormGroup } from "react-bootstrap";
import Select from 'react-select';
import conxtdOut from "apis/conxtdOut";
import Alert from "components/Common/Alert";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import {
  getGroupFromRoute,
  getGroups,
  getGroupsTreeOptions,
} from "services/redux/selectors/groups";
// import {
//   // loadGroupOverviewStart,
//   // loadGroupsBackgroundStart,
//   // requestAddGroupStart,
//   getGroupStructureStart,
//   getCountForMainPageStart
// } from "services/redux/actions/enterpriseManager";
// import { loadUserManagementAccessStart, loadGroupsBackgroundStart } from "services/redux/actions/";
// import Loading from "components/Loading";
import _ from "lodash";

import "./AddGroupModal.scss";

/**
 * Enterprise Management Add Group
 *
 * Allows user to add and delete group
 *
 **/

class AddGroupModal extends Component {
  constructor(props) {
    super(props);
    const { enterprise } = this.props;

    this.state = {
      groupIdForDelete: enterprise.root_group_id,
      selectedParentOption: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { show, enterprise } = this.props;

    if ((!prevProps.show) && show) { // show changing from false to true
      this.setState({
        selectedParentOption: _.find(this.props.groupstreeoptions, ['value', enterprise.root_group_id]),
      });
    }
  }

  handleChange = (event) => {
    const groupIdForDelete = event.target.value;
    this.setState({ groupIdForDelete });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // const { enterprise, group } = this.props;
    const { enterprise } = this.props;
    try {
      await conxtdOut.post(`/EnterpriseGroups/add/${enterprise.id}`, {
        parent_id: event.target.parent.value || enterprise.root_group_id,
        name: event.target.name.value,
        description: event.target.description.value,
        custom_group_id: event.target.custom_group_id.value,
        custom_field_name_1: event.target.custom_field_name_1.value,
        custom_field_value_1: event.target.custom_field_value_1.value,
        custom_field_name_2: event.target.custom_field_name_2.value,
        custom_field_value_2: event.target.custom_field_value_2.value,
        custom_field_name_3: event.target.custom_field_name_3.value,
        custom_field_value_3: event.target.custom_field_value_3.value,
      });
      /* Dispatch actions instead of page refresh for later
      Alert({
        text: "Group has been added.",
        icon: "success",
        timerProgressBar: true,
        timer: 1500,
      });

      /*   const params = {
        enterprise_id: enterprise.id,
        group_id: group_id,
        key: "all",
        refresh: true,
        interval: 30 * 1000,
      };

       this.props.dispatch(loadGroupOverviewStart(params));
      // close the form
      await new Promise((resolve) => setTimeout(resolve, 1500));
      this.props.onHide();
      */
      Alert({
        text: "Group has been added.",
        icon: "success",
        // showConfirmButton: false,
        timerProgressBar: true,
        timer: 5000,
      });
      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);

      // experimenting with alternatives to page reload - see https://conxtd.monday.com/boards/1612986077/views/59302442/pulses/2944002525
      // await new Promise((resolve) => setTimeout(resolve, 5000));

      // // setTimeout(
      //   this.props.dispatch(loadGroupsBackgroundStart({ enterprise_id: enterprise.id }))
      //   // , 5000);

      // // setTimeout(
      //   this.props.dispatch(getCountForMainPageStart({ enterpriseId: enterprise.id }))
      //   // , 5000);

      //   this.props.dispatch(
      //     loadUserManagementAccessStart({
      //       enterprise_id: enterprise.id,
      //       group_id: group.id,
      //     })
      //   );
  

      // setTimeout(function () {
      //   this.props.dispatch(getGroupStructureStart({ enterprise_id: enterprise.id }));
      //   this.props.dispatch(getCountForMainPageStart({ enterpriseId: enterprise.id }));
      // }, 1000);
      
    } catch (error) {
      let apiError = error.response.data.message;
      let customError = error.response.data.message;

      if (apiError.includes("Group already exists")) {
        customError = "Group already exists.";
      } else {
        return customError;
      }
      Alert({
        text: customError,
        icon: "error",
      });
    }
  };

  handleDelete = async (event) => {
    event.preventDefault();

    try {
      await conxtdOut.delete(
        `/EnterpriseGroups/deleteGroup/${this.state.groupIdForDelete}`
      );
      Alert({
        text: "Group has been deleted.",
        icon: "success",
        showConfirmButton: false,
      });
      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);
    } catch (error) {
      let apiError = error.response.data.message;
      let customError = error.response.data.message;

      if (apiError.includes("group when it has children")) {
        customError = "Cannot delete group when it has children.";
      } else if (apiError.includes("group with sites")) {
        customError = "Cannot remove a group with sites in it.";
      } else {
        return customError;
      }
      Alert({
        text: customError,
        icon: "error",
      });
    }
  };

  render() {
    const { groupstreeoptions } = this.props;
    const { selectedParentOption} = this.state;

    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Form className="add-group-form" onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              New Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <Row>
                <Col sm={7}>
                  <Form.Group controlId="NewGroup" style={{ width: "440px" }}>
                    <Form.Group controlId="parent" style={{ width: "440px" }}>
                      <Form.Label>Parent</Form.Label>
                      <Select
                        name="parent"
                        classNamePrefix="add-group-modal-parent-select"
                        options={groupstreeoptions}
                        value={selectedParentOption}
                        maxMenuHeight={400}
                        onChange={(e) => {
                          this.setState({ selectedParentOption: _.find(groupstreeoptions, ['value', e.value]) })
                        }}
                      />
                      {/* <Form.Control
                        as="select"
                        name="Parent"
                        className="mb-3"
                        required
                        placeholder="Parent"
                        defaultValue={enterprise.root_group_id}
                        onChange={(event) => this.handleChange(event)}
                      >
                        {groupstree.map((obj) => {
                          return (
                            <option key={obj.group_id} value={obj.group_id}>
                              {obj.group_name}
                            </option>
                          );
                        })}
                      </Form.Control> */}
                    </Form.Group>
                    <FormGroup controlId="name" style={{ width: "440px" }}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        className="mb-3"
                        required
                        placeholder="Name"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="description"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="Description"
                        className="mb-3"
                        // required
                        placeholder="Description"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_group_id"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Group Ref</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Group Ref"
                        placeholder="maximum character limit: 4"
                        maxLength="4"
                      />
                    </FormGroup>
                    <Form.Text
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      Custom Fields
                      <hr
                        className="borderBreak"
                        style={{ border: "1px solid black" }}
                      ></hr>
                    </Form.Text>
                    <FormGroup
                      controlId="custom_field_name_1"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 1"
                        className="mb-3"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_1"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 1"
                        className="mb-3"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_name_2"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 2"
                        className="mb-3"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_2"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 2"
                        className="mb-3"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_name_3"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 3</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 3"
                        className="mb-3"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_3"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 3</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 3"
                        className="mb-4"
                      />
                    </FormGroup>
                    
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex">
                <Button
                  className="mr-auto p-2"
                  variant="danger"
                  type="submit"
                  onClick={this.handleDelete}
                >
                  Delete
                </Button>
                <Button
                  className="mr-2 p-2"
                  variant="primary"
                  onClick={this.props.onHide}
                >
                  Close
                </Button>
                <Button className="p-2" variant="success" type="submit">
                  Update
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groups: getGroups(state, props),
    groupstreeoptions: getGroupsTreeOptions(state, props),
  };
};

export default connect(mapStateToProps)(AddGroupModal);
