import { all } from "redux-saga/effects";

import { watchLoadAccessBackground } from "./access";
import { watchLoadUserListBackground } from "./userList";
import { watchInvite } from "./invite";
import { watchSearch } from "./search";
import { watchRemove } from "./remove";
import { watchEdit } from "./edit";

export function* rootSaga() {
  yield all([
    watchLoadAccessBackground(),
    watchLoadUserListBackground(),
    watchInvite(),
    watchSearch(),
    watchRemove(),
    watchEdit()
  ]);
}
