import React from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

import "./DataTable.scss";

import { ButtonToolbar } from "react-bootstrap";

import SiteView from "components/Site/SiteView/";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import ReactTable from "react-table";
import "react-table/react-table.css";

import TimezoneDate from "components/Common/TimezoneDate";

// Bootstrap style for ReactTable
// import "components/Enterprise/Reports/Common/DataTableBootstrap.scss";

import _ from "lodash";

import GROUPS from "constants/GROUPS";

import {
  getOutstandingEventsFiltered,
  NA,
  getOutstandingReportTitle,
} from "services/redux/selectors";
import { getRouterParams } from "services/redux/selectors/app/";
import { getColumns } from "services/redux/selectors/reports/common/";
import { getConfig } from "services/redux/selectors/reports/common/";

import moment from "services/locale/momentInit.js";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";

import AllowAccessApiButton from "components/OutLocks/Common/AllowAccessApiButton";
import DenyAccessApiButton from "components/OutLocks/Common/DenyAccessApiButton";
import ViewApiButton from "components/OutLocks/Common/ViewApiButton";

const today = new moment();

/**
 * Custom ID / Site Name / [Group (path)] / Site ref / [...event data]
 */
class DataTable extends React.Component {
  state = {
    selectedSite: null,
    bundle_id: null,
  };
  onSelectSite = (site) => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      this.setState({
        selectedSite: site,
        bundle_id: null,
      });

      // Scroll type column into view (doesn't work very well)
      // document
      //   .querySelector(".header-type")
      //   .scrollIntoView({ behavior: "smooth", inline: "start" });

      // Only set bundle id if selecting for the first time
      // const bundle_id =
      //   selectedSite === null ? this.props.router.params.bundle_id || "" : "";

      // this.props.history.push(
      //   `/enterprise/${enterprise_id}/${group_id}/${site.id}/${bundle_id}`
      // );
    } else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        // this.props.history.push(`/enterprise/${enterprise_id}/${group_id}`);

        this.setState({
          selectedSite: null,
        });
      }, 600);
    }
  };

  render() {
    const { columns, events, router, config } = this.props;
    const { selectedSite } = this.state;

    if (this.props.events === null || typeof this.props.events === "undefined")
      return null;

    // const pageSize = events.length > 20 ? 20 : 10;
    const defaultPageSize = 20;

    const tableColumns = [
      {
        ...columns["CUSTOM_ID"],
        id: "custom-ref",
        accessor: (row) => row.custom_ref || NA(),
      },
      {
        Header: columns["NAME"].Header,
        minWidth: columns["NAME"].minWidth,
        id: "site-name",
        accessor: (row) => row.name || NA(),
        Cell: (row) => {
          return (
            <>
              <SiteNameCell
                {...row}
                selectSite={() => {
                  this.onSelectSite({
                    ...row.original,
                    id: row.original.enterprise_site_id,
                  });
                }}
              />
            </>
          );
        },
      },

      {
        ...columns["GROUP"],
        id: "group",
        accessor: (row) => {
          const path = _.get(row, "enterprise_groups.0.path");
          return path && path.join(GROUPS.GROUP_PATH_DELIMITER);
        },
      },

      {
        ...columns["SITE_REF"],
        id: "site-ref",
        accessor: (row) => row.site_ref || NA(),
      },
      {
        Header: "Time since event",
        className: "alarm-type",
        id: "recvd",
        accessor: (row) => {
          const recvd = new moment(row.recvd);
          const fromNow = recvd.fromNow();
          const daysAgo = -recvd.diff(today, "day");

          let daysAgoLevel = 1;
          // Over 1 month
          if (daysAgo > 28) {
            daysAgoLevel = 5;
          }
          // Less than 4 weeks
          if (daysAgo < 28) {
            daysAgoLevel = 4;
          }
          if (daysAgo < 7) {
            daysAgoLevel = 3;
          }
          if (daysAgo < 2) {
            daysAgoLevel = 2;
          }
          if (daysAgo < 1) {
            daysAgoLevel = 1;
          }

          return (
            <span className={`text-light inner days-ago-level-${daysAgoLevel}`}>
              <span className="float-left px-1">{fromNow}</span>
              {/* <span className="float-right">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={`tooltip-bottom`}
                            style={{
                              pointerEvents: "none"
                            }}>
                            {todayOrYesteday + recvd.format("LLLL")}
                          </Tooltip>
                        }>
                        <Icon icon="info" />
                      </OverlayTrigger>
                    </span> */}
            </span>
          );
        },
      },
      {
        Header: "Event time",
        id: "actual-time",
        width: 115,
        accessor: (row) => {
          const recvd = new moment(row.recvd).format("HH:mm DD/MM/YY");

          if (!row.timezone) return recvd;

          return <TimezoneDate date={row.recvd} timezone={row.timezone} />;
        },
      },
      {
        Header: "Area",
        id: "area",
        width: 55,

        accessor: (row) => {
          return row.area || "";
        },
      },
      {
        Header: "Zone",
        id: "zone",
        width: 55,

        accessor: (row) => {
          return row.zone || "";
        },
      },
      {
        Header: "Event text",
        id: "text",
        accessor: "text",
        width: 500,
        style: { whiteSpace: "unset" },
      },
    ];
    const bundle_id = parseInt(router.params.bundle_id);

    if (config.getShowAccessColumn(bundle_id)) {
      tableColumns.push({
        Header: "Access",
        id: "access",
        width: 240,

        Cell: ({ original }) => {
          return (
            <div>
              <AllowAccessApiButton
                site_id={original.enterprise_site_id}
                lock_id={original.zone}
                username={original.text}
              />
              <DenyAccessApiButton
                site_id={original.enterprise_site_id}
                lock_id={original.zone}
                username={original.text}
              />
            </div>
          );
        },
      });
    }
    //show action column for Confirmed Alarm
    if (config.getShowActionColumn(bundle_id)) {
      tableColumns.splice(
        7,
        0,

        {
          Header: "Status",
          id: "status",
          className: "alarm-type",
          accessor: (row) => {
            const acked = row.acked;
            if (acked === null)
              return (
                <span
                  className="text-light inner days-ago-level-1"
                  style={{ backgroundColor: "#777777" }}
                >
                  <span className="float-left px-1">Unassigned</span>
                </span>
              );
            else if (acked === "Y")
              return (
                <span className="text-light inner days-ago-level-1">
                  <span className="float-left px-1">In Progress</span>
                </span>
              );
          },
        },
        {
          Header: "Action",
          id: "action",
          width: 79,
          Cell: ({ original }) => {
            return (
              <div>
                <ViewApiButton
                  site_id={original.enterprise_site_id}
                  site_name={original.company}
                  time={original.recvd}
                  zone={original.zone}
                  event_text={original.text}
                  event_id={original.eventId}
                  timezone={original.timezone}
                />
              </div>
            );
          },
        }
      );
    }

    return (
      <>
        <ButtonToolbar className="p-2">
          <ExportDropdown
            disabled={events.length === 0}
            reportTitle={this.props.reportTitle}
            dataResolver={() => {
              const data = this.reactTable.getResolvedState().sortedData;

              let result = data.map((row) => {
                const recvd = new moment(row._original.recvd);
                const timeSinceEvent = recvd.fromNow();

                return [
                  row["custom-ref"],
                  row["site-name"],
                  row["group"],
                  row["site-ref"],
                  timeSinceEvent,
                  recvd.format("LLLL"),
                  row["zone"],
                  row["text"],
                ];
              });
              result.unshift([
                "Custom ID",
                "Site Name",
                "Group",
                "Site Ref",
                "Time since event",
                "Actual time",
                "Zone",
                "Event text",
              ]);

              return result;
            }}
          />
        </ButtonToolbar>
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={events}
          filterable
          defaultPageSize={defaultPageSize}
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          // Extends from scheduled/default column config
          columns={tableColumns}
          defaultSorted={[
            {
              id: "recvd",
              desc: false,
            },
          ]}
        />
        <SiteView
          site={selectedSite}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={(bundle_id) => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    events: getOutstandingEventsFiltered(state, props),
    reportTitle: getOutstandingReportTitle(state, props),
    columns: getColumns(state, props),
    router: { params: getRouterParams(state, props) },
    config: getConfig(state, props),
  };
};
export default connect(mapStateToProps)(DataTable);
