import React from "react";
import { connect } from "react-redux";

import { Row, Col, Card, Button } from "react-bootstrap";

import DOMPurify from 'dompurify';

import Loading from "components/Loading";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

import { withT } from "services/translation/";

import moment from "services/locale/momentInit.js";

import conxtdOut from "apis/conxtdOut";

import { 
  loadCallLogsStart,
  getTranscriptionStart,
} from "services/redux/actions/voiceCalls";

import {
  getEnterpriseFromRoute,
} from "services/redux/selectors/enterprises";

import "./CallLogs.scss";

class CallLogs extends React.Component {
  state = {
    transcriptionsExpanded: {}
  };

  componentDidMount() {
    const { site } = this.props;
    this.props.dispatch(
      loadCallLogsStart({ 
        enterprise_site_id: site.id,
      })
    ); 
  }

  componentDidUpdate(prevProps) {
    const { site } = this.props;
    if (site.id && site.id !== prevProps.site.id) {
      this.props.dispatch(
        loadCallLogsStart({ 
          enterprise_site_id: site.id,
        })
      );

      this.setState({
        transcriptionsExpanded: {}
      })
    }
  }

  toggleExpandTranscription = (transcriptionElementId) => {
    const expanded = typeof this.state.transcriptionsExpanded[transcriptionElementId] === "undefined" 
                      ? false 
                      : this.state.transcriptionsExpanded[transcriptionElementId]

    this.setState({
      transcriptionsExpanded: { ...this.state.transcriptionsExpanded, [transcriptionElementId]: !expanded }
    })
  }

  render() {

    const { enterprise, callLogs, loadingCallLogs, t } = this.props;

    return (
      <div className="px-2">
        <div className="row no-gutters pb-3">
          <div className="col-12 d-flex">
            {loadingCallLogs && <Loading />}
          </div>
        </div>
        {callLogs 
          ? callLogs.map((callLog, index) => {

            const logCreatedTimeMoment = new moment(callLog.created);

            const monthString = logCreatedTimeMoment.format("MMM");
            const day = logCreatedTimeMoment.format("DD");
            const dayString = logCreatedTimeMoment.format("dddd");
            const time = logCreatedTimeMoment.format("HH:mm");

            const expanded = typeof this.state.transcriptionsExpanded[`transcription-${callLog.recording_sid}`] === "undefined" 
                                  ? false 
                                  : this.state.transcriptionsExpanded[`transcription-${callLog.recording_sid}`]

            return (
              <Card 
                key={index}
                bg="light"
                className="px-2 py-1"
              >
                <Row >
                  <Col xs="4" sm="3" style={{ textAlign: "left" }} className="col-left-circle">
                    <Row className="no-gutters justify-content-center align-items-center circle-date-container">
                      <div className="circle-date-element">
                        <Tooltip
                          placement="top"
                          description={logCreatedTimeMoment.format("HH:mm DD/MM/YYYY") + " Europe\\London"}
                        >
                          <div className="circle-date-text">
                            <span className="circle-date-day">{day}</span>
                            <br />
                            <span className="circle-date-month">{monthString}</span>
                          </div>
                        </Tooltip>
                      </div>
                    </Row>
                    <Row className="no-gutters justify-content-center align-items-center">
                      <span className="col-day-string">
                          {dayString}
                      </span>
                    </Row>
                  </Col>
                  <Col xs="8" sm="9">
                    <Row className="no-gutters border-bottom align-items-center flex-nowrap col-mid-card">
                      <span className="user-icon text-dark"><Icon className="fas fa-user mr-2" /></span>
                      <span className="col-call-details">{`${callLog.user.first_name} ${callLog.user.last_name}`}</span>
                    </Row>
                    <Row className="no-gutters border-bottom align-items-center flex-nowrap">
                      <span className="phone-icon"><Icon className="fas fa-phone mr-2" /></span>
                      <span className="col-call-details">{`${callLog.enterprise_keyholder.name}: ${callLog.to_number}`}</span>
                    </Row>
                    <Row className="no-gutters align-items-center flex-nowrap row-time">
                      <span className="col-call-details col-time">{time}</span>
                    </Row>
                  </Col>
                </Row>
                {callLog.recording &&
                  <div>
                    <Row className="no-gutters align-items-center flex-nowrap">
                      <Col xs="10">
                        <audio controls preload="metadata" id={callLog.recording_sid} className="call-audio-control">
                          <source src={callLog.recording} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </Col>
                      <Col xs="2">
                        <Row className="justify-content-center align-items-center">
                          <Tooltip
                            placement="bottom"
                            description="Download"
                          >
                            {/* <a href={callLog.recording + "?Download=true"} download> */}
                            <a href={`${conxtdOut.BASE_URLS[conxtdOut.environment]}/TwilioRequest/downloadRecording/${callLog.recording_sid}`} download>
                              <span className="download-icon text-dark"><Icon className="fas fa-cloud-download-alt" /></span>
                            </a>
                          </Tooltip>
                          {enterprise.transcription &&
                            <Tooltip
                              placement="bottom"
                              description="View transcription (beta)"
                            >
                              <span 
                                className="file-icon text-dark"
                                onClick={() => {
                                  if (!callLog.transcription) {
                                    this.props.dispatch(
                                      getTranscriptionStart({ 
                                        enterprise_id: enterprise.id,
                                        recording_sid: callLog.recording_sid,
                                      })
                                    );
                                  }
                                  this.toggleExpandTranscription(`transcription-${callLog.recording_sid}`)
                                }}
                              >
                                <Icon className="fas fa-file-alt" />
                                <span className="transcribe-chevron">
                                  <Icon className={expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }/>
                                </span>
                              </span>
                            </Tooltip>
                          }
                        </Row>
                      </Col>
                    </Row>
                    <Row 
                      className={`no-gutters ${
                        expanded? "transcription-expanded" : ""
                      }`}
                      id={`transcription-${callLog.recording_sid}`}
                    >
                      <div className="transcription-container">
                        {callLog.transcription || callLog.transcription === "" ? null : <Loading />}
                        {callLog.transcription || callLog.transcription === "" 
                          ? <Row className="no-gutters align-items-center font-weight-bold pb-1">
                              <span className="pr-1">Transcription (beta)</span>
                              <Tooltip 
                                placement="right"
                                description="Help"
                              >
                                <Button
                                  variant="primary"
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    borderRadius: "50%",
                                    padding: "0",
                                  }}
                                  onClick={() => {
                                    window.Intercom('showArticle', 6209597) 
                                  }}
                                ><Icon 
                                  className="fas fa-question fa-xs"
                                  style={{
                                    position: "relative",
                                    bottom: "5px",
                                  }}
                                /></Button>
                              </Tooltip>
                            </Row>
                          : null
                        }
                        <div dangerouslySetInnerHTML={{__html: callLog.transcription !== "" ? DOMPurify.sanitize(callLog.transcription) : "No dialogue"}}></div>
                      </div>
                      
                    </Row>
                  </div>
                }
              </Card>
            )
          })
        : t("no_call_logs")}

      </div>
    )
  }

}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    callLogs: state.voiceCalls.callLogs,
    loadingCallLogs: state.voiceCalls.loadingCallLogs,
  };
};

export default withT(
  connect(mapStateToProps)(CallLogs),
  "site_overview.side_pane.voice_calls"
);