import React from "react";
import { connect } from "react-redux";

import { Button, Modal } from "react-bootstrap";

import { saveAs } from "file-saver";

import { getUptickLinkedSitesStart } from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import Loading from "components/Loading";
import Alert from "components/Common/Alert";
import Progress from "components/Common/EnterpriseManager/Progress";

import axios from "axios";
import conxtdOut from "apis/conxtdOut";

import _ from "lodash";

import "./BulkAssociationModal.scss";

class BulkAssociationModal extends React.Component {
  state = {
    downloadingCONXTD: false,
    downloadingUptick: false,
    uploadingSites: false,
    filename: '',
    uploadPercentage: 0,
    importErrors: '',
  }

  exportSitesAssociates = () => {
    this.setState({ downloadingCONXTD: true });

    const { enterprise } = this.props;

    const request = conxtdOut.get(`/uptick/exportSitesAssociates/${enterprise.id}`, { responseType: "arraybuffer" });

    request.then((response) => {
      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "CONXTD Import Template - Uptick - " + enterprise.name + ".xlsx");

      this.setState({ downloadingCONXTD: false });

    }).catch((error) => {
      this.setState({ downloadingCONXTD: false });

      console.log("Error on Uptick export site associates", error);
      Alert({
        text: "Issue exporting CONXTD sites. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
    })
  }

  getUptickSitesExport = (attempt) => {
    const { enterprise } = this.props;

    // this is a request to our backend. the backend will then call the uptick api to generate an export of the uptick properties.
    // the uptick api itself will not return the export. instead, it will return a link to the export. 
    // first, it will see if there is a cached copy of the export, and return a link to it if there is,
    // along with a status of 'GENERATED'.
    // if there isn't a cached export it will trigger the generation of the export and return a status of either 'PENDING' or 'PROCESSING'.
    // if we get a status of 'PENDING' or 'PROCESSING' we will have to keep calling the api until we get a status of 'GENERATED'
    // and a link to the export
    const request = conxtdOut.get(`/uptick/exportUptickProperties/${enterprise.id}`);

    request.then((response) => {
      if (response.data.status === "GENERATED" && response.data.url) {
        // uptick have generated the export on thier side, so we need to download it from the url they've given us
        axios.get(response.data.url).then(data => {
          try {
            const blob = new Blob([data.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "Uptick Sites Export - " + enterprise.name + ".csv");

            this.setState({ downloadingUptick: false });
          } catch(error) {
            this.setState({ downloadingUptick: false });

            console.log("Error on Uptick export csv", error);

            Alert({
              text: "Issue exporting Uptick sites. Please try again later.",
              icon: "warning",
              showConfirmButton: true,
            });
          }
        }).catch((error) => {
          this.setState({ downloadingUptick: false });

          console.log("Error on Uptick export csv", error);

          Alert({
            text: "Issue exporting Uptick sites. Please try again later.",
            icon: "warning",
            showConfirmButton: true,
          });
        });
      } else {
        // if the csv file hasn't been generated yet, wait 5 secs then make another attempt. do this up to 10 times
        if (attempt <= 10) {
          setTimeout(this.getUptickSitesExport, 5000, (attempt + 1))
        } else {
          this.setState({ downloadingUptick: false });

          if (response.data.status === "PENDING" || response.data.status === "PROCESSING") {
            Alert({
              text: "Export generating, please try again shortly.",
              
              icon: "warning",
              showConfirmButton: true,
            });
          } else {
            Alert({
              text: "Issue exporting Uptick sites. Please try again later.",
              icon: "warning",
              showConfirmButton: true,
            });
          }
        };
      }

    }).catch((error) => {
      this.setState({ downloadingUptick: false });

      console.log("Error on Uptick export csv", error);

      Alert({
        text: "Issue exporting Uptick sites. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
    })
  }

  exportUptickSites = () => {
    this.setState({ downloadingUptick: true });
    this.getUptickSitesExport(1); // first attempt
  }

  onFileChange = (e) => {
    this.setState({
      file: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  importSites = () => {
    this.setState({ uploadingSites: true });

    const { file } = this.state;
    const { enterprise } = this.props;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const request = conxtdOut.post(
        `/uptick/importSitesAssociates/${enterprise.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.setState({
              uploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            });

            // Clear percentage
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 1500);
          },
        }
      );

      request.then((response) => {
        let importErrors = "";

        if (response.data && response.data.errors && !_.isEmpty(response.data.errors)) {
          for (const [key, value] of Object.entries(response.data.errors)) {
            importErrors = importErrors + "Row " + key + ":\n";
            for (const [key2, value2] of Object.entries(value)) {
              importErrors = importErrors + " - " + key2 + ": " + value2 + "\n";
            }
          }

          this.setState({ importErrors: importErrors });
        }

        if (response.status === 200 && importErrors === "") {
          Alert({
            text: "File has been imported.",
            icon: "success",
            timerProgressBar: true,
            timer: 3000,
          });
        }

        this.props.dispatch(
          getUptickLinkedSitesStart({
            enterprise_id: enterprise.id,
          })
        );

        this.setState({ uploadingSites: false });

      }).catch((error) => {
        this.setState({ uploadingSites: false });
  
        console.log("Error on importing sites", error);
        Alert({
          text: "Issue importing sites Please try again later.",
          icon: "warning",
          showConfirmButton: true,
        });
      })
    } catch (err) {
      console.log(err);
    }
  }

  onHide = () => {
    this.setState({ importErrors: '' });
    this.props.onHide();
  }

  render() {
    const { downloadingCONXTD, downloadingUptick, uploadingSites, filename, uploadPercentage, importErrors } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Association
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div>
              1. Download CONXTD Sites for a list of your sites in CONXTD. This will show any that are already linked to Uptick sites.
            </div>
            <div className="mt-2 d-flex justify-content-start align-items-center">
              <Button
                className="mr-2"
                style={{ width: "117px" }}
                onClick={this.exportSitesAssociates}
                disabled={downloadingCONXTD}
              >CONXTD Sites</Button>
              { downloadingCONXTD && <Loading /> }
            </div>
            <div className="mt-4">
              2. Download Uptick Sites for a list of your sites in Uptick.
            </div>
            <div className="mt-2 d-flex justify-content-start align-items-center">
              <Button
                className="mr-2"
                style={{ width: "117px" }}
                onClick={this.exportUptickSites}
                disabled={downloadingUptick}
              >Uptick Sites</Button>
              { downloadingUptick && <Loading /> }
            </div>
            <div className="mt-4">
              3. Match up sites using site name / address data. Populate the CONXTD sites spreadsheet with Property Id and Site Name from the Uptick Sites spreadsheet. Import into CONXTD.
            </div>
            <div className="custom-file mt-2 mb-2">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={this.onFileChange}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {filename}
              </label>
            </div>

            <Progress percentage={uploadPercentage} />

            <div className={`bulk-import-${ importErrors === "" ? "hide" : "show" }-errors mt-3`}>
              <div className="font-weight-bold" style={{ color: "red" }}>Unable to save import due to the following errors. Please correct the errors and re-upload.</div>
              <div className="bulk-import-errors">{importErrors}</div>
            </div>

            <div className="mt-2">
              <Button
                style={{ width: "100%" }}
                variant="success"
                onClick={this.importSites}
                disabled={uploadingSites}
              >Import</Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            className="mr-2 p-2"
            variant="primary"
            onClick={this.onHide}
          >
            Close
          </Button>
        </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};

export default connect(mapStateToProps)(BulkAssociationModal);