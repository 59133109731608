//alarm-response
import React from "react";

import SVGIcon from "components/Icons/SVGIcon";

import moment from "services/locale/momentInit.js";

import { Tooltip } from "components/Common/Tooltip";

export const OtherEvent = (props) => {
  const  { recvd, alarm_text, area, zone } = props || {
    recvd: "",
    alarm_text: "",
    area: "",
    zone: "",
  };
  const { icon, caption } = props.alarm || {
    icon: "",
    caption: "",
  };

  const colour = !!props.overrideColor ? props.overrideColor : (props.alarm.colour || "#ffffff");

  const recvdMoment = recvd && new moment(recvd);
  const recvdMomentFromNow = recvd && recvdMoment.fromNow();
  // const monthYear = recvd && recvdMoment.format("LL");
  const time = recvd && recvdMoment.format("HH:mm");

  const IconComponent = (
    <div
      className="svg-container-alarm"
      style={{
        padding: "6px",
        minWidth: "32px",
        background: colour,
        borderRadius: "20px",
        margin: "2px",
      }}
    >
      <div
        id={icon}
        className="svg-icon"
        style={{
          width: "20px",
          height: "20px",
          margin: "0 auto",
          // position: "relative",
          // top: "-1px",
        }}
      >
        <SVGIcon type={icon} color={"#ffffff"} />
      </div>
    </div>
  );

  return (
    <Tooltip
      placement="left"
      TooltipComponent={
        <div className="text-left p-1">
          <div className="pb-1">
            <span className="font-14 font-weight-bold pr-2">{caption} </span>
            {/* <span
              className="float-right"
              style={{
                lineHeight: "24px",
              }}
            >
              {recvdMomentFromNow}
            </span> */}
          </div>
          <div>Time:&nbsp;{time}&nbsp;-&nbsp;{recvdMomentFromNow}</div>
          {/* <div>{monthYear}</div> */}
          <div>Area:&nbsp;{area}</div>
          <div>Zone:&nbsp;{zone}</div>
          <div>{alarm_text}</div>
        </div>
      }
      className="text-left"
    >
      {IconComponent}
    </Tooltip>
  );
};