import React from "react";
import { connect } from "react-redux";

import { setEnterpriseCallLimits } from "services/redux/actions";

import { 
  initiateVoiceCall,
} from "services/redux/actions/voiceCalls";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import Keyholders from "./Keyholders.js";

import conxtdOut from "apis/conxtdOut";

import "./VoiceCallOptions.scss";

class VoiceCallOptions extends React.Component {
  state = {
    device: null, 
    token: null,
  };

  initiateCall = (phoneNumber, keyholderUuid, keyholderName, siteName, siteId, customRef) => {
    // initiateCall is passed down on the props to Keyholders/KeyholderCard and called from there
    if (phoneNumber !== "") {

      let phoneNumberInternational = phoneNumber;
      if (String(phoneNumberInternational[0]) === "0") {
        phoneNumberInternational = "+44" + String(phoneNumberInternational).substring(1);
      }

      // initiateVoiceCall sets the callStatus to "initiate".
      // componentDidUpdate on VoiceCallControls watches for this change in the callStatus,
      // and when that happens it calls makeCall() with the call options.
      // makeCall uses the Twilio device to start the call, and added the "show" class to call-controls-container to show the controls
      this.props.dispatch(
        initiateVoiceCall({ 
          phoneNumber: phoneNumberInternational,
          // recordChannel: document.getElementById('recordChannel').value,
          recordChannel: "record-from-answer-dual",
          // transcribe: document.getElementById('transcribe').value,
          keyholderUuid: keyholderUuid,
          keyholderName: keyholderName,
          siteName: siteName,
          siteId: siteId,
          customRef: customRef,
        })
      );
      
      // document.getElementById('call-controls-container').classList.add("show");
    }
  }

  componentDidMount() {
    const { enterprise } = this.props;
    const requestToken = conxtdOut.post(`/TwilioRequest/getCallLimit/${enterprise.id}`);

    requestToken.then((response) => {
      this.props.dispatch(
        setEnterpriseCallLimits({ 
          enterprise_id: enterprise.id,
          call_limits: response.data.call_limits,
        })
      );
    }).catch((error) => {
      console.log("Error on get call limits", error);
    })
  }

  render() {
    const { enterprise, site } = this.props;
    
    const { call_limits } = enterprise;

    let callLimitWarning = null;

    if (call_limits === null) {
      callLimitWarning = <div className="row py-2 bg-warning text-white justify-content-center call-limit-message">Call feature setup required</div>
    } else if (call_limits === 90) {
      callLimitWarning = <div className="row py-2 bg-warning text-white justify-content-center call-limit-message">Call limit at 90% usage</div>
    } else if (call_limits === 100) {
      callLimitWarning = <div className="row py-2 bg-danger text-white justify-content-center call-limit-message">Call limit reached, please top up</div>
    }

    return (
      <div>
        {callLimitWarning}
        <div className="row px-2">
          <div id="controls" className="col">
            <div id="call-controls">
              {/* <div id="record">
                <p className="instructions">Recording Types:</p>
                <select name="recordChannel" id="recordChannel">
                  <option value="">No Recording</option>
                  <option value="record-from-answer">Mono Recording</option>
                  <option value="record-from-answer-dual">Dual Recording</option>
                </select>
              </div> */}
              {/* <div>
                <p className="instructions">Transcribe</p>
                <select name="transcribe" id="transcribe">
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div> */}
              <div>
                <p className="instructions">Make a Call:</p>
                <Keyholders 
                  site={site}
                  initiateCall={this.initiateCall}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};

export default connect(mapStateToProps)(VoiceCallOptions);