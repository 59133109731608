import React from "react";
import PropTypes from "prop-types";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import { ButtonToolbar } from "react-bootstrap";

function Toolbar({ sites, dataResolver, dataResolverFull, reportTitle, reportType }) {
  return (
    <ButtonToolbar className="p-2">
      <ExportDropdown
        disabled={sites.length === 0}
        reportTitle={reportTitle}
        dataResolver={dataResolver}
      />&nbsp;
      {(reportType === "peoplecounthourly" || reportType === "peoplecount") && //08/21 add another dropdown allowing export of full data
        <ExportDropdown
          disabled={sites.length === 0}
          reportTitle={reportTitle}
          dropdownTitle={"Export Full"}
          dataResolver={dataResolverFull}
        />
      }
    </ButtonToolbar>
  );
}
Toolbar.propTypes = {
  /** @param sites list of sites */
  sites: PropTypes.array.isRequired,
  /** @param dataResolver export data resolver */
  dataResolver: PropTypes.func.isRequired,
  /**
   * @param reportTitle
   * title of report for export filename
   * */
  reportTitle: PropTypes.string.isRequired
};

export default Toolbar;
