import React from "react";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

import { getUptickSitesStart } from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getUptickSitesWithLinks } from "services/redux/selectors/enterpriseManager";

import DataTableManual from "components/Common/DataTableManual/DataTableManual";

import UptickLinkSiteModal from "./UptickLinkSiteModal";

class DataTable extends React.Component {
  state = {
    displayPageIndex : 0,
    uptickLinkSiteModalShow: false,
    selectedUptickSite: {
      id: "",
      name: ""
    },
    conxtdSite: {
      id: "",
      name: "",
      address: ""
    },
  }

  render() {
    const { displayPageIndex } = this.state;

    const {
      enterprise,
      uptickSitesWithLinks,
      pagination,
    } = this.props;

    let loadingMsg = "No data available...";

    const columns = [
      {
        key: "ID",
        Header: "Uptick Site ID",
        id: "id",
        width: 100,
        accessor: "id",
        filterable: false,
      },
      {
        key: "REF",
        Header: "Uptick Site Ref",
        id: "ref",
        width: 200,
        accessor: "ref",
        style: {
          fontWeight: "bold",
        }
      },
      {
        key: "NAME",
        Header: "Uptick Site Name",
        id: "name",
        width: 200,
        accessor: "name",
        style: {
          fontWeight: "bold",
        }
      },
      {
        key: "CONXTD_SITE_NAME",
        Header: "CONXTD Site Name",
        id: "conxtd_site_name",
        width: 200,
        accessor: "conxtd_site_name",
        sortable: false,
        filterable: false,
        style: {
          borderLeft: "3px solid #DEE2E6",
          fontWeight: "bold",
        },
        getHeaderProps: () => {
          return {
            style: {
              borderLeft: "3px solid #DEE2E6",

            }
          }
        },
      },
      {
        key: "CONXTD_ADDRESS",
        Header: "CONXTD Address",
        id: "conxtd_address",
        minWidth: 300,
        accessor: "conxtd_address",
        sortable: false,
        filterable: false,
      },
      {
        key: "SUDO_SITE_ID",
        Header: "CONXTD Site Id",
        id: "sudo_site_id",
        width: 30,
        accessor: "sudo_site_id",
        sortable: false,
        filterable: false,
        show: false,
      },
      {
        key: "LINK_SITE_BUTTON",
        Header: "Link Site",
        id: "link_site_button",
        width: 120,
        sortable: false,
        filterable: false,
        accessor: ( data ) => {
          return data.sudo_site_id ? "Change Site" : "Link Site";
        },
        Cell: ( data ) => {
          const { original } = data;
          return <Button
            onClick={() => {
              this.setState({
                selectedUptickSite: {
                  id: original.id,
                  name: original.name,
                },
                conxtdSite: {
                  id: original.sudo_site_id,
                  name: original.conxtd_site_name,
                  address: original.conxtd_address,
                  sudo_site_associate_id: original.sudo_site_associate_id,
                },
                uptickLinkSiteModalShow: true
              })
            }}
            variant={original.sudo_site_id ? "primary" : "success"}
          >
            {data.row.link_site_button}
          </Button>
        },
      },
    ];

    let uptickLinkSiteModalClose = () => this.setState({ uptickLinkSiteModalShow: false });

    return (
      <div>
        <div className="card visual-data">
          <DataTableManual
            columns={columns}
            data={uptickSitesWithLinks}
            loadDataAction={getUptickSitesStart}
            loadDataParams={{
              enterprise_id: enterprise.id,
            }}
            pages={pagination.pageCount}
            page={displayPageIndex} // keeps track of the page index that the table component displays (0-indexed)
            setDisplayPageIndex={(pageIndex) => {
              this.setState({
                displayPageIndex: pageIndex,
              })
            }}
            filterable={true}
            sortable={uptickSitesWithLinks.length > 0}
            defaultPageSize={20}
            noDataText={loadingMsg}
          />
        </div>
        <UptickLinkSiteModal 
          show={this.state.uptickLinkSiteModalShow}
          onHide={uptickLinkSiteModalClose}
          selectedUptickSite={this.state.selectedUptickSite}
          conxtdSite={this.state.conxtdSite}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    uptickSitesWithLinks: getUptickSitesWithLinks(state),
    pagination: state.enterpriseManager.uptick.pagination,
  };
};
export default connect(mapStateToProps)(DataTable);