import React from "react";

import { Link } from "react-router-dom";

import { withT } from "services/translation/";

import IntegrationList from "./IntegrationList/";

function ManageIntegrations({ onBackToOverview, t }) {
  return (
    <>
      <div className="row px-2 mt-4">
        <div className="col-md-12">
          <Link to="#" onClick={onBackToOverview}>
            {t("back_to_overview")}
          </Link>
        </div>
      </div>

      <div className="row px-2 mt-3">
        <div className="col-md-12">
          <IntegrationList />
        </div>
      </div>
    </>
  );
}

export default withT(
  ManageIntegrations,
  "site_overview.side_pane.manage_integrations"
);
