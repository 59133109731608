import { takeLatest, takeEvery } from "redux-saga/effects";

import * as links from "services/redux/sagas/links";
import * as exception from "./exception";

export function* rootSaga() {
  // Groups - When exception reports finish loading,
  // start a background task to load sites
  yield takeLatest(
    ["LOAD_EXCEPTION_REPORT_START"],
    links.toSitesBackgroundLoader
  );

  // Link scheduled report loading to event meta
  yield takeLatest(
    ["LOAD_EXCEPTION_REPORT_START"],
    links.toEventMetaBackgroundLoader
  );

  yield takeEvery("LOAD_EXCEPTION_REPORT_START", exception.loadStart);

  // yield all([bundle.watchLiveRefresh(), outstanding.watchLiveRefresh()]);
}
