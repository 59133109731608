import { createSelector } from "reselect";

import get from "lodash/get";

// export const getSites = state => state.sites.data;

import { getEnterpriseId } from "services/redux/selectors/reports/router";
import { getCurrentGroup, getGroups, getGroupById } from "./groups";
import { getRouterParams } from "services/redux/selectors/app/";
import {
  getEnterpriseFromRoute,
} from "services/redux/selectors/enterprises";


export const getSites = createSelector(
  [(state) => state.sites.all.data, getEnterpriseId],
  (sites, enterprise_id) => {
    return sites[enterprise_id] || [];
  }
);
export const getSitesLoading = (state) => state.sites.all.loading;

// # Non-memoized
export const getSiteById = (sites, id) =>
  sites.find((site) => site.id === id) || {};

export const getSiteByRef = (sites, ref) =>
  sites.find((site) => site.site_ref === ref) || {};

export const getSiteBySudoSiteId = (sites, ssId) =>
  sites.find((site) => site.sudo_site ? site.sudo_site.id === ssId : {}) || {};

export const getCurrentSite = createSelector(
  [
    // Note: component props are being merged here
    (state, props) => {
      const site = props.site || {};
      // Merge site list/group site data with site view (current)
      return {
        ...site,
        ...state.sites.current,
        // Make sure props.site.id takes precedence here
        id: site.id || null,
      };
    },
    getCurrentGroup,
    getRouterParams,
  ],
  (current, group, router) => {
    const id = current.id || router.site_id;
    return {
      ...current,
      id,
      // Timeframe is inherited from current group
      timeframe: group.timeframe,
    };
  }
);

export const getBundleId = (state, props) => {
  return props.bundle_id || get(props, "router.params.bundle_id");
};

export const getSitesByGroup = (state, sitesList, enterprise, groupId) => {
  const enterpriseGroups = getGroups(state);
  let groups = enterpriseGroups; // top level array of group objects for the enterprise. we'll work with this if we aren't passed a specific group id to narrow it down.
  if (groupId && groupId !== enterprise.root_group_id) { // we've been passed a specific group id to narrow it down, so just get the specific group we're interested in
    groups = [ getGroupById(enterpriseGroups, groupId) ]; // getGroupById returns a group object, so put it in an array to make it consistent
  }

  // go through the groups objects, and recursively through their children, to gather all the group ids under the group/groups we're interested in
  const getGroupIds = (groups) => {
    return groups.reduce((acc, group) => {
      if (group.children.length > 0) {
        return [...acc, group.id, ...getGroupIds(group.children)];
      }
      return [...acc, group.id];
    }, []);
  }
  const groupIds = getGroupIds(groups);

  // filter sites based on whether any of the groups that they are included in appear in the list of groups we're interested in
  return sitesList.filter((site) => {
    if (site.enterprise_groups && site.enterprise_groups.length > 0) {
      let siteInGroups = false;
      for (let i=0; i<site.enterprise_groups.length; i++) {
        if (groupIds.includes(site.enterprise_groups[i].id)) {
          siteInGroups = true;
          break;
        }
      };
      return siteInGroups;
    }
    return false;
  });
};

export const createGetSitesByGroup = createSelector(
  [(state) => state, getSites, getEnterpriseFromRoute],
  (state, sitesList, enterprise) => {
    return (groupId) => {
      return getSitesByGroup(state, sitesList, enterprise, groupId);
    }
  }
);
