import React from "react";

const exit = props => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 314 314"
    width="100%"
    height="100%">
    <g>
      <path
        fill={props.color}
        d="M304,75.607H10c-5.522,0-10,4.477-10,10v142.785c0,5.523,4.478,10,10,10h294c5.522,0,10-4.477,10-10V85.607
		C314,80.084,309.522,75.607,304,75.607z M294,218.393H20V95.607h274V218.393z"
      />
      <path
        fill={props.color}
        d="M55.995,196.333h37.838c5.522,0,10-4.477,10-10s-4.478-10-10-10H65.995v-8h22.334c5.522,0,10-4.477,10-10s-4.478-10-10-10
		H65.995v-8h27.838c5.522,0,10-4.477,10-10s-4.478-10-10-10H55.995c-5.522,0-10,4.477-10,10v56
		C45.995,191.856,50.473,196.333,55.995,196.333z"
      />
      <path
        fill={props.color}
        d="M117.234,194.619c1.717,1.16,3.663,1.715,5.59,1.715c3.21,0,6.362-1.542,8.295-4.402l10.633-15.737l10.633,15.737
		c1.933,2.86,5.085,4.402,8.295,4.402c1.927,0,3.874-0.556,5.59-1.715c4.576-3.092,5.779-9.308,2.688-13.884l-15.136-22.401
		l15.136-22.401c3.092-4.576,1.889-10.792-2.688-13.884c-4.576-3.093-10.792-1.889-13.885,2.687l-10.633,15.737l-10.633-15.737
		c-3.093-4.576-9.311-5.779-13.885-2.687c-4.576,3.092-5.779,9.308-2.688,13.884l15.136,22.401l-15.136,22.401
		C111.455,185.311,112.658,191.527,117.234,194.619z"
      />
      <path
        fill={props.color}
        d="M189.671,196.333c5.522,0,10-4.477,10-10v-56c0-5.523-4.478-10-10-10s-10,4.477-10,10v56
		C179.671,191.856,184.148,196.333,189.671,196.333z"
      />
      <path
        fill={props.color}
        d="M216.671,137.667h11.334v48.667c0,5.523,4.478,10,10,10s10-4.477,10-10v-48.667h10c5.522,0,10-4.477,10-10s-4.478-10-10-10
		h-41.334c-5.522,0-10,4.477-10,10S211.148,137.667,216.671,137.667z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default exit;