//alarm-response

import React from "react";

import get from "lodash/get";

import { getExpanded } from "services/redux/selectors/alarmresponse";

import ChildRowColumns from "./ChildRowColumns";

function TrGroupComponent(component) {
  const { 
    inAlarmSite,
    id,
    // filtered,
    style,
    getEventMetaById,
    showSOP,
    onClickViewSOP,
    t,
  } = component;

  const expandedContent = getExpanded(inAlarmSite)
    ? inAlarmSite.sudo_state.map((sudo_state, index) => {
      let colsShown = -1;

      const getStyle = index =>
        get(component, `children.0.props.children.${index}.props.style`);

      return (
        <div
          className="rt-tr sub-row"
          role="row"
          key={inAlarmSite.enterprise_site_id + " " + index}>
          {
            ChildRowColumns.filter((cell) => {

              let show = true;
              if (cell.show) show = cell.show({ showSOP: showSOP });

              return show;
            }).map(cell => {

              colsShown++;

              let event_id = "";
              if (sudo_state.state === 'A') {
                event_id = sudo_state.alarm_event_id;
              } else {
                event_id = sudo_state.restore_event_id;
              }

              const event = getEventMetaById(event_id);

              const content =
                (cell.content &&
                  cell.content({
                    inAlarmSite,
                    sudo_state,
                    event,
                    onClickViewSOP,
                    t,
                    index,
                  })) ||
                null;

              return (
                <div
                  className={`rt-td ${cell.className || ""}`}
                  role="gridcell"
                  style={{
                    ...getStyle(colsShown),
                  }}
                  key={inAlarmSite.enterprise_site_id + " " + index + " " + cell.key}>
                  {content}
                </div>
              );
            })
          }
        </div>
      );
    })
  : null;

  const expandedClass = component.expanded === 1 ? "expanded" : "";

  return (
    <>
      <div
        className={`rt-tr-group ${expandedClass}`}
        role="rowgroup"
        key={inAlarmSite && inAlarmSite.enterprise_site_id}
        style={{
          ...style
        }}
        id={id}
      >
        {component.children}
        {expandedContent}
      </div>
    </>
  );
}

export default TrGroupComponent;