// Integrations
export const loadSiteIntegrationsStart = params => {
  return { type: "LOAD_SITE_INTEGRATIONS_START", ...params };
};
export const loadSiteIntegrationsFailed = error => {
  return { type: "LOAD_SITE_INTEGRATIONS_FAILED", ...error };
};
export const loadSiteIntegrationsSuccess = response => {
  return {
    type: "LOAD_SITE_INTEGRATIONS_SUCCESS",
    ...response
  };
};
export const loadSiteIntegrationsCancel = response => {
  return {
    type: "LOAD_SITE_INTEGRATIONS_CANCEL",
    ...response
  };
};
