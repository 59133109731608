import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";
import { withRouter, Redirect } from "react-router-dom";
import conxtdOut from "apis/conxtdOut";

import { getRouterParams } from "services/redux/selectors/app/";
import { requestAppIdentityCheckStart } from "services/redux/actions";

// https://app.conxtd.com/#/accept-invite?U=e4ee0435c6d2d386955e5bd073ba3ded

class Identity extends React.Component {
  state = {};

  componentDidMount() {
    const { sessionId } = this.props.match.params;

    this.props.dispatch(
      requestAppIdentityCheckStart({
        sessionId,
      })
    );
    const { user, loggedInUser } = this.props;
    console.log("user", user);

    const session = conxtdOut.getSession();
    console.log("session", session);

    // const loggedIn = session && session.sessionId;
    // const loading = user.loading;

    console.log("loggedInUser", loggedInUser);
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {}
  render() {
    return (
      <Redirect
        to={{
          pathname: "/enterprise",
          state: { from: this.props.location },
        }}
      />
    );

    // return <div>You are not logged in.</div>;
  }
}

const mapStateToProps = (state, props) => {
  return {
    router: { params: getRouterParams(state, props) },
    user: state.user,
    loggedInUser: state.user,
  };
};
export default withRouter(withT(connect(mapStateToProps)(Identity)));
