const initialState = {
  loading: null,
  data: [],
};

const eventPairsReducer = (state = initialState, action) => {
  switch (action.type) {

    case "LOAD_EVENT_PAIRS_START":
      return {
        ...state,
        loading: true,
      };

    case "LOAD_EVENT_PAIRS_SUCCESS":
      return {
        ...state,
        data: [...action.data.event_pairs],
        loading: false,
      }

    case "LOAD_EVENT_PAIRS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "LOAD_EVENT_PAIRS_CANCEL":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default eventPairsReducer;
