import React, { useState } from "react";

// import _ from "lodash";

import Icon from "components/Icons/Icon";

import { Button, Row, Col, Modal } from "react-bootstrap";

import { formatUrl } from "../FormatUrl";

import "./ExternalRecordingView.scss";

import eagleEyeLogo from "./eagle_eye_logo.png";
import tetherLogo from "./tether_logo.png";
import videoLoftLogo from "./video_loft_logo.png";
import chubbViewLogo from "./chubb_view_logo.png";

function ExternalRecordingView({ integration, event }) {

  const [modal, showModal] = useState(false);

  const toggleModal = () => {
    showModal(!modal);
  }

  const buttonContent = (
    <Col md="auto">
      <div className="flex-center">
        <span style={{ height: "21px", paddingRight: "5px" }}>
          Investigate Event
        </span>
        <Icon className="fas fa-external-link-alt fa-1x" />
      </div>
    </Col>
  )

  if (integration.integration_name === 'Eagle Eye') {

    const cameras = integration.camera_integration_site.camera_sudo_sites;

    let camera = cameras.find(camera => camera.id === event.camera_id)

    const formatUrl =
      "https://login.eagleeyenetworks.com" +
      "/hist/index.html?" +
      "id=" + camera.camera_ref +
      "&ts=" + event.start_timestamp +
      "&A=" + integration.auth_token
      ;

    return (
      <>
        <Row className="justify-content-md-center flex-center" style={{ height: "50px" }}>
          <Col xs lg="2">
          </Col>
          <Button
            onClick={toggleModal}
            variant="primary"
            size="lg"
            className={integration.camera_integration_site.auth_expired ? 'invisible' : 'visible'}
            style={{ height: "39px", maxWidth: "90%", margin: "0 auto" }}
          >
            {buttonContent}
          </Button>
          <Col xs lg="2" className="flex-center logo-col">
            <span className="logo-img-element">
              <img src={eagleEyeLogo} alt={event.start_timestamp} className="integration-logo-small"></img>
            </span>
          </Col>
        </Row>
        <Modal show={modal} size="xl" onHide={toggleModal} contentClassName="customclass-modal-main">
          <Modal.Header closeButton>
            <Modal.Title className="customclass-modaltitle">
              {/* <img className="d-block mx-auto img-fluid w-50" src={integration.logo} alt={integration.integration_name} /> */}
              <img className="view-logo" src={integration.logo} alt={integration.integration_name} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body clasName="customclass-modalbody">
            <iframe className="iframe-modal" src={formatUrl} title="eagle_eye_historic_view"></iframe>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  else if (integration.integration_name === 'Xanview') {
    return (
      <Row className="justify-content-md-center flex-center" style={{ height: "50px" }}>
        <Col xs lg="2">
        </Col>
        <Button
          onClick={() => window.open(formatUrl(event.recording_id))}
          variant="primary"
          size="lg"
          style={{ height: "39px", maxWidth: "90%", margin: "0 auto" }}
        >
          {buttonContent}
        </Button>
        <Col xs lg="2" className="flex-center logo-col">
          <span className="logo-img-element">
            <img src={tetherLogo} alt={event.start_timestamp} className="integration-logo-small"></img>
          </span>
        </Col>
      </Row>
    );
  } else if (integration.integration_name === 'Videoloft' || integration.integration_name === 'ChubbView') {
    let logo = integration.integration_name === 'ChubbView' ? chubbViewLogo : videoLoftLogo;
    return (<Row className="justify-content-md-center flex-center" style={{ height: "50px" }}>
      <Col xs lg="2">
      </Col>
      <Button
        disabled
        variant="primary"
        size="lg"
        class={integration.camera_integration_site.auth_expired ? 'invisible' : 'visible'}
        style={{ height: "39px", maxWidth: "90%", margin: "0 auto", opacity: "0" }}
      >
        {buttonContent}
      </Button>
      <Col xs lg="2" className="flex-center logo-col">
        <span className="logo-img-element">
          <img src={logo} alt={event.start_timestamp} className="integration-logo-small"></img>
        </span>
      </Col>
    </Row>
    );
  } else {
    return null;
  }
}

export default ExternalRecordingView;