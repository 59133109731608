/**
 * Utility for api calls that don't require live refresh
 * or debouncing
 * 
 * E.g:
 * 
  function* watchAddKeyholder() {
    yield actionWatcher({
      actions: {
        start: requestAddKeyholderStart,
        failed: requestAddKeyholderFailed,
        success: requestAddKeyholderSuccess,
        cancel: requestAddKeyholderCancel
      },
      saga: function*(action) {
        yield call(conxtdOut.post, "/EnterpriseKeyholders/add", action.params);
      }
    });
  }
 */

import _ from "lodash";

import { call, take, race, takeLatest, put } from "redux-saga/effects";

export function* actionWatcher({ actions, saga }) {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest(actions.start().type, function*(action) {
    yield race({
      task: call(function*(action) {
        try {
          const response = yield saga(action);

          yield put(
            actions.success({
              ..._.omit(response, ["headers", "config"]),
              startAction: action
            })
          );
        } catch (error) {
          yield put(
            actions.failed({
              error: {
                message: error.message,
                status: error.response && error.response.status,
                statusText: error.response && error.response.statusText
              },
              startAction: action
            })
          );
        }
      }, action),
      cancel: take([actions.cancel().type, actions.failed().type, "CANCEL_ALL"])
    });
  });
}
