import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { toggleNavCollapse } from "services/redux/actions";

import { Sidebar } from "./Nav/Sidebar/";
import NavTop from "./Nav/NavTop";
import BreadCrumbs from "./BreadCrumbs/";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import { conxtdAdmin } from "services/redux/selectors/";
import { getPathname } from "services/redux/selectors/reports/router";
import {
  getEnterprisesLoading,
  getEnterpriseFromRoute,
} from "services/redux/selectors/enterprises";
import { getEnterpriseAvailable } from "services/redux/selectors";
import { routerNavigate, loadEnterprisesStart } from "services/redux/actions";

import "./Page.scss";

class Page extends React.Component {
  toggleNavCollapse = () => {
    // Dispatch collapse toggle event
    this.props.dispatch(toggleNavCollapse());
    // Has to be done here since body and wrapper are outside of our app
    document.body.classList.toggle("fixed-left-void");
    document.getElementById("wrapper").classList.toggle("enlarged");
  };
  UNSAFE_componentWillReceiveProps = (props) => {
    if (props.pathname === this.props.pathname) {
      return;
    }
    this.props.dispatch(
      routerNavigate({
        params: props.match.params,
        location: props.location,
      })
    );
    // Refresh enterprise cache if not already loading
    //  this prevents duplicate api requests being sent
    if (this.props.enterprisesLoading !== true) {
      this.props.dispatch(loadEnterprisesStart());
    }
  };
  componentDidMount = () => {
    this.props.dispatch(
      routerNavigate({
        params: this.props.match.params,
        location: this.props.location,
      })
    );
  };
  render() {
    const {
      enterpriseAvailable,
      enterprise,
      enterprises,
      match,
      disableBreadcrumbs = false,
      t,
    } = this.props;
    const navCollapsed = _.get(this.props, "app.navCollapsed");

    const enterprisesLoading =
      // enterprise_id has a value but no enterprise is available
      (match.params.enterprise_id && !enterprise) ||
      (this.props.loading !== false && enterprises.length === 0);

    // Show loading if there are no enterprises available
    // const enterprisesLoading = enterprises.data.length === 0;

    const loading = (
      <div className="row mt-3">
        <div className="col-12">
          <Loading />
        </div>
      </div>
    );

    let content = enterprisesLoading ? (
      loading
    ) : (
      <>
        <div className="row">
          <div className="col-md-8">
            {!disableBreadcrumbs && <BreadCrumbs />}
          </div>
          <div className="col-md-4 text-right">
            <div
              className="btn-group-select"
              style={{ marginRight: "1rem", marginTop: "2rem" }}
            ></div>
          </div>
        </div>
        {this.props.children}
      </>
    );

    // Trying to access an enterprise that's unavailable, redirect
    if (!enterpriseAvailable) {
      this.props.history.push("/enterprise");
      content = loading;
    }
    return (
      <>
        <Sidebar
          pathname={this.props.location.pathname}
          navCollapsed={navCollapsed}
          toggleNavCollapse={this.toggleNavCollapse}
        />
        <div className={"content-page " + this.props.className}>
          <div className="content px-0">
            <NavTop toggleNavCollapse={this.toggleNavCollapse} />
            <div className="page-content-wrapper">
              <div className="container-fluid">
                <ErrorBoundary
                  component={
                    <div className="page-title-box pt-0">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <div className="page-title mb-2 mt-0">
                            {t("render_error.title")}
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="pt-2">
                            {t("render_error.description")}
                          </p>
                          <Link to="/enterprise">
                            {t("render_error.return_to_dashboard")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  }
                >
                  {content}
                </ErrorBoundary>

                <footer className="footer text-center text-lg-right px-4">
                  &copy; {new Date().getFullYear()} CONXTD Technologies LTD{" "}
                  <span className="d-none d-md-inline-block">
                    {conxtdAdmin(this.props) &&
                      "v" + process.env.REACT_APP_VERSION}
                  </span>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Page.propTypes = {
  pathname: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    app: state.app,
    user: state.user,
    pathname: getPathname(props),
    enterprise: getEnterpriseFromRoute(state, props),
    enterprises: state.enterprises,
    loading: getEnterprisesLoading(state, props),
    enterpriseAvailable: getEnterpriseAvailable(state, props),
  };
};
export default withT(withRouter(connect(mapStateToProps)(Page)), "page");
