import React from "react";
import PropTypes from "prop-types";

import { default as T } from "services/i18n/Translate";

import moment from "services/locale/momentInit.js";

import ReportOverview from "./ReportOverview";

import Loading from "components/Loading";

/**
 * Reports Overview Template
 *
 * This is where customisation of report layout or display occurs,
 * for example based on user type
 *
 */
const Default = ({ overview }) => {
  const { reportPaths, sectionOrder, loading } = overview.all;
  const dailyReports = reportPaths.filter(
    path =>
      path[0] === "scheduled" ||
      path[0] === "outstanding" ||
      path[0] === "openclosebyarea" || // 09/21 - open close by area
      path[0] === "default"
  );
  const monthlyReports = reportPaths.filter(path => path[0] === "exception");
  // Only show "daily" section if at least one daily
  // (scheduled/outstanding) report is available
  const showDaily = dailyReports.length > 0;
  // Only show monthly if a monthly report is available
  // (exception)
  const showMonthly = monthlyReports.length > 0;

  if (loading === true && reportPaths.length === 0) {
    return (
      <div className="row">
        <div className="col-md-12 pl-4">
          <Loading />
        </div>
      </div>
    );
  }

  const DailySection = !showDaily ? null : (
    <>
      <div className={`row${loading ? " loading" : ""}`}>
        <h4 className="mt-0 pl-3 pb-2 header-title">
          <T>Daily</T> - {new moment().format("Do MMMM")}
        </h4>
      </div>
      <div className={`row${loading ? " loading" : ""}`}>
        {dailyReports.map((path, index) => {
          return <ReportOverview key={index} reportPath={path} />;
        })}
      </div>
    </>
  );

  const MonthlySection = !showMonthly ? null : (
    <>
      <div className={`row${loading ? " loading" : ""}`}>
        <h4 className="mt-0 pl-3 pb-2 header-title">
          <T>Monthly</T> - {new moment().subtract(1, "month").format("MMMM")}
        </h4>
      </div>

      <div className={`row${loading ? " loading" : ""}`}>
        {monthlyReports.map((path, index) => {
          return <ReportOverview key={index} reportPath={path} />;
        })}
      </div>
    </>
  );

  // Reverse order
  if (sectionOrder === -1) {
    return (
      <>
        {MonthlySection}

        <hr />
        <div className="mt-4"></div>

        {DailySection}
      </>
    );
  }

  return (
    <>
      {DailySection}

      <hr />
      <div className="mt-4"></div>

      {MonthlySection}
    </>
  );
};

Default.propTypes = {
  overview: PropTypes.object.isRequired
};

export default Default;
