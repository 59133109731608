import { 
  watchLoadCompanyFields,
  watchLoadEnterpriseFields,
  watchLoadEnterpriseGroups,
  watchLoadSudoSiteId,
  watchLoadEnterpriseAssociations,
 } from "./serviceDesk";
import { watchLoadPinMaps } from "../serviceDesk/pinMaps";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCompanyFields(),
      watchLoadEnterpriseFields(),
      watchLoadEnterpriseGroups(),
      watchLoadSudoSiteId(),
      watchLoadPinMaps(),
      watchLoadEnterpriseAssociations(),
    ],
  );
}