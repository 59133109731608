import React from "react";
import { connect } from "react-redux";

import _ from "lodash";

import { default as T } from "services/i18n/Translate";

import { changeCurrentReportFilter } from "services/redux/actions";

import "./FilterLegend.scss";

import { getConfig } from "services/redux/selectors/reports/common/";

import { getFilter } from "services/redux/selectors/reports/common/filters";
import { 
  getPieChartTotals, 
  getPieChartTotal,
} from "services/redux/selectors/reports/common/pie";
import { getReportType } from "services/redux/selectors/reports/router";

class FilterLegend extends React.Component {
  render() {
    const {
      show,
      reportType,
      filter,
      totals,
      clickableEventFilters,
      config,
    } = this.props;
    return (
      <div>
        {config.timeRangeSelection ? <h6 className="total-sites">Total Sites: {this.props.total}</h6> : ""}
        <div> 
        {filter.keys.map((description, index) => {
            const total = _.find(totals.data, (item) => {
              return item.name === description;
            }) || { value: 0 };

            // Default filter state, show all
            let hide = "";
            if (Object.keys(show).length > 0) {
              hide = (!_.get(show, description) && "hide") || "";
            }
            // _.get(
            //   this.props,
            //   "totals.data." + index + ".value"
            // ) || 0;
            return (
              <div
                className={`${reportType} event-filter alert text-light ${hide}`}
                role="alert"
                onClick={() => {
                  if (!clickableEventFilters) return;

                  if (total.value === 0 || this.props.disableFilter)
                    // Short circuit for totals of 0
                    // and when disableFilter prop is set
                    return;
                  // this.props.dispatch(changeOutstandingReportFilter(description));
                  this.props.dispatch(
                    changeCurrentReportFilter({
                      label: description,
                      filterType: filter.type,
                      reportType,
                    })
                  );
                }}
                key={index}
                style={{
                  background: filter.colors[index],
                }}
              >
                <T>{description}</T>
                <span>
                  {": "}
                  {total.value}
                </span>
              </div>
            );
          })
        }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const filter = getFilter(state, props);

  return {
    totals: getPieChartTotals(state, props)(state, props),
    total: getPieChartTotal(state, props)(state, props),
    show: filter && filter(state, props),
    filter: getConfig(state, props).charts.pie.filter,
    clickableEventFilters: getConfig(state, props).charts.pie
      .clickableEventFilters,
    reportType: getReportType(state, props),
    config: getConfig(state, props),
  };
};
export default connect(mapStateToProps)(FilterLegend);
