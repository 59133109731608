import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";

import UrlClipView from "./UrlClipView";
import EnhancedClipView from "./EnhancedClipView";

import Icon from "components/Icons/Icon";

import { Card } from "react-bootstrap";

import { getIntegrations } from "services/redux/selectors/integrations";

import ExternalRecordingView from "components/Common/Cameras/CameraList/ExternalRecordingView";
import ExternalImagesView from "components/Common/Cameras/CameraList/ExternalImagesView";
import EagleEyeView from "components/Common/Cameras/CameraList/EagleEyeView";
import VideoLoftView from "components/Common/Cameras/CameraList/VidoeLoftView";


class ClipList extends React.Component {
  render() {
    const { camera_events, integrations, index, colour, conxtd_alarm_id, user } = this.props;

    // find the camera events that are not associated with an integration
    const nonIntegrationCameraEvents = camera_events.filter((event) => {
      const integration = integrations.find(integration => integration.integration_id === event.integration_id);
      return (_.get(integration, 'camera_integration_site') === undefined || integration === null || integration.camera_integration_site === null);
    });

    if (nonIntegrationCameraEvents.length > 0) {
      return (
        <ExternalImagesView
          events={nonIntegrationCameraEvents}
          conxtd_alarm_id={conxtd_alarm_id}
          user={user}
        />
      )
    } else {
      return camera_events.map((event, eventIndex) => {
        // Use enhanced player if integration_id exists
        const ClipComponent = event.integration_id
          ? EnhancedClipView
          : UrlClipView;

        const integration = integrations.find(integration => integration.integration_id === event.integration_id);

        if (_.get(integration, 'camera_integration_site') === undefined || integration === null || integration.camera_integration_site === null) {
          return (null);
        }

        const camera_sudo_sites = integration.camera_integration_site.camera_sudo_sites;

        const camera = camera_sudo_sites.find(camera_sudo_site => camera_sudo_site.id === event.camera_id);

        const tether = integration.integration_name === "Xanview";
        const eagleEye = integration.integration_name === "Eagle Eye";

        const videoLoft = integration.integration_name === "Videoloft";
        const chubbView = integration.integration_name === "ChubbView";

        if (camera !== undefined) {
          return (
            <Card className="mb-2" style={{ backgroundColor: `${colour}` }} key={camera.camera_name}>
              <p style={{ textAlign: "center" }}><Icon className="fas fa-video" /> {camera.camera_name} </p>

              <ClipComponent
                event={event}
                integrations={integrations}
                index={index}
                key={eventIndex}
              />
              {tether &&
                <>
                  {/* <span style={{textAlign: "right", paddingRight: "15px"}}>Powered by Tether</span> */}
                </>
              }
              {eagleEye &&
                <>
                  <EagleEyeView
                    integration={integration}
                    camera={camera}
                    event={event}
                  />
                  {/* <span style={{textAlign: "right", paddingRight: "15px"}}>Powered by Eagle Eye</span> */}
                </>
              }
              {videoLoft &&
                <>
                  <VideoLoftView
                    integration={integration}
                    camera={camera}
                    event={event}
                  />
                </>
              }
              {chubbView &&
                <>
                  <VideoLoftView
                    integration={integration}
                    camera={camera}
                    event={event}
                  />
                </>
              }
              <ExternalRecordingView
                integration={integration}
                event={event}
              />
            </Card>
          );
        } else {
          return (null);
        }
      });
    }
  }
}
ClipList.propTypes = {
  /**
   * @param camera_events array of camera events containing tether
   * details
   */
  camera_events: PropTypes.array.isRequired,
  /**
   * @param integrations optional camera integrations. If non specified,
   * reverts to using URL instead of enhanced player
   */
  integrations: PropTypes.any
};

const mapStateToProps = state => {
  return {
    integrations: getIntegrations(state),
    user: state.user,
  };
};
export default connect(mapStateToProps)(ClipList);
