import { call, put, takeLatest } from "redux-saga/effects";

import {
  requestTetherConnectFailed,
  requestTetherConnectSuccess
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* loadTetherConnectWatcher() {
  yield takeLatest("REQUEST_TETHER_CONNECT_START", function*(action) {
    try {
      yield call(conxtdOut.post, "/integrations/add", action.params);
      // yield call(
      //   () => {
      //     console.log(action.params);
      //   },
      //   "/connect/add",
      //   action.params
      // );

      yield put(requestTetherConnectSuccess());
    } catch (error) {
      yield put(
        requestTetherConnectFailed({
          error: {
            message: error.message,
            status: error.response && error.response.status,
            statusText: error.response && error.response.statusText
          },
          startAction: action
        })
      );
    }
  });
}
