const csv = {
  exportCSV: content => {
    // No data available
    if (content.length < 1) return;

    const csvContent = csv.arrayToCSV(content);

    var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    window.open(encodedUri);
  },
  arrayToCSV: objArray => {
    // const array =
    //   typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    // let str = `${Object.keys(array[0])
    //   .map(value => `"${value}"`)
    //   .join(",")}\r\n`;

    // return array.reduce((str, next) => {
    //   str += `${Object.values(next)
    //     .map(value => `"${value}"`)
    //     .join(",")}\r\n`;
    //   return str;
    // }, str);

    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    return array.reduce((str, next) => {
      str += `${Object.values(next)
        .map(value => `"${value.toString().replace(/#/g, "")}"`)
        .join(",")}\r\n`;
      return str;
    }, str);
  }
};

export default csv;
