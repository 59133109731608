import React from "react";

import Loading from "components/Loading";

import { ButtonToolbar } from "react-bootstrap";

import CardList from "./CardList";
import DataTable from "./DataTable";

import ViewAs from "components/Common/ViewAs/";

import Icon from "components/Icons/Icon";

import Add from "./Add";

import { withT } from "services/translation/";

import { connect } from "react-redux";

import {
  loadSiteKeyholdersStart,
  loadSiteKeyholdersCancel,
} from "services/redux/actions";

class Keyholders extends React.Component {
  state = {
    // This prevents multiple dispatches
    dispatched: false,
    viewAs: this.props.viewAs,
  };
  loadKeyholders = (props) => {
    const { site } = props;

    this.setState({ dispatched: true }, () => {
      if (!site.id) {
        return;
      }

      this.props.dispatch(
        loadSiteKeyholdersStart({
          id: site.id,
        })
      );
    });

    // site.id
  };
  // Uncomment for live refresh for site to refresh
  // keyholders
  // componentDidUpdate() {
  //   const stale = this.props.site.keyholders.stale;
  //   const { dispatched } = this.state;

  //   if (stale && !dispatched) {
  //     this.loadKeyholders(this.props);
  //   }

  //   if (!stale && dispatched && this.props.site.keyholders.loading === false) {
  //     // Reset dispatched
  //     this.setState({ dispatched: false });
  //   }
  // }
  componentWillUnmount() {
    this.props.dispatch(loadSiteKeyholdersCancel());
  }
  componentDidMount() {
    this.loadKeyholders(this.props);
  }
  componentDidUpdate(prevProps) {
    if (this.props.site.id !== prevProps.site.id) {
      this.loadKeyholders(this.props);
    }
  }
  render() {
    const { viewAs } = this.state;
    const { site, t } = this.props;

    const loading = site.keyholders.loading !== false;

    return (
      <>
        <div className="row">
          <div className="col-12 d-flex">
            {loading && <Loading />}
            {/* TODO */}
            {/* Export */}
            <ButtonToolbar
              style={{
                margin: "auto 0 auto auto",
              }}
            >
              <ViewAs
                options={[
                  {
                    key: "cards",
                    Icon: <Icon className="far fa-clone" />,
                    description: t("view_as.cards"),
                  },
                  {
                    key: "table",
                    Icon: <Icon className="fas fa-table" />,
                    description: t("view_as.table"),
                  },
                ]}
                viewAs={viewAs}
                changeViewAs={(viewAs) => this.setState({ viewAs })}
              />
            </ButtonToolbar>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <Add
              total={site.keyholders.data.length}
              enterprise_site_id={site.id}
            />
          </div>
        </div>
        <div className="row mt-2" style={{ marginBottom: "90px" }}>
          <div className="col-12">
            {viewAs === "table" ? (
              <DataTable data={site.keyholders.data} />
            ) : (
              <CardList
                data={site.keyholders.data}
                enterprise_site_id={site.id}
                t={t}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};
export default withT(
  connect(mapStateToProps)(Keyholders),
  "site_overview.side_pane.keyholders"
);
