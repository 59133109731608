import { call, takeLatest, take, race, takeEvery } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import { liveRefreshFor } from "services/redux/sagas/common";

import conxtdOut from "apis/conxtdOut";

import * as links from "./links";

/**
 * OUTSTANDING - watchLiveRefresh
 */
export function* watchLiveRefresh() {
  // Sites - When outstanding reports finish loading, start a background task to load sites
  yield takeLatest(
    [
      // Pre-loading
      "LOAD_OUTSTANDING_REPORT_SUCCESS"
    ],
    links.toSitesBackgroundLoader
  );

  // Event meta data - when outstanding reports finish loading, start a background task to load event meta
  yield takeLatest(
    [
      // Pre-loading
      "LOAD_OUTSTANDING_REPORT_SUCCESS"
    ],
    links.toEventMetaBackgroundLoader
  );

  yield takeEvery("LOAD_OUTSTANDING_REPORT_START", function*(action) {
    yield race({
      task: call(loadStart, action),
      cancel: take([
        "LOAD_OUTSTANDING_REPORT_CANCEL",
        "LOAD_OUTSTANDING_REPORT_FAILED", //#failedRemoved
        "CANCEL_ALL_LIVE_REFRESH",
        "CANCEL_ALL"
      ])
    });
  });
}

/**
 * OUTSTANDING - loadStart
 *
 * @param {enterprise_id: (int), group_id (int), interval (int - refresh delay in ms)} params
 */
export function* loadStart(action) {
  const { interval, enterprise_id, group_id, refresh } = action;
  const reportType = "index";

  const params = {
    startAction: actions.loadOutstandingReportStart,
    startActionParams: action,
    successAction: actions.loadOutstandingReportSuccess,
    failedAction: actions.loadOutstandingReportFailed,
    apiCall: call(
      conxtdOut.get,
      `/Reports/Outstanding/index/${enterprise_id}/${group_id}`
    ),
    interval,
    key: reportType,
    successActionParams: {
      enterprise_id,
      group_id
    },
    refresh
  };

  yield liveRefreshFor(params);
}
