import React from "react";

import Icon from "components/Icons/Icon";

import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import ConfirmedAlarmViewModal from "../ConfirmedAlarmViewModal";

// import { showModal, hideModal } from "react-redux-modal-provider";
import { showModal } from "react-redux-modal-provider";
import {
  loadSiteKeyholdersStart,
  outstandingEventPairAcknowledgementStart,
} from "services/redux/actions";

class ViewApiButton extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {
      site_name,
      time,
      zone,
      event_text,
      timezone,
      site_id,
      event_id,
    } = this.props;
    const id = site_id;
    const events = this.props.sudo_state_id.outstanding.bundle.data.events;
    const eventsFilteredbySiteId = events.filter(
      (event) => event.id === event_id
    );
    const sudo_state_id = parseInt(eventsFilteredbySiteId.map((a) => a.id));

    return (
      <div>
        <Button
          size="sm"
          variant="success"
          className="mr-2"
          onClick={(e) => {
            e.preventDefault();

            this.props.dispatch(
              outstandingEventPairAcknowledgementStart({ sudo_state_id })
            );
            this.props.dispatch(loadSiteKeyholdersStart({ id }));
            showModal(ConfirmedAlarmViewModal, {
              site_id: site_id,
              site_name: site_name,
              time: time,
              timezone: timezone,
              zone: zone,
              event_text: event_text,
              sudo_state_id: sudo_state_id,
              // onHide: hideModal,
            });
          }}
        >
          <Icon className="fas fa-check" /> Action
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    sudo_state_id: state.reports,
  };
};

export default connect(mapStateToProps)(ViewApiButton);
