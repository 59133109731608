import React from "react";

// import VictoryPie from "./Libraries/VictoryPie";
// import ReChartPie from "./Libraries/ReChartPie";
import ChartJSDoughnut from "./Libraries/ChartJSDoughnut";

class PieChart extends React.Component {
  render() {
    /*const victoryPie = (
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <VictoryPie {...this.props} />
        </div>
      </div>
    );
    const reChartPie = (
      <div className="row">
        <div className="col-md-12">
          <ReChartPie {...this.props} />
        </div>
      </div>
    );
    const both = (
      <div className="row">
        <div className="col-md-6">
          <VictoryPie {...this.props} />
        </div>
        <div className="col-md-6">
          <ReChartPie {...this.props} />
        </div>
      </div>
    );*/

    const chartJsDoughnut = <ChartJSDoughnut {...this.props} />;
    //return <ReChartPie {...this.props} />;
    // return reChartPie;
    // return victoryPie;
    return chartJsDoughnut;
  }
}
export default PieChart;
