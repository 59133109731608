export const loadDeveloperAppsStart = (params) => {
  return { type: "LOAD_DEVELOPER_APPS_START", ...params };
};

export const loadDeveloperAppsSuccess = (params) => {
  return { type: "LOAD_DEVELOPER_APPS_SUCCESS", ...params, };
};

export const loadDeveloperAppsFailed = (params) => {
  return { type: "LOAD_DEVELOPER_APPS_FAILED", ...params };
};

export const loadDeveloperAppsCancel = (params) => {
  return { type: "LOAD_DEVELOPER_APPS_CANCEL", ...params };
};