import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserCreateAccountSuccess,
  requestUserCreateAccountFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchCreateAccount() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_CREATE_ACCOUNT_START", function*(action) {
    yield race({
      task: call(requestCreateAccount, action),
      cancel: take([
        "REQUEST_USER_CREATE_ACCOUNT_CANCEL",
        "REQUEST_USER_CREATE_ACCOUNT_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestCreateAccount(action) {
  try {
    const {
      firstName,
      lastName,
      locale,
      timezone,
      // avatar,
      password
    } = action.user;
    if (!firstName) {
      throw new Error("Invalid value supplied for firstName: " + firstName);
    }
    if (!lastName) {
      throw new Error("Invalid value supplied for lastName: " + lastName);
    }
    if (!locale) {
      throw new Error("Invalid value supplied for locale: " + locale);
    }
    if (!timezone) {
      throw new Error("Invalid value supplied for timezone: " + timezone);
    }
    if (!password) {
      throw new Error("Invalid value supplied for password (value hidden)");
    }
    // const response = yield call(() => {
    //   return { data: {} };
    // });
    const response = yield call(conxtdOut.post, "/users/edit", {
      firstName,
      lastName,
      locale,
      timezone,
      // avatar,
      password
    });

    yield put(requestUserCreateAccountSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestUserCreateAccountFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
