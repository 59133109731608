import * as jsPDF from "jspdf";

const DEFAULT_COL_WIDTH = 135 * 0.264583;
let pdfWidth = 0;

// var generateData = function(amount) {
//   var result = [];
//   var data = {
//     coin: "100",
//     game_group: "GameGroup",
//     game_name: "XPTO2",
//     game_version: "25",
//     machine: "20485861",
//     vlt: "0"
//   };
//   for (var i = 0; i < amount; i += 1) {
//     data.id = (i + 1).toString();
//     result.push(Object.assign({}, data));
//   }
//   return result;
// };

function createHeaders(keys) {
  var result = [];

  const colWidth = (keys.length - 1) * DEFAULT_COL_WIDTH;
  const firstColWidth = pdfWidth * 1.3249528 - colWidth;

  for (var i = 0; i < keys.length; i += 1) {
    const width = i === 0 ? firstColWidth : DEFAULT_COL_WIDTH;
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width,
      align: "center",
      padding: 0
    });
  }
  return result;
}

// var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
// doc.table(1, 1, generateData(100), headers, { autoSize: true });

const pdf = (data, filename) => {
  var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

  pdfWidth = doc.internal.pageSize.getWidth();

  // Remove first element (headers)

  const headers = data.shift();

  const objectData = data.map((row, i) => {
    let result = {};
    result.id = i;
    headers.forEach((key, index) => {
      result[key] = row[index] || "";
    });
    return result;
    // return {
    //   id: i,
    //   Name: [row[0] || ""],
    //   Address: [row[1] || ""],
    //   Postcode: [row[2] || ""],
    //   "Site Ref": [row[3] || ""],
    //   "Transmitter Ref": [row[4] || ""]
    // };
  });

  // console.log(generateData(10));
  // console.log(objectData);

  // const headers = [
  //   "Name",
  //   "Address",
  //   "Postcode",
  //   "Site Ref",
  //   "Transmitter Ref"
  // ];

  // console.log(createHeaders(headers));

  // return;

  doc.table(1, 1, objectData, createHeaders(headers), {
    autoSize: false,
    fontSize: 8
  });

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    const blob = new Blob([doc.output()], { type: "application/pdf" });
    window.open(URL.createObjectURL(blob));
  } else {
    doc.save(filename + ".pdf");
  }
};

export default pdf;