import React from "react";

const wifi = props => (
  <svg
    version="1.1"
    viewBox="0 0 26 26"
    enableBackground="new 0 0 26 26"
    width="100%"
    height="100%">
    <g>
      <g>
        <path
          d="M25.7,8.3C22.4,5,18,3,13,3S3.5,5,0.3,8.3C0.1,8.5,0,8.7,0,9c0,0.3,0.1,0.5,0.3,0.7l1.4,1.4c0.4,0.4,1,0.4,1.4,0   C5.6,8.6,9.1,7,13,7s7.4,1.6,9.9,4.1c0.4,0.4,1,0.4,1.4,0l1.4-1.4C25.9,9.5,26,9.3,26,9S25.9,8.4,25.7,8.3z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
        <path
          d="m13,11c-2.8,0-5.2,1.1-7,2.9-0.4,0.4-0.4,1 0,1.4l1.4,1.4c0.4,0.4 1,0.4 1.4,0 1.1-1.1 2.6-1.7 4.2-1.7 1.6,0 3.1,0.7 4.2,1.7 0.4,0.4 1,0.4 1.4,0l1.4-1.4c0.4-0.4 0.4-1 0-1.4-1.8-1.8-4.2-2.9-7-2.9z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
        <circle
          cx="13"
          cy="21"
          r="2"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default wifi;