import { Types } from "../actions/comments";

const INITIAL_STATE = {
  text: [],
};

export default function comments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CREATE_COMMENT: {
      return {
        text: action.payload.text,
      };
    }
    default: {
      return state;
    }
  }
}
