import resources from "./resources";
import rules from "./rules/";

const initialState = {
  resources: {
    loading: null,
    error: {},
    data: []
  },
  rules: {
    loading: null,
    error: {},
    data: [],
    actions: {
      add: {
        loading: null,
        success: null,
        error: {}
      },
      remove: {
        loading: null,
        success: null,
        error: {}
      }
    }
  }
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS_RESOURCES_START":
    case "LOAD_NOTIFICATIONS_RESOURCES_FAILED":
    case "LOAD_NOTIFICATIONS_RESOURCES_SUCCESS":
      return {
        ...state,
        resources: resources(state.resources, action)
      };
    case "REQUEST_NOTIFICATIONS_ADD_RULE_START":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_FAILED":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_SUCCESS":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_NOTIFIED":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_START":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_FAILED":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_SUCCESS":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_NOTIFIED":
    case "LOAD_NOTIFICATIONS_RULES_START":
    case "LOAD_NOTIFICATIONS_RULES_FAILED":
    case "LOAD_NOTIFICATIONS_RULES_SUCCESS":
      return {
        ...state,
        rules: rules(state.rules, action)
      };
    default:
      return state;
  }
};

export default notifications;