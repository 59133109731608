import React from "react";

import { Link } from "react-router-dom";

import Icon from "components/Icons/Icon";

/**
 * Expects props to be a DataTable row
 */
const SiteNameCell = props => {
  const { value, selectSite } = props;

  return (
    <Link to="#" onClick={selectSite}>
      {/* <Button size="sm" variant="outline-primary" className="mr-2"> */}
      {/* <Icon className="ml-1 fas fa-table-tennis" /> */}
      <Icon className="fas fa-external-link-square-alt mr-2" />
      {/* </Button> */}

      {value}
    </Link>
  );
};

export default SiteNameCell;