import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadCompaniesStart,
  loadCompaniesSuccess,
  loadCompaniesFailed,
  loadCompaniesCancel,
} from "services/redux/actions/companies";

import conxtdOut from "apis/conxtdOut";

// one-off saga for loading companies list

export function* watchLoadCompanies() {

  yield actionWatcher({
    actions: {
      start: loadCompaniesStart,
      failed: loadCompaniesFailed,
      success: loadCompaniesSuccess,
      cancel: loadCompaniesCancel,
    },
    saga: loadCompaniesRe,
  });
}

// https://mars.conxtd.com/out_api//Authake/companies
export function* loadCompaniesRe(action) {
  try {
    const limit = action.pageSize || 20;
    const page = action.requestPageNumber || 1;
    const name = action.nameFilter || null;
    const sort = action.sortField || null;
    const direction = action.sortDirection || null;

    const url = `/Authake/companies?limit=${limit}&page=${page}${name ? "&name="+name : ""}${sort ? "&sort="+sort : ""}${direction ? "&direction="+direction : ""}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}