import REPORTS_CONFIG from "constants/Reports/config/";

const defaultInitial = {
  loading: null,
  data: {},
  config: REPORTS_CONFIG["default"]["default"]
};
const initialState = {
  default: defaultInitial
};

const reportsDefault = (state = initialState) => state;

export default reportsDefault;