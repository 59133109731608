/**
 * 09/21 - open close by area
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProgressBar from "components/Enterprise/Reports/Common/ProgressBar";

import DataTable from "./DataTable/DataTable";

import ErrorBoundary from "components/Layout/ErrorBoundary";

// import ActiveFilters from "components/Analytics/Filters/ActiveFilters/ActiveFilters";
import SuggestedFilters from "./DataTable/SuggestedFilters";

import { Card } from "react-bootstrap";

import { default as T } from "services/i18n/Translate";

import { getReport } from "services/redux/selectors/reports/common/index";

import { getReportType } from "services/redux/selectors/reports/router";

import { getConfig } from "services/redux/selectors/reports/common/";

class PageLayout extends React.Component {
  render() {
    const { config, report, reportType, eventMetaLoading } = this.props;

    const { nextRefresh, lastRefresh } = report;

    const name = config.title;

    const dataAvailable = !(
      report.loading === false && report.data.sudoStates.length === 0
    );

    let DataComponent = null;
    if (dataAvailable) {
      DataComponent = <DataTable />;
    }
    let SuggestedFiltersComponent = null;
    if ((reportType === "openclosebyareafull") && dataAvailable && (eventMetaLoading === false)) {
      SuggestedFiltersComponent = <SuggestedFilters />;
    }

    return (
      <div className={this.props.className}>
        <div className="page-title-box pt-0 pb-4">
          <div className="d-flex justify-content-between flex-wrap">
            <div style={{display: "inline-block"}}>
              <div
                className="page-title mb-2 mt-0"
                style={{
                  fontSize: "26px",
                  margin: "0 7px",
                }}>
                <span>
                  <T>{name}</T>
                </span>
              </div>
            </div>
            <div className="d-inline-block">
              <div
                style={{ 
                  fontSize: "12px",
                  margin: "0 7px" ,
                }}
                className="text-center">
                <ProgressBar
                  placement="right"
                  nextRefresh={nextRefresh}
                  lastRefresh={lastRefresh}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 visual-data">
            <Card className="m-b-30">
              {SuggestedFiltersComponent}
              <ErrorBoundary>
                <div>{DataComponent}</div> 
              </ErrorBoundary>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

PageLayout.propTypes = {
  config: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props),
    report: getReport(state, props),
    reportType: getReportType(state, props),
    eventMetaLoading: state.eventMeta.loading, 
  };
};
export default connect(mapStateToProps)(PageLayout);