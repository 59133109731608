import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import IntervalFilter from "./IntervalFilter";
import EventTypeFilter from "./EventTypeFilter";
import SuggestedFilters from "./SuggestedFilters";

import { Card } from "react-bootstrap";

import RemoveFilter from "./RemoveFilter";

import "./ActiveFilter.scss";

import { getIntervalFilter } from "services/redux/selectors/reports/scheduled/interval";
import { getScheduledFilter } from "services/redux/selectors/reports/scheduled/";

import {
  getConfig,
  getFilteredActive
} from "services/redux/selectors/reports/common/";

import { getReportType } from "services/redux/selectors/reports/router";
import { changeCurrentReportFilter } from "services/redux/actions/";

class ActiveFilters extends React.Component {
  filterIsActive = () => {
    const { eventType, interval, eventCount } = this.props.filter;
    return (
      Object.keys(eventType.show).length > 0 ||
      Object.keys(eventCount.show).length > 0 ||
      interval.timeSegment !== null ||
      this.props.filteredActive.length > 0
    );
    // const filter = this.props.filter.eventType;
    // let labelsShown = 0;
    // _.each(filter, (visible, key) => {
    //   if (visible) {
    //     labelsShown++;
    //   }
    // });
    // return labelsShown;
  };
  render() {
    const { filter, config, reportType } = this.props;
    const intervalFilter = getIntervalFilter(filter.interval);

    if (!this.filterIsActive()) {
      return null;
    }

    const eventTypeFilters = Object.keys(filter.eventType.show).map(
      (label, index) => <EventTypeFilter key={index} label={label} />
    );

    const suggestedFilters = <SuggestedFilters />;

    const intervalFilters = intervalFilter && (
      <IntervalFilter changeIntervalFilter={this.props.changeIntervalFilter} />
    );

    const eventCountFilters = Object.keys(filter.eventCount.show).map(label => {
      const visible = filter.eventCount.show[label];

      const key = label.toLowerCase().replace(" ", "-");
      if (!visible) return null;

      return (
        <span
          key={key}
          className={`${key} text-light`}
          style={{
            backgroundColor:
              config.charts.pie.filter.colors[
                config.charts.pie.filter.keys.indexOf(label)
              ],
            padding: "0.3rem 0.6rem"
          }}>
          {label}{" "}
          <RemoveFilter
            className="remove-filter"
            onClick={e => {
              this.props.dispatch(
                changeCurrentReportFilter({
                  label,
                  reportType,
                  filterType: "eventCount"
                })
              );
            }}
          />
        </span>
      );
    });

    return (
      <Card.Body>
        <T>Showing only</T>: {eventTypeFilters}
        {suggestedFilters}
        {intervalFilters}
        {eventCountFilters}
      </Card.Body>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    // ALARM_TYPES: getAlarmTypes(state, props),
    config: getConfig(state, props),
    filter: getScheduledFilter(state, props),
    filteredActive: getFilteredActive(state, props)
  };
};
export default withRouter(connect(mapStateToProps)(ActiveFilters));
