import React from "react";
import { withRouter } from "react-router-dom";
import WaveLink from "components/Layout/WaveLink";

import Icon from "components/Icons/Icon";

import NavIcon from "./NavIcon";

import Loading from "components/Loading";

import _ from "lodash";

import { default as T } from "services/i18n/Translate";

class NavLink extends React.Component {
  state = {
    active: false
  };
  render() {
    let { children, onClick = () => {} } = this.props;
    const { active } = this.state;

    if (!this.props.location) return null;
    const pathname = this.props.location.pathname;

    const label = <T>{this.props.label}</T>;

    let subArrow = null,
      subMenu = null,
      listClasses = [];
    // if (!_.get(this.props, "className")) this.props.className = "";
    const className = this.props.className || "";
    let linkClasses = className.split(" ");

    let to = this.props.to;

    // Children menu items (sub)
    if (_.get(this.props, "children")) {
      pathname.indexOf(this.props.to) === 0 && linkClasses.push("active");

      to = "#";
      listClasses.push("has_sub");

      // Open/close nav sub items collapse
      active && listClasses.push("active nav-active");

      subArrow = (
        <span className="menu-arrow float-right">
          <Icon className="fas fa-chevron-right" />
        </span>
      );

      subMenu = (
        <ul
          className="list-unstyled expandable"
          style={{
            // Animate to contain each child:
            // children * item height (50)
            maxHeight: (active ? children.length : 0) * 50 + "px"
          }}>
          {children.length > 0 ? (
            children.map((child, index) => {
              const listProps = {};
              const activeRoute = pathname === child.props.to;

              if (activeRoute) listProps.className = "active";

              return (
                <li key={index} {...listProps}>
                  {child}
                </li>
              );
            })
          ) : (
            <Loading center />
          )}
        </ul>
      );

      // Append state mod to click event
      onClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick && this.props.onClick(!active);
        this.setState({ active: !active });
      };
    } else {
      // This links to the currently active route
      // exact match, since there are no children
      this.props.to === pathname && listClasses.push("active");
    }

    const badge = _.get(this.props, "badge") ? (
      <span className="badge badge-success badge-pill float-right">
        {this.props.badge}
      </span>
    ) : null;

    return (
      <li className={listClasses.join(" ")} style={this.props.style}>
        <WaveLink
          target={this.props.external ? "_blank" : ""}
          data-intercom-target={this.props.dataIntercomTarget}
          external={this.props.external}
          to={to}
          onClick={onClick}
          className={linkClasses.join(" ")}>
          {this.props.icon && <NavIcon className={this.props.icon} />}
          <span>
            {label}
            {badge}
          </span>
          {subArrow}
        </WaveLink>
        {subMenu}
      </li>
    );
  }
}

export default withRouter(NavLink);
